import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore, arrayUnion } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from "firebase/messaging";
import { doc, updateDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD5c-Ecgxv0C5WrHNQx02lqrjrF00bkNYE",
  authDomain: "foundry-2024.firebaseapp.com",
  projectId: "foundry-2024",
  storageBucket: "foundry-2024.appspot.com",
  messagingSenderId: "608195603992",
  appId: "1:608195603992:web:eac09fbc51d9a375b89d6d",
  measurementId: "G-9ZZ740K9S9",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app, "europe-west1");
const messaging = getMessaging(app);

// Helper function to check for in-app browser
const isInAppBrowser = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return /FBAN|FBAV|Instagram|LinkedIn|Line|WhatsApp/.test(ua);
};

export const requestNotificationPermission = async () => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User must be authenticated to request notifications");
    }

    if (isInAppBrowser()) {
      return null;
    }

    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      throw new Error("Notification permission denied");
    }

    const idToken = await currentUser.getIdToken(true);

    let registration;
    if ("serviceWorker" in navigator) {
      registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js"
      );
      await navigator.serviceWorker.ready;
    }

    try {
      await deleteToken(messaging);
    } catch (e) {}

    let token = null;
    if (registration) {
      token = await getToken(messaging, {
        vapidKey:
          "BFeLhiG5h4hnSnQBVPUmSTjykGVjIeZRBbUx03rQ1B6jeOdF1VQ7eHH8eDF8I2oPGAHhJT4TWG6OOLRTTOzAwUo",
        serviceWorkerRegistration: registration,
      });
    }

    if (!token) {
      return null;
    }

    const userRef = doc(firestore, "users", currentUser.uid);
    await updateDoc(userRef, {
      fcmToken: token,
      lastTokenUpdate: new Date(),
    });

    return token;
  } catch (error) {
    return null;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    // Only set up message listener if not in in-app browser
    if (!isInAppBrowser() && "serviceWorker" in navigator) {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    } else {
      resolve(null);
    }
  });

export {
  app,
  analytics,
  auth,
  firestore,
  storage,
  functions,
  messaging,
  GoogleAuthProvider,
  signInWithPopup,
  httpsCallable,
};
