import React, { useState, useRef, useEffect, useCallback } from "react";
import imageCompression from "browser-image-compression";
import {
  addDoc,
  collection,
  serverTimestamp,
  updateDoc,
  doc,
  getDocs,
  query,
  where,
  writeBatch,
  setDoc, // Add setDoc import
  getDoc,
} from "firebase/firestore";
import { useMentions } from "../../hooks/useMentions";
import MentionDropdown from "../../components/Dropdowns/MentionDropdown";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { firestore } from "../../firebase";
import classes from "./PostModal.module.css";
import { useUpload } from "../../contexts/UploadContext";

const PostModal = ({
  onClose,
  currentUser,
  onManualClose,
  initialFile,
  canceledUploads,
  initialPost,
  onEditComplete,
  selectedStartup,
  communityId,
  setMentionedUsers,
  communityOwnerId, // Add this prop
  isCommunityPost, // Add this prop
}) => {
  // Add debug logging

  useEffect(() => {}, [selectedStartup]);
  const {
    state,
    updateUploadProgress,
    removeUploadingPost,
    registerCancelCallback,
  } = useUpload();

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState(0);
  const carouselRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [postText, setPostText] = useState(initialPost?.text || "");
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(initialPost?.content?.type || null);
  const [filePreview, setFilePreview] = useState(
    initialPost?.content?.url || null
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const textAreaRef = useRef(null);
  useEffect(() => {
    const textarea = textAreaRef.current;
    if (textarea) {
      // Height adjustment without triggering reflow
      let raf = requestAnimationFrame(() => {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;

        // Only auto-scroll if we're actively typing at the bottom of the textarea
        const cursorPosition = textarea.selectionStart;
        const lastNewlineIndex = textarea.value.lastIndexOf("\n");
        const isNearEnd = cursorPosition > lastNewlineIndex;

        // Check if textarea is focused
        const isTextareaFocused = document.activeElement === textarea;

        if (isNearEnd && isTextareaFocused) {
          const scrollableContent = document.querySelector(
            `.${classes.scrollableContent}`
          );
          if (scrollableContent) {
            scrollableContent.scrollTo({
              top: scrollableContent.scrollHeight,
              behavior: "instant",
            });
          }
        }
      });

      return () => cancelAnimationFrame(raf);
    }
  }, [postText]);
  const {
    showMentions,
    mentionSearch,
    handleInput,
    handleSelectUser,
    setShowMentions,
    mentionedUsers,
    initializeMentions,
  } = useMentions(textAreaRef);
  const [isImageHovered, setIsImageHovered] = useState(false);
  const [isFileHovered, setIsFileHovered] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState(
    initialPost?.content?.fileName || null
  );
  const [isVideoHovered, setIsVideoHovered] = useState(false);

  const [videoThumbnail, setVideoThumbnail] = useState(
    initialPost?.content?.thumbnail || null
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const isEditMode = !!initialPost;
  const [hasChanges, setHasChanges] = useState(false);
  const [multipleImages, setMultipleImages] = useState([]);

  let activeUploadTasks = {};

  window.cancelUpload = (postId) => {
    if (activeUploadTasks[postId]) {
      activeUploadTasks[postId].forEach((task) => task.cancel());
      delete activeUploadTasks[postId];
    }
  };

  const handleTouchStart = (e) => {
    if (
      e.target.closest(`.${classes.imageCloseIcon}`) ||
      e.target.closest(`.${classes.dotNavigation}`)
    ) {
      return;
    }
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe) {
      handleNextImage();
    } else if (isRightSwipe) {
      handlePrevImage();
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === multipleImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? multipleImages.length - 1 : prevIndex - 1
    );
  };

  const handleMouseDown = (e) => {
    if (
      e.target.closest(`.${classes.imageCloseIcon}`) ||
      e.target.closest(`.${classes.dotNavigation}`)
    ) {
      return;
    }
    setIsDragging(true);
    setDragStart(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const dragDistance = dragStart - e.clientX;
    if (Math.abs(dragDistance) > 50) {
      if (dragDistance > 0) {
        handleNextImage();
      } else {
        handlePrevImage();
      }
      setIsDragging(false);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleImageClick = (e) => {
    // Prevent click event when interacting with close icon or dot navigation
    if (
      e.target.closest(`.${classes.imageCloseIcon}`) ||
      e.target.closest(`.${classes.dotNavigation}`)
    ) {
      return;
    }
    handleNextImage();
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const stripHtmlTags = (html) => {
    if (!html) return "";
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return (tmp.textContent || tmp.innerText || "").trim();
  };

  useEffect(() => {
    if (initialPost) {
      // Extract mentions from the HTML
      const mentionRegex =
        /<a href="\/([^"]+)" class="mentionText">([^<]+)<\/a>/g;
      const matches = [...initialPost.text.matchAll(mentionRegex)];

      // Get the clean text
      const parser = new DOMParser();
      const doc = parser.parseFromString(initialPost.text, "text/html");
      const plainText = doc.body.textContent || "";

      // Set the clean text
      setPostText(plainText);

      // Initialize mentions
      const newMentions = {};
      matches.forEach((match) => {
        const urlSlug = match[1]; // The URL slug
        const fullName = match[2]; // The display name
        newMentions[fullName] = { urlSlug };
      });

      // Use the new function to initialize mentions
      initializeMentions(newMentions);

      // Handle file content...
      if (initialPost.content) {
        setFilePreview(
          Array.isArray(initialPost.content.url)
            ? initialPost.content.url
            : [initialPost.content.url]
        );

        setFileType(initialPost.content.type);

        if (initialPost.content.type === "video") {
          setFileName(removeFileExtension(initialPost.content.fileName));
        } else {
          setFileName(initialPost.content.fileName);
        }

        if (initialPost.content.type === "image") {
          setMultipleImages(
            Array.isArray(initialPost.content.url)
              ? initialPost.content.url
              : [initialPost.content.url]
          );
        }
      }
    }
  }, [initialPost, initializeMentions]);

  useEffect(() => {
    if (isEditMode) {
      setHasChanges(postText.trim() !== initialPost.text.trim());
    }
  }, [postText, initialPost, isEditMode]);

  const renderFilePreview = () => {
    if (isProcessing) {
      return (
        <div className={classes.uploadingPreview}>
          <span className={`material-symbols-outlined ${classes.loadingIcon}`}>
            progress_activity
          </span>
        </div>
      );
    }

    if (!filePreview && !initialPost?.content) return null;

    return (
      <FilePreview
        fileType={fileType || initialPost?.content?.type}
        filePreview={filePreview || initialPost?.content?.url}
        fileName={fileName || initialPost?.content?.fileName}
        isUploading={isUploading}
        multipleImages={multipleImages}
        currentImageIndex={currentImageIndex}
        handleImageChange={handleImageChange}
        videoThumbnail={videoThumbnail || initialPost?.content?.thumbnail}
        onRemove={handleRemoveFile}
      />
    );
  };

  const renderInitialPostContent = () => {
    if (!initialPost || !initialPost.content) return null;

    switch (initialPost.content.type) {
      case "image":
        return (
          <img
            src={initialPost.content.url}
            alt="Post content"
            className={classes.imagePreview}
          />
        );
      case "video":
        return (
          <video
            src={initialPost.content.url}
            className={classes.videoPreview}
            controls
          />
        );
      case "pdf":
        return (
          <div className={classes.filePreview}>
            <svg
              className={classes.pdfIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="orangered"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.75"
                d="M19 10V4a1 1 0 0 0-1-1H9.914a1 1 0 0 0-.707.293L5.293 7.207A1 1 0 0 0 5 7.914V20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2M10 3v4a1 1 0 0 1-1 1H5m5 6h9m0 0-2-2m2 2-2 2"
              />
            </svg>
            <span>{initialPost.content.fileName}</span>
          </div>
        );
      default:
        return null;
    }
  };

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const removeFileExtension = (fileName) => {
    if (typeof fileName !== "string") {
      return fileName; // Return the original value if it's not a string
    }
    return fileName.replace(/\.[^/.]+$/, "");
  };

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Restore scroll position
      window.scrollTo(0, scrollY);
    };
  }, []);

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose(false);
    }
    setMouseDownOnBackdrop(false);
  };

  const getIconColor = (iconType) => {
    if (!file) return "gray";
    if (
      iconType === "image" &&
      (fileType === "image" || fileType === "video")
    ) {
      return "orangered";
    }
    if (iconType === "file" && fileType === "pdf") {
      return "orangered";
    }
    return "gray";
  };

  const handleImageChange = (index) => {
    setCurrentImageIndex(index);
  };

  const scrollToImage = (index) => {
    if (carouselRef.current) {
      const scrollPosition = index * carouselRef.current.offsetWidth;
      carouselRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (
      initialPost &&
      initialPost.content &&
      initialPost.content.type === "video"
    ) {
      setFileName(removeFileExtension(initialPost.content.fileName));
    }
  }, [initialPost]);

  useEffect(() => {
    scrollToImage(currentImageIndex);
  }, [currentImageIndex]);

  const handleRemoveFile = () => {
    setFile(null);
    setFileType(null);
    setFilePreview(null);
    setFileName(null);
    setVideoThumbnail(null);
    setMultipleImages([]);
    resetFileInput();
  };

  const handleRemoveImage = (index) => {
    setMultipleImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setFile((prevFiles) =>
      Array.isArray(prevFiles) ? prevFiles.filter((_, i) => i !== index) : null
    );
    setFileName((prevNames) =>
      Array.isArray(prevNames) ? prevNames.filter((_, i) => i !== index) : null
    );

    if (multipleImages.length === 1) {
      // If it's the last image, clear all image-related states
      handleRemoveFile();
    } else if (index === currentImageIndex) {
      // If the removed image is the current one, adjust the currentImageIndex
      setCurrentImageIndex((prev) => (prev > 0 ? prev - 1 : 0));
    }

    resetFileInput();
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const PDFPreview = React.memo(
    ({ content, hasInteractions, classes }) => {
      const getFileName = React.useCallback(() => {
        if (!content?.fileName) return "Unnamed PDF";
        const rawFileName = Array.isArray(content.fileName)
          ? content.fileName[0] || "Unnamed PDF"
          : content.fileName;
        return rawFileName.replace(/\.pdf$/i, "");
      }, [content?.fileName]);

      if (!content || !content.url) return null;

      return (
        <div className={classes.postContentWrapper}>
          <a
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              !hasInteractions ? classes.pdfPreviewNoInteractions : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <div className={classes.fileContainer}>
                <div className={classes.fileAbout}>
                  <div className={classes.fileName}>{getFileName()}</div>
                </div>
                <div className={classes.open}>
                  Open PDF
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.content?.url === nextProps.content?.url &&
        prevProps.content?.fileName === nextProps.content?.fileName &&
        prevProps.hasInteractions === nextProps.hasInteractions
      );
    }
  );

  const FilePreview = ({
    fileType,
    filePreview,
    fileName,
    isUploading,
    multipleImages,
    currentImageIndex,
    handleImageChange,
    videoThumbnail,
    onRemove,
  }) => {
    if (isUploading) {
      return (
        <div className={classes.uploadingPreview}>
          <span className={`material-symbols-outlined ${classes.loadingIcon}`}>
            progress_activity
          </span>
        </div>
      );
    }

    switch (fileType) {
      case "image":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.imageCarousel}>
              {multipleImages.length > 1 && (
                <div className={classes.imageCounter}>
                  <div className={classes.imageCounterInner}>
                    {`${currentImageIndex + 1}/${multipleImages.length}`}
                  </div>
                </div>
              )}
              {multipleImages.map((img, index) => (
                <div
                  key={index}
                  className={`${classes.imageWrapper} ${
                    index === currentImageIndex ? classes.activeImage : ""
                  }`}
                >
                  <img
                    src={img}
                    alt={`Preview ${index + 1}`}
                    className={classes.postContentImage}
                  />
                  {!isEditMode && (
                    <button
                      className={classes.imageCloseIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveImage(index);
                      }}
                      aria-label="Remove image"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                    </button>
                  )}
                </div>
              ))}
              {multipleImages.length > 1 && (
                <div className={classes.navigationButtons}>
                  <div
                    className={classes.navButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePrevImage(e);
                    }}
                    aria-label="Previous image"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m14 8-4 4 4 4"
                      />
                    </svg>
                  </div>
                  <div
                    className={classes.navButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleNextImage(multipleImages.length, e);
                    }}
                    aria-label="Next image"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m10 16 4-4-4-4"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      case "video":
        return (
          <div className={classes.videoPreviewWrapper}>
            {!isEditMode && (
              <button
                className={classes.imageCloseIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove();
                }}
                aria-label="Remove video"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            )}
            <div className={classes.pdfPreview}>
              <div className={classes.pdfBox}>
                <div className={classes.fileContainer}>
                  <div className={classes.fileAbout}>
                    <div className={classes.fileName}>{fileName}</div>
                    <div className={classes.open}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387 4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Video viewable once posted.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "pdf":
        return (
          <div className={classes.pdfPreviewWrapper}>
            {/* {!isEditMode && (
              <button
                className={classes.imageCloseIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove();
                }}
                aria-label="Remove PDF"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            )} */}
            <div className={classes.pdfPreview}>
              <div className={classes.pdfBox}>
                <div className={classes.fileContainer}>
                  <div className={classes.fileAbout}>
                    <div className={classes.fileName}>{fileName}</div>
                    <div className={classes.open}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      PDF ready to post.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1, // Set to your desired max file size
      maxWidthOrHeight: 1920, // Set to your desired max width/height
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      return compressedFile;
    } catch (error) {
      return file; // Return original file if compression fails
    }
  };

  const VideoIcon = ({ isHovered }) => (
    <svg
      className={classes.videoIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={isHovered ? "gray" : "none"}
      viewBox="0 0 24 24"
    >
      {isHovered ? (
        <path
          fillRule="evenodd"
          d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387 4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z"
          clipRule="evenodd"
        />
      ) : (
        <path
          stroke="gray"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 6H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm7 11-6-2V9l6-2v10Z"
        />
      )}
    </svg>
  );

  const ImageIcon = ({ isHovered }) => (
    <svg
      className={classes.imageIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={isHovered ? "gray" : "none"}
      viewBox="0 0 24 24"
    >
      {isHovered ? (
        <path
          fillRule="evenodd"
          d="M7.5 4.586A2 2 0 0 1 8.914 4h6.172a2 2 0 0 1 1.414.586L17.914 6H19a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.086L7.5 4.586ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
          clipRule="evenodd"
        />
      ) : (
        <>
          <path
            stroke="gray"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 18V8a1 1 0 0 1 1-1h1.5l1.707-1.707A1 1 0 0 1 8.914 5h6.172a1 1 0 0 1 .707.293L17.5 7H19a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Z"
          />
          <path
            stroke="gray"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </>
      )}
    </svg>
  );

  const FileIcon = ({ isHovered }) => (
    <svg
      className={classes.fileIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="gray"
      viewBox="0 0 24 24"
    >
      {isHovered ? (
        <path
          fillRule="evenodd"
          d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
          clipRule="evenodd"
        />
      ) : (
        <path
          stroke="gray"
          strokeLinejoin="round"
          strokeWidth="2"
          fill="none"
          d="M5 17v-5h1.5a1.5 1.5 0 1 1 0 3H5m12 2v-5h2m-2 3h2M5 10V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1v6M5 19v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1M10 3v4a1 1 0 0 1-1 1H5m6 4v5h1.375A1.627 1.627 0 0 0 14 15.375v-1.75A1.627 1.627 0 0 0 12.375 12H11Z"
        />
      )}
    </svg>
  );

  const handlePostTextChange = (e) => {
    if (e.target.value.length <= 2500) {
      handleInput(e); // Handle mentions
      setPostText(e.target.value);
    }
  };

  const handleFileUpload = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      setIsProcessing(true);
      setIsUploading(true);
      setFile(null);
      setFileType(null);
      setFilePreview(null);

      try {
        if (selectedFiles[0].type.startsWith("image/")) {
          if (selectedFiles.length > 10) {
            setError("You can upload a maximum of 10 images.");
            return;
          }

          const compressedImages = await Promise.all(
            selectedFiles.map(async (img) => await compressImage(img))
          );

          setFile(compressedImages);
          setFileType("image");
          setFileName(compressedImages.map((img) => img.name));

          const previews = await Promise.all(
            compressedImages.map((img) => {
              return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(img);
              });
            })
          );

          setMultipleImages(previews);
          setFilePreview(previews[0]);
        } else if (selectedFiles[0].type.startsWith("video/")) {
          let fileToUse = selectedFiles[0];
          setFile(fileToUse);
          setFileType("video");
          setFileName(removeFileExtension(fileToUse.name));

          const reader = new FileReader();
          reader.onloadend = () => {
            setFilePreview(reader.result);
          };
          reader.readAsDataURL(fileToUse);

          const thumbnailBlob = await generateVideoThumbnail(fileToUse);
          if (thumbnailBlob) {
            const thumbnailUrl = URL.createObjectURL(thumbnailBlob);
            setVideoThumbnail(thumbnailUrl);
          }
        } else if (selectedFiles[0].type === "application/pdf") {
          setFile(selectedFiles[0]);
          setFileType("pdf");
          // Remove .pdf extension from the file name
          const fileNameWithoutExtension = removeFileExtension(
            selectedFiles[0].name
          );
          setFileName(fileNameWithoutExtension);
          setFilePreview(URL.createObjectURL(selectedFiles[0]));
        } else {
          throw new Error("Unsupported file type");
        }
      } catch (error) {
        setError("Failed to process the file. Please try again.");
        resetFileInput();
      } finally {
        setIsProcessing(false);
        setIsUploading(false);
      }
    }
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.playsInline = true;
      video.muted = true;

      video.onloadedmetadata = () => {
        video.currentTime = 1; // Set to 1 second to avoid potential black frames at the start
      };

      video.onseeked = () => {
        const canvas = document.createElement("canvas");
        const aspectRatio = video.videoWidth / video.videoHeight;
        let width = 320;
        let height = 320 / aspectRatio;

        // If height is greater than 240, set height to 240 and adjust width
        if (height > 240) {
          height = 240;
          width = 240 * aspectRatio;
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(video, 0, 0, width, height);
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.7
        );
      };

      video.onerror = () => {
        resolve(null);
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const handleFileClick = (type) => {
    if (fileInputRef.current) {
      resetFileInput(); // Reset the file input before opening the file dialog
      if (type === "image") {
        fileInputRef.current.setAttribute("accept", "image/*");
        fileInputRef.current.setAttribute("multiple", "true");
      } else if (type === "pdf") {
        fileInputRef.current.setAttribute("accept", ".pdf");
        fileInputRef.current.removeAttribute("multiple");
      } else if (type === "video") {
        fileInputRef.current.setAttribute("accept", "video/*");
        fileInputRef.current.removeAttribute("multiple");
      }
      fileInputRef.current.click();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      let processedText = postText.trim();

      Object.entries(mentionedUsers).forEach(([name, user]) => {
        console.log("User data:", {
          name,
          urlSlug: user.urlSlug,
          isStartup: user.isStartup,
        });
        const escapedName = name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedName, "g");
        processedText = processedText.replace(
          regex,
          `<a href="${
            user.isStartup ? `/${user.urlSlug}` : `/${user.urlSlug}`
          }" class="mentionText">${name}</a>`
        );
      });

      if (isEditMode) {
        if (communityId && communityOwnerId && initialPost.communityPostId) {
          let postRef;

          if (initialPost.user.isStartup) {
            postRef = doc(
              firestore,
              `users/${initialPost.user.startupOwnerId}/startups/${initialPost.user.startupId}/communityPosts/${initialPost.id}`
            );
          } else {
            postRef = doc(
              firestore,
              `users/${initialPost.user.id}/communityPosts/${initialPost.id}`
            );
          }

          const communityPostRef = doc(
            firestore,
            `users/${communityOwnerId}/communities/${communityId}/posts/${initialPost.communityPostId}`
          );

          await Promise.all([
            updateDoc(postRef, {
              text: processedText,
              isEdited: true,
              lastEditedAt: serverTimestamp(),
            }),
            updateDoc(communityPostRef, {
              lastUpdated: serverTimestamp(),
            }),
          ]);

          if (Object.keys(mentionedUsers).length > 0) {
            const batch = writeBatch(firestore);

            Object.values(mentionedUsers).forEach((user) => {
              // Add self-mention checks
              const isSelfMention = user.id === currentUser.uid;
              const isOwnedStartup =
                user.isStartup && user.startupOwnerId === currentUser.uid;
              const isOwnedCommunity =
                user.isCommunity && user.communityOwnerId === currentUser.uid;
              const isOwnedEvent =
                user.isEvent && user.eventOwnerId === currentUser.uid;

              const wasAlreadyMentioned = initialPost.text.includes(
                `href="/${user.urlSlug}"`
              );

              if (
                !wasAlreadyMentioned &&
                !isSelfMention &&
                !isOwnedStartup &&
                !isOwnedCommunity &&
                !isOwnedEvent
              ) {
                const recipientId = user.isCommunity
                  ? user.communityOwnerId
                  : user.isEvent
                  ? user.eventOwnerId
                  : user.isStartup
                  ? user.startupOwnerId
                  : user.id;

                const notificationRef = doc(
                  collection(firestore, `users/${recipientId}/notifications`)
                );
                batch.set(notificationRef, {
                  type: "mention",
                  from: currentUser.uid,
                  fromUserName: initialPost.user.isStartup
                    ? initialPost.user.firstName
                    : `${userData.firstName} ${userData.lastName}`,
                  fromUserImage: initialPost.user.isStartup
                    ? initialPost.user.profileImage
                    : userData.profileImage,
                  postId: initialPost.id,
                  ...(initialPost.user.isStartup && {
                    startupId: initialPost.user.startupId,
                    startupOwnerId: initialPost.user.startupOwnerId,
                    isStartup: true,
                    startupName: initialPost.user.firstName,
                    startupImage: initialPost.user.profileImage,
                  }),
                  ...(user.isStartup && {
                    mentionedStartupId: user.startupId,
                    mentionedStartupOwnerId: user.startupOwnerId,
                  }),
                  ...(user.isCommunity && {
                    mentionedCommunityId: user.communityId,
                    mentionedCommunityOwnerId: user.communityOwnerId,
                  }),
                  ...(user.isEvent && {
                    mentionedEventId: user.eventId,
                    mentionedEventOwnerId: user.eventOwnerId,
                  }),
                  postPreview: {
                    text: processedText,
                    mediaType: initialPost.content?.type || null,
                    mediaUrl: initialPost.content?.url || null,
                    thumbnail: initialPost.content?.thumbnail || null,
                    fileName: initialPost.content?.fileName || null,
                  },
                  createdAt: serverTimestamp(),
                  isNew: true,
                });
              }
            });

            await batch.commit();
          }

          onEditComplete(processedText, true);
          return;
        }

        let postRef;
        if (initialPost.user.isStartup) {
          postRef = doc(
            firestore,
            `users/${initialPost.user.startupOwnerId}/startups/${initialPost.user.startupId}/posts/${initialPost.id}`
          );
        } else {
          postRef = doc(
            firestore,
            `users/${initialPost.user.id}/posts/${initialPost.id}`
          );
        }

        const updateData = {
          text: processedText,
          isEdited: true,
        };

        await updateDoc(postRef, updateData);

        if (Object.keys(mentionedUsers).length > 0) {
          const batch = writeBatch(firestore);

          Object.values(mentionedUsers).forEach((user) => {
            // Add self-mention checks
            const isSelfMention = user.id === currentUser.uid;
            const isOwnedStartup =
              user.isStartup && user.startupOwnerId === currentUser.uid;
            const isOwnedCommunity =
              user.isCommunity && user.communityOwnerId === currentUser.uid;
            const isOwnedEvent =
              user.isEvent && user.eventOwnerId === currentUser.uid;

            const wasAlreadyMentioned = initialPost.text.includes(
              `href="/${user.urlSlug}"`
            );

            if (
              !wasAlreadyMentioned &&
              !isSelfMention &&
              !isOwnedStartup &&
              !isOwnedCommunity &&
              !isOwnedEvent
            ) {
              const recipientId = user.isCommunity
                ? user.communityOwnerId
                : user.isEvent
                ? user.eventOwnerId
                : user.isStartup
                ? user.startupOwnerId
                : user.id;

              const notificationRef = doc(
                collection(firestore, `users/${recipientId}/notifications`)
              );
              batch.set(notificationRef, {
                type: "mention",
                from: currentUser.uid,
                fromUserName: initialPost.user.isStartup
                  ? initialPost.user.firstName
                  : `${userData.firstName} ${userData.lastName}`,
                fromUserImage: initialPost.user.isStartup
                  ? initialPost.user.profileImage
                  : userData.profileImage,
                postId: initialPost.id,
                ...(initialPost.user.isStartup && {
                  startupId: initialPost.user.startupId,
                  startupOwnerId: initialPost.user.startupOwnerId,
                  isStartup: true,
                  startupName: initialPost.user.firstName,
                  startupImage: initialPost.user.profileImage,
                }),
                ...(user.isStartup && {
                  mentionedStartupId: user.startupId,
                  mentionedStartupOwnerId: user.startupOwnerId,
                }),
                ...(user.isCommunity && {
                  mentionedCommunityId: user.communityId,
                  mentionedCommunityOwnerId: user.communityOwnerId,
                }),
                ...(user.isEvent && {
                  mentionedEventId: user.eventId,
                  mentionedEventOwnerId: user.eventOwnerId,
                }),
                postPreview: {
                  text: processedText,
                  mediaType: initialPost.content?.type || null,
                  mediaUrl: initialPost.content?.url || null,
                  thumbnail: initialPost.content?.thumbnail || null,
                  fileName: initialPost.content?.fileName || null,
                },
                createdAt: serverTimestamp(),
                isNew: true,
              });
            }
          });

          await batch.commit();
        }

        onEditComplete(processedText, true);
        return;
      }

      const timestamp = serverTimestamp();
      const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
      const userData = userDoc.exists() ? userDoc.data() : {};

      const postData = {
        text: processedText,
        createdAt: timestamp,
        latestActivity: timestamp,
        userId: currentUser.uid,
        user: selectedStartup
          ? {
              id: `startup_${selectedStartup.id}`,
              firstName: selectedStartup.startupName,
              lastName: "",
              profileImage: selectedStartup.startupImage,
              bio: selectedStartup.bio || "",
              link: selectedStartup.link || null,
              linkText: selectedStartup.linkText || null,
              connectionType: "You",
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
            }
          : {
              id: currentUser.uid,
              firstName: userData.firstName || "",
              lastName: userData.lastName || "",
              profileImage: userData.profileImage || null,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              connectionType: "You",
            },
        content: file
          ? {
              type: "pending",
              fileName: Array.isArray(file)
                ? file.map((f) => f.name)
                : file.name,
            }
          : null,
        isEdited: false,
      };

      if (selectedStartup) {
        postData.isStartup = true;
        postData.startupId = selectedStartup.id;
        postData.startupOwnerId = currentUser.uid;
        postData.originalPosterId = currentUser.uid;
      }

      let newPostRef;

      if (communityId && communityOwnerId) {
        const communityRef = doc(
          firestore,
          `users/${communityOwnerId}/communities/${communityId}`
        );
        const communitySnapshot = await getDoc(communityRef);
        const communityDocData = communitySnapshot.data();

        postData.communityId = communityId;
        postData.communityData = {
          id: communityId,
          name: communityDocData.communityName,
          image: communityDocData.communityImage,
          ownerId: communityOwnerId,
        };

        const communityPostRef = await addDoc(
          collection(
            firestore,
            `users/${communityOwnerId}/communities/${communityId}/posts`
          ),
          {
            postId: null,
            userId: currentUser.uid,
            createdAt: timestamp,
            isStartup: !!selectedStartup,
            ...(selectedStartup && { startupId: selectedStartup.id }),
          }
        );

        if (selectedStartup) {
          newPostRef = await addDoc(
            collection(
              firestore,
              `users/${currentUser.uid}/startups/${selectedStartup.id}/communityPosts`
            ),
            {
              ...postData,
              communityPostId: communityPostRef.id,
            }
          );
        } else {
          newPostRef = await addDoc(
            collection(firestore, `users/${currentUser.uid}/communityPosts`),
            {
              ...postData,
              communityPostId: communityPostRef.id,
            }
          );
        }

        await updateDoc(communityPostRef, {
          postId: newPostRef.id,
        });
      } else {
        if (selectedStartup) {
          newPostRef = await addDoc(
            collection(
              firestore,
              `users/${currentUser.uid}/startups/${selectedStartup.id}/posts`
            ),
            postData
          );
        } else {
          newPostRef = await addDoc(
            collection(firestore, `users/${currentUser.uid}/posts`),
            postData
          );
        }
      }

      if (Object.keys(mentionedUsers).length > 0) {
        const batch = writeBatch(firestore);

        Object.values(mentionedUsers).forEach((user) => {
          // Add checks for self-mentions and owned content
          const isSelfMention = user.id === currentUser.uid;
          const isOwnedStartup =
            user.isStartup && user.startupOwnerId === currentUser.uid;
          const isOwnedCommunity =
            user.isCommunity && user.communityOwnerId === currentUser.uid;
          const isOwnedEvent =
            user.isEvent && user.eventOwnerId === currentUser.uid;

          // Only create notification if it's not a self-mention or owned content
          if (
            !isSelfMention &&
            !isOwnedStartup &&
            !isOwnedCommunity &&
            !isOwnedEvent
          ) {
            const recipientId = user.isCommunity
              ? user.communityOwnerId
              : user.isEvent
              ? user.eventOwnerId
              : user.isStartup
              ? user.startupOwnerId
              : user.id;

            const notificationRef = doc(
              collection(firestore, `users/${recipientId}/notifications`)
            );

            batch.set(notificationRef, {
              type: "mention",
              from: currentUser.uid,
              fromUserName: selectedStartup
                ? selectedStartup.startupName
                : `${userData.firstName} ${userData.lastName}`,
              fromUserImage: selectedStartup
                ? selectedStartup.startupImage
                : userData.profileImage,
              postId: newPostRef.id,
              ...(selectedStartup && {
                startupId: selectedStartup.id,
                startupOwnerId: currentUser.uid,
                isStartup: true,
                startupName: selectedStartup.startupName,
                startupImage: selectedStartup.startupImage,
              }),
              ...(user.isStartup && {
                mentionedStartupId: user.startupId,
                mentionedStartupOwnerId: user.startupOwnerId,
              }),
              ...(user.isCommunity && {
                mentionedCommunityId: user.communityId,
                mentionedCommunityOwnerId: user.communityOwnerId,
              }),
              ...(user.isEvent && {
                mentionedEventId: user.eventId,
                mentionedEventOwnerId: user.eventOwnerId,
              }),
              postPreview: {
                text: processedText,
                mediaType: fileType || null,
                mediaUrl: file ? null : null,
                thumbnail: null,
                fileName: null,
              },
              createdAt: serverTimestamp(),
              isNew: true,
            });
          }
        });

        await batch.commit();
      }

      if (file) {
        uploadFileAndUpdatePost(file, newPostRef.id);
        onClose(true, newPostRef.id, true, 0, false, canceledUploads);
      } else {
        onClose(true, newPostRef.id, false, 100, true, canceledUploads);
      }
    } catch (error) {
      setError("Failed to create post. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const uploadFileToStorage = useCallback(
    async (file, postId, index, progressCallback) => {
      const storage = getStorage();
      const fileName = file.name || `image_${index}.jpg`;

      let storagePath;
      if (communityId) {
        storagePath = selectedStartup
          ? `communityContent/${communityId}/${currentUser.uid}/${selectedStartup.id}/${postId}/${fileName}`
          : `communityContent/${communityId}/${currentUser.uid}/${postId}/${fileName}`;
      } else {
        storagePath = selectedStartup
          ? `startupImages/${currentUser.uid}/${selectedStartup.id}/${postId}/${fileName}`
          : `postContent/${currentUser.uid}/${postId}/${fileName}`;
      }

      const fileRef = ref(storage, storagePath);

      return new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(fileRef, file);

        registerCancelCallback(postId, () => {
          uploadTask.cancel();
          reject(new Error("Upload canceled"));
        });

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progressCallback(progress);
          },
          (error) => {
            if (error.code === "storage/canceled") {
            } else {
            }
            reject(error);
          },
          async () => {
            if (!state.cancelledPosts[postId]) {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            } else {
              reject(new Error("Upload canceled"));
            }
          }
        );
      });
    },
    [
      currentUser.uid,
      communityId,
      selectedStartup,
      registerCancelCallback,
      state.cancelledPosts,
    ]
  );

  const uploadThumbnail = async (thumbnailBlob, postId) => {
    if (!thumbnailBlob) {
      return null;
    }

    const storage = getStorage();
    const thumbnailPath = selectedStartup
      ? `startupImages/${currentUser.uid}/${selectedStartup.id}/${postId}/thumbnail.jpg`
      : `postContent/${currentUser.uid}/${postId}/thumbnail.jpg`;

    const thumbnailRef = ref(storage, thumbnailPath);

    try {
      const uploadTask = await uploadBytesResumable(
        thumbnailRef,
        thumbnailBlob
      );
      const downloadURL = await getDownloadURL(uploadTask.ref);
      return downloadURL;
    } catch (error) {
      return null;
    }
  };

  const stripMentionHtml = (text) => {
    return text.replace(
      /<a href="\/[^"]*" class="mentionText">([^<]*)<\/a>/g,
      "$1" // Replace HTML with just the mention name
    );
  };

  const getMentionHighlightedContent = () => {
    let content = postText;

    // First handle any existing HTML mentions in the text
    content = content.replace(
      /<a[^>]*>(.*?)<\/a>/g,
      (match, name) =>
        `<span class="mention" style="font-weight: 900 !important;">${name}</span>`
    );

    // Then handle any new mentions
    Object.entries(mentionedUsers).forEach(([name, user]) => {
      const escapedName = name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const regex = new RegExp(escapedName, "g");
      content = content.replace(
        regex,
        `<span class="mention" style="font-weight: 900 !important;">${name}</span>`
      );
    });

    return content;
  };

  const uploadFileAndUpdatePost = useCallback(
    async (file, postId) => {
      try {
        if (state.cancelledPosts[postId]) {
          throw new Error("Upload canceled");
        }

        let fileUrls = [];
        let thumbnailUrl = null;
        let fileNames = [];
        let finalFileType = fileType;

        if (Array.isArray(file)) {
          const uploadPromises = file.map((f, index) =>
            uploadFileToStorage(f, postId, index, (progress) => {
              if (state.cancelledPosts[postId]) {
                throw new Error("Upload canceled");
              }
              updateUploadProgress(postId, progress, "uploading");
            })
          );

          fileUrls = await Promise.all(uploadPromises);
          fileNames = file.map((f) => f.name);
        } else if (file) {
          const url = await uploadFileToStorage(file, postId, 0, (progress) => {
            if (state.cancelledPosts[postId]) {
              throw new Error("Upload canceled");
            }
            updateUploadProgress(postId, progress, "uploading");
          });

          fileUrls = [url];
          fileNames = [file.name];

          if (file.type.startsWith("video/")) {
            const thumbnailBlob = await generateVideoThumbnail(file);
            if (thumbnailBlob) {
              thumbnailUrl = await uploadThumbnail(thumbnailBlob, postId);
            }
          }
        }

        if (state.cancelledPosts[postId]) {
          throw new Error("Upload canceled");
        }

        // Determine the correct path for the post update
        let postRef;
        if (communityId) {
          // For community posts, update the post in the user's or startup's communityPosts collection
          if (selectedStartup) {
            postRef = doc(
              firestore,
              `users/${currentUser.uid}/startups/${selectedStartup.id}/communityPosts/${postId}`
            );
          } else {
            postRef = doc(
              firestore,
              `users/${currentUser.uid}/communityPosts/${postId}`
            );
          }
        } else {
          // For regular posts
          postRef = selectedStartup
            ? doc(
                firestore,
                `users/${currentUser.uid}/startups/${selectedStartup.id}/posts/${postId}`
              )
            : doc(firestore, `users/${currentUser.uid}/posts/${postId}`);
        }

        if (fileUrls.length > 0) {
          const contentUpdate = {
            type: finalFileType,
            fileName: fileNames.length === 1 ? fileNames[0] : fileNames,
            url: fileUrls.length === 1 ? fileUrls[0] : fileUrls,
          };

          if (thumbnailUrl) {
            contentUpdate.thumbnail = thumbnailUrl;
          }

          await updateDoc(postRef, {
            content: contentUpdate,
            latestActivity: serverTimestamp(),
          });

          onClose(true, postId, false, 100, true);
        } else {
          onClose(true, postId, false, 100, true);
        }
      } catch (error) {
        onClose(false, postId, false, 0, false);
      } finally {
        removeUploadingPost(postId);
      }
    },
    [
      state.cancelledPosts,
      updateUploadProgress,
      removeUploadingPost,
      onClose,
      uploadFileToStorage,
      generateVideoThumbnail,
      uploadThumbnail,
      fileType,
      currentUser.uid,
      selectedStartup,
      communityId,
    ]
  );

  // useEffect(() => {
  //   const scrollableContent = document.querySelector(
  //     `.${classes.scrollableContent}`
  //   );
  //   if (scrollableContent && showMentions) {
  //     const handleScroll = () => {
  //       // Force a re-render to update position
  //       setShowMentions(false);
  //       setTimeout(() => setShowMentions(true), 0);
  //     };

  //     scrollableContent.addEventListener("scroll", handleScroll);
  //     return () =>
  //       scrollableContent.removeEventListener("scroll", handleScroll);
  //   }
  // }, [showMentions]);

  useEffect(() => {
    const textarea = textAreaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [postText]);

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            {isEditMode ? "Edit" : "What's new?"}
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>

        <div className={classes.scrollableContent}>
          <form>
            <div className={classes.textAreaWrapper}>
              <textarea
                ref={textAreaRef}
                value={postText}
                onChange={handlePostTextChange}
                className={classes.textArea}
                placeholder="Share something..."
              />
              <div
                className={classes.textOverlay}
                dangerouslySetInnerHTML={{
                  __html: getMentionHighlightedContent(),
                }}
                onClick={(e) => {
                  // If clicked on a mention, prevent textarea interaction
                  if (e.target.closest('span[style*="font-weight: 900"]')) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              />
              {showMentions && (
                <MentionDropdown
                  searchText={mentionSearch}
                  inputElement={textAreaRef.current}
                  onSelectUser={(user) => handleSelectUser(user, setPostText)}
                  onClose={() => setShowMentions(false)}
                  positionOverride={{
                    position: "fixed",
                    bottom: `${
                      window.innerHeight -
                      document
                        .querySelector(`.${classes.cardFooter}`)
                        ?.getBoundingClientRect().top
                    }px`,
                    left:
                      document
                        .querySelector(`.${classes.modalContent}`)
                        ?.getBoundingClientRect().left + 16, // 16px for padding
                    width:
                      document.querySelector(`.${classes.modalContent}`)
                        ?.offsetWidth - 32, // Subtract padding

                    zIndex: 10000,
                  }}
                />
              )}
            </div>
            {renderFilePreview()}
            {error && <p className={classes.error}>{error}</p>}
          </form>
        </div>

        <div className={classes.fileUploadIcons}>
          <div className={classes.fileIconsWrapper}>
            {!isEditMode && !isCommunityPost && (
              <div className={classes.fileIcons}>
                <div
                  onMouseEnter={() => setIsImageHovered(true)}
                  onMouseLeave={() => setIsImageHovered(false)}
                  onClick={() => handleFileClick("image")}
                >
                  <ImageIcon isHovered={isImageHovered} />
                </div>
                <div
                  onMouseEnter={() => setIsVideoHovered(true)}
                  onMouseLeave={() => setIsVideoHovered(false)}
                  onClick={() => handleFileClick("video")}
                >
                  <VideoIcon isHovered={isVideoHovered} />
                </div>
                <div
                  className={classes.icon}
                  onMouseEnter={() => setIsFileHovered(true)}
                  onMouseLeave={() => setIsFileHovered(false)}
                  onClick={() => handleFileClick("pdf")}
                >
                  <FileIcon isHovered={isFileHovered} />
                </div>
              </div>
            )}
          </div>
          <div className={classes.charCount}>{postText.length}/2500</div>
        </div>

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />

        <div className={classes.cardFooter}>
          <button
            type="submit"
            className={`${classes.button} ${loading ? classes.loading : ""}`}
            disabled={
              loading ||
              (!postText.trim() && !file) ||
              (isEditMode && !hasChanges)
            }
            onClick={handleSubmit}
          >
            {loading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : isEditMode ? (
              "Save"
            ) : (
              "Share"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostModal;
