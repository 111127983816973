import { useState } from "react";
import classes from "./Ad.module.css";

const Ad = () => {
  const [shareButtonText, setShareButtonText] = useState("Share");

  // All brands from Affiliates.js
  const ads = [
    {
      name: "Brevo",
      icon: "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/brevo.jpg?alt=media&token=a15a0cc4-f4d8-45a2-bf31-5cb7e35c4e9f",
      link: "https://get.brevo.com/qq7m3lhqb6bl",
      adText: "Smart emails that convert",
      company: "Brevo",
    },
    {
      name: "ClickUp",
      icon: "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/clickup.jpg?alt=media&token=efdad85a-9558-403b-b37a-1acaa58f1ac6",
      link: "https://try.web.clickup.com/5rbffbnys91v",
      adText: "Your team's productivity hub",
      company: "ClickUp",
    },
    {
      name: "Notion",
      icon: "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/notion-logo.png?alt=media&token=002694cc-c186-42fb-9aee-80a157624121",
      link: "https://affiliate.notion.so/mvw80dbxrh8s",
      adText: "The all-in-one workspace",
      company: "Notion",
    },
    {
      name: "Printify",
      icon: "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/printify.jpg?alt=media&token=371afe5c-6367-4c7e-b335-a15a7e02f22b",
      link: "https://try.printify.com/mh1ivpf8saoo",
      adText: "Turn designs into merch",
      company: "Printify",
    },
    {
      name: "Melio",
      icon: "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/melio.jpeg?alt=media&token=1f77a088-f644-4a75-8cc2-aa92f22f0f56",
      link: "https://affiliates.meliopayments.com/bxd42sj1tmul",
      adText: "Startup payments made easy",
      company: "Melio",
    },
  ];

  // Select one random ad on component initialization
  const [currentAd] = useState(() => {
    const randomIndex = Math.floor(Math.random() * ads.length);
    return ads[randomIndex];
  });

  const handleShare = () => {
    navigator.clipboard
      .writeText("foundry.bz")
      .then(() => {
        setShareButtonText("Copied");
        setTimeout(() => {
          setShareButtonText("Share");
        }, 5000);
      })
      .catch((err) => {});
  };

  return (
    <div className={classes.card}>
      <div className={classes.alphaContent}>
        <div className={classes.message}>
          <span>Promoted</span>
          <a href="/partnerships" target="_blank" rel="noopener noreferrer">
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className={classes.cardContent}>
        <a href={currentAd.link} target="_blank" rel="noopener noreferrer">
          <img
            className={classes.icon}
            src={currentAd.icon}
            alt={`${currentAd.name} logo`}
          />
        </a>
        <div className={classes.text}>
          <a
            href={currentAd.link}
            className={classes.link1}
            target="_blank"
            rel="noopener noreferrer"
          >
            {currentAd.adText}
          </a>
          <div className={classes.link2}>from {currentAd.company}</div>
        </div>
      </div>
    </div>
  );
};

export default Ad;
