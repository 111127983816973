import React, { useEffect, useState, useMemo, useCallback } from "react";
import { isEventClosed } from "../../../utils/timeUtils";
import Ad from "../../../components/Cards/Ad";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import greyProfileImage from "../../../assets/icons/greyProfileImage.jpg";
import greyStartupImage from "../../../assets/icons/greyStartupImage.jpg";
import greyCommunityImage from "../../../assets/icons/greyCommunityImage.jpg";
import greyEventImage from "../../../assets/icons/greyEventImage.jpg";
import greyPostImage from "../../../assets/icons/greyPostImage.jpg";
import startupImage from "../../../assets/images/startupImage.jpg";
import communityImage from "../../../assets/icons/communityImage.jpg";
import eventImage from "../../../assets/icons/eventImage.jpg";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Settings.module.css";
import StartupButtons from "../../../components/Buttons/StartupButtons";
import CommunityButtons from "../../../components/Buttons/CommunityButtons";
import EventButtons from "../../../components/Buttons/EventButtons";
import ProfileModal from "../../../components/Modals/ProfileModal";
import IntroModal from "../../../components/Modals/IntroModal";
import SkillsModal from "../../../components/Modals/SkillsModal";
import { firestore, auth } from "../../../firebase";
import RepostsModal from "../../../components/Modals/RepostsModal";
import ActivityButtons from "../../../components/Buttons/ActivityButtons";
import EditCommunityModal from "../../../components/Modals/EditCommunityModal";
import AddStartupModal from "../../../components/Modals/AddStartupModal";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import EditStartupsModal from "../../../components/Modals/EditStartupsModal";
import FollowModal from "../../../components/Modals/FollowModal";
import AddEventModal from "../../../components/Modals/AddEventModal";
import AddCommunityModal from "../../../components/Modals/AddCommunityModal";
import {
  getDoc,
  collection,
  doc,
  getDocs,
  query,
  orderBy,
  limit,
  updateDoc,
  deleteDoc,
  writeBatch,
  increment,
  serverTimestamp,
  Timestamp,
  where,
} from "firebase/firestore";
import EditEventModal from "../../../components/Modals/EditEventModal";
import {
  onAuthStateChanged,
  updateEmail,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  verifyBeforeUpdateEmail,
  updatePassword,
} from "firebase/auth";

import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import profileImage from "../../../assets/icons/profileImage.jpg";
import ActivityDropdown from "../../../components/Dropdowns/ActivityDropdown";
import InterestsModal from "../../../components/Modals/InterestsModal";
import AddExperienceModal from "../../../components/Modals/AddExperienceModal";
import EditExperienceModal from "../../../components/Modals/EditExperienceModal";
import EditSingleExperienceModal from "../../../components/Modals/EditSingleExperienceModal";
import AddEducationModal from "../../../components/Modals/AddEducationModal";
import EditEducationModal from "../../../components/Modals/EditEducationModal";
import EditSingleEducationModal from "../../../components/Modals/EditSingleEducationModal";
import monthsList from "../../../utils/monthsList";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import { useNavigate } from "react-router-dom";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import { getTopThreeEmojis } from "../../../utils/emojiUtils";
import { convertUrlsToLinks } from "../../../utils/textUtils";

function Settings() {
  const [isEmailChangeOpen, setIsEmailChangeOpen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [isCurrentEmailValid, setIsCurrentEmailValid] = useState(false);
  const [hoverStates, setHoverStates] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [isValidForm, setIsValidForm] = useState(false);
  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [isPasswordChangeOpen, setIsPasswordChangeOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [loadingEventType, setLoadingEventType] = useState(false);
  const [loadingStartupType, setLoadingStartupType] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    intro: "",
    link: "",
    linkText: "",
    skills: [],
    interests: [],
    experiences: [],
    education: [],
    profileImage: "",
  });
  const [displayedEventCount, setDisplayedEventCount] = useState(3);
  const [loadingCommunityType, setLoadingCommunityType] = useState(false);
  const [startupSearchTerm, setStartupSearchTerm] = useState("");
  const [startupType, setStartupType] = useState("Owner");
  const [displayedStartups, setDisplayedStartups] = useState(3);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [isLoadingBlocked, setIsLoadingBlocked] = useState(true);
  const [displayedBlockedUsers, setDisplayedBlockedUsers] = useState(3);

  const [startupCounts, setStartupCounts] = useState({
    Owner: 0,
    TeamMember: 0,
    Supported: 0,
    Following: 0,
  });
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);

  const handleInitiatePasswordChange = () => {
    setIsPasswordChangeOpen(true);
  };

  const handleCancelPasswordChange = () => {
    setIsPasswordChangeOpen(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
  };

  const handleConfirmPasswordChange = async () => {
    setIsChangingPassword(true);
    try {
      await updatePassword(auth.currentUser, newPassword);

      // Show success message
      setPasswordError("Password successfully changed.");

      // Close modal and reset fields after delay (same as email change - 5 seconds)
      setTimeout(() => {
        setIsPasswordChangeOpen(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setPasswordError("");
      }, 5000);
    } catch (error) {
      // Handle specific error cases with user-friendly messages
      switch (error.code) {
        case "auth/requires-recent-login":
          setPasswordError(
            "For security, please sign out and sign in again before changing your password."
          );
          break;
        case "auth/weak-password":
          setPasswordError("Password must be at least 6 characters long.");
          break;
        default:
          setPasswordError("Error changing password. Please try again.");
      }
    } finally {
      setIsChangingPassword(false);
    }
  };

  const checkEmailAvailability = async (email) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      const isAvailable = signInMethods.length === 0;
      setIsEmailAvailable(isAvailable);
      if (!isAvailable) {
        setEmailError("This email address is already in use");
      }
      return isAvailable;
    } catch (error) {
      setEmailError("Error checking email availability");
      return false;
    }
  };

  const [communitySearchTerm, setCommunitySearchTerm] = useState("");
  const [communityType, setCommunityType] = useState("Owner");
  const [communityCounts, setCommunityCounts] = useState({
    Owner: 0,
    Moderator: 0,
    Member: 0,
  });
  const [eventSearchTerm, setEventSearchTerm] = useState("");
  const [eventData, setEventData] = useState({});
  const [eventType, setEventType] = useState("Owner");
  const [eventCounts, setEventCounts] = useState({
    Owner: 0,
    Attending: 0,
    Attended: 0,
    Organising: 0, // Add this line
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditConnectionsModalOpen, setIsEditConnectionsModalOpen] =
    useState(false);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const [displayedCommunities, setDisplayedCommunities] = useState(3);

  const [
    editConnectionsModalScrollPosition,
    setEditConnectionsModalScrollPosition,
  ] = useState(0);
  const [connections, setConnections] = useState([]);
  const [displayedConnections, setDisplayedConnections] = useState(3);
  const [shareButtonText, setShareButtonText] = useState("Share");
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isLoadingActivity, setIsLoadingActivity] = useState(true);
  const [isLoadingStartups, setIsLoadingStartups] = useState(true);
  const [isLoadingConnections, setIsLoadingConnections] = useState(true);
  const [communities, setCommunities] = useState([]);
  const [showAddCommunityModal, setShowAddCommunityModal] = useState(false);
  const [isLoadingCommunities, setIsLoadingCommunities] = useState(true);
  const [addCommunityModalScrollPosition, setAddCommunityModalScrollPosition] =
    useState(0);
  const [showEditCommunityModal, setShowEditCommunityModal] = useState(false);
  const [isEditCommunityModalOpen, setIsEditCommunityModalOpen] =
    useState(false);
  const [
    editCommunityModalScrollPosition,
    setEditCommunityModalScrollPosition,
  ] = useState(0);
  const [showAllCommunities, setShowAllCommunities] = useState(false);

  const [addStartupModalScrollPosition, setAddStartupModalScrollPosition] =
    useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [followModalScrollPosition, setFollowModalScrollPosition] = useState(0);
  const [isFollowModalOpen, setIsFollowModalOpen] = useState(false);
  const [isAddStartupModalOpen, setIsAddStartupModalOpen] = useState(false);
  const [showEditStartupsModal, setShowEditStartupsModal] = useState(false);
  const [isEditStartupsModalOpen, setIsEditStartupsModalOpen] = useState(false);
  const [editStartupsModalScrollPosition, setEditStartupsModalScrollPosition] =
    useState(0);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [startups, setStartups] = useState([]);
  const [loadingStartups, setLoadingStartups] = useState(false);
  const [showAddStartupModal, setShowAddStartupModal] = useState(false);
  const [expandedTexts, setExpandedTexts] = useState({});
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const [showInterestsModal, setShowInterestsModal] = useState(false);
  const [showAddExperienceModal, setShowAddExperienceModal] = useState(false);
  const [showEditExperienceModal, setShowEditExperienceModal] = useState(false);
  const [showEditSingleExperienceModal, setShowEditSingleExperienceModal] =
    useState(false);
  const [showFollowModal, setShowFollowModal] = useState(false);
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [addEventModalScrollPosition, setAddEventModalScrollPosition] =
    useState(0);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [showEditEventsModal, setShowEditEventsModal] = useState(false);
  const [isEditEventsModalOpen, setIsEditEventsModalOpen] = useState(false);
  const [editEventsModalScrollPosition, setEditEventsModalScrollPosition] =
    useState(0);

  const [selectedExperience, setSelectedExperience] = useState(null);
  const [commentedPosts, setCommentedPosts] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [displayedComments, setDisplayedComments] = useState(3);

  const [showAddEducationModal, setShowAddEducationModal] = useState(false);
  const [showEditEducationModal, setShowEditEducationModal] = useState(false);
  const [showEditSingleEducationModal, setShowEditSingleEducationModal] =
    useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);

  const [displayedReactions, setDisplayedReactions] = useState(3);

  const [currentUser, setCurrentUser] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);

  const [connectionsCount, setConnectionsCount] = useState(0);

  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [activePostReposts, setActivePostReposts] = useState(null);

  const [activityType, setActivityType] = useState("Posts");

  const [posts, setPosts] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState(3);
  const [loading, setLoading] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingBoosts, setLoadingBoosts] = useState(false);
  const [loadingReposts, setLoadingReposts] = useState(false);

  const [visibleSkills, setVisibleSkills] = useState(9);
  const [visibleInterests, setVisibleInterests] = useState(9);
  const [debugExperiences, setDebugExperiences] = useState([]);

  const toggleAddCommunityModal = (success = false) => {
    if (!showAddCommunityModal) {
      setAddCommunityModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addCommunityModalScrollPosition);
      }, 0);
    }
    setShowAddCommunityModal(!showAddCommunityModal);
  };

  const handleCommunityTypeChange = (type) => {
    setLoadingCommunityType(true);
    setCommunityType(type);
    // Use a small timeout to simulate loading and prevent flickering
    setTimeout(() => {
      setLoadingCommunityType(false);
    }, 300); // Adjust timing as needed
  };

  const handleCommunityAdded = (newCommunity) => {
    const communityWithTimestamp = {
      ...newCommunity,
      createdAt:
        newCommunity.createdAt instanceof Date
          ? Timestamp.fromDate(newCommunity.createdAt)
          : newCommunity.createdAt,
      membershipType: "owner", // Explicitly set the membership type
    };

    // Update both the communities state and community counts
    setCommunities((prevCommunities) => [
      communityWithTimestamp,
      ...prevCommunities,
    ]);
    setCommunityCounts((prevCounts) => ({
      ...prevCounts,
      Owner: prevCounts.Owner + 1,
    }));
  };

  const toggleConnectionsModal = () => {
    if (!showConnectionsModal) {
      setConnectionsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, connectionsModalScrollPosition);
      }, 0);
    }
    setShowConnectionsModal(!showConnectionsModal);
    setIsConnectionsModalOpen(!isConnectionsModalOpen);
  };

  // Update the add event modal toggle function
  const toggleAddEventModal = (success = false) => {
    if (!showAddEventModal) {
      setAddEventModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addEventModalScrollPosition);
      }, 0);
    }
    setShowAddEventModal(!showAddEventModal);
    setIsAddEventModalOpen(!isAddEventModalOpen);
  };

  const toggleShowAllCommunities = () => {
    setShowAllCommunities(true);
  };

  const handleEventTypeChange = (type) => {
    setLoadingEventType(true);
    setEventType(type);
    // Use a small timeout to simulate loading and prevent flickering
    setTimeout(() => {
      setLoadingEventType(false);
    }, 300); // Adjust timing as needed
  };

  const handleStartupTypeChange = (type) => {
    setLoadingStartupType(true);
    setStartupType(type);
    // Use a small timeout to simulate loading and prevent flickering
    setTimeout(() => {
      setLoadingStartupType(false);
    }, 300); // Adjust timing as needed
  };

  const toggleEditCommunityModal = () => {
    if (!showEditCommunityModal) {
      setEditCommunityModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editCommunityModalScrollPosition);
      }, 0);
    }
    setShowEditCommunityModal(!showEditCommunityModal);
    setIsEditCommunityModalOpen(!isEditCommunityModalOpen);
  };

  useEffect(() => {
    if (communities.length > 0) {
      const counts = {
        Owner: communities.filter((c) => c.membershipType === "owner").length,
        Moderator: communities.filter((c) => c.membershipType === "moderator")
          .length,
        Member: communities.filter((c) => c.membershipType === "member").length,
      };
      setCommunityCounts(counts);
    }
  }, [communities]);

  const handleInitiateEmailChange = () => {
    setIsEmailChangeOpen(true);
  };

  // Add these handlers for the inputs
  const handleNewEmailChange = (e) => {
    setNewEmail(e.target.value);
    setEmailError(""); // Clear any existing errors
  };

  const handleCancelEmailChange = () => {
    setIsEmailChangeOpen(false);
    setCurrentEmail("");
    setNewEmail("");
    setConfirmEmail("");
    setEmailError("");
    setIsCurrentEmailValid(false);
  };

  const areEmailsValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const hasValidFormat =
      emailRegex.test(newEmail) && emailRegex.test(confirmEmail);
    const emailsMatch = newEmail === confirmEmail;
    return hasValidFormat && emailsMatch && isCurrentEmailValid;
  };

  const checkCurrentEmail = async () => {
    if (currentEmail === currentUser.email) {
      setIsCurrentEmailValid(true);
      setEmailError("");
    } else {
      setIsCurrentEmailValid(false);
      setEmailError("Current email is incorrect");
    }
  };

  const handleConfirmEmailChange = async () => {
    setIsChangingEmail(true);
    try {
      // First check if email already exists using fetchSignInMethodsForEmail
      const signInMethods = await fetchSignInMethodsForEmail(auth, newEmail);

      // If any sign-in methods exist for this email, stop here
      if (signInMethods && signInMethods.length > 0) {
        setEmailError("This email address is already in use");
        return;
      }

      // Only attempt to send verification email if we get here
      await verifyBeforeUpdateEmail(auth.currentUser, newEmail);

      setEmailError(
        "Verification email sent. Please check your new email address to complete the change."
      );

      // Close modal after showing success message
      setTimeout(() => {
        setIsEmailChangeOpen(false);
        setCurrentEmail("");
        setNewEmail("");
        setConfirmEmail("");
        setEmailError("");
      }, 5000);
    } catch (error) {
      switch (error.code) {
        case "auth/requires-recent-login":
          setEmailError(
            "For security, please sign out and sign in again before changing your email."
          );
          break;
        case "auth/invalid-email":
          setEmailError("Please enter a valid email address.");
          break;
        case "auth/email-already-in-use":
          setEmailError("This email address is already in use.");
          break;
        default:
          setEmailError("Error changing email. Please try again.");
      }
    } finally {
      setIsChangingEmail(false);
    }
  };

  const filteredEvents = useMemo(() => {
    if (!events || !Array.isArray(events)) {
      return [];
    }

    const parseEventDateTime = (event) => {
      try {
        let day, month, year;
        const [hours, minutes] = event.endTime.match(/(\d+):(\d+)/).slice(1);

        if (event.endDate.includes("-")) {
          [year, month, day] = event.endDate.split("-");
        } else {
          [day, month, year] = event.endDate.split("/");
        }

        const date = new Date(
          Number(year),
          Number(month) - 1,
          Number(day),
          Number(hours),
          Number(minutes)
        );

        return date;
      } catch (error) {
        return new Date(0);
      }
    };

    const isEventInPast = (event) => {
      if (!event.endDate || !event.endTime || !event.timezone) return false;
      return isEventClosed({
        endDate: event.endDate,
        endTime: event.endTime,
        timezone: event.timezone,
      });
    };

    return events
      .filter((event) => {
        const eventClosed = isEventInPast(event);

        switch (eventType) {
          case "Owner":
            return event.membershipType === "owner";
          case "Organiser":
            return event.membershipType === "Organising";
          case "Attending":
            return event.membershipType === "attending" && !eventClosed;
          case "Attended":
            return event.membershipType === "attending" && eventClosed;
          default:
            return false;
        }
      })
      .sort((a, b) => {
        const aDate = parseEventDateTime(a);
        const bDate = parseEventDateTime(b);

        const now = new Date();
        const aInFuture = aDate > now;
        const bInFuture = bDate > now;

        if (aInFuture !== bInFuture) {
          return aInFuture ? -1 : 1;
        }

        if (aInFuture) {
          return aDate.getTime() - bDate.getTime();
        } else {
          return bDate.getTime() - aDate.getTime();
        }
      });
  }, [events, eventType]);

  const searchFilteredEvents = useMemo(() => {
    if (!filteredEvents) return [];

    return filteredEvents.filter((event) => {
      const matchesSearch = event.eventName
        ?.toLowerCase()
        .includes(eventSearchTerm.toLowerCase());
      return matchesSearch;
    });
  }, [filteredEvents, eventSearchTerm]);

  const handleShare = useCallback(() => {
    const profileUrl = `${window.location.origin}/${userData.urlSlug}`;
    navigator.clipboard.writeText(profileUrl).then(() => {
      setShareButtonText("Copied");
      setTimeout(() => {
        setShareButtonText("Share");
      }, 5000);
    });
  }, [userData.urlSlug]);

  useEffect(() => {
    if (startups.length > 0) {
      const counts = {
        Owner: startups.filter((s) => s.membershipType === "owner").length,
        TeamMember: startups.filter((s) => s.membershipType === "member")
          .length,
        Supported: startups.filter((s) => s.supported).length,
        Following: startups.filter((s) => s.following).length,
      };
      setStartupCounts(counts);
    }
  }, [startups]);

  const filteredCommunities = useMemo(() => {
    if (!communities) return [];

    const filtered = communities.filter((community) => {
      switch (communityType) {
        case "Owner":
          return community.membershipType === "owner";
        case "Moderator":
          return community.membershipType === "moderator";
        case "Member":
          return community.membershipType === "member";
        default:
          return false;
      }
    });

    return filtered.sort((a, b) => {
      if (communityType === "Owner") {
        const aTime = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const bTime = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return bTime - aTime;
      } else if (communityType === "Moderator") {
        const aTime = a.acceptedAt?.toDate?.() || new Date(a.acceptedAt);
        const bTime = b.acceptedAt?.toDate?.() || new Date(b.acceptedAt);
        return bTime - aTime;
      } else if (communityType === "Member") {
        const aTime = a.joinedAt?.toDate?.() || new Date(a.joinedAt);
        const bTime = b.joinedAt?.toDate?.() || new Date(b.joinedAt);
        return bTime - aTime;
      }
      return 0;
    });
  }, [communities, communityType]);

  const handleCommunityUpdated = (updatedCommunities) => {
    // If we received a single community that's been deleted
    if (updatedCommunities.deleted) {
      setCommunities((prevCommunities) =>
        prevCommunities.filter(
          (community) => community.id !== updatedCommunities.id
        )
      );
      // Update the community counts
      setCommunityCounts((prevCounts) => ({
        ...prevCounts,
        Owner: prevCounts.Owner - 1,
      }));
      return;
    }

    // If we received an array, use it directly
    if (Array.isArray(updatedCommunities)) {
      setCommunities(updatedCommunities);
    } else {
      // If we received a single community update, update it in the existing array
      setCommunities((prevCommunities) =>
        prevCommunities.map((community) =>
          community.id === updatedCommunities.id
            ? {
                ...community,
                ...updatedCommunities,
                membershipType: community.membershipType,
                createdAt: community.createdAt,
              }
            : community
        )
      );
    }
  };

  const toggleEditEventsModal = () => {
    if (!showEditEventsModal) {
      setEditEventsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editEventsModalScrollPosition);
      }, 0);
    }
    setShowEditEventsModal(!showEditEventsModal);
    setIsEditEventsModalOpen(!isEditEventsModalOpen);
  };

  // Add this handler function with your other handlers
  const handleEventUpdate = async (updatedEvent) => {
    try {
      const batch = writeBatch(firestore);

      // Update main event document
      const eventRef = doc(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}`
      );
      batch.update(eventRef, updatedEvent);

      // Get all attendees
      const attendeesRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/attendees`
      );
      const attendeesSnapshot = await getDocs(attendeesRef);

      // Update each attendee's eventsAttending record
      attendeesSnapshot.docs.forEach((attendeeDoc) => {
        const attendeeEventRef = doc(
          firestore,
          `users/${attendeeDoc.id}/eventsAttending/${eventData.id}`
        );

        // Update the critical time-related fields in the attendee's record
        batch.update(attendeeEventRef, {
          endDate: updatedEvent.endDate,
          endTime: updatedEvent.endTime,
          timezone: updatedEvent.timezone,
          // Also update other important fields that might have changed
          eventName: updatedEvent.eventName,
          eventImage: updatedEvent.eventImage,
          eventUrlSlug: updatedEvent.eventUrlSlug,
          isOnline: updatedEvent.isOnline,
          city: updatedEvent.city,
          country: updatedEvent.country,
        });
      });

      await batch.commit();

      setEventData((prev) => ({
        ...prev,
        ...updatedEvent,
      }));
    } catch (error) {}
  };

  const getOriginalPostRef = async (firestore, originalPosterId, postId) => {
    if (!originalPosterId || !postId) return null;
    try {
      if (originalPosterId.startsWith("startup_")) {
        const startupId = originalPosterId.replace("startup_", "");
        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);

        for (const userDoc of usersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupId}`
          );
          const startupSnap = await getDoc(startupRef);

          if (startupSnap.exists()) {
            const startupData = startupSnap.data();
            return {
              ref: doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/posts/${postId}`
              ),
              startupData: {
                ...startupData,
                ownerId: userDoc.id,
                startupId: startupId,
                isStartupPost: true,
              },
            };
          }
        }
        return null;
      }

      // For user posts, check personal posts first
      const userPostRef = doc(
        firestore,
        `users/${originalPosterId}/posts/${postId}`
      );
      const userPostSnap = await getDoc(userPostRef);

      if (userPostSnap.exists()) {
        return {
          ref: userPostRef,
          startupData: null,
        };
      }

      // Then check startup posts
      const startupsRef = collection(
        firestore,
        `users/${originalPosterId}/startups`
      );
      const startupsSnapshot = await getDocs(startupsRef);

      for (const startupDoc of startupsSnapshot.docs) {
        const postRef = doc(
          firestore,
          `users/${originalPosterId}/startups/${startupDoc.id}/posts/${postId}`
        );
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
          const startupData = startupDoc.data();
          return {
            ref: postRef,
            startupData: {
              ...startupData,
              ownerId: originalPosterId,
              startupId: startupDoc.id,
              isStartupPost: true,
            },
          };
        }
      }

      return null;
    } catch (error) {
      return null;
    }
  };

  const handleEventAdded = (newEvent) => {
    // Create an event object with membershipType for proper filtering
    const eventWithMembership = {
      ...newEvent,
      membershipType: "owner",
      ownerId: currentUser.uid,
    };

    // Update the events state with the new event at the beginning of the array
    setEvents((prevEvents) => [eventWithMembership, ...prevEvents]);

    // Update the event counts
    setEventCounts((prevCounts) => ({
      ...prevCounts,
      Owner: prevCounts.Owner + 1,
    }));
  };

  const toggleShowAllEvents = () => {
    setShowAllEvents(true);
  };

  // Add this section component with your other section components

  const fetchPosts = useCallback(async () => {
    if (!currentUser) return;
    setLoadingPosts(true);
    try {
      const postsRef = collection(firestore, `users/${currentUser.uid}/posts`);
      const postsSnapshot = await getDocs(
        query(postsRef, orderBy("createdAt", "desc"), limit(20))
      );

      const postsData = postsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt?.toDate() || new Date(),
          postPreview: {
            mediaType: data.content?.type || null,
            mediaUrl: data.content?.url || null,
            text: data.text,
            thumbnail: data.content?.thumbnail || null,
          },
          reactions: data.reactions || {},
          reposts: data.reposts || {},
          commentCount: data.commentCount || 0,
        };
      });

      postsData.sort((a, b) => b.createdAt - a.createdAt);
      setPosts(postsData);
    } catch (error) {
    } finally {
      setLoadingPosts(false);
    }
  }, [currentUser]);

  const fetchReactions = useCallback(async () => {
    if (!currentUser?.uid) return;

    setLoadingBoosts(true);
    try {
      const reactionsRef = collection(
        firestore,
        `users/${currentUser.uid}/reactedPosts`
      );
      const reactionsQuery = query(
        reactionsRef,
        orderBy("reactedAt", "desc"),
        limit(20)
      );
      const reactionsSnapshot = await getDocs(reactionsQuery);

      const reactionsPromises = reactionsSnapshot.docs.map(
        async (docSnapshot) => {
          const reactionData = docSnapshot.data();
          const postInfo = await getOriginalPostRef(
            firestore,
            reactionData.originalPosterId,
            reactionData.postId
          );
          if (!postInfo?.ref) return null;

          const originalPostSnap = await getDoc(postInfo.ref);
          if (!originalPostSnap.exists()) return null;

          const originalPostData = originalPostSnap.data();
          const originalPost = {
            id: reactionData.postId,
            ...originalPostData,
            postPreview: {
              mediaType: originalPostData.content?.type || null,
              mediaUrl: originalPostData.content?.url || null,
              text: originalPostData.text || "",
              thumbnail: originalPostData.content?.thumbnail || null,
            },
            reactions: originalPostData.reactions || {},
            reposts: originalPostData.reposts || {},
            commentCount: originalPostData.commentCount || 0,
          };

          if (postInfo.startupData) {
            originalPost.isStartupPost = true;
            originalPost.startupName = postInfo.startupData.startupName;
            originalPost.startupImage = postInfo.startupData.startupImage;
            originalPost.startupId = postInfo.startupData.startupId;
            originalPost.startupUrlSlug = postInfo.startupData.startupUrlSlug;
            originalPost.ownerId = postInfo.startupData.ownerId;
          }

          return {
            id: docSnapshot.id,
            ...reactionData,
            originalPost,
            reactedAt: reactionData.reactedAt?.toDate() || new Date(),
            emoji: reactionData.emoji,
          };
        }
      );

      const reactionsResults = await Promise.all(reactionsPromises);
      const validReactions = reactionsResults.filter(Boolean);
      setReactions(validReactions);
    } catch (error) {
      setReactions([]);
    } finally {
      setLoadingBoosts(false);
    }
  }, [currentUser?.uid]);

  const filteredConnections = useMemo(() => {
    return connections.filter((connection) => {
      if (!connection?.userData?.firstName || !connection?.userData?.lastName)
        return false;
      const fullName = `${connection.userData.firstName} ${connection.userData.lastName}`;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [connections, searchTerm]);

  const fetchReposts = useCallback(async () => {
    if (!currentUser?.uid) return;

    setLoadingReposts(true);
    try {
      const repostsRef = collection(
        firestore,
        `users/${currentUser.uid}/reposts`
      );
      const repostsQuery = query(
        repostsRef,
        orderBy("repostedAt", "desc"),
        limit(20)
      );
      const repostsSnapshot = await getDocs(repostsQuery);

      const repostsPromises = repostsSnapshot.docs.map(async (docSnapshot) => {
        const repostData = docSnapshot.data();
        const postInfo = await getOriginalPostRef(
          firestore,
          repostData.originalPosterId,
          repostData.postId
        );
        if (!postInfo?.ref) return null;

        const originalPostSnap = await getDoc(postInfo.ref);
        if (!originalPostSnap.exists()) return null;

        const originalPostData = originalPostSnap.data();
        const originalPost = {
          id: repostData.postId,
          ...originalPostData,
          postPreview: {
            mediaType: originalPostData.content?.type || null,
            mediaUrl: originalPostData.content?.url || null,
            text: originalPostData.text || "",
            thumbnail: originalPostData.content?.thumbnail || null,
          },
          reactions: originalPostData.reactions || {},
          reposts: originalPostData.reposts || {},
          commentCount: originalPostData.commentCount || 0,
        };

        if (postInfo.startupData) {
          originalPost.isStartupPost = true;
          originalPost.startupName = postInfo.startupData.startupName;
          originalPost.startupImage = postInfo.startupData.startupImage;
          originalPost.startupId = postInfo.startupData.startupId;
          originalPost.startupUrlSlug = postInfo.startupData.startupUrlSlug;
          originalPost.ownerId = postInfo.startupData.ownerId;
        }

        return {
          id: docSnapshot.id,
          ...repostData,
          originalPost,
          repostedAt: repostData.repostedAt?.toDate() || new Date(),
        };
      });

      const repostsResults = await Promise.all(repostsPromises);
      const validReposts = repostsResults.filter(Boolean);
      setReposts(validReposts);
    } catch (error) {
      setReposts([]);
    } finally {
      setLoadingReposts(false);
    }
  }, [currentUser?.uid]);

  const fetchComments = useCallback(async () => {
    if (!currentUser?.uid) return;

    setLoadingComments(true);
    try {
      const commentedPostsRef = collection(
        firestore,
        `users/${currentUser.uid}/commentedPosts`
      );
      const commentedPostsQuery = query(
        commentedPostsRef,
        orderBy("lastCommentedAt", "desc"),
        limit(20)
      );
      const commentedPostsSnapshot = await getDocs(commentedPostsQuery);

      const commentsPromises = commentedPostsSnapshot.docs.map(
        async (docSnapshot) => {
          const commentData = docSnapshot.data();
          const postInfo = await getOriginalPostRef(
            firestore,
            commentData.originalPosterId,
            commentData.postId
          );
          if (!postInfo?.ref) return null;

          const originalPostSnap = await getDoc(postInfo.ref);
          if (!originalPostSnap.exists()) return null;

          const originalPostData = originalPostSnap.data();
          const originalPost = {
            id: commentData.postId,
            ...originalPostData,
            postPreview: {
              mediaType: originalPostData.content?.type || null,
              mediaUrl: originalPostData.content?.url || null,
              text: originalPostData.text || "",
              thumbnail: originalPostData.content?.thumbnail || null,
            },
            reactions: originalPostData.reactions || {},
            reposts: originalPostData.reposts || {},
            commentCount: originalPostData.commentCount || 0,
          };

          if (postInfo.startupData) {
            originalPost.isStartupPost = true;
            originalPost.startupName = postInfo.startupData.startupName;
            originalPost.startupImage = postInfo.startupData.startupImage;
            originalPost.startupId = postInfo.startupData.startupId;
            originalPost.startupUrlSlug = postInfo.startupData.startupUrlSlug;
            originalPost.ownerId = postInfo.startupData.ownerId;
          }

          return {
            id: docSnapshot.id,
            ...commentData,
            originalPost,
            lastCommentedAt:
              commentData.lastCommentedAt?.toDate() || new Date(),
          };
        }
      );

      const commentsResults = await Promise.all(commentsPromises);
      const validComments = commentsResults.filter(Boolean);
      setCommentedPosts(validComments);
    } catch (error) {
      setCommentedPosts([]);
    } finally {
      setLoadingComments(false);
    }
  }, [currentUser?.uid]);

  const toggleAddStartupModal = (success = false) => {
    if (!showAddStartupModal) {
      setAddStartupModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addStartupModalScrollPosition);
      }, 0);
    }
    setShowAddStartupModal(!showAddStartupModal);
    setIsAddStartupModalOpen(!isAddStartupModalOpen);
  };

  const toggleFollowModal = () => {
    if (!showFollowModal) {
      setFollowModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, followModalScrollPosition);
      }, 0);
    }
    setShowFollowModal(!showFollowModal);
    setIsFollowModalOpen(!isFollowModalOpen);
  };

  // Add this toggle function with your other modal toggle functions
  const toggleEditStartupsModal = () => {
    if (!showEditStartupsModal) {
      setEditStartupsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editStartupsModalScrollPosition);
      }, 0);
    }
    setShowEditStartupsModal(!showEditStartupsModal);
    setIsEditStartupsModalOpen(!isEditStartupsModalOpen);
  };

  // Add this handler for when a single startup is selected for editing
  const openEditSingleStartupModal = (startup) => {
    setSelectedStartup(startup);
    // You'll implement the EditSingleStartupModal later
    // setShowEditSingleStartupModal(true);
    setShowEditStartupsModal(false);
  };

  const [activityCounts, setActivityCounts] = useState({
    posts: 0,
    reactions: 0,
    reposts: 0,
    comments: 0,
  });

  // Add these functions after your other useCallback functions
  const fetchPostsCount = useCallback(async (userId) => {
    const postsRef = collection(firestore, `users/${userId}/posts`);
    const snapshot = await getDocs(query(postsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchReactionsCount = useCallback(async (userId) => {
    const reactionsRef = collection(firestore, `users/${userId}/reactedPosts`);
    const snapshot = await getDocs(query(reactionsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchRepostsCount = useCallback(async (userId) => {
    const repostsRef = collection(firestore, `users/${userId}/reposts`);
    const snapshot = await getDocs(query(repostsRef, limit(1)));
    return snapshot.size;
  }, []);

  // Update handleStartupAdded in Profile.js to properly integrate the new startup
  const handleStartupAdded = (newStartup) => {
    const startupWithTimestamp = {
      ...newStartup,
      createdAt:
        newStartup.createdAt instanceof Date
          ? Timestamp.fromDate(newStartup.createdAt)
          : newStartup.createdAt,
      membershipType: "owner", // Explicitly set the membership type
    };

    // Update both the startups state and startup counts
    setStartups((prevStartups) => [...prevStartups, startupWithTimestamp]);
    setStartupCounts((prevCounts) => ({
      ...prevCounts,
      Owner: prevCounts.Owner + 1,
    }));
  };

  // In Profile.js
  const handleStartupUpdated = (startupUpdate) => {
    // Check if this is a deletion
    if (startupUpdate.deleted) {
      setStartups((prevStartups) =>
        prevStartups.filter((startup) => startup.id !== startupUpdate.id)
      );
      // Update startup counts
      setStartupCounts((prevCounts) => ({
        ...prevCounts,
        Owner: prevCounts.Owner - 1,
      }));
      return;
    }

    // Handle normal updates
    setStartups((prevStartups) => {
      return prevStartups.map((startup) =>
        startup.id === startupUpdate.id
          ? {
              ...startup,
              ...startupUpdate,
              membershipType: "owner",
            }
          : startup
      );
    });
  };

  const isMobile = () => {
    return window.innerWidth < 648;
  };

  const [modalScrollPosition, setModalScrollPosition] = useState(0);
  const [skillsModalScrollPosition, setSkillsModalScrollPosition] = useState(0);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [profileModalScrollPosition, setProfileModalScrollPosition] =
    useState(0);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [introModalScrollPosition, setIntroModalScrollPosition] = useState(0);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const [
    addExperienceModalScrollPosition,
    setAddExperienceModalScrollPosition,
  ] = useState(0);
  const [isAddExperienceModalOpen, setIsAddExperienceModalOpen] =
    useState(false);
  const [addEducationModalScrollPosition, setAddEducationModalScrollPosition] =
    useState(0);
  const [isAddEducationModalOpen, setIsAddEducationModalOpen] = useState(false);
  const [
    editExperienceModalScrollPosition,
    setEditExperienceModalScrollPosition,
  ] = useState(0);
  const [isEditExperienceModalOpen, setIsEditExperienceModalOpen] =
    useState(false);
  const [
    editEducationModalScrollPosition,
    setEditEducationModalScrollPosition,
  ] = useState(0);
  const [isEditEducationModalOpen, setIsEditEducationModalOpen] =
    useState(false);

  const [reposts, setReposts] = useState([]);
  const [displayedReposts, setDisplayedReposts] = useState(3);

  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);

  const storage = getStorage();

  const navigate = useNavigate();

  const [showFullIntro, setShowFullIntro] = useState(false);

  const [visibleExperiences, setVisibleExperiences] = useState(3);
  const [visibleEducation, setVisibleEducation] = useState(3);

  const [reactions, setReactions] = useState([]);

  const [isInterestsModalOpen, setIsInterestsModalOpen] = useState(false);
  const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const toggleExpandText = useCallback((id) => {
    setExpandedTexts((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  }, []);

  const MAX_LENGTH = 125; // Same as Intro text

  const renderDescription = (description, id) => {
    if (!description) return null;

    return (
      <>
        <p
          className={`${classes.role} ${
            expandedDescriptions[id] ? classes.expanded : ""
          }`}
          style={{
            whiteSpace: "pre-wrap",
            WebkitLineClamp: expandedDescriptions[id] ? "unset" : 3,
          }}
        >
          {description}
          {!expandedDescriptions[id] && description.split("\n").length > 3 && (
            <span
              className={classes.moreButton}
              onClick={() => toggleDescription(id)}
            >
              ...more
            </span>
          )}
        </p>
        {expandedDescriptions[id] && (
          <span
            className={classes.moreButton}
            onClick={() => toggleDescription(id)}
          >
            Show less
          </span>
        )}
      </>
    );
  };

  const renderIntroText = () => {
    const introText =
      userData.introHtml || "Describe who you are, and what you do.";
    const maxLength = 250; // Adjust this value to set the desired text limit

    if (introText.length <= maxLength || showFullIntro) {
      return { text: introText, showMore: false };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return { text: truncatedText, showMore: true };
    }
  };

  // Add this sorting function near other sorting-related code
  const sortedStartups = useMemo(() => {
    if (!startups) return [];
    return [...startups].sort((a, b) => {
      // Convert month strings to numbers for comparison
      const aMonth = monthsList.indexOf(a.monthFounded);
      const bMonth = monthsList.indexOf(b.monthFounded);

      // Create dates for comparison
      const aDate = new Date(a.yearFounded, aMonth);
      const bDate = new Date(b.yearFounded, bMonth);

      // Compare dates first
      if (aDate.getTime() !== bDate.getTime()) {
        return bDate.getTime() - aDate.getTime(); // Newest first
      }

      // If dates are the same, compare creation timestamps
      return b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime();
    });
  }, [startups]);

  const sortedExperiences = useMemo(() => {
    if (!userData.experiences) return [];
    return [...userData.experiences].sort((a, b) => {
      const aEndDate = a.isCurrentlyWorking
        ? Date.now()
        : new Date(`${a.endMonth} 1, ${a.endYear}`).getTime();
      const bEndDate = b.isCurrentlyWorking
        ? Date.now()
        : new Date(`${b.endMonth} 1, ${b.endYear}`).getTime();

      if (aEndDate !== bEndDate) {
        return bEndDate - aEndDate; // Most recent end date first
      }

      const aStartDate = new Date(
        `${a.startMonth} 1, ${a.startYear}`
      ).getTime();
      const bStartDate = new Date(
        `${b.startMonth} 1, ${b.startYear}`
      ).getTime();

      if (aStartDate !== bStartDate) {
        return bStartDate - aStartDate; // Most recent start date first
      }

      // If both start and end dates are the same, use dateAdded
      return (b.dateAdded?.toMillis() || 0) - (a.dateAdded?.toMillis() || 0);
    });
  }, [userData.experiences]);

  const handleEventUpdated = (eventUpdate) => {
    // Check if this is a deletion
    if (eventUpdate.deleted) {
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== eventUpdate.id)
      );
      // Update event counts
      setEventCounts((prevCounts) => ({
        ...prevCounts,
        Owner: prevCounts.Owner - 1,
      }));
      return;
    }

    // Handle normal updates
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.id === eventUpdate.id
          ? {
              ...event,
              ...eventUpdate,
              membershipType: "owner", // Ensure membership type is preserved
            }
          : event
      )
    );
  };

  const fetchActivityData = useCallback(async () => {
    if (!currentUser?.uid) return;

    const activityTypeMap = {
      Posts: fetchPosts,
      Reactions: fetchReactions,
      Comments: fetchComments,
      Reposts: fetchReposts,
    };

    const fetchFunction = activityTypeMap[activityType];
    if (fetchFunction) {
      await fetchFunction();
    }
  }, [
    currentUser?.uid,
    activityType,
    fetchPosts,
    fetchReactions,
    fetchComments,
    fetchReposts,
  ]);

  useEffect(() => {
    if (!currentUser?.uid || !activityType) return;

    const fetchData = async () => {
      switch (activityType) {
        case "Posts":
          await fetchPosts();
          break;
        case "Reactions":
          await fetchReactions();
          break;
        case "Comments":
          await fetchComments();
          break;
        case "Reposts":
          await fetchReposts();
          break;
      }
    };

    fetchData();
  }, [
    currentUser?.uid,
    activityType,
    fetchPosts,
    fetchReactions,
    fetchComments,
    fetchReposts,
  ]);

  const handleMoreClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowFullIntro(true);
  };

  const checkConnectionType = useCallback(
    async (currentUserId, targetUserId) => {
      if (!currentUserId || !targetUserId) return "Extended";
      if (currentUserId === targetUserId) return "You";

      const userConnectionRef = doc(
        firestore,
        `users/${currentUserId}/connections/${targetUserId}`
      );
      const userConnectionDoc = await getDoc(userConnectionRef);

      if (
        userConnectionDoc.exists() &&
        userConnectionDoc.data().status === "Connected"
      ) {
        return "Direct";
      }

      return "Extended";
    },
    []
  );

  const fetchConnections = useCallback(async () => {
    if (!currentUser?.uid) return;

    try {
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      // Only get connections with "Connected" status
      const connectionsQuery = query(
        connectionsRef,
        where("status", "==", "Connected")
      );
      const connectionsSnapshot = await getDocs(connectionsQuery);

      const connectionsData = await Promise.all(
        connectionsSnapshot.docs.map(async (connectionDoc) => {
          // Verify the connection still exists and is still "Connected"
          const connectionData = connectionDoc.data();
          if (connectionData.status !== "Connected") {
            return null;
          }

          const userDocRef = doc(firestore, "users", connectionDoc.id);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            const connectionType = await checkConnectionType(
              currentUser.uid,
              connectionDoc.id
            );

            // Also verify the reverse connection exists and is "Connected"
            const reverseConnectionRef = doc(
              firestore,
              `users/${connectionDoc.id}/connections/${currentUser.uid}`
            );
            const reverseConnectionDoc = await getDoc(reverseConnectionRef);

            if (
              !reverseConnectionDoc.exists() ||
              reverseConnectionDoc.data().status !== "Connected"
            ) {
              return null;
            }

            return {
              id: connectionDoc.id,
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                urlSlug: userData.urlSlug || "",
              },
              connectionType,
              connectedAt: connectionData.connectedAt?.toDate() || new Date(0),
            };
          }
          return null;
        })
      );

      // Filter out null values and sort by connectedAt timestamp
      const validConnections = connectionsData
        .filter(Boolean)
        .sort((a, b) => b.connectedAt - a.connectedAt);

      setConnections(validConnections);
    } catch (error) {}
  }, [currentUser?.uid, checkConnectionType]);

  const handleConnectionRemoved = useCallback(() => {
    fetchConnections(); // Re-fetch connections after removal
    setConnectionsCount((prev) => prev - 1); // Update the count
  }, [fetchConnections]);

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const fetchBlockedUsers = useCallback(async () => {
    if (!currentUser?.uid) {
      return;
    }

    try {
      const blockedRef = collection(
        firestore,
        `users/${currentUser.uid}/blockedUsers`
      );
      // First, get all documents to see what we have
      const blockedSnapshot = await getDocs(blockedRef);

      // Log each document's data
      blockedSnapshot.docs.forEach((doc) => {});

      const blockedData = await Promise.all(
        blockedSnapshot.docs.map(async (blockedDoc) => {
          const data = blockedDoc.data();

          // Get the blocked user's ID - try both id and userId
          const blockedUserId = data.userId || blockedDoc.id;

          const userDoc = await getDoc(doc(firestore, "users", blockedUserId));

          if (userDoc.exists()) {
            const userData = userDoc.data();
            return {
              id: blockedDoc.id,
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                urlSlug: userData.urlSlug || "",
              },
              blockedAt: data.blockedAt?.toDate() || new Date(0),
            };
          }

          return null;
        })
      );

      const validBlockedUsers = blockedData
        .filter(Boolean)
        .sort((a, b) => b.blockedAt - a.blockedAt);

      setBlockedUsers(validBlockedUsers);
    } catch (error) {
    } finally {
      setIsLoadingBlocked(false);
    }
  }, [currentUser?.uid, firestore]);

  // Add useEffect to fetch blocked users
  useEffect(() => {
    if (currentUser) {
      fetchBlockedUsers();
    }
  }, [currentUser, fetchBlockedUsers]);

  const CommunitySection = ({ community }) => {
    const navigate = useNavigate();

    const handleCommunityClick = () => {
      if (community.communityUrlSlug) {
        navigate(`/community/${community.communityUrlSlug}`);
      }
    };

    const handleLinkClick = (e) => {
      e.stopPropagation();
      if (e.target.tagName === "A") {
        e.preventDefault();
        window.open(e.target.href, "_blank", "noopener,noreferrer");
      }
    };

    return (
      <div key={community.id} className={classes.userContainer}>
        <img
          src={community.communityImage || communityImage}
          alt={community.communityName}
          className={classes.communityImage}
          onClick={handleCommunityClick}
          style={{ cursor: "pointer" }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = greyCommunityImage;
          }}
        />
        <div className={classes.userInfo}>
          <div className={classes.userNameContainer}>
            <div
              className={classes.userName}
              onClick={handleCommunityClick}
              style={{ cursor: "pointer" }}
            >
              {community.communityName}
            </div>
            <span className={classes.userConnectionType}>• Community</span>
          </div>
          {community.tagline && (
            <div className={classes.userBio}>{community.tagline}</div>
          )}
          {community.link && (
            <a
              href={community.link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.userLink}
              onClick={handleLinkClick}
            >
              <span>{community.linkText || community.link}</span>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                />
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  };

  const StartupSection = ({ startup }) => {
    const navigate = useNavigate();

    const handleStartupClick = () => {
      if (startup.startupUrlSlug) {
        navigate(`/startup/${startup.startupUrlSlug}`);
      }
    };

    const handleLinkClick = (e) => {
      e.stopPropagation();
      if (e.target.tagName === "A") {
        e.preventDefault();
        window.open(e.target.href, "_blank", "noopener,noreferrer");
      }
    };

    return (
      <div key={startup.id} className={classes.userContainer}>
        <img
          src={startup.startupImage || startupImage}
          alt={startup.startupName}
          className={classes.startupImage}
          onClick={handleStartupClick}
          style={{ cursor: "pointer" }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = startupImage;
          }}
        />
        <div className={classes.userInfo}>
          <div className={classes.userNameContainer}>
            <div
              className={classes.userName}
              onClick={handleStartupClick}
              style={{ cursor: "pointer" }}
            >
              {startup.startupName}
            </div>
            <span className={classes.userConnectionType}>• Startup</span>
          </div>
          {startup.bio && <div className={classes.userBio}>{startup.bio}</div>}
          {startup.link && (
            <a
              href={startup.link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.userLink}
              onClick={handleLinkClick}
            >
              <span>{startup.linkText || startup.link}</span>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                />
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  };

  const handleRepostClick = (repostId) => {
    navigate(`/repost/${repostId}`, {
      state: {
        fromOwnProfile: true,
        userData: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage,
        },
      },
    });
  };

  const handleActivityChange = (selectedActivity) => {
    setActivityType(selectedActivity);
    if (currentUser?.uid) {
      // Reset all loading states
      setLoadingPosts(false);
      setLoadingBoosts(false);
      setLoadingComments(false);
      setLoadingReposts(false);

      // Set the appropriate loading state based on selection
      if (selectedActivity === "Posts") setLoadingPosts(true);
      if (selectedActivity === "Boosts") setLoadingBoosts(true);
      if (selectedActivity === "Comments") setLoadingComments(true);
      if (selectedActivity === "Reposts") setLoadingReposts(true);

      const activityTypeMap = {
        Posts: fetchPosts,
        Boosts: fetchReactions,
        Comments: fetchComments,
        Reposts: fetchReposts,
      };

      const fetchFunction = activityTypeMap[selectedActivity];
      if (fetchFunction) {
        fetchFunction();
      }
    }
  };

  const filteredStartups = useMemo(() => {
    if (!startups) return [];

    const filtered = startups.filter((startup) => {
      switch (startupType) {
        case "Owner":
          return startup.membershipType === "owner";
        case "TeamMember":
          return startup.membershipType === "member";
        case "Supported":
          return startup.supported;
        case "Following":
          return startup.following;
        default:
          return false;
      }
    });

    // For supported startups, deduplicate by keeping only the most recent donation
    if (startupType === "Supported") {
      const uniqueStartups = filtered.reduce((acc, current) => {
        const existing = acc.find((item) => item.id === current.id);

        if (!existing) {
          acc.push(current);
        } else {
          const currentDonationDate =
            current.supportedAt?.toDate?.() || new Date(current.supportedAt);
          const existingDonationDate =
            existing.supportedAt?.toDate?.() || new Date(existing.supportedAt);

          if (currentDonationDate > existingDonationDate) {
            const index = acc.findIndex((item) => item.id === current.id);
            acc[index] = current;
          }
        }

        return acc;
      }, []);

      return uniqueStartups.sort((a, b) => {
        const aDonationDate =
          a.supportedAt?.toDate?.() || new Date(a.supportedAt);
        const bDonationDate =
          b.supportedAt?.toDate?.() || new Date(b.supportedAt);
        return bDonationDate - aDonationDate;
      });
    }

    return filtered.sort((a, b) => {
      if (startupType === "Following") {
        const aTime = a.followedAt?.toDate?.() || new Date(a.followedAt);
        const bTime = b.followedAt?.toDate?.() || new Date(b.followedAt);
        return bTime - aTime;
      } else if (startupType === "Owner") {
        const aTime = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const bTime = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return bTime - aTime;
      } else if (startupType === "TeamMember") {
        const aTime = a.acceptedAt?.toDate?.() || new Date(a.acceptedAt);
        const bTime = b.acceptedAt?.toDate?.() || new Date(b.acceptedAt);
        return bTime - aTime;
      }
      return 0;
    });
  }, [startups, startupType]);

  const handlePostClick = (postId, fromComments = false) => {
    let navigationPath;
    let state = {
      fromUserProfile: false,
      userData: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        profileImage: userData.profileImage,
        urlSlug: userData.urlSlug, // Add this line
        id: userData.id, // Add this line
      },
    };

    switch (activityType) {
      case "Comments":
        navigationPath = `/comment/${postId}`;
        break;
      case "Reactions":
        navigationPath = `/reaction/${postId}`;
        state.reactionEmoji = reactions.find(
          (r) => r.originalPost.id === postId
        )?.emoji;
        break;
      case "Reposts":
        navigationPath = `/repost/${postId}`;
        break;
      default: // "Posts" or any other case
        navigationPath = `/post/${postId}`;
    }

    navigate(navigationPath, { state });
  };

  const handleShowAllComments = () => {
    navigate("/all-comments/me");
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  useEffect(() => {
    if (currentUser && activityType === "Posts") {
      fetchPosts();
    }
  }, [currentUser, activityType, fetchPosts]);

  const [showConnectionsModal, setShowConnectionsModal] = useState(false);

  const PostInteractionCount = ({
    postId,
    reposts,
    commentCount,
    onClick,
    onCommentClick,
  }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0 && commentCount === 0) return null;

    return (
      <>
        {commentCount > 0 && (
          <span>
            {commentCount} comment{commentCount !== 1 ? "s" : ""}
          </span>
        )}
        {repostCount > 0 && commentCount > 0 && (
          <span className={classes.dot}>•</span>
        )}
        {repostCount > 0 && (
          <span>
            {repostCount} repost{repostCount !== 1 ? "s" : ""}
          </span>
        )}
      </>
    );
  };

  const fetchConnectionsCount = useCallback(async (userId) => {
    const connectionsRef = collection(firestore, `users/${userId}/connections`);
    const connectionsSnapshot = await getDocs(connectionsRef);
    const connectedCount = connectionsSnapshot.docs.filter(
      (doc) => doc.data().status === "Connected"
    ).length;
    setConnectionsCount(connectedCount);
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchConnections();
    }
  }, [currentUser, fetchConnections]); // Add fetchConnections as dependency since it's memoized

  useEffect(() => {
    if (currentUser) {
      fetchConnectionsCount(currentUser.uid);
    }
  }, [currentUser, fetchConnectionsCount]);

  const ReactionsSummary = ({ reactions, onClick, post }) => {
    const reactionCount = Object.keys(reactions).length;
    const topEmojis = useMemo(() => getTopThreeEmojis(reactions), [reactions]);

    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
      const checkSafari = () => {
        const isSafari =
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
          /apple/i.test(navigator.vendor);
        setIsSafari(isSafari);
        document.documentElement.style.setProperty(
          "--emoji-font-size",
          isSafari ? "0.75rem" : "0.875rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-left",
          isSafari ? "-0.25rem" : "-0.25rem"
        );
        document.documentElement.style.setProperty(
          "--count-margin-left",
          isSafari ? "0.25rem" : "0.25rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-text-size",
          isSafari ? "0.75rem" : "0.875rem"
        );

        document.documentElement.style.setProperty(
          "--emoji-margin-bottom",
          isSafari ? "0rem" : "0rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-top",
          isSafari ? "0rem" : "0rem"
        );
      };

      checkSafari();
    }, []);

    return (
      <div>
        {reactionCount} boost{reactionCount !== 1 ? "s" : ""}
        {(post?.commentCount > 0 ||
          Object.keys(post?.reposts || {}).length > 0) && (
          <span className={classes.dot}>•</span>
        )}
      </div>
    );
  };

  const truncateText = (text, lines) => {
    const words = text.split(" ");
    const truncated = words.slice(0, lines * 10).join(" ");
    return truncated.length < text.length ? `${truncated}...` : truncated;
  };

  const RepostCount = ({ postId, reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div
        className={classes.repostCount}
        onClick={() => onClick(postId, reposts)}
      >
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const formatTimestamp = (date) => {
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return "now";
    if (minutes < 60) return `${minutes}m`;
    if (hours < 24) return `${hours}h`;
    if (days < 7) return `${days}d`;
    if (weeks < 4) return `${weeks}w`;
    if (months < 12) return `${months}mo`;
    return `${years}y`;
  };

  const toggleProfileModal = (isSave = false) => {
    if (!showProfileModal) {
      setProfileModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, profileModalScrollPosition);
      }, 0);
    }
    setShowProfileModal(!showProfileModal);
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  const toggleIntroModal = (isSave = false) => {
    if (!showIntroModal) {
      setIntroModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, introModalScrollPosition);
      }, 0);
    }
    setShowIntroModal(!showIntroModal);
    setIsIntroModalOpen(!isIntroModalOpen);
  };

  const toggleSkillsModal = (isSave = false) => {
    if (!showSkillsModal) {
      // Opening the modal
      setSkillsModalScrollPosition(window.scrollY);
    } else {
      // Closing the modal
      setTimeout(() => {
        window.scrollTo(0, skillsModalScrollPosition);
      }, 0);
    }
    setShowSkillsModal(!showSkillsModal);
    setIsSkillsModalOpen(!isSkillsModalOpen);
  };

  const toggleInterestsModal = (isSave = false) => {
    if (!showInterestsModal) {
      // Opening the modal
      setModalScrollPosition(window.scrollY);
    } else {
      // Closing the modal
      setTimeout(() => {
        window.scrollTo(0, modalScrollPosition);
      }, 0);
    }
    setShowInterestsModal(!showInterestsModal);
    setIsInterestsModalOpen(!isInterestsModalOpen);
  };

  const toggleAddExperienceModal = (isSave = false) => {
    if (!showAddExperienceModal) {
      setAddExperienceModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addExperienceModalScrollPosition);
      }, 0);
    }
    setShowAddExperienceModal(!showAddExperienceModal);
    setIsAddExperienceModalOpen(!isAddExperienceModalOpen);
  };

  const toggleEditExperienceModal = () => {
    if (!showEditExperienceModal) {
      setEditExperienceModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editExperienceModalScrollPosition);
      }, 0);
    }
    setShowEditExperienceModal(!showEditExperienceModal);
    setIsEditExperienceModalOpen(!isEditExperienceModalOpen);
    setShowEditSingleExperienceModal(false);
  };

  const toggleAddEducationModal = (isSave = false) => {
    if (!showAddEducationModal) {
      setAddEducationModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addEducationModalScrollPosition);
      }, 0);
    }
    setShowAddEducationModal(!showAddEducationModal);
    setIsAddEducationModalOpen(!isAddEducationModalOpen);
  };

  const toggleEditEducationModal = () => {
    if (!showEditEducationModal) {
      setEditEducationModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editEducationModalScrollPosition);
      }, 0);
    }
    setShowEditEducationModal(!showEditEducationModal);
    setIsEditEducationModalOpen(!isEditEducationModalOpen);
    setShowEditSingleEducationModal(false);
  };

  const openEditSingleEducationModal = (education) => {
    setSelectedEducation(education);
    setShowEditSingleEducationModal(true);
    setShowEditEducationModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (index) => {
    if (
      index !== 2 ||
      (userData.experiences && userData.experiences.length > 0)
    ) {
      setHoverStates((prevHoverStates) =>
        prevHoverStates.map((state, i) => (i === index ? true : state))
      );
    }
  };

  const handleMouseLeave = (index) => {
    if (
      index !== 2 ||
      (userData.experiences && userData.experiences.length > 0)
    ) {
      setHoverStates((prevHoverStates) =>
        prevHoverStates.map((state, i) => (i === index ? false : state))
      );
    }
  };

  const handleProfileSave = (newUserData) => {
    setUserData((prevData) => {
      const updatedData = { ...prevData };

      Object.keys(newUserData).forEach((key) => {
        if (key !== "experiences" && key !== "education") {
          updatedData[key] = newUserData[key];
        }
      });

      if (newUserData.experiences) {
        updatedData.experiences = newUserData.experiences;
      }

      if (newUserData.education) {
        updatedData.education = newUserData.education;
      }

      return updatedData;
    });
  };

  const handleExperienceAdded = (newExperience) => {
    setUserData((prevData) => ({
      ...prevData,
      experiences: [...prevData.experiences, newExperience],
    }));
  };

  const renderFilePreview = (postPreview) => {
    if (postPreview.mediaType === "image") {
      // Handle single image
      if (typeof postPreview.mediaUrl === "string") {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl} alt="Post preview" />
          </div>
        );
      }
      // Handle multiple images
      else if (
        Array.isArray(postPreview.mediaUrl) &&
        postPreview.mediaUrl.length > 0
      ) {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl[0]} alt="Post preview" />
            {postPreview.mediaUrl.length > 1 && (
              <div className={classes.multipleImagesIndicator}>
                +{postPreview.mediaUrl.length - 1}
              </div>
            )}
          </div>
        );
      }
    }

    if (postPreview.mediaType === "video") {
      return (
        <div className={classes.mediaPreview}>
          <video
            src={postPreview.mediaUrl}
            preload="metadata"
            style={{ display: "none" }} // Hide the video element completely
            muted
            playsInline
            onLoadedMetadata={(e) => {
              const video = e.target;
              const canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              video.currentTime = 0.1; // Set to 0.1s to avoid black frame

              video.addEventListener(
                "seeked",
                () => {
                  try {
                    // Draw the video frame to canvas
                    canvas
                      .getContext("2d")
                      .drawImage(video, 0, 0, canvas.width, canvas.height);

                    // Create and insert the static image
                    const img = new Image();
                    img.src = canvas.toDataURL();
                    img.style.width = "100%";
                    img.style.height = "100%";
                    img.style.objectFit = "cover";

                    // Find the container and insert the image
                    const container = video.parentNode;
                    container.insertBefore(img, container.firstChild);

                    // Cleanup
                    video.remove();
                  } catch (err) {
                    // If canvas operations fail, show the first frame of video as fallback
                    video.style.display = "block";
                    video.controls = false;
                    video.autoPlay = false;
                    video.style.pointerEvents = "none";
                  }
                },
                { once: true }
              );
            }}
          />
          <div className={classes.playButton}></div>
        </div>
      );
    }

    if (postPreview.mediaType === "pdf" || postPreview.fileName) {
      return (
        <div className={classes.filePreview}>
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      );
    }

    return null;
  };

  const handleEducationAdded = (newEducation) => {
    setUserData((prevData) => ({
      ...prevData,
      education: [...(prevData.education || []), newEducation],
    }));
  };

  const userImage = userData.profileImage || profileImage;

  const openEditSingleExperienceModal = (experience) => {
    setSelectedExperience(experience);
    setShowEditSingleExperienceModal(true);
    setShowEditExperienceModal(false);
  };

  const openConnectionsModal = () => {
    setConnectionsModalScrollPosition(window.scrollY);
    setShowConnectionsModal(true);
    setIsConnectionsModalOpen(true);
  };

  const handleModalClose = () => {
    setShowConnectionsModal(false);
    setIsConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, connectionsModalScrollPosition);
    }, 0);
  };

  // Update the renderComments function to use the new data structure
  const renderComments = () => {
    if (loadingComments) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {commentedPosts.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>
                You haven’t commented yet
              </p>{" "}
              <p className={classes.defaultText}>
                Posts you comment on will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}> commented on this </span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <img
                    src={greyPostImage}
                    alt="Default startup"
                    className={classes.noPostImage}
                  />
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 boosts</div>
                <span className={classes.dot}>•</span>
                <div>50 comments</div>
              </div>
            </div>
          ) : (
            <>
              {commentedPosts
                .slice(0, displayedComments)
                .map((commentedPost, index) => (
                  <React.Fragment key={commentedPost.id}>
                    {index !== 0 && <div className={classes.postBorder}></div>}
                    <div>
                      <div className={classes.postHeader}>
                        <span className={classes.postAuthor}>You</span>
                        <span className={classes.postMessage}>
                          {" "}
                          commented on this{" "}
                        </span>
                        <span className={classes.dot}>•</span>
                        <span>
                          {formatTimestamp(commentedPost.lastCommentedAt)}
                        </span>
                      </div>
                      <div
                        className={`${classes.post} ${
                          !commentedPost.originalPost.postPreview.mediaType &&
                          commentedPost.originalPost.postPreview.text
                            ? classes.textOnlyPost
                            : ""
                        }`}
                        onClick={() =>
                          handlePostClick(commentedPost.originalPost.id, true)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className={classes.postContent}>
                          {commentedPost.originalPost?.postPreview?.mediaType &&
                            renderFilePreview(
                              commentedPost.originalPost.postPreview
                            )}
                          {commentedPost.originalPost?.postPreview?.text && (
                            <p className={classes.textPreview}>
                              {truncateText(
                                commentedPost.originalPost.postPreview.text,
                                3
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {(commentedPost.originalPost?.reactions &&
                      Object.keys(commentedPost.originalPost.reactions).length >
                        0) ||
                    (commentedPost.originalPost?.reposts &&
                      Object.keys(commentedPost.originalPost.reposts).length >
                        0) ||
                    (commentedPost.originalPost?.commentCount &&
                      commentedPost.originalPost.commentCount > 0) ? (
                      <div className={classes.postInteractions}>
                        {commentedPost.originalPost?.reactions &&
                          Object.keys(commentedPost.originalPost.reactions)
                            .length > 0 && (
                            <ReactionsSummary
                              reactions={commentedPost.originalPost.reactions}
                              post={commentedPost.originalPost}
                              onClick={() =>
                                handleReactionsClick(
                                  commentedPost.originalPost.id,
                                  commentedPost.originalPost.reactions
                                )
                              }
                            />
                          )}

                        <PostInteractionCount
                          postId={commentedPost.originalPost?.id}
                          reposts={commentedPost.originalPost?.reposts}
                          commentCount={
                            commentedPost.originalPost?.commentCount
                          }
                          onClick={handleRepostsClick}
                          onCommentClick={() =>
                            handlePostClick(commentedPost.originalPost?.id)
                          }
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
            </>
          )}
          {commentedPosts.length > 3 &&
            displayedComments < commentedPosts.length && (
              <div
                className={classes.showAllPosts}
                onClick={handleShowAllComments}
              >
                Show all comments
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 12H5m14 0-4 4m4-4-4-4"
                  />
                </svg>
              </div>
            )}
        </div>
      </>
    );
  };

  const handleReactionClick = (reactionId, reactionEmoji) => {
    navigate(`/reaction/${reactionId}`, {
      state: { fromUserProfile: false, reactionEmoji: reactionEmoji },
    });
  };

  const renderReactions = () => {
    if (loadingBoosts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reactions.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>You haven’t boosted yet</p>{" "}
              <p className={classes.defaultText}>
                Posts you boost will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}> boosted this</span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <img
                    src={greyPostImage}
                    alt="Default startup"
                    className={classes.noPostImage}
                  />
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 boosts</div>
                <span className={classes.dot}>•</span>
                <div>50 comments</div>
              </div>
            </div>
          ) : (
            <>
              {reactions.slice(0, displayedReactions).map((reaction, index) => (
                <React.Fragment key={reaction.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>You</span>
                      <span className={classes.postMessage}> boosted this</span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(reaction.reactedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !reaction.originalPost.postPreview.mediaType &&
                        reaction.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() =>
                        handleReactionClick(reaction.id, reaction.emoji)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {reaction.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(reaction.originalPost.postPreview)}
                        {reaction.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              reaction.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {(reaction.originalPost?.reactions &&
                    Object.keys(reaction.originalPost.reactions).length > 0) ||
                  (reaction.originalPost?.reposts &&
                    Object.keys(reaction.originalPost.reposts).length > 0) ||
                  (reaction.originalPost?.commentCount &&
                    reaction.originalPost.commentCount > 0) ? (
                    <div className={classes.postInteractions}>
                      {reaction.originalPost?.reactions &&
                        Object.keys(reaction.originalPost.reactions).length >
                          0 && (
                          <ReactionsSummary
                            reactions={reaction.originalPost.reactions}
                            post={reaction.originalPost}
                            onClick={() =>
                              handleReactionsClick(
                                reaction.originalPost.id,
                                reaction.originalPost.reactions
                              )
                            }
                          />
                        )}

                      <PostInteractionCount
                        postId={reaction.originalPost?.id}
                        reposts={reaction.originalPost?.reposts}
                        commentCount={reaction.originalPost?.commentCount}
                        onClick={handleRepostsClick}
                        onCommentClick={() =>
                          handlePostClick(reaction.originalPost?.id)
                        }
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
            </>
          )}
          {reactions.length > 3 && displayedReactions < reactions.length && (
            <div
              className={classes.showAllPosts}
              onClick={() => navigate("/all-reactions/me")}
            >
              Show all boosts
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderReposts = () => {
    if (loadingReposts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reposts.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>
                You haven’t reposted yet
              </p>{" "}
              <p className={classes.defaultText}>
                Posts you repost will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}> reposted this </span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <img
                    src={greyPostImage}
                    alt="Default startup"
                    className={classes.noPostImage}
                  />
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 boosts</div>
                <span className={classes.dot}>•</span>
                <div>50 comments</div>
              </div>
            </div>
          ) : (
            <>
              {reposts.slice(0, displayedReposts).map((repost, index) => (
                <React.Fragment key={repost.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>You</span>
                      <span className={classes.postMessage}>
                        {" "}
                        reposted this{" "}
                      </span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(repost.repostedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !repost.originalPost.postPreview.mediaType &&
                        repost.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handleRepostClick(repost.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {repost.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(repost.originalPost.postPreview)}
                        {repost.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              repost.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {(repost.originalPost?.reactions &&
                    Object.keys(repost.originalPost.reactions).length > 0) ||
                  (repost.originalPost?.reposts &&
                    Object.keys(repost.originalPost.reposts).length > 0) ||
                  (repost.originalPost?.commentCount &&
                    repost.originalPost.commentCount > 0) ? (
                    <div className={classes.postInteractions}>
                      {repost.originalPost?.reactions &&
                        Object.keys(repost.originalPost.reactions).length >
                          0 && (
                          <ReactionsSummary
                            reactions={repost.originalPost.reactions}
                            post={repost.originalPost}
                            onClick={() =>
                              handleReactionsClick(
                                repost.originalPost.id,
                                repost.originalPost.reactions
                              )
                            }
                          />
                        )}

                      <PostInteractionCount
                        postId={repost.originalPost?.id}
                        reposts={repost.originalPost?.reposts}
                        commentCount={repost.originalPost?.commentCount}
                        onClick={handleRepostsClick}
                        onCommentClick={() =>
                          handlePostClick(repost.originalPost?.id)
                        }
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {reposts.length > 3 && displayedReposts < reposts.length && (
          <div
            className={classes.showAllPosts}
            onClick={() => navigate("/all-reposts/me")}
          >
            Show all reposts
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const handleCloseTouch = (modalToggleFunction) => (event) => {
    event.preventDefault();
    modalToggleFunction();
  };

  useEffect(() => {
    if (currentUser) {
      if (activityType === "Posts") {
        fetchPosts();
      } else if (activityType === "Reposts") {
        fetchReposts();
      } else if (activityType === "Reactions") {
        fetchReactions();
      } else if (activityType === "Comments") {
        fetchComments();
      }
    }
  }, [
    currentUser,
    activityType,
    fetchPosts,
    fetchReposts,
    fetchReactions,
    fetchComments,
  ]);

  const fetchActivityCounts = useCallback(async () => {
    if (!currentUser) return;

    try {
      const userId = currentUser.uid;

      // Fetch posts count
      const postsRef = collection(firestore, `users/${userId}/posts`);
      const postsSnapshot = await getDocs(query(postsRef, limit(1)));
      const postsCount = postsSnapshot.size;

      // Fetch reactions count
      const reactionsRef = collection(
        firestore,
        `users/${userId}/reactedPosts`
      );
      const reactionsSnapshot = await getDocs(query(reactionsRef, limit(1)));
      const reactionsCount = reactionsSnapshot.size;

      // Fetch reposts count
      const repostsRef = collection(firestore, `users/${userId}/reposts`);
      const repostsSnapshot = await getDocs(query(repostsRef, limit(1)));
      const repostsCount = repostsSnapshot.size;

      // Fetch comments count
      const commentsRef = collection(
        firestore,
        `users/${userId}/commentedPosts`
      );
      const commentsSnapshot = await getDocs(query(commentsRef, limit(1)));
      const commentsCount = commentsSnapshot.size;

      setActivityCounts({
        posts: postsCount,
        reactions: reactionsCount,
        reposts: repostsCount,
        comments: commentsCount,
      });
    } catch (error) {}
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchActivityCounts();
    }
  }, [currentUser, fetchActivityCounts]);

  const fetchFollowingCount = useCallback(async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setFollowingCount(userData.followingCount || 0);
      }
    } catch (error) {}
  }, []);

  const fetchStartupData = async (userId) => {
    try {
      const batch = [];

      // Fetch owned startups
      const startupsSnapshot = await getDocs(
        collection(firestore, `users/${userId}/startups`)
      );
      const startupsData = startupsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        membershipType: "owner",
      }));
      batch.push(...startupsData);

      // Fetch TeamMember startups
      const allUsersSnapshot = await getDocs(collection(firestore, "users"));
      const teamMemberPromises = allUsersSnapshot.docs.map(async (userDoc) => {
        const startupRef = collection(
          firestore,
          `users/${userDoc.id}/startups`
        );
        const startupDocs = await getDocs(startupRef);

        const startupTeamPromises = startupDocs.docs.map(async (startupDoc) => {
          const teamRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupDoc.id}/team/${userId}`
          );
          const teamDoc = await getDoc(teamRef);

          if (teamDoc.exists() && teamDoc.data().status === "active") {
            return {
              id: startupDoc.id,
              ownerId: userDoc.id,
              ...startupDoc.data(),
              membershipType: "member",
              role: teamDoc.data().role,
              acceptedAt: teamDoc.data().acceptedAt, // Changed from joinedAt to acceptedAt
            };
          }
          return null;
        });

        const resolvedStartups = await Promise.all(startupTeamPromises);
        return resolvedStartups.filter((startup) => startup !== null);
      });

      // Fetch donated/supported startups
      const donationsRef = collection(firestore, `users/${userId}/donations`);
      const donationsSnapshot = await getDocs(donationsRef);
      const supportedPromises = donationsSnapshot.docs.map(
        async (donationDoc) => {
          const donationData = donationDoc.data();
          if (donationData.status !== "completed") return null;

          const startupRef = doc(
            firestore,
            `users/${donationData.startupOwnerId}/startups/${donationData.startupId}`
          );
          const startupDoc = await getDoc(startupRef);

          if (startupDoc.exists()) {
            return {
              id: startupDoc.id,
              ...startupDoc.data(),
              supported: true,
              supportedAt: donationData.createdAt,
              ownerId: donationData.startupOwnerId,
              donationAmount: donationData.amount,
              donationCurrency: donationData.currency,
            };
          }
          return null;
        }
      );

      // Fetch followed startups
      const followedStartupsRef = collection(
        firestore,
        `users/${userId}/startupsFollowing`
      );
      const followedSnapshot = await getDocs(followedStartupsRef);

      const followedPromises = followedSnapshot.docs.map(async (followDoc) => {
        const startupRef = doc(
          firestore,
          `users/${followDoc.data().ownerId}/startups/${followDoc.id}`
        );
        const startupDoc = await getDoc(startupRef);

        if (startupDoc.exists()) {
          return {
            id: startupDoc.id,
            ...startupDoc.data(),
            following: true,
            followedAt: followDoc.data().followedAt,
            ownerId: followDoc.data().ownerId,
          };
        }
        return null;
      });

      const teamMemberStartups = (await Promise.all(teamMemberPromises)).flat();
      const supportedStartups = (await Promise.all(supportedPromises)).filter(
        Boolean
      );
      const followedStartups = (await Promise.all(followedPromises)).filter(
        Boolean
      );

      batch.push(
        ...teamMemberStartups,
        ...supportedStartups,
        ...followedStartups
      );

      const counts = {
        Owner: batch.filter((s) => s.membershipType === "owner").length,
        TeamMember: batch.filter((s) => s.membershipType === "member").length,
        Supported: batch.filter((s) => s.supported).length,
        Following: batch.filter((s) => s.following).length,
      };
      setStartupCounts(counts);

      setStartups(batch);
    } catch (error) {}
  };

  // Complete fetchAllData function
  const fetchAllData = useCallback(
    async (userId) => {
      try {
        setIsLoading(true);
        setIsLoadingProfile(true);
        setIsLoadingActivity(true);
        setIsLoadingStartups(true);
        setIsLoadingConnections(true);
        setIsLoadingCommunities(true);
        setIsLoadingEvents(true);

        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setFollowingCount(data.followingCount || 0);

          const [experiencesSnapshot, educationSnapshot] = await Promise.all([
            getDocs(collection(firestore, "users", userId, "experiences")),
            getDocs(collection(firestore, "users", userId, "education")),
          ]);

          const experiences = experiencesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            dateAdded: doc.data().dateAdded,
          }));

          const education = educationSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setUserData((prevData) => ({
            ...prevData,
            ...data,
            experiences: experiences || [],
            education: education || [],
          }));
          setIsLoadingProfile(false);

          try {
            await fetchStartupData(userId);
          } finally {
            setIsLoadingStartups(false);
          }

          try {
            await Promise.all([
              fetchPosts(),
              fetchComments(),
              fetchReactions(),
              fetchReposts(),
              fetchActivityCounts(),
            ]);
          } finally {
            setIsLoadingActivity(false);
          }

          try {
            await Promise.all([
              fetchConnectionsCount(userId),
              fetchFollowingCount(userId),
            ]);
          } finally {
            setIsLoadingConnections(false);
          }

          try {
            const userCommunitiesRef = collection(
              firestore,
              `users/${userId}/communities`
            );
            const memberOfRef = collection(
              firestore,
              `users/${userId}/communitiesMemberOf`
            );
            const moderatingRef = collection(
              firestore,
              `users/${userId}/communitiesModerating`
            );

            const [
              userCommunitiesSnapshot,
              memberOfSnapshot,
              moderatingSnapshot,
            ] = await Promise.all([
              getDocs(userCommunitiesRef),
              getDocs(memberOfRef),
              getDocs(moderatingRef),
            ]);

            const ownedCommunitiesData = userCommunitiesSnapshot.docs.map(
              (doc) => ({
                id: doc.id,
                ...doc.data(),
                membershipType: "owner",
                createdAt: doc.data().createdAt,
              })
            );

            // Fetch member communities
            const memberCommunitiesPromises = memberOfSnapshot.docs.map(
              async (memberDoc) => {
                const memberData = memberDoc.data();
                const usersRef = collection(firestore, "users");
                const usersSnapshot = await getDocs(usersRef);

                for (const userDoc of usersSnapshot.docs) {
                  const communityRef = doc(
                    firestore,
                    `users/${userDoc.id}/communities/${memberDoc.id}`
                  );
                  const communityDoc = await getDoc(communityRef);

                  if (communityDoc.exists()) {
                    return {
                      id: memberDoc.id,
                      ownerId: userDoc.id,
                      ...communityDoc.data(),
                      membershipType: "member",
                      joinedAt: memberData.joinedAt || memberData.createdAt,
                    };
                  }
                }
                return null;
              }
            );

            // Fetch moderator communities
            const moderatorCommunitiesPromises = moderatingSnapshot.docs.map(
              async (moderatorDoc) => {
                const moderatorData = moderatorDoc.data();
                const communityRef = doc(
                  firestore,
                  `users/${moderatorData.communityOwnerId}/communities/${moderatorDoc.id}`
                );
                const communityDoc = await getDoc(communityRef);

                if (communityDoc.exists()) {
                  return {
                    id: moderatorDoc.id,
                    ownerId: moderatorData.communityOwnerId,
                    ...communityDoc.data(),
                    membershipType: "moderator",
                    acceptedAt: moderatorData.acceptedAt,
                  };
                }
                return null;
              }
            );

            const [memberCommunitiesData, moderatorCommunitiesData] =
              await Promise.all([
                Promise.all(memberCommunitiesPromises),
                Promise.all(moderatorCommunitiesPromises),
              ]);

            const allCommunitiesData = [
              ...ownedCommunitiesData,
              ...memberCommunitiesData.filter(Boolean),
              ...moderatorCommunitiesData.filter(Boolean),
            ];

            setCommunities(allCommunitiesData);

            const communityCounts = {
              Owner: allCommunitiesData.filter(
                (c) => c.membershipType === "owner"
              ).length,
              Moderator: allCommunitiesData.filter(
                (c) => c.membershipType === "moderator"
              ).length,
              Member: allCommunitiesData.filter(
                (c) => c.membershipType === "member"
              ).length,
            };
            setCommunityCounts(communityCounts);
          } finally {
            setIsLoadingCommunities(false);
          }

          try {
            const ownedEventsRef = collection(
              firestore,
              `users/${userId}/events`
            );
            const eventsOrganisingRef = collection(
              firestore,
              `users/${userId}/eventsOrganising`
            );
            const attendingEventsRef = collection(
              firestore,
              `users/${userId}/eventsAttending`
            );

            const [
              ownedEventsSnapshot,
              eventsOrganisingSnapshot,
              attendingEventsSnapshot,
            ] = await Promise.all([
              getDocs(ownedEventsRef),
              getDocs(eventsOrganisingRef),
              getDocs(attendingEventsRef),
            ]);

            const ownedEvents = ownedEventsSnapshot.docs.map((docSnapshot) => ({
              id: docSnapshot.id,
              ...docSnapshot.data(),
              membershipType: "owner",
            }));

            const organiserEventsPromises = eventsOrganisingSnapshot.docs.map(
              async (docSnapshot) => {
                const eventData = docSnapshot.data();
                if (!eventData.eventOwnerId) return null;

                const eventRef = doc(
                  firestore,
                  `users/${eventData.eventOwnerId}/events/${docSnapshot.id}`
                );
                const eventSnapshot = await getDoc(eventRef);

                if (eventSnapshot.exists()) {
                  const fullEventData = eventSnapshot.data();
                  // Create the event object BEFORE spreading the full event data
                  const organiserEvent = {
                    id: docSnapshot.id,
                    ...fullEventData,
                    membershipType: "Organising", // Make sure this exactly matches what we check for
                    eventOwnerId: eventData.eventOwnerId,
                    acceptedAt: eventData.acceptedAt,
                    // Force these fields again to ensure they're correct
                    membershipType: "Organising", // Set it again after spread to be sure
                    eventName: fullEventData.eventName,
                    eventImage: fullEventData.eventImage,
                    eventUrlSlug: fullEventData.eventUrlSlug,
                    tagline: fullEventData.tagline,
                    link: fullEventData.link,
                    linkText: fullEventData.linkText,
                    isOnline: fullEventData.isOnline,
                    city: fullEventData.city,
                    country: fullEventData.country,
                  };
                  return organiserEvent;
                }
                return null;
              }
            );

            const organiserEvents = (
              await Promise.all(organiserEventsPromises)
            ).filter(Boolean);

            const attendingEventsPromises = attendingEventsSnapshot.docs.map(
              async (docSnapshot) => {
                const eventData = docSnapshot.data();
                const eventRef = doc(
                  firestore,
                  `users/${eventData.eventOwnerId}/events/${docSnapshot.id}`
                );
                const eventSnapshot = await getDoc(eventRef);

                if (eventSnapshot.exists()) {
                  return {
                    id: docSnapshot.id,
                    ...eventSnapshot.data(),
                    membershipType: "attending",
                    endDate: eventData.endDate,
                    endTime: eventData.endTime,
                    timezone: eventData.timezone,
                  };
                }
                return null;
              }
            );

            const attendingEvents = (
              await Promise.all(attendingEventsPromises)
            ).filter(Boolean);
            const allEvents = [
              ...ownedEvents,
              ...organiserEvents,
              ...attendingEvents,
            ];

            const counts = {
              Owner: ownedEvents.length,
              Organising: organiserEvents.length, // Make sure this is being set correctly
              Attending: attendingEvents.filter(
                (event) =>
                  !isEventClosed({
                    endDate: event.endDate,
                    endTime: event.endTime,
                    timezone: event.timezone,
                  })
              ).length,
              Attended: attendingEvents.filter((event) =>
                isEventClosed({
                  endDate: event.endDate,
                  endTime: event.endTime,
                  timezone: event.timezone,
                })
              ).length,
            };

            setEvents(allEvents);
            setEventCounts(counts);
          } finally {
            setIsLoadingEvents(false);
          }
        }

        try {
          await fetchConnections();
        } finally {
          setIsLoadingConnections(false);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [
      fetchPosts,
      fetchComments,
      fetchReactions,
      fetchReposts,
      fetchConnectionsCount,
      fetchActivityCounts,
      fetchFollowingCount,
      fetchConnections,
    ]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchAllData(user.uid);
      } else {
        setCurrentUser(null);
        setUserData({});
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [fetchAllData]);

  const handleInitiateDelete = () => {
    setIsConfirmationOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await handleDeleteUser(currentUser, setDeleteStatus);
      navigate("/");
    } catch (error) {
      setDeleteStatus("Error closing account. Please try again.");
      setIsDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmationOpen(false);
  };

  const handleDeleteUser = async (currentUser, setDeleteStatus) => {
    try {
      setDeleteStatus("Closing account...");
      const userDocRef = doc(firestore, `users/${currentUser.uid}`);

      const connectionsRef = collection(userDocRef, "connections");
      const connectionsSnapshot = await getDocs(connectionsRef);

      await Promise.all(
        connectionsSnapshot.docs.map(async (connectionDoc) => {
          const connectionId = connectionDoc.id;
          const reverseConnectionRef = doc(
            firestore,
            `users/${connectionId}/connections/${currentUser.uid}`
          );
          await deleteDoc(reverseConnectionRef);
          await deleteDoc(connectionDoc.ref);
        })
      );

      const donationsRef = collection(userDocRef, "donations");
      const donationsSnapshot = await getDocs(donationsRef);

      await Promise.all(
        donationsSnapshot.docs.map(async (donationDoc) => {
          const donationData = donationDoc.data();
          const startupRef = doc(
            firestore,
            `users/${donationData.startupOwnerId}/startups/${donationData.startupId}/donations/${donationDoc.id}`
          );
          await deleteDoc(startupRef);
          await deleteDoc(donationDoc.ref);
        })
      );

      const notificationsRef = collection(userDocRef, "notifications");
      const notificationsSnapshot = await getDocs(notificationsRef);
      await Promise.all(
        notificationsSnapshot.docs.map((docSnapshot) =>
          deleteDoc(docSnapshot.ref)
        )
      );

      const postsRef = collection(userDocRef, "posts");
      const postsSnapshot = await getDocs(postsRef);

      await Promise.all(
        postsSnapshot.docs.map(async (postDoc) => {
          const commentsRef = collection(postDoc.ref, "comments");
          const commentsSnapshot = await getDocs(commentsRef);
          await Promise.all(
            commentsSnapshot.docs.map((docSnapshot) =>
              deleteDoc(docSnapshot.ref)
            )
          );
          await deleteDoc(postDoc.ref);
        })
      );

      const commentedPostsRef = collection(userDocRef, "commentedPosts");
      const commentedPostsSnapshot = await getDocs(commentedPostsRef);

      await Promise.all(
        commentedPostsSnapshot.docs.map(async (commentDoc) => {
          const postRef = doc(
            firestore,
            `users/${commentDoc.data().originalPosterId}/posts/${
              commentDoc.data().postId
            }/comments/${commentDoc.id}`
          );
          await deleteDoc(postRef);
          await deleteDoc(commentDoc.ref);
        })
      );

      const reactedPostsRef = collection(userDocRef, "reactedPosts");
      const reactedPostsSnapshot = await getDocs(reactedPostsRef);
      await Promise.all(
        reactedPostsSnapshot.docs.map((docSnapshot) =>
          deleteDoc(docSnapshot.ref)
        )
      );

      const communityPostsRef = collection(userDocRef, "communityPosts");
      const communityPostsSnapshot = await getDocs(communityPostsRef);

      await Promise.all(
        communityPostsSnapshot.docs.map(async (postDoc) => {
          const communityRef = doc(
            firestore,
            `users/${postDoc.data().communityOwnerId}/communities/${
              postDoc.data().communityId
            }/posts/${postDoc.id}`
          );
          const commentsRef = collection(communityRef, "comments");
          const commentsSnapshot = await getDocs(commentsRef);
          await Promise.all(
            commentsSnapshot.docs.map((docSnapshot) =>
              deleteDoc(docSnapshot.ref)
            )
          );
          await deleteDoc(communityRef);
          await deleteDoc(postDoc.ref);
        })
      );

      const eventsAttendingRef = collection(userDocRef, "eventsAttending");
      const eventsOrganisingRef = collection(userDocRef, "eventsOrganising");
      const [attendingSnapshot, organisingSnapshot] = await Promise.all([
        getDocs(eventsAttendingRef),
        getDocs(eventsOrganisingRef),
      ]);

      await Promise.all([
        ...attendingSnapshot.docs.map(async (docSnapshot) => {
          const eventRef = doc(
            firestore,
            `users/${docSnapshot.data().eventOwnerId}/events/${
              docSnapshot.id
            }/attendees/${currentUser.uid}`
          );
          await deleteDoc(eventRef);
          await deleteDoc(docSnapshot.ref);
        }),
        ...organisingSnapshot.docs.map(async (docSnapshot) => {
          const eventRef = doc(
            firestore,
            `users/${docSnapshot.data().eventOwnerId}/events/${
              docSnapshot.id
            }/hosts/${currentUser.uid}`
          );
          await deleteDoc(eventRef);
          await deleteDoc(docSnapshot.ref);
        }),
      ]);

      const experiencesRef = collection(userDocRef, "experiences");
      const educationRef = collection(userDocRef, "education");
      const [experiencesSnapshot, educationSnapshot] = await Promise.all([
        getDocs(experiencesRef),
        getDocs(educationRef),
      ]);

      await Promise.all([
        ...experiencesSnapshot.docs.map((docSnapshot) =>
          deleteDoc(docSnapshot.ref)
        ),
        ...educationSnapshot.docs.map((docSnapshot) =>
          deleteDoc(docSnapshot.ref)
        ),
      ]);

      const startupsRef = collection(userDocRef, "startups");
      const startupsSnapshot = await getDocs(startupsRef);

      await Promise.all(
        startupsSnapshot.docs.map(async (startupDoc) => {
          const startupId = startupDoc.id;
          const startup = startupDoc.data();

          const [
            communityPostsSnapshot,
            postsSnapshot,
            reactedPostsSnapshot,
            commentedPostsSnapshot,
            followersSnapshot,
            donationsSnapshot,
            teamSnapshot,
          ] = await Promise.all([
            getDocs(collection(startupDoc.ref, "communityPosts")),
            getDocs(collection(startupDoc.ref, "posts")),
            getDocs(collection(startupDoc.ref, "reactedPosts")),
            getDocs(collection(startupDoc.ref, "commentedPosts")),
            getDocs(collection(startupDoc.ref, "followers")),
            getDocs(collection(startupDoc.ref, "donations")),
            getDocs(collection(startupDoc.ref, "team")),
          ]);

          await Promise.all(
            communityPostsSnapshot.docs.map(async (postDoc) => {
              const postData = postDoc.data();
              if (postData.communityId) {
                const communityPostRef = doc(
                  firestore,
                  `communities/${postData.communityId}/posts/${postDoc.id}`
                );
                const commentsRef = collection(communityPostRef, "comments");
                const commentsSnapshot = await getDocs(commentsRef);
                await Promise.all(
                  commentsSnapshot.docs.map((docSnapshot) =>
                    deleteDoc(docSnapshot.ref)
                  )
                );
                await deleteDoc(communityPostRef);
              }
              await deleteDoc(postDoc.ref);
            })
          );

          await Promise.all(
            postsSnapshot.docs.map(async (postDoc) => {
              const commentsRef = collection(postDoc.ref, "comments");
              const commentsSnapshot = await getDocs(commentsRef);
              await Promise.all(
                commentsSnapshot.docs.map((docSnapshot) =>
                  deleteDoc(docSnapshot.ref)
                )
              );
              await deleteDoc(postDoc.ref);
            })
          );

          await Promise.all(
            followersSnapshot.docs.map(async (followerDoc) => {
              const followerId = followerDoc.id;
              const followingRef = doc(
                firestore,
                `users/${followerId}/startupsFollowing/${startupId}`
              );
              await deleteDoc(followingRef);
              await deleteDoc(followerDoc.ref);
            })
          );

          await Promise.all(
            teamSnapshot.docs.map(async (teamDoc) => {
              const teamMemberId = teamDoc.id;
              const memberTeamRef = doc(
                firestore,
                `users/${teamMemberId}/team/${startupId}`
              );
              await deleteDoc(memberTeamRef);
              await deleteDoc(teamDoc.ref);
            })
          );

          await Promise.all([
            ...reactedPostsSnapshot.docs.map((docSnapshot) =>
              deleteDoc(docSnapshot.ref)
            ),
            ...commentedPostsSnapshot.docs.map((docSnapshot) =>
              deleteDoc(docSnapshot.ref)
            ),
            ...donationsSnapshot.docs.map((docSnapshot) =>
              deleteDoc(docSnapshot.ref)
            ),
          ]);

          if (startup.startupImage) {
            try {
              if (
                !startup.startupImage.includes("startupImage.jpg") &&
                !startup.startupImage.includes("default.jpg")
              ) {
                const storage = getStorage();
                const imageUrl = new URL(startup.startupImage);
                const imagePath = decodeURIComponent(
                  imageUrl.pathname.split("/o/")[1].split("?")[0]
                );
                const imageRef = ref(storage, imagePath);
                try {
                  await getDownloadURL(imageRef);
                  await deleteObject(imageRef);
                } catch (error) {
                  if (error.code !== "storage/object-not-found") {
                    throw error;
                  }
                }
              }
            } catch (error) {}
          }

          await deleteDoc(startupDoc.ref);
        })
      );

      const communitiesRef = collection(userDocRef, "communities");
      const communitiesSnapshot = await getDocs(communitiesRef);

      await Promise.all(
        communitiesSnapshot.docs.map(async (communityDoc) => {
          const communityId = communityDoc.id;
          const community = communityDoc.data();

          const [membersSnapshot, moderatorsSnapshot, postsSnapshot] =
            await Promise.all([
              getDocs(collection(communityDoc.ref, "members")),
              getDocs(collection(communityDoc.ref, "moderators")),
              getDocs(collection(communityDoc.ref, "posts")),
            ]);

          await Promise.all(
            postsSnapshot.docs.map(async (postDoc) => {
              const commentsRef = collection(postDoc.ref, "comments");
              const commentsSnapshot = await getDocs(commentsRef);
              await Promise.all(
                commentsSnapshot.docs.map((docSnapshot) =>
                  deleteDoc(docSnapshot.ref)
                )
              );
              await deleteDoc(postDoc.ref);
            })
          );

          await Promise.all([
            ...membersSnapshot.docs.map(async (memberDoc) => {
              const memberRef = doc(
                firestore,
                `users/${memberDoc.id}/communitiesMemberOf/${communityId}`
              );
              await deleteDoc(memberRef);
              await deleteDoc(memberDoc.ref);
            }),
            ...moderatorsSnapshot.docs.map(async (modDoc) => {
              const modRef = doc(
                firestore,
                `users/${modDoc.id}/communitiesModerating/${communityId}`
              );
              await deleteDoc(modRef);
              await deleteDoc(modDoc.ref);
            }),
          ]);

          if (
            community.communityImage &&
            !community.communityImage.includes("communityImage.jpg")
          ) {
            const storage = getStorage();
            const imageUrl = new URL(community.communityImage);
            const imagePath = decodeURIComponent(
              imageUrl.pathname.split("/o/")[1].split("?")[0]
            );
            const imageRef = ref(storage, imagePath);
            await deleteObject(imageRef);
          }

          await deleteDoc(communityDoc.ref);
        })
      );

      const eventsRef = collection(userDocRef, "events");
      const eventsSnapshot = await getDocs(eventsRef);

      await Promise.all(
        eventsSnapshot.docs.map(async (eventDoc) => {
          const eventId = eventDoc.id;
          const event = eventDoc.data();

          const [hostsSnapshot, attendeesSnapshot] = await Promise.all([
            getDocs(collection(eventDoc.ref, "hosts")),
            getDocs(collection(eventDoc.ref, "attendees")),
          ]);

          await Promise.all([
            ...hostsSnapshot.docs.map(async (hostDoc) => {
              const hostRef = doc(
                firestore,
                `users/${hostDoc.id}/eventsOrganising/${eventId}`
              );
              await deleteDoc(hostRef);
              await deleteDoc(hostDoc.ref);
            }),
            ...attendeesSnapshot.docs.map(async (attendeeDoc) => {
              const attendeeRef = doc(
                firestore,
                `users/${attendeeDoc.id}/eventsAttending/${eventId}`
              );
              await deleteDoc(attendeeRef);
              await deleteDoc(attendeeDoc.ref);
            }),
          ]);

          if (
            event.eventImage &&
            !event.eventImage.includes("eventImage.jpg")
          ) {
            const storage = getStorage();
            const imageUrl = new URL(event.eventImage);
            const imagePath = decodeURIComponent(
              imageUrl.pathname.split("/o/")[1].split("?")[0]
            );
            const imageRef = ref(storage, imagePath);
            await deleteObject(imageRef);
          }

          await deleteDoc(eventDoc.ref);
        })
      );

      const startupsFollowingRef = collection(userDocRef, "startupsFollowing");
      const teamRef = collection(userDocRef, "team");
      const [followingSnapshot, teamSnapshot] = await Promise.all([
        getDocs(startupsFollowingRef),
        getDocs(teamRef),
      ]);

      await Promise.all([
        ...followingSnapshot.docs.map(async (docSnapshot) => {
          const startupRef = doc(
            firestore,
            `users/${docSnapshot.data().ownerId}/startups/${
              docSnapshot.id
            }/followers/${currentUser.uid}`
          );
          await deleteDoc(startupRef);
          await deleteDoc(docSnapshot.ref);
        }),
        ...teamSnapshot.docs.map(async (docSnapshot) => {
          const startupRef = doc(
            firestore,
            `users/${docSnapshot.data().ownerId}/startups/${
              docSnapshot.id
            }/team/${currentUser.uid}`
          );
          await deleteDoc(startupRef);
          await deleteDoc(docSnapshot.ref);
        }),
      ]);

      const communitiesMemberOfRef = collection(
        userDocRef,
        "communitiesMemberOf"
      );
      const communityModeratingRef = collection(
        userDocRef,
        "communitiesModerating"
      );
      const [memberOfSnapshot, moderatingSnapshot] = await Promise.all([
        getDocs(communitiesMemberOfRef),
        getDocs(communityModeratingRef),
      ]);

      await Promise.all([
        ...memberOfSnapshot.docs.map(async (docSnapshot) => {
          const communityRef = doc(
            firestore,
            `users/${docSnapshot.data().communityOwnerId}/communities/${
              docSnapshot.id
            }/members/${currentUser.uid}`
          );
          await deleteDoc(communityRef);
          await deleteDoc(docSnapshot.ref);
        }),
        ...moderatingSnapshot.docs.map(async (docSnapshot) => {
          const communityRef = doc(
            firestore,
            `users/${docSnapshot.data().communityOwnerId}/communities/${
              docSnapshot.id
            }/moderators/${currentUser.uid}`
          );
          await deleteDoc(communityRef);
          await deleteDoc(docSnapshot.ref);
        }),
      ]);

      const userData = (await getDoc(userDocRef)).data();
      if (
        userData.profileImage &&
        !userData.profileImage.includes("profileImage.jpg")
      ) {
        const storage = getStorage();
        const imageUrl = new URL(userData.profileImage);
        const imagePath = decodeURIComponent(
          imageUrl.pathname.split("/o/")[1].split("?")[0]
        );
        const imageRef = ref(storage, imagePath);
        try {
          await deleteObject(imageRef);
        } catch (error) {}
      }

      await deleteDoc(userDocRef);

      try {
        await currentUser.delete();
      } catch (error) {
        if (error.code === "auth/requires-recent-login") {
          await auth.signOut();
          setDeleteStatus(
            "For security reasons, please sign in again to delete your account"
          );
          navigate("/login", { state: { afterDelete: true } });
          return;
        }
        throw error;
      }

      setDeleteStatus("Account closed");
      navigate("/");
      return true;
    } catch (error) {
      if (error.code === "auth/requires-recent-login") {
        await auth.signOut();
      }
      throw error;
    }
  };

  const arePasswordsValid = () => {
    const hasMinLength =
      currentPassword.length >= 6 &&
      newPassword.length >= 6 &&
      confirmPassword.length >= 6;
    const passwordsMatch = newPassword === confirmPassword;

    return hasMinLength && passwordsMatch && isCurrentPasswordValid;
  };

  const checkCurrentPassword = async () => {
    if (currentPassword.length >= 6) {
      try {
        await signInWithEmailAndPassword(
          auth,
          currentUser.email,
          currentPassword
        );
        setIsCurrentPasswordValid(true);
        setPasswordError("");
      } catch (error) {
        setIsCurrentPasswordValid(false);
        setPasswordError("Current password is incorrect");
      }
    }
  };

  // Add this useEffect for real-time validation
  useEffect(() => {
    const hasMinLength =
      currentPassword.length >= 6 &&
      newPassword.length >= 6 &&
      confirmPassword.length >= 6;
    const passwordsMatch = newPassword === confirmPassword;
    const isValid = hasMinLength && passwordsMatch && isCurrentPasswordValid;

    setIsValidForm(isValid);
  }, [currentPassword, newPassword, confirmPassword, isCurrentPasswordValid]);

  return (
    <div className={classes.page}>
      {isMobileView ? <MobileNavbar /> : <MainNavbar />}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div className={classes.cardTitle}>Email</div>
            <div className={classes.bio}>
              If you'd like to change your email address, click the button
              below.
            </div>
            <button
              className={classes.deleteButton}
              onClick={handleInitiateEmailChange}
              disabled={isChangingEmail}
            >
              Change email address
            </button>

            {isEmailChangeOpen && (
              <div className={classes.confirmationModal}>
                <div className={classes.confirmationContent}>
                  <>
                    <div className={classes.passwordInputs}>
                      <input
                        type="email"
                        placeholder="Current Email"
                        value={currentEmail}
                        onChange={(e) => setCurrentEmail(e.target.value)}
                        onBlur={checkCurrentEmail}
                        className={classes.userInput}
                      />
                      <input
                        type="email"
                        placeholder="New Email"
                        value={newEmail}
                        onChange={handleNewEmailChange}
                        className={classes.userInput}
                      />
                      <input
                        type="email"
                        placeholder="Confirm New Email"
                        value={confirmEmail}
                        onChange={(e) => setConfirmEmail(e.target.value)}
                        className={classes.userInput}
                      />
                    </div>
                    {emailError && (
                      <div className={classes.error}>
                        <svg
                          className={classes.errorIcon}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                        <div className={classes.errorText}>{emailError}</div>
                      </div>
                    )}
                    <div className={classes.confirmationButtons}>
                      <div className={classes.postBorder}></div>
                      <button
                        className={`${classes.confirmButton} ${
                          !areEmailsValid() || isCheckingEmail
                            ? classes.disabledButton
                            : ""
                        }`}
                        onClick={handleConfirmEmailChange}
                        disabled={
                          !areEmailsValid() ||
                          isCheckingEmail ||
                          isChangingEmail
                        }
                      >
                        {isChangingEmail || isCheckingEmail ? (
                          <span
                            className={`${classes.loadingIcon} material-symbols-outlined`}
                          >
                            progress_activity
                          </span>
                        ) : (
                          "Save changes"
                        )}
                      </button>
                      <button
                        className={classes.cancelButton}
                        onClick={handleCancelEmailChange}
                      >
                        Go back
                      </button>
                    </div>
                  </>
                </div>
              </div>
            )}
          </div>
          <div className={classes.card}>
            <div className={classes.cardTitle}>Password</div>
            <div className={classes.bio}>
              If you'd like to change your password, click the button below.
            </div>
            <button
              className={classes.deleteButton}
              onClick={handleInitiatePasswordChange}
              disabled={isChangingPassword}
            >
              Change password
            </button>

            {isPasswordChangeOpen && (
              <div className={classes.confirmationModal}>
                <div className={classes.confirmationContent}>
                  <div className={classes.passwordInputs}>
                    <input
                      type="password"
                      placeholder="Current Password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      onBlur={checkCurrentPassword}
                      className={classes.userInput}
                    />
                    <input
                      type="password"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className={classes.userInput}
                    />
                    <input
                      type="password"
                      placeholder="Confirm New Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className={classes.userInput}
                    />
                  </div>
                  {passwordError && (
                    <div className={classes.error}>
                      <svg
                        className={classes.errorIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      <div className={classes.errorText}>{passwordError}</div>
                    </div>
                  )}
                  <div className={classes.confirmationButtons}>
                    <div className={classes.postBorder}></div>
                    <button
                      className={`${classes.confirmButton} ${
                        !arePasswordsValid() ? classes.disabledButton : ""
                      }`}
                      onClick={handleConfirmPasswordChange}
                      disabled={!arePasswordsValid() || isChangingPassword}
                    >
                      {isChangingPassword ? (
                        <span
                          className={`${classes.loadingIcon} material-symbols-outlined`}
                        >
                          progress_activity
                        </span>
                      ) : (
                        "Save changes"
                      )}
                    </button>
                    <button
                      className={classes.cancelButton}
                      onClick={handleCancelPasswordChange}
                    >
                      Go back
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <div className={classes.card}>
            <div className={classes.cardTitle}>Notifications</div>
            <div className={classes.bio}>
              If you'd like to stop receiving push notifications, click the
              button below.
            </div>
            <button
              className={classes.deleteButton}
              onClick={handleInitiateDelete}
              disabled={isDeleting}
            >
              Stop receiving push notifications
            </button>
          </div> */}
          {/* <div className={classes.card}>
            <div className={classes.cardTitle}>Donations</div>
            You haven't donated to any startups yet. Want to support a startup?
            Go to the startup page you want to donate to and select "Support".
            After you’ve donated to a startup, your donation will be visible to
            anyway who vists the startup page and your donation will also be
            visible here, for you to track all of your donation history.
          </div> */}
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                blockedUsers.length === 0 ? classes.emptyCardTitle : ""
              }`}
            >
              Blocked
            </div>

            {isLoadingBlocked ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            ) : (
              <>
                {blockedUsers.length === 0 ? (
                  <div>
                    <div className={classes.defaultText}>
                      Need to block someone? Go to the profile of the person you
                      want to block and select "Block" from the drop-down menu
                      at the top of the profile summary.
                    </div>
                    <div className={classes.noPostBorder}></div>
                    <div className={classes.userContainer}>
                      <img
                        src={greyProfileImage}
                        alt="Default profile"
                        className={classes.noUserImage}
                      />
                      <div className={classes.startupAbout}>
                        <div className={classes.userInfo}>
                          <div className={classes.userNameContainer}>
                            <div className={classes.noStartupRole}>
                              Username
                            </div>
                            <span className={classes.userConnectionType}>
                              • Blocked
                            </span>
                          </div>
                          <div className={classes.noStartupName}>Headline</div>
                          <div className={classes.noUserLink}>
                            Link
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {blockedUsers
                      .slice(0, displayedBlockedUsers)
                      .map((blockedUser) => (
                        <div
                          key={blockedUser.id}
                          className={classes.userContainer}
                        >
                          <img
                            src={
                              blockedUser.userData?.profileImage || defaultImage
                            }
                            alt={`${blockedUser.userData?.firstName} ${blockedUser.userData?.lastName}`}
                            className={classes.userImage}
                            onClick={() =>
                              navigate(`/${blockedUser.userData?.urlSlug}`)
                            }
                            style={{ cursor: "pointer" }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = defaultImage;
                            }}
                          />
                          <div className={classes.userInfo}>
                            <div className={classes.userNameContainer}>
                              <div
                                className={classes.userName}
                                onClick={() =>
                                  navigate(`/${blockedUser.userData?.urlSlug}`)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {blockedUser.userData?.firstName}{" "}
                                {blockedUser.userData?.lastName}
                              </div>
                              <span className={classes.userConnectionType}>
                                • Blocked
                              </span>
                            </div>
                            {blockedUser.userData?.bio && (
                              <div className={classes.userBio}>
                                {blockedUser.userData.bio}
                              </div>
                            )}
                            {blockedUser.userData?.link && (
                              <a
                                href={blockedUser.userData.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.userLink}
                                onClick={handleLinkClick}
                              >
                                <span>
                                  {blockedUser.userData.linkText ||
                                    blockedUser.userData.link}
                                </span>
                                <svg
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                  />
                                </svg>
                              </a>
                            )}
                          </div>
                        </div>
                      ))}

                    {blockedUsers.length > 3 && (
                      <div
                        className={classes.showAllExperiences}
                        onClick={() => {
                          if (displayedBlockedUsers >= blockedUsers.length) {
                            setDisplayedBlockedUsers(3);
                          } else {
                            const additionalItems = displayedBlockedUsers * 2;
                            setDisplayedBlockedUsers(
                              Math.min(
                                displayedBlockedUsers + additionalItems,
                                blockedUsers.length
                              )
                            );
                          }
                        }}
                      >
                        {displayedBlockedUsers >= blockedUsers.length
                          ? "Show less"
                          : "Show more"}
                        {displayedBlockedUsers >= blockedUsers.length ? (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m5 15 7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 9-7 7-7-7"
                            />
                          </svg>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className={classes.card}>
            <div className={classes.cardTitle}>Log out</div>
            <div className={classes.bio}>
              If you'd like to log out, click the button below.
            </div>
            <button
              className={classes.deleteButton}
              onClick={async () => {
                try {
                  await auth.signOut();
                  navigate("/login");
                } catch (error) {}
              }}
            >
              Log out
            </button>
          </div>
          <div className={classes.card}>
            <div className={classes.cardTitle}>Deactivate</div>
            <div className={classes.bio}>
              If you'd like to deactivate your account, click the button below.
            </div>
            <button
              className={classes.deleteButton}
              onClick={handleInitiateDelete}
              disabled={isDeleting}
            >
              Deactivate account
            </button>

            {isConfirmationOpen && (
              <div className={classes.confirmationModal}>
                <div className={classes.confirmationContent}>
                  <h3>
                    {isDeleting
                      ? "Closing your account..."
                      : `${userData.firstName}, we're sorry to see you go...`}
                  </h3>
                  <p>
                    {isDeleting
                      ? "Please be patient while we safely delete all of your data. This may take a few moments."
                      : "Are you sure you want to deactivate your account? You’ll lose your connections, startups, communities, events, posts, and messages."}
                  </p>
                  {!isDeleting && (
                    <div className={classes.confirmationButtons}>
                      <div className={classes.postBorder}></div>

                      <button
                        className={classes.confirmButton}
                        onClick={handleConfirmDelete}
                      >
                        I'm sure, deactivate my account
                      </button>
                      <button
                        className={classes.cancelButton}
                        onClick={handleCancelDelete}
                      >
                        Go back
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={classes.rightCards}>
          <Ad />
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Settings;
