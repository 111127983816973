import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { collection, query, getDocs, orderBy, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import defaultStartupImage from "../../assets/images/startupImage.jpg";
import defaultCommunityImage from "../../assets/icons/communityImage.jpg";
import defaultEventImage from "../../assets/icons/eventImage.jpg";
import defaultProfileImage from "../../assets/icons/profileImage.jpg";

const MentionDropdown = ({
  searchText,
  inputElement,
  onSelectUser,
  onClose,
  positionOverride,
}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (inputElement && !positionOverride) {
      const rect = inputElement.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;

      setPosition({
        top: rect.bottom + scrollTop,
        left: rect.left + scrollLeft,
      });
    } else if (positionOverride) {
      setPosition(positionOverride);
    }
  }, [inputElement, positionOverride]);

  useEffect(() => {
    const fetchItems = async () => {
      if (!searchText.trim()) {
        setItems([]);
        return;
      }

      setLoading(true);
      try {
        const searchLower = searchText.toLowerCase();
        const results = [];

        const usersRef = collection(firestore, "users");
        const usersQuery = query(usersRef, orderBy("firstName"));
        const usersPromise = getDocs(usersQuery);

        const [startupResults, communityResults, eventResults] =
          await Promise.all([
            new Promise(async (resolve) => {
              const userSnapshot = await getDocs(usersQuery);
              const startupPromises = userSnapshot.docs.map(async (userDoc) => {
                const startupsRef = collection(
                  firestore,
                  `users/${userDoc.id}/startups`
                );
                const q = query(startupsRef);
                const startupSnapshot = await getDocs(q);

                return startupSnapshot.docs
                  .map((doc) => ({
                    id: doc.id,
                    type: "startup",
                    startupOwnerId: userDoc.id,
                    ...doc.data(),
                  }))
                  .filter((startup) =>
                    startup.startupName?.toLowerCase().includes(searchLower)
                  );
              });

              const results = await Promise.all(startupPromises);
              resolve(results.flat());
            }),
            new Promise(async (resolve) => {
              const userSnapshot = await getDocs(usersQuery);
              const communityPromises = userSnapshot.docs.map(
                async (userDoc) => {
                  const communitiesRef = collection(
                    firestore,
                    `users/${userDoc.id}/communities`
                  );
                  const q = query(communitiesRef);
                  const communitySnapshot = await getDocs(q);

                  return communitySnapshot.docs
                    .map((doc) => ({
                      id: doc.id,
                      type: "community",
                      communityOwnerId: userDoc.id,
                      ...doc.data(),
                    }))
                    .filter((community) =>
                      community.communityName
                        ?.toLowerCase()
                        .includes(searchLower)
                    );
                }
              );

              const results = await Promise.all(communityPromises);
              resolve(results.flat());
            }),
            new Promise(async (resolve) => {
              const userSnapshot = await getDocs(usersQuery);
              const eventPromises = userSnapshot.docs.map(async (userDoc) => {
                const eventsRef = collection(
                  firestore,
                  `users/${userDoc.id}/events`
                );
                const q = query(eventsRef);
                const eventSnapshot = await getDocs(q);

                return eventSnapshot.docs
                  .map((doc) => ({
                    id: doc.id,
                    type: "event",
                    eventOwnerId: userDoc.id,
                    ...doc.data(),
                  }))
                  .filter((event) =>
                    event.eventName?.toLowerCase().includes(searchLower)
                  );
              });

              const results = await Promise.all(eventPromises);
              resolve(results.flat());
            }),
          ]);

        const usersSnapshot = await usersPromise;
        const userResults = usersSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            type: "user",
            ...doc.data(),
          }))
          .filter((user) => {
            const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
            return fullName.includes(searchLower);
          });

        const allResults = [
          ...userResults,
          ...startupResults,
          ...communityResults,
          ...eventResults,
        ].sort((a, b) => {
          const getExactMatch = (item) => {
            switch (item.type) {
              case "startup":
                return item.startupName?.toLowerCase() === searchLower;
              case "community":
                return item.communityName?.toLowerCase() === searchLower;
              case "event":
                return item.eventName?.toLowerCase() === searchLower;
              default:
                return (
                  `${item.firstName} ${item.lastName}`.toLowerCase() ===
                  searchLower
                );
            }
          };

          const getName = (item) => {
            switch (item.type) {
              case "startup":
                return item.startupName;
              case "community":
                return item.communityName;
              case "event":
                return item.eventName;
              default:
                return `${item.firstName} ${item.lastName}`;
            }
          };

          const aExact = getExactMatch(a);
          const bExact = getExactMatch(b);

          if (aExact && !bExact) return -1;
          if (!aExact && bExact) return 1;

          const aName = getName(a);
          const bName = getName(b);
          const aIndex = aName.toLowerCase().indexOf(searchLower);
          const bIndex = bName.toLowerCase().indexOf(searchLower);

          if (aIndex !== bIndex) return aIndex - bIndex;

          return aName.localeCompare(bName);
        });

        setItems(allResults.slice(0, 5));
      } catch (error) {
        console.error("Error fetching items:", error);
      }
      setLoading(false);
    };

    const timeoutId = setTimeout(fetchItems, 300);
    return () => clearTimeout(timeoutId);
  }, [searchText]);

  const hideScrollbarStyles = document.createElement("style");
  hideScrollbarStyles.textContent = `
    .mention-dropdown::-webkit-scrollbar {
      display: none;
    }
  `;
  document.head.appendChild(hideScrollbarStyles);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleItemClick = (item) => {
    if (item.type === "startup") {
      onSelectUser({
        firstName: item.startupName,
        lastName: "",
        urlSlug: `startup/${item.startupUrlSlug}`,
        id: item.id,
        startupOwnerId: item.startupOwnerId,
        isStartup: true,
      });
    } else if (item.type === "community") {
      onSelectUser({
        firstName: item.communityName,
        lastName: "",
        urlSlug: `community/${item.communityUrlSlug}`,
        id: item.id,
        communityOwnerId: item.communityOwnerId,
        isCommunity: true,
      });
    } else if (item.type === "event") {
      onSelectUser({
        firstName: item.eventName,
        lastName: "",
        urlSlug: `event/${item.eventUrlSlug}`,
        id: item.id,
        eventOwnerId: item.eventOwnerId,
        isEvent: true,
      });
    } else {
      onSelectUser(item);
    }
  };

  if (items.length === 0 && !loading) {
    return null;
  }

  const dropdownContent = (
    <div
      ref={dropdownRef}
      className="mention-dropdown"
      style={{
        position: "absolute",
        ...(positionOverride
          ? positionOverride
          : {
              top: position.top + 8,
              left: position.left,
            }),
        backgroundColor: "white",
        borderRadius: "0.5rem",
        boxShadow: "1px 2px 3px hsla(0, 0%, 0%, 0.1)",
        width: window.innerWidth <= 648 ? "70%" : "20rem",
        overflowY: "auto",
        zIndex: 99999,
      }}
    >
      {loading ? (
        <div style={{ padding: "0.5rem", color: "gray", fontSize: "1rem" }}>
          Loading...
        </div>
      ) : (
        items.map((item) => (
          <div
            key={`${item.type}-${item.id}`}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0.5rem",
              cursor: "pointer",
              transition: "0.2s",
            }}
            onClick={() => handleItemClick(item)}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "whitesmoke";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "white";
            }}
          >
            <img
              src={
                item.type === "startup"
                  ? item.startupImage || defaultStartupImage
                  : item.type === "community"
                  ? item.communityImage || defaultCommunityImage
                  : item.type === "event"
                  ? item.eventImage || defaultEventImage
                  : item.profileImage || defaultProfileImage
              }
              alt={
                item.type === "startup"
                  ? item.startupName
                  : item.type === "community"
                  ? item.communityName
                  : item.type === "event"
                  ? item.eventName
                  : `${item.firstName} ${item.lastName}`
              }
              style={{
                width: item.type === "event" ? "3rem" : "2rem",
                height: "2rem",
                borderRadius:
                  item.type === "user"
                    ? "50%"
                    : item.type === "community" || item.type === "event"
                    ? "0.25rem"
                    : "0",
                marginRight: "0.5rem",
                objectFit: "cover",
              }}
            />
            <span style={{ fontWeight: "900", fontSize: "1rem" }}>
              {item.type === "startup"
                ? item.startupName
                : item.type === "community"
                ? item.communityName
                : item.type === "event"
                ? item.eventName
                : `${item.firstName} ${item.lastName}`}
            </span>
          </div>
        ))
      )}
    </div>
  );

  return createPortal(dropdownContent, document.body);
};

export default MentionDropdown;
