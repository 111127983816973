import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useReducer,
  useRef,
} from "react";
import Ad from "../../../components/Cards/Ad";
import { isEventClosed } from "../../../utils/timeUtils";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import greyProfileImage from "../../../assets/icons/greyProfileImage.jpg";
import greyStartupImage from "../../../assets/icons/greyStartupImage.jpg";
import greyCommunityImage from "../../../assets/icons/greyCommunityImage.jpg";
import greyEventImage from "../../../assets/icons/greyEventImage.jpg";
import greyPostImage from "../../../assets/icons/greyPostImage.jpg";
import startupImage from "../../../assets/images/startupImage.jpg";
import communityImage from "../../../assets/icons/communityImage.jpg";
import eventImage from "../../../assets/icons/eventImage.jpg";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Profile.module.css";
import StartupButtons from "../../../components/Buttons/StartupButtons";
import CommunityButtons from "../../../components/Buttons/CommunityButtons";
import EventButtons from "../../../components/Buttons/EventButtons";
import ProfileModal from "../../../components/Modals/ProfileModal";
import IntroModal from "../../../components/Modals/IntroModal";
import SkillsModal from "../../../components/Modals/SkillsModal";
import { firestore, auth } from "../../../firebase";
import RepostsModal from "../../../components/Modals/RepostsModal";
import ActivityButtons from "../../../components/Buttons/ActivityButtons";
import EditCommunityModal from "../../../components/Modals/EditCommunityModal";
import AddStartupModal from "../../../components/Modals/AddStartupModal";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import EditStartupsModal from "../../../components/Modals/EditStartupsModal";
import FollowModal from "../../../components/Modals/FollowModal";
import AddEventModal from "../../../components/Modals/AddEventModal";
import AddCommunityModal from "../../../components/Modals/AddCommunityModal";
import {
  getDoc,
  collection,
  doc,
  getDocs,
  query,
  orderBy,
  limit,
  updateDoc,
  writeBatch,
  increment,
  serverTimestamp,
  Timestamp,
  where,
} from "firebase/firestore";
import EditEventModal from "../../../components/Modals/EditEventModal";
import { onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import profileImage from "../../../assets/icons/profileImage.jpg";
import ActivityDropdown from "../../../components/Dropdowns/ActivityDropdown";
import InterestsModal from "../../../components/Modals/InterestsModal";
import AddExperienceModal from "../../../components/Modals/AddExperienceModal";
import EditExperienceModal from "../../../components/Modals/EditExperienceModal";
import EditSingleExperienceModal from "../../../components/Modals/EditSingleExperienceModal";
import AddEducationModal from "../../../components/Modals/AddEducationModal";
import EditEducationModal from "../../../components/Modals/EditEducationModal";
import EditSingleEducationModal from "../../../components/Modals/EditSingleEducationModal";
import monthsList from "../../../utils/monthsList";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import { useNavigate } from "react-router-dom";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import { getTopThreeEmojis } from "../../../utils/emojiUtils";
import { convertUrlsToLinks } from "../../../utils/textUtils";

function Profile() {
  const [hoverStates, setHoverStates] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [loadingEventType, setLoadingEventType] = useState(false);
  const [loadingStartupType, setLoadingStartupType] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    intro: "",
    link: "",
    linkText: "",
    skills: [],
    interests: [],
    experiences: [],
    education: [],
    profileImage: "",
  });
  const [displayedEventCount, setDisplayedEventCount] = useState(3);
  const [loadingCommunityType, setLoadingCommunityType] = useState(false);
  const [startupSearchTerm, setStartupSearchTerm] = useState("");
  const [startupType, setStartupType] = useState("Owner");
  const [displayedStartups, setDisplayedStartups] = useState(3);
  const [startupCounts, setStartupCounts] = useState({
    Owner: 0,
    TeamMember: 0,
    Supported: 0,
    Following: 0,
  });
  const [completionStatus, setCompletionStatus] = useState({
    profilePicture: false,
    about: false,
    skills: false,
    interests: false,
    experience: false,
    education: false,
    post: false,
    connection: false,
  });
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [isChecklistOpen, setIsChecklistOpen] = useState(true);
  const [communitySearchTerm, setCommunitySearchTerm] = useState("");
  const [communityType, setCommunityType] = useState("Owner");
  const [communityCounts, setCommunityCounts] = useState({
    Owner: 0,
    Moderator: 0,
    Member: 0,
  });
  const [eventSearchTerm, setEventSearchTerm] = useState("");
  const [eventData, setEventData] = useState({});
  const [eventType, setEventType] = useState("Owner");
  const [eventCounts, setEventCounts] = useState({
    Owner: 0,
    Attending: 0,
    Attended: 0,
    Organising: 0, // Add this line
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditConnectionsModalOpen, setIsEditConnectionsModalOpen] =
    useState(false);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const [displayedCommunities, setDisplayedCommunities] = useState(3);

  const [
    editConnectionsModalScrollPosition,
    setEditConnectionsModalScrollPosition,
  ] = useState(0);
  const [connections, setConnections] = useState([]);
  const [displayedConnections, setDisplayedConnections] = useState(3);
  const [shareButtonText, setShareButtonText] = useState("Share");
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isLoadingActivity, setIsLoadingActivity] = useState(true);
  const [isLoadingStartups, setIsLoadingStartups] = useState(true);
  const [isLoadingConnections, setIsLoadingConnections] = useState(true);
  const [communities, setCommunities] = useState([]);
  const [showAddCommunityModal, setShowAddCommunityModal] = useState(false);
  const [isLoadingCommunities, setIsLoadingCommunities] = useState(true);
  const [addCommunityModalScrollPosition, setAddCommunityModalScrollPosition] =
    useState(0);
  const [showEditCommunityModal, setShowEditCommunityModal] = useState(false);
  const [isEditCommunityModalOpen, setIsEditCommunityModalOpen] =
    useState(false);
  const [
    editCommunityModalScrollPosition,
    setEditCommunityModalScrollPosition,
  ] = useState(0);
  const [showAllCommunities, setShowAllCommunities] = useState(false);

  const [addStartupModalScrollPosition, setAddStartupModalScrollPosition] =
    useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [followModalScrollPosition, setFollowModalScrollPosition] = useState(0);
  const [isFollowModalOpen, setIsFollowModalOpen] = useState(false);
  const [isAddStartupModalOpen, setIsAddStartupModalOpen] = useState(false);
  const [showEditStartupsModal, setShowEditStartupsModal] = useState(false);
  const [isEditStartupsModalOpen, setIsEditStartupsModalOpen] = useState(false);
  const [editStartupsModalScrollPosition, setEditStartupsModalScrollPosition] =
    useState(0);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [startups, setStartups] = useState([]);
  const [loadingStartups, setLoadingStartups] = useState(false);
  const [showAddStartupModal, setShowAddStartupModal] = useState(false);
  const [expandedTexts, setExpandedTexts] = useState({});
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const [showInterestsModal, setShowInterestsModal] = useState(false);
  const [showAddExperienceModal, setShowAddExperienceModal] = useState(false);
  const [showEditExperienceModal, setShowEditExperienceModal] = useState(false);
  const [showEditSingleExperienceModal, setShowEditSingleExperienceModal] =
    useState(false);
  const [showFollowModal, setShowFollowModal] = useState(false);
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [addEventModalScrollPosition, setAddEventModalScrollPosition] =
    useState(0);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [showEditEventsModal, setShowEditEventsModal] = useState(false);
  const [isEditEventsModalOpen, setIsEditEventsModalOpen] = useState(false);
  const [editEventsModalScrollPosition, setEditEventsModalScrollPosition] =
    useState(0);

  const [selectedExperience, setSelectedExperience] = useState(null);
  const [commentedPosts, setCommentedPosts] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [displayedComments, setDisplayedComments] = useState(3);

  const [showAddEducationModal, setShowAddEducationModal] = useState(false);
  const [showEditEducationModal, setShowEditEducationModal] = useState(false);
  const [showEditSingleEducationModal, setShowEditSingleEducationModal] =
    useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);

  const [displayedReactions, setDisplayedReactions] = useState(3);

  const [currentUser, setCurrentUser] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);

  const [connectionsCount, setConnectionsCount] = useState(0);

  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [activePostReposts, setActivePostReposts] = useState(null);

  const [activityType, setActivityType] = useState("Posts");

  const [posts, setPosts] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState(3);
  const [loading, setLoading] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingBoosts, setLoadingBoosts] = useState(false);
  const [loadingReposts, setLoadingReposts] = useState(false);

  const [visibleSkills, setVisibleSkills] = useState(9);
  const [visibleInterests, setVisibleInterests] = useState(9);
  const [debugExperiences, setDebugExperiences] = useState([]);

  const toggleAddCommunityModal = (success = false) => {
    if (!showAddCommunityModal) {
      setAddCommunityModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addCommunityModalScrollPosition);
      }, 0);
    }
    setShowAddCommunityModal(!showAddCommunityModal);
  };

  const handleCommunityTypeChange = (type) => {
    setLoadingCommunityType(true);
    setCommunityType(type);
    // Use a small timeout to simulate loading and prevent flickering
    setTimeout(() => {
      setLoadingCommunityType(false);
    }, 300); // Adjust timing as needed
  };

  const handleCommunityAdded = (newCommunity) => {
    const communityWithTimestamp = {
      ...newCommunity,
      createdAt:
        newCommunity.createdAt instanceof Date
          ? Timestamp.fromDate(newCommunity.createdAt)
          : newCommunity.createdAt,
      membershipType: "owner", // Explicitly set the membership type
    };

    // Update both the communities state and community counts
    setCommunities((prevCommunities) => [
      communityWithTimestamp,
      ...prevCommunities,
    ]);
    setCommunityCounts((prevCounts) => ({
      ...prevCounts,
      Owner: prevCounts.Owner + 1,
    }));
  };

  const toggleConnectionsModal = () => {
    if (!showConnectionsModal) {
      setConnectionsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, connectionsModalScrollPosition);
      }, 0);
    }
    setShowConnectionsModal(!showConnectionsModal);
    setIsConnectionsModalOpen(!isConnectionsModalOpen);
  };

  // Update the add event modal toggle function
  const toggleAddEventModal = (success = false) => {
    if (!showAddEventModal) {
      setAddEventModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addEventModalScrollPosition);
      }, 0);
    }
    setShowAddEventModal(!showAddEventModal);
    setIsAddEventModalOpen(!isAddEventModalOpen);
  };

  const toggleShowAllCommunities = () => {
    setShowAllCommunities(true);
  };

  const handleEventTypeChange = (type) => {
    setLoadingEventType(true);
    setEventType(type);
    // Use a small timeout to simulate loading and prevent flickering
    setTimeout(() => {
      setLoadingEventType(false);
    }, 300); // Adjust timing as needed
  };

  const handleStartupTypeChange = (type) => {
    setLoadingStartupType(true);
    setStartupType(type);
    // Use a small timeout to simulate loading and prevent flickering
    setTimeout(() => {
      setLoadingStartupType(false);
    }, 300); // Adjust timing as needed
  };

  const toggleEditCommunityModal = () => {
    if (!showEditCommunityModal) {
      setEditCommunityModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editCommunityModalScrollPosition);
      }, 0);
    }
    setShowEditCommunityModal(!showEditCommunityModal);
    setIsEditCommunityModalOpen(!isEditCommunityModalOpen);
  };

  useEffect(() => {
    if (communities.length > 0) {
      const counts = {
        Owner: communities.filter((c) => c.membershipType === "owner").length,
        Moderator: communities.filter((c) => c.membershipType === "moderator")
          .length,
        Member: communities.filter((c) => c.membershipType === "member").length,
      };
      setCommunityCounts(counts);
    }
  }, [communities]);

  const filteredEvents = useMemo(() => {
    if (!events || !Array.isArray(events)) {
      return [];
    }

    const parseEventDateTime = (event) => {
      try {
        let day, month, year;
        const [hours, minutes] = event.endTime.match(/(\d+):(\d+)/).slice(1);

        if (event.endDate.includes("-")) {
          [year, month, day] = event.endDate.split("-");
        } else {
          [day, month, year] = event.endDate.split("/");
        }

        const date = new Date(
          Number(year),
          Number(month) - 1,
          Number(day),
          Number(hours),
          Number(minutes)
        );

        return date;
      } catch (error) {
        return new Date(0);
      }
    };

    const isEventInPast = (event) => {
      if (!event.endDate || !event.endTime || !event.timezone) return false;
      return isEventClosed({
        endDate: event.endDate,
        endTime: event.endTime,
        timezone: event.timezone,
      });
    };

    return events
      .filter((event) => {
        const eventClosed = isEventInPast(event);

        switch (eventType) {
          case "Owner":
            return event.membershipType === "owner";
          case "Organiser":
            return event.membershipType === "Organising";
          case "Attending":
            return event.membershipType === "attending" && !eventClosed;
          case "Attended":
            return event.membershipType === "attending" && eventClosed;
          default:
            return false;
        }
      })
      .sort((a, b) => {
        const aDate = parseEventDateTime(a);
        const bDate = parseEventDateTime(b);

        const now = new Date();
        const aInFuture = aDate > now;
        const bInFuture = bDate > now;

        if (aInFuture !== bInFuture) {
          return aInFuture ? -1 : 1;
        }

        if (aInFuture) {
          return aDate.getTime() - bDate.getTime();
        } else {
          return bDate.getTime() - aDate.getTime();
        }
      });
  }, [events, eventType]);

  const searchFilteredEvents = useMemo(() => {
    if (!filteredEvents) return [];

    return filteredEvents.filter((event) => {
      const matchesSearch = event.eventName
        ?.toLowerCase()
        .includes(eventSearchTerm.toLowerCase());
      return matchesSearch;
    });
  }, [filteredEvents, eventSearchTerm]);

  const handleShare = useCallback(() => {
    const profileUrl = `${window.location.origin}/${userData.urlSlug}`;
    navigator.clipboard.writeText(profileUrl).then(() => {
      setShareButtonText("Copied");
      setTimeout(() => {
        setShareButtonText("Share");
      }, 5000);
    });
  }, [userData.urlSlug]);

  useEffect(() => {
    if (startups.length > 0) {
      const counts = {
        Owner: startups.filter((s) => s.membershipType === "owner").length,
        TeamMember: startups.filter((s) => s.membershipType === "member")
          .length,
        Supported: startups.filter((s) => s.supported).length,
        Following: startups.filter((s) => s.following).length,
      };
      setStartupCounts(counts);
    }
  }, [startups]);

  const filteredCommunities = useMemo(() => {
    if (!communities) return [];

    const filtered = communities.filter((community) => {
      switch (communityType) {
        case "Owner":
          return community.membershipType === "owner";
        case "Moderator":
          return community.membershipType === "moderator";
        case "Member":
          return community.membershipType === "member";
        default:
          return false;
      }
    });

    return filtered.sort((a, b) => {
      if (communityType === "Owner") {
        const aTime = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const bTime = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return bTime - aTime;
      } else if (communityType === "Moderator") {
        const aTime = a.acceptedAt?.toDate?.() || new Date(a.acceptedAt);
        const bTime = b.acceptedAt?.toDate?.() || new Date(b.acceptedAt);
        return bTime - aTime;
      } else if (communityType === "Member") {
        const aTime = a.joinedAt?.toDate?.() || new Date(a.joinedAt);
        const bTime = b.joinedAt?.toDate?.() || new Date(b.joinedAt);
        return bTime - aTime;
      }
      return 0;
    });
  }, [communities, communityType]);

  const handleCommunityUpdated = (updatedCommunities) => {
    // If we received a single community that's been deleted
    if (updatedCommunities.deleted) {
      setCommunities((prevCommunities) =>
        prevCommunities.filter(
          (community) => community.id !== updatedCommunities.id
        )
      );
      // Update the community counts
      setCommunityCounts((prevCounts) => ({
        ...prevCounts,
        Owner: prevCounts.Owner - 1,
      }));
      return;
    }

    // If we received an array, use it directly
    if (Array.isArray(updatedCommunities)) {
      setCommunities(updatedCommunities);
    } else {
      // If we received a single community update, update it in the existing array
      setCommunities((prevCommunities) =>
        prevCommunities.map((community) =>
          community.id === updatedCommunities.id
            ? {
                ...community,
                ...updatedCommunities,
                membershipType: community.membershipType,
                createdAt: community.createdAt,
              }
            : community
        )
      );
    }
  };

  const toggleEditEventsModal = () => {
    if (!showEditEventsModal) {
      setEditEventsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editEventsModalScrollPosition);
      }, 0);
    }
    setShowEditEventsModal(!showEditEventsModal);
    setIsEditEventsModalOpen(!isEditEventsModalOpen);
  };

  // Add this handler function with your other handlers
  const handleEventUpdate = async (updatedEvent) => {
    try {
      const batch = writeBatch(firestore);

      // Update main event document
      const eventRef = doc(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}`
      );
      batch.update(eventRef, updatedEvent);

      // Get all attendees
      const attendeesRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/attendees`
      );
      const attendeesSnapshot = await getDocs(attendeesRef);

      // Update each attendee's eventsAttending record
      attendeesSnapshot.docs.forEach((attendeeDoc) => {
        const attendeeEventRef = doc(
          firestore,
          `users/${attendeeDoc.id}/eventsAttending/${eventData.id}`
        );

        // Update the critical time-related fields in the attendee's record
        batch.update(attendeeEventRef, {
          endDate: updatedEvent.endDate,
          endTime: updatedEvent.endTime,
          timezone: updatedEvent.timezone,
          // Also update other important fields that might have changed
          eventName: updatedEvent.eventName,
          eventImage: updatedEvent.eventImage,
          eventUrlSlug: updatedEvent.eventUrlSlug,
          isOnline: updatedEvent.isOnline,
          city: updatedEvent.city,
          country: updatedEvent.country,
        });
      });

      await batch.commit();

      setEventData((prev) => ({
        ...prev,
        ...updatedEvent,
      }));
    } catch (error) {}
  };

  const getOriginalPostRef = async (firestore, originalPosterId, postId) => {
    if (!originalPosterId || !postId) return null;
    try {
      if (originalPosterId.startsWith("startup_")) {
        const startupId = originalPosterId.replace("startup_", "");
        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);

        for (const userDoc of usersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupId}`
          );
          const startupSnap = await getDoc(startupRef);

          if (startupSnap.exists()) {
            const startupData = startupSnap.data();
            return {
              ref: doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/posts/${postId}`
              ),
              startupData: {
                ...startupData,
                ownerId: userDoc.id,
                startupId: startupId,
                isStartupPost: true,
              },
            };
          }
        }
        return null;
      }

      // For user posts, check personal posts first
      const userPostRef = doc(
        firestore,
        `users/${originalPosterId}/posts/${postId}`
      );
      const userPostSnap = await getDoc(userPostRef);

      if (userPostSnap.exists()) {
        return {
          ref: userPostRef,
          startupData: null,
        };
      }

      // Then check startup posts
      const startupsRef = collection(
        firestore,
        `users/${originalPosterId}/startups`
      );
      const startupsSnapshot = await getDocs(startupsRef);

      for (const startupDoc of startupsSnapshot.docs) {
        const postRef = doc(
          firestore,
          `users/${originalPosterId}/startups/${startupDoc.id}/posts/${postId}`
        );
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
          const startupData = startupDoc.data();
          return {
            ref: postRef,
            startupData: {
              ...startupData,
              ownerId: originalPosterId,
              startupId: startupDoc.id,
              isStartupPost: true,
            },
          };
        }
      }

      return null;
    } catch (error) {
      return null;
    }
  };

  const handleEventAdded = (newEvent) => {
    // Create an event object with membershipType for proper filtering
    const eventWithMembership = {
      ...newEvent,
      membershipType: "owner",
      ownerId: currentUser.uid,
    };

    // Update the events state with the new event at the beginning of the array
    setEvents((prevEvents) => [eventWithMembership, ...prevEvents]);

    // Update the event counts
    setEventCounts((prevCounts) => ({
      ...prevCounts,
      Owner: prevCounts.Owner + 1,
    }));
  };

  const toggleShowAllEvents = () => {
    setShowAllEvents(true);
  };

  // Add this section component with your other section components

  const fetchPosts = useCallback(async () => {
    if (!currentUser) return;
    setLoadingPosts(true);
    try {
      const postsRef = collection(firestore, `users/${currentUser.uid}/posts`);
      const postsSnapshot = await getDocs(
        query(postsRef, orderBy("createdAt", "desc"), limit(20))
      );

      const postsData = postsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt?.toDate() || new Date(),
          postPreview: {
            mediaType: data.content?.type || null,
            mediaUrl: data.content?.url || null,
            text: data.text,
            thumbnail: data.content?.thumbnail || null,
          },
          reactions: data.reactions || {},
          reposts: data.reposts || {},
          commentCount: data.commentCount || 0,
        };
      });

      postsData.sort((a, b) => b.createdAt - a.createdAt);
      setPosts(postsData);
    } catch (error) {
    } finally {
      setLoadingPosts(false);
    }
  }, [currentUser]);

  const fetchReactions = useCallback(async () => {
    if (!currentUser?.uid) return;

    setLoadingBoosts(true);
    try {
      const reactionsRef = collection(
        firestore,
        `users/${currentUser.uid}/reactedPosts`
      );
      const reactionsQuery = query(
        reactionsRef,
        orderBy("reactedAt", "desc"),
        limit(20)
      );
      const reactionsSnapshot = await getDocs(reactionsQuery);

      const reactionsPromises = reactionsSnapshot.docs.map(
        async (docSnapshot) => {
          const reactionData = docSnapshot.data();
          const postInfo = await getOriginalPostRef(
            firestore,
            reactionData.originalPosterId,
            reactionData.postId
          );
          if (!postInfo?.ref) return null;

          const originalPostSnap = await getDoc(postInfo.ref);
          if (!originalPostSnap.exists()) return null;

          const originalPostData = originalPostSnap.data();
          const originalPost = {
            id: reactionData.postId,
            ...originalPostData,
            postPreview: {
              mediaType: originalPostData.content?.type || null,
              mediaUrl: originalPostData.content?.url || null,
              text: originalPostData.text || "",
              thumbnail: originalPostData.content?.thumbnail || null,
            },
            reactions: originalPostData.reactions || {},
            reposts: originalPostData.reposts || {},
            commentCount: originalPostData.commentCount || 0,
          };

          if (postInfo.startupData) {
            originalPost.isStartupPost = true;
            originalPost.startupName = postInfo.startupData.startupName;
            originalPost.startupImage = postInfo.startupData.startupImage;
            originalPost.startupId = postInfo.startupData.startupId;
            originalPost.startupUrlSlug = postInfo.startupData.startupUrlSlug;
            originalPost.ownerId = postInfo.startupData.ownerId;
          }

          return {
            id: docSnapshot.id,
            ...reactionData,
            originalPost,
            reactedAt: reactionData.reactedAt?.toDate() || new Date(),
            emoji: reactionData.emoji,
          };
        }
      );

      const reactionsResults = await Promise.all(reactionsPromises);
      const validReactions = reactionsResults.filter(Boolean);
      setReactions(validReactions);
    } catch (error) {
      setReactions([]);
    } finally {
      setLoadingBoosts(false);
    }
  }, [currentUser?.uid]);

  const filteredConnections = useMemo(() => {
    return connections.filter((connection) => {
      if (!connection?.userData?.firstName || !connection?.userData?.lastName)
        return false;
      const fullName = `${connection.userData.firstName} ${connection.userData.lastName}`;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [connections, searchTerm]);

  const fetchReposts = useCallback(async () => {
    if (!currentUser?.uid) return;

    setLoadingReposts(true);
    try {
      const repostsRef = collection(
        firestore,
        `users/${currentUser.uid}/reposts`
      );
      const repostsQuery = query(
        repostsRef,
        orderBy("repostedAt", "desc"),
        limit(20)
      );
      const repostsSnapshot = await getDocs(repostsQuery);

      const repostsPromises = repostsSnapshot.docs.map(async (docSnapshot) => {
        const repostData = docSnapshot.data();
        const postInfo = await getOriginalPostRef(
          firestore,
          repostData.originalPosterId,
          repostData.postId
        );
        if (!postInfo?.ref) return null;

        const originalPostSnap = await getDoc(postInfo.ref);
        if (!originalPostSnap.exists()) return null;

        const originalPostData = originalPostSnap.data();
        const originalPost = {
          id: repostData.postId,
          ...originalPostData,
          postPreview: {
            mediaType: originalPostData.content?.type || null,
            mediaUrl: originalPostData.content?.url || null,
            text: originalPostData.text || "",
            thumbnail: originalPostData.content?.thumbnail || null,
          },
          reactions: originalPostData.reactions || {},
          reposts: originalPostData.reposts || {},
          commentCount: originalPostData.commentCount || 0,
        };

        if (postInfo.startupData) {
          originalPost.isStartupPost = true;
          originalPost.startupName = postInfo.startupData.startupName;
          originalPost.startupImage = postInfo.startupData.startupImage;
          originalPost.startupId = postInfo.startupData.startupId;
          originalPost.startupUrlSlug = postInfo.startupData.startupUrlSlug;
          originalPost.ownerId = postInfo.startupData.ownerId;
        }

        return {
          id: docSnapshot.id,
          ...repostData,
          originalPost,
          repostedAt: repostData.repostedAt?.toDate() || new Date(),
        };
      });

      const repostsResults = await Promise.all(repostsPromises);
      const validReposts = repostsResults.filter(Boolean);
      setReposts(validReposts);
    } catch (error) {
      setReposts([]);
    } finally {
      setLoadingReposts(false);
    }
  }, [currentUser?.uid]);

  const fetchComments = useCallback(async () => {
    if (!currentUser?.uid) return;

    setLoadingComments(true);
    try {
      const commentedPostsRef = collection(
        firestore,
        `users/${currentUser.uid}/commentedPosts`
      );
      const commentedPostsQuery = query(
        commentedPostsRef,
        orderBy("lastCommentedAt", "desc"),
        limit(20)
      );
      const commentedPostsSnapshot = await getDocs(commentedPostsQuery);

      const commentsPromises = commentedPostsSnapshot.docs.map(
        async (docSnapshot) => {
          const commentData = docSnapshot.data();
          const postInfo = await getOriginalPostRef(
            firestore,
            commentData.originalPosterId,
            commentData.postId
          );
          if (!postInfo?.ref) return null;

          const originalPostSnap = await getDoc(postInfo.ref);
          if (!originalPostSnap.exists()) return null;

          const originalPostData = originalPostSnap.data();
          const originalPost = {
            id: commentData.postId,
            ...originalPostData,
            postPreview: {
              mediaType: originalPostData.content?.type || null,
              mediaUrl: originalPostData.content?.url || null,
              text: originalPostData.text || "",
              thumbnail: originalPostData.content?.thumbnail || null,
            },
            reactions: originalPostData.reactions || {},
            reposts: originalPostData.reposts || {},
            commentCount: originalPostData.commentCount || 0,
          };

          if (postInfo.startupData) {
            originalPost.isStartupPost = true;
            originalPost.startupName = postInfo.startupData.startupName;
            originalPost.startupImage = postInfo.startupData.startupImage;
            originalPost.startupId = postInfo.startupData.startupId;
            originalPost.startupUrlSlug = postInfo.startupData.startupUrlSlug;
            originalPost.ownerId = postInfo.startupData.ownerId;
          }

          return {
            id: docSnapshot.id,
            ...commentData,
            originalPost,
            lastCommentedAt:
              commentData.lastCommentedAt?.toDate() || new Date(),
          };
        }
      );

      const commentsResults = await Promise.all(commentsPromises);
      const validComments = commentsResults.filter(Boolean);
      setCommentedPosts(validComments);
    } catch (error) {
      setCommentedPosts([]);
    } finally {
      setLoadingComments(false);
    }
  }, [currentUser?.uid]);

  const toggleAddStartupModal = (success = false) => {
    if (!showAddStartupModal) {
      setAddStartupModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addStartupModalScrollPosition);
      }, 0);
    }
    setShowAddStartupModal(!showAddStartupModal);
    setIsAddStartupModalOpen(!isAddStartupModalOpen);
  };

  const toggleFollowModal = () => {
    if (!showFollowModal) {
      setFollowModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, followModalScrollPosition);
      }, 0);
    }
    setShowFollowModal(!showFollowModal);
    setIsFollowModalOpen(!isFollowModalOpen);
  };

  // Add this toggle function with your other modal toggle functions
  const toggleEditStartupsModal = () => {
    if (!showEditStartupsModal) {
      setEditStartupsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editStartupsModalScrollPosition);
      }, 0);
    }
    setShowEditStartupsModal(!showEditStartupsModal);
    setIsEditStartupsModalOpen(!isEditStartupsModalOpen);
  };

  // Add this handler for when a single startup is selected for editing
  const openEditSingleStartupModal = (startup) => {
    setSelectedStartup(startup);
    // You'll implement the EditSingleStartupModal later
    // setShowEditSingleStartupModal(true);
    setShowEditStartupsModal(false);
  };

  const [activityCounts, setActivityCounts] = useState({
    posts: 0,
    reactions: 0,
    reposts: 0,
    comments: 0,
  });

  // Add these functions after your other useCallback functions
  const fetchPostsCount = useCallback(async (userId) => {
    const postsRef = collection(firestore, `users/${userId}/posts`);
    const snapshot = await getDocs(query(postsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchReactionsCount = useCallback(async (userId) => {
    const reactionsRef = collection(firestore, `users/${userId}/reactedPosts`);
    const snapshot = await getDocs(query(reactionsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchRepostsCount = useCallback(async (userId) => {
    const repostsRef = collection(firestore, `users/${userId}/reposts`);
    const snapshot = await getDocs(query(repostsRef, limit(1)));
    return snapshot.size;
  }, []);

  // Update handleStartupAdded in Profile.js to properly integrate the new startup
  const handleStartupAdded = (newStartup) => {
    const startupWithTimestamp = {
      ...newStartup,
      createdAt:
        newStartup.createdAt instanceof Date
          ? Timestamp.fromDate(newStartup.createdAt)
          : newStartup.createdAt,
      membershipType: "owner", // Explicitly set the membership type
    };

    // Update both the startups state and startup counts
    setStartups((prevStartups) => [...prevStartups, startupWithTimestamp]);
    setStartupCounts((prevCounts) => ({
      ...prevCounts,
      Owner: prevCounts.Owner + 1,
    }));
  };

  // In Profile.js
  const handleStartupUpdated = (startupUpdate) => {
    // Check if this is a deletion
    if (startupUpdate.deleted) {
      setStartups((prevStartups) =>
        prevStartups.filter((startup) => startup.id !== startupUpdate.id)
      );
      // Update startup counts
      setStartupCounts((prevCounts) => ({
        ...prevCounts,
        Owner: prevCounts.Owner - 1,
      }));
      return;
    }

    // Handle normal updates
    setStartups((prevStartups) => {
      return prevStartups.map((startup) =>
        startup.id === startupUpdate.id
          ? {
              ...startup,
              ...startupUpdate,
              membershipType: "owner",
            }
          : startup
      );
    });
  };

  const isMobile = () => {
    return window.innerWidth < 648;
  };

  const [modalScrollPosition, setModalScrollPosition] = useState(0);
  const [skillsModalScrollPosition, setSkillsModalScrollPosition] = useState(0);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [profileModalScrollPosition, setProfileModalScrollPosition] =
    useState(0);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [introModalScrollPosition, setIntroModalScrollPosition] = useState(0);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const [
    addExperienceModalScrollPosition,
    setAddExperienceModalScrollPosition,
  ] = useState(0);
  const [isAddExperienceModalOpen, setIsAddExperienceModalOpen] =
    useState(false);
  const [addEducationModalScrollPosition, setAddEducationModalScrollPosition] =
    useState(0);
  const [isAddEducationModalOpen, setIsAddEducationModalOpen] = useState(false);
  const [
    editExperienceModalScrollPosition,
    setEditExperienceModalScrollPosition,
  ] = useState(0);
  const [isEditExperienceModalOpen, setIsEditExperienceModalOpen] =
    useState(false);
  const [
    editEducationModalScrollPosition,
    setEditEducationModalScrollPosition,
  ] = useState(0);
  const [isEditEducationModalOpen, setIsEditEducationModalOpen] =
    useState(false);

  const [reposts, setReposts] = useState([]);
  const [displayedReposts, setDisplayedReposts] = useState(3);

  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);

  const storage = getStorage();

  const navigate = useNavigate();

  const [showFullIntro, setShowFullIntro] = useState(false);

  const [visibleExperiences, setVisibleExperiences] = useState(3);
  const [visibleEducation, setVisibleEducation] = useState(3);

  const [reactions, setReactions] = useState([]);

  const [isInterestsModalOpen, setIsInterestsModalOpen] = useState(false);
  const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const toggleExpandText = useCallback((id) => {
    setExpandedTexts((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  }, []);

  const MAX_LENGTH = 125; // Same as Intro text

  const renderDescription = (description, id) => {
    if (!description) return null;

    return (
      <>
        <p
          className={`${classes.role} ${
            expandedDescriptions[id] ? classes.expanded : ""
          }`}
          style={{
            whiteSpace: "pre-wrap",
            WebkitLineClamp: expandedDescriptions[id] ? "unset" : 3,
          }}
        >
          {description}
          {!expandedDescriptions[id] && description.split("\n").length > 3 && (
            <span
              className={classes.moreButton}
              onClick={() => toggleDescription(id)}
            >
              ...more
            </span>
          )}
        </p>
        {expandedDescriptions[id] && (
          <span
            className={classes.moreButton}
            onClick={() => toggleDescription(id)}
          >
            Show less
          </span>
        )}
      </>
    );
  };

  const renderIntroText = () => {
    const introText =
      userData.introHtml || "Describe who you are, and what you do.";
    const maxLength = 250; // Adjust this value to set the desired text limit

    if (introText.length <= maxLength || showFullIntro) {
      return { text: introText, showMore: false };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return { text: truncatedText, showMore: true };
    }
  };

  // Add this sorting function near other sorting-related code
  const sortedStartups = useMemo(() => {
    if (!startups) return [];
    return [...startups].sort((a, b) => {
      // Convert month strings to numbers for comparison
      const aMonth = monthsList.indexOf(a.monthFounded);
      const bMonth = monthsList.indexOf(b.monthFounded);

      // Create dates for comparison
      const aDate = new Date(a.yearFounded, aMonth);
      const bDate = new Date(b.yearFounded, bMonth);

      // Compare dates first
      if (aDate.getTime() !== bDate.getTime()) {
        return bDate.getTime() - aDate.getTime(); // Newest first
      }

      // If dates are the same, compare creation timestamps
      return b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime();
    });
  }, [startups]);

  const sortedExperiences = useMemo(() => {
    if (!userData.experiences) return [];
    return [...userData.experiences].sort((a, b) => {
      const aEndDate = a.isCurrentlyWorking
        ? Date.now()
        : new Date(`${a.endMonth} 1, ${a.endYear}`).getTime();
      const bEndDate = b.isCurrentlyWorking
        ? Date.now()
        : new Date(`${b.endMonth} 1, ${b.endYear}`).getTime();

      if (aEndDate !== bEndDate) {
        return bEndDate - aEndDate; // Most recent end date first
      }

      const aStartDate = new Date(
        `${a.startMonth} 1, ${a.startYear}`
      ).getTime();
      const bStartDate = new Date(
        `${b.startMonth} 1, ${b.startYear}`
      ).getTime();

      if (aStartDate !== bStartDate) {
        return bStartDate - aStartDate; // Most recent start date first
      }

      // If both start and end dates are the same, use dateAdded
      return (b.dateAdded?.toMillis() || 0) - (a.dateAdded?.toMillis() || 0);
    });
  }, [userData.experiences]);

  const handleEventUpdated = (eventUpdate) => {
    // Check if this is a deletion
    if (eventUpdate.deleted) {
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== eventUpdate.id)
      );
      // Update event counts
      setEventCounts((prevCounts) => ({
        ...prevCounts,
        Owner: prevCounts.Owner - 1,
      }));
      return;
    }

    // Handle normal updates
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.id === eventUpdate.id
          ? {
              ...event,
              ...eventUpdate,
              membershipType: "owner", // Ensure membership type is preserved
            }
          : event
      )
    );
  };

  const fetchActivityData = useCallback(async () => {
    if (!currentUser?.uid) return;

    const activityTypeMap = {
      Posts: fetchPosts,
      Reactions: fetchReactions,
      Comments: fetchComments,
      Reposts: fetchReposts,
    };

    const fetchFunction = activityTypeMap[activityType];
    if (fetchFunction) {
      await fetchFunction();
    }
  }, [
    currentUser?.uid,
    activityType,
    fetchPosts,
    fetchReactions,
    fetchComments,
    fetchReposts,
  ]);

  useEffect(() => {
    if (!currentUser?.uid || !activityType) return;

    const fetchData = async () => {
      switch (activityType) {
        case "Posts":
          await fetchPosts();
          break;
        case "Reactions":
          await fetchReactions();
          break;
        case "Comments":
          await fetchComments();
          break;
        case "Reposts":
          await fetchReposts();
          break;
      }
    };

    fetchData();
  }, [
    currentUser?.uid,
    activityType,
    fetchPosts,
    fetchReactions,
    fetchComments,
    fetchReposts,
  ]);

  const handleMoreClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowFullIntro(true);
  };

  const checkConnectionType = useCallback(
    async (currentUserId, targetUserId) => {
      if (!currentUserId || !targetUserId) return "Extended";
      if (currentUserId === targetUserId) return "You";

      const userConnectionRef = doc(
        firestore,
        `users/${currentUserId}/connections/${targetUserId}`
      );
      const userConnectionDoc = await getDoc(userConnectionRef);

      if (
        userConnectionDoc.exists() &&
        userConnectionDoc.data().status === "Connected"
      ) {
        return "Direct";
      }

      return "Extended";
    },
    []
  );

  const fetchConnections = useCallback(async () => {
    if (!currentUser?.uid) return;

    try {
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      // Only get connections with "Connected" status
      const connectionsQuery = query(
        connectionsRef,
        where("status", "==", "Connected")
      );
      const connectionsSnapshot = await getDocs(connectionsQuery);

      const connectionsData = await Promise.all(
        connectionsSnapshot.docs.map(async (connectionDoc) => {
          // Verify the connection still exists and is still "Connected"
          const connectionData = connectionDoc.data();
          if (connectionData.status !== "Connected") {
            return null;
          }

          const userDocRef = doc(firestore, "users", connectionDoc.id);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            const connectionType = await checkConnectionType(
              currentUser.uid,
              connectionDoc.id
            );

            // Also verify the reverse connection exists and is "Connected"
            const reverseConnectionRef = doc(
              firestore,
              `users/${connectionDoc.id}/connections/${currentUser.uid}`
            );
            const reverseConnectionDoc = await getDoc(reverseConnectionRef);

            if (
              !reverseConnectionDoc.exists() ||
              reverseConnectionDoc.data().status !== "Connected"
            ) {
              return null;
            }

            return {
              id: connectionDoc.id,
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                urlSlug: userData.urlSlug || "",
              },
              connectionType,
              connectedAt: connectionData.connectedAt?.toDate() || new Date(0),
            };
          }
          return null;
        })
      );

      // Filter out null values and sort by connectedAt timestamp
      const validConnections = connectionsData
        .filter(Boolean)
        .sort((a, b) => b.connectedAt - a.connectedAt);

      setConnections(validConnections);
    } catch (error) {}
  }, [currentUser?.uid, checkConnectionType]);

  const handleConnectionRemoved = useCallback(() => {
    fetchConnections(); // Re-fetch connections after removal
    setConnectionsCount((prev) => prev - 1); // Update the count
  }, [fetchConnections]);

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const CommunitySection = ({ community }) => {
    const navigate = useNavigate();

    const handleCommunityClick = () => {
      if (community.communityUrlSlug) {
        navigate(`/community/${community.communityUrlSlug}`);
      }
    };

    const handleLinkClick = (e) => {
      e.stopPropagation();
      if (e.target.tagName === "A") {
        e.preventDefault();
        window.open(e.target.href, "_blank", "noopener,noreferrer");
      }
    };

    return (
      <div key={community.id} className={classes.userContainer}>
        <img
          src={community.communityImage || communityImage}
          alt={community.communityName}
          className={classes.communityImage}
          onClick={handleCommunityClick}
          style={{ cursor: "pointer" }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = greyCommunityImage;
          }}
        />
        <div className={classes.userInfo}>
          <div className={classes.userNameContainer}>
            <div
              className={classes.userName}
              onClick={handleCommunityClick}
              style={{ cursor: "pointer" }}
            >
              {community.communityName}
            </div>
            <span className={classes.userConnectionType}>• Community</span>
          </div>
          {community.tagline && (
            <div className={classes.userBio}>{community.tagline}</div>
          )}
          {community.link && (
            <a
              href={community.link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.userLink}
              onClick={handleLinkClick}
            >
              <span>{community.linkText || community.link}</span>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                />
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  };

  const StartupSection = ({ startup }) => {
    const navigate = useNavigate();

    const handleStartupClick = () => {
      if (startup.startupUrlSlug) {
        navigate(`/startup/${startup.startupUrlSlug}`);
      }
    };

    const handleLinkClick = (e) => {
      e.stopPropagation();
      if (e.target.tagName === "A") {
        e.preventDefault();
        window.open(e.target.href, "_blank", "noopener,noreferrer");
      }
    };

    return (
      <div key={startup.id} className={classes.userContainer}>
        <img
          src={startup.startupImage || startupImage}
          alt={startup.startupName}
          className={classes.startupImage}
          onClick={handleStartupClick}
          style={{ cursor: "pointer" }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = startupImage;
          }}
        />
        <div className={classes.userInfo}>
          <div className={classes.userNameContainer}>
            <div
              className={classes.userName}
              onClick={handleStartupClick}
              style={{ cursor: "pointer" }}
            >
              {startup.startupName}
            </div>
            <span className={classes.userConnectionType}>• Startup</span>
          </div>
          {startup.bio && <div className={classes.userBio}>{startup.bio}</div>}
          {startup.link && (
            <a
              href={startup.link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.userLink}
              onClick={handleLinkClick}
            >
              <span>{startup.linkText || startup.link}</span>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                />
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  };

  const handleRepostClick = (repostId) => {
    navigate(`/repost/${repostId}`, {
      state: {
        fromOwnProfile: true,
        userData: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage,
        },
      },
    });
  };

  const handleActivityChange = (selectedActivity) => {
    setActivityType(selectedActivity);
    if (currentUser?.uid) {
      // Reset all loading states
      setLoadingPosts(false);
      setLoadingBoosts(false);
      setLoadingComments(false);
      setLoadingReposts(false);

      // Set the appropriate loading state based on selection
      if (selectedActivity === "Posts") setLoadingPosts(true);
      if (selectedActivity === "Boosts") setLoadingBoosts(true);
      if (selectedActivity === "Comments") setLoadingComments(true);
      if (selectedActivity === "Reposts") setLoadingReposts(true);

      const activityTypeMap = {
        Posts: fetchPosts,
        Boosts: fetchReactions,
        Comments: fetchComments,
        Reposts: fetchReposts,
      };

      const fetchFunction = activityTypeMap[selectedActivity];
      if (fetchFunction) {
        fetchFunction();
      }
    }
  };

  const filteredStartups = useMemo(() => {
    if (!startups) return [];

    const filtered = startups.filter((startup) => {
      switch (startupType) {
        case "Owner":
          return startup.membershipType === "owner";
        case "TeamMember":
          return startup.membershipType === "member";
        case "Supported":
          return startup.supported;
        case "Following":
          return startup.following;
        default:
          return false;
      }
    });

    // For supported startups, deduplicate by keeping only the most recent donation
    if (startupType === "Supported") {
      const uniqueStartups = filtered.reduce((acc, current) => {
        const existing = acc.find((item) => item.id === current.id);

        if (!existing) {
          acc.push(current);
        } else {
          const currentDonationDate =
            current.supportedAt?.toDate?.() || new Date(current.supportedAt);
          const existingDonationDate =
            existing.supportedAt?.toDate?.() || new Date(existing.supportedAt);

          if (currentDonationDate > existingDonationDate) {
            const index = acc.findIndex((item) => item.id === current.id);
            acc[index] = current;
          }
        }

        return acc;
      }, []);

      return uniqueStartups.sort((a, b) => {
        const aDonationDate =
          a.supportedAt?.toDate?.() || new Date(a.supportedAt);
        const bDonationDate =
          b.supportedAt?.toDate?.() || new Date(b.supportedAt);
        return bDonationDate - aDonationDate;
      });
    }

    return filtered.sort((a, b) => {
      if (startupType === "Following") {
        const aTime = a.followedAt?.toDate?.() || new Date(a.followedAt);
        const bTime = b.followedAt?.toDate?.() || new Date(b.followedAt);
        return bTime - aTime;
      } else if (startupType === "Owner") {
        const aTime = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const bTime = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return bTime - aTime;
      } else if (startupType === "TeamMember") {
        const aTime = a.acceptedAt?.toDate?.() || new Date(a.acceptedAt);
        const bTime = b.acceptedAt?.toDate?.() || new Date(b.acceptedAt);
        return bTime - aTime;
      }
      return 0;
    });
  }, [startups, startupType]);

  const handlePostClick = (postId, fromComments = false) => {
    let navigationPath;
    let state = {
      fromUserProfile: false,
      userData: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        profileImage: userData.profileImage,
        urlSlug: userData.urlSlug, // Add this line
        id: userData.id, // Add this line
      },
    };

    switch (activityType) {
      case "Comments":
        navigationPath = `/comment/${postId}`;
        break;
      case "Reactions":
        navigationPath = `/reaction/${postId}`;
        state.reactionEmoji = reactions.find(
          (r) => r.originalPost.id === postId
        )?.emoji;
        break;
      case "Reposts":
        navigationPath = `/repost/${postId}`;
        break;
      default: // "Posts" or any other case
        navigationPath = `/post/${postId}`;
    }

    navigate(navigationPath, { state });
  };

  const handleShowAllComments = () => {
    navigate("/all-comments/me");
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  useEffect(() => {
    if (currentUser && activityType === "Posts") {
      fetchPosts();
    }
  }, [currentUser, activityType, fetchPosts]);

  const [showConnectionsModal, setShowConnectionsModal] = useState(false);

  const PostInteractionCount = ({
    postId,
    reposts,
    commentCount,
    onClick,
    onCommentClick,
  }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0 && commentCount === 0) return null;

    return (
      <>
        {commentCount > 0 && (
          <span>
            {commentCount} comment{commentCount !== 1 ? "s" : ""}
          </span>
        )}
        {repostCount > 0 && commentCount > 0 && (
          <span className={classes.dot}>•</span>
        )}
        {repostCount > 0 && (
          <span>
            {repostCount} repost{repostCount !== 1 ? "s" : ""}
          </span>
        )}
      </>
    );
  };

  const fetchConnectionsCount = useCallback(async (userId) => {
    const connectionsRef = collection(firestore, `users/${userId}/connections`);
    const connectionsSnapshot = await getDocs(connectionsRef);
    const connectedCount = connectionsSnapshot.docs.filter(
      (doc) => doc.data().status === "Connected"
    ).length;
    setConnectionsCount(connectedCount);
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchConnections();
    }
  }, [currentUser, fetchConnections]); // Add fetchConnections as dependency since it's memoized

  useEffect(() => {
    if (currentUser) {
      fetchConnectionsCount(currentUser.uid);
    }
  }, [currentUser, fetchConnectionsCount]);

  const ReactionsSummary = ({ reactions, onClick, post }) => {
    const reactionCount = Object.keys(reactions).length;
    const topEmojis = useMemo(() => getTopThreeEmojis(reactions), [reactions]);

    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
      const checkSafari = () => {
        const isSafari =
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
          /apple/i.test(navigator.vendor);
        setIsSafari(isSafari);
        document.documentElement.style.setProperty(
          "--emoji-font-size",
          isSafari ? "0.75rem" : "0.875rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-left",
          isSafari ? "-0.25rem" : "-0.25rem"
        );
        document.documentElement.style.setProperty(
          "--count-margin-left",
          isSafari ? "0.25rem" : "0.25rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-text-size",
          isSafari ? "0.75rem" : "0.875rem"
        );

        document.documentElement.style.setProperty(
          "--emoji-margin-bottom",
          isSafari ? "0rem" : "0rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-top",
          isSafari ? "0rem" : "0rem"
        );
      };

      checkSafari();
    }, []);

    return (
      <div>
        {reactionCount} boost{reactionCount !== 1 ? "s" : ""}
        {(post?.commentCount > 0 ||
          Object.keys(post?.reposts || {}).length > 0) && (
          <span className={classes.dot}>•</span>
        )}
      </div>
    );
  };

  const stripHtmlTags = (html) => {
    if (!html) return "";
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const truncateText = (text, lines) => {
    const words = text.split(" ");
    const truncated = words.slice(0, lines * 10).join(" ");
    return truncated.length < text.length ? `${truncated}...` : truncated;
  };

  const RepostCount = ({ postId, reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div
        className={classes.repostCount}
        onClick={() => onClick(postId, reposts)}
      >
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const formatTimestamp = (date) => {
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return "now";
    if (minutes < 60) return `${minutes}m`;
    if (hours < 24) return `${hours}h`;
    if (days < 7) return `${days}d`;
    if (weeks < 4) return `${weeks}w`;
    if (months < 12) return `${months}mo`;
    return `${years}y`;
  };

  const toggleProfileModal = (isSave = false) => {
    if (!showProfileModal) {
      setProfileModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, profileModalScrollPosition);
      }, 0);
    }
    setShowProfileModal(!showProfileModal);
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  const toggleIntroModal = (isSave = false) => {
    if (!showIntroModal) {
      setIntroModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, introModalScrollPosition);
      }, 0);
    }
    setShowIntroModal(!showIntroModal);
    setIsIntroModalOpen(!isIntroModalOpen);
  };

  const toggleSkillsModal = (isSave = false) => {
    if (!showSkillsModal) {
      // Opening the modal
      setSkillsModalScrollPosition(window.scrollY);
    } else {
      // Closing the modal
      setTimeout(() => {
        window.scrollTo(0, skillsModalScrollPosition);
      }, 0);
    }
    setShowSkillsModal(!showSkillsModal);
    setIsSkillsModalOpen(!isSkillsModalOpen);
  };

  const toggleInterestsModal = (isSave = false) => {
    if (!showInterestsModal) {
      // Opening the modal
      setModalScrollPosition(window.scrollY);
    } else {
      // Closing the modal
      setTimeout(() => {
        window.scrollTo(0, modalScrollPosition);
      }, 0);
    }
    setShowInterestsModal(!showInterestsModal);
    setIsInterestsModalOpen(!isInterestsModalOpen);
  };

  const toggleAddExperienceModal = (isSave = false) => {
    if (!showAddExperienceModal) {
      setAddExperienceModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addExperienceModalScrollPosition);
      }, 0);
    }
    setShowAddExperienceModal(!showAddExperienceModal);
    setIsAddExperienceModalOpen(!isAddExperienceModalOpen);
  };

  const toggleEditExperienceModal = () => {
    if (!showEditExperienceModal) {
      setEditExperienceModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editExperienceModalScrollPosition);
      }, 0);
    }
    setShowEditExperienceModal(!showEditExperienceModal);
    setIsEditExperienceModalOpen(!isEditExperienceModalOpen);
    setShowEditSingleExperienceModal(false);
  };

  const toggleAddEducationModal = (isSave = false) => {
    if (!showAddEducationModal) {
      setAddEducationModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addEducationModalScrollPosition);
      }, 0);
    }
    setShowAddEducationModal(!showAddEducationModal);
    setIsAddEducationModalOpen(!isAddEducationModalOpen);
  };

  const toggleEditEducationModal = () => {
    if (!showEditEducationModal) {
      setEditEducationModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editEducationModalScrollPosition);
      }, 0);
    }
    setShowEditEducationModal(!showEditEducationModal);
    setIsEditEducationModalOpen(!isEditEducationModalOpen);
    setShowEditSingleEducationModal(false);
  };

  const openEditSingleEducationModal = (education) => {
    setSelectedEducation(education);
    setShowEditSingleEducationModal(true);
    setShowEditEducationModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (index) => {
    if (
      index !== 2 ||
      (userData.experiences && userData.experiences.length > 0)
    ) {
      setHoverStates((prevHoverStates) =>
        prevHoverStates.map((state, i) => (i === index ? true : state))
      );
    }
  };

  const handleMouseLeave = (index) => {
    if (
      index !== 2 ||
      (userData.experiences && userData.experiences.length > 0)
    ) {
      setHoverStates((prevHoverStates) =>
        prevHoverStates.map((state, i) => (i === index ? false : state))
      );
    }
  };

  const handleProfileSave = (newUserData) => {
    setUserData((prevData) => {
      const updatedData = { ...prevData };

      Object.keys(newUserData).forEach((key) => {
        if (key !== "experiences" && key !== "education") {
          updatedData[key] = newUserData[key];
        }
      });

      if (newUserData.experiences) {
        updatedData.experiences = newUserData.experiences;
      }

      if (newUserData.education) {
        updatedData.education = newUserData.education;
      }

      return updatedData;
    });
  };

  const handleExperienceAdded = (newExperience) => {
    setUserData((prevData) => ({
      ...prevData,
      experiences: [...prevData.experiences, newExperience],
    }));
  };

  const renderFilePreview = (postPreview) => {
    if (postPreview.mediaType === "image") {
      // Handle single image
      if (typeof postPreview.mediaUrl === "string") {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl} alt="Post preview" />
          </div>
        );
      }
      // Handle multiple images
      else if (
        Array.isArray(postPreview.mediaUrl) &&
        postPreview.mediaUrl.length > 0
      ) {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl[0]} alt="Post preview" />
            {postPreview.mediaUrl.length > 1 && (
              <div className={classes.multipleImagesIndicator}>
                +{postPreview.mediaUrl.length - 1}
              </div>
            )}
          </div>
        );
      }
    }

    if (postPreview.mediaType === "video") {
      return (
        <div className={classes.mediaPreview}>
          <video
            src={postPreview.mediaUrl}
            preload="metadata"
            style={{ display: "none" }} // Hide the video element completely
            muted
            playsInline
            onLoadedMetadata={(e) => {
              const video = e.target;
              const canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              video.currentTime = 0.1; // Set to 0.1s to avoid black frame

              video.addEventListener(
                "seeked",
                () => {
                  try {
                    // Draw the video frame to canvas
                    canvas
                      .getContext("2d")
                      .drawImage(video, 0, 0, canvas.width, canvas.height);

                    // Create and insert the static image
                    const img = new Image();
                    img.src = canvas.toDataURL();
                    img.style.width = "100%";
                    img.style.height = "100%";
                    img.style.objectFit = "cover";

                    // Find the container and insert the image
                    const container = video.parentNode;
                    container.insertBefore(img, container.firstChild);

                    // Cleanup
                    video.remove();
                  } catch (err) {
                    // If canvas operations fail, show the first frame of video as fallback
                    video.style.display = "block";
                    video.controls = false;
                    video.autoPlay = false;
                    video.style.pointerEvents = "none";
                  }
                },
                { once: true }
              );
            }}
          />
          <div className={classes.playButton}></div>
        </div>
      );
    }

    if (postPreview.mediaType === "pdf" || postPreview.fileName) {
      return (
        <div className={classes.filePreview}>
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      );
    }

    return null;
  };

  const handleEducationAdded = (newEducation) => {
    setUserData((prevData) => ({
      ...prevData,
      education: [...(prevData.education || []), newEducation],
    }));
  };

  const userImage = userData.profileImage || profileImage;

  useEffect(() => {
    const updateProfileCompletionStatus = async () => {
      if (!currentUser?.uid) return;

      const isComplete = Object.values(completionStatus).every(
        (status) => status === true
      );

      try {
        const userRef = doc(firestore, "users", currentUser.uid);

        // Only update if the completion status has changed
        const userDoc = await getDoc(userRef);
        const currentCompletionStatus = userDoc.data()?.isProfileComplete;

        if (currentCompletionStatus !== isComplete) {
          await updateDoc(userRef, {
            isProfileComplete: isComplete,
          });
        }
      } catch (error) {
        console.error("Error updating profile completion status:", error);
      }
    };

    // Only run this effect if we have valid completion status data
    if (Object.keys(completionStatus).length > 0) {
      updateProfileCompletionStatus();
    }
  }, [completionStatus, currentUser?.uid]);
  useEffect(() => {
    const updateProfileCompletionStatus = async () => {
      if (!currentUser?.uid) return;

      const isComplete = Object.values(completionStatus).every(
        (status) => status === true
      );

      try {
        const userRef = doc(firestore, "users", currentUser.uid);

        // Only update if the completion status has changed
        const userDoc = await getDoc(userRef);
        const currentCompletionStatus = userDoc.data()?.isProfileComplete;

        if (currentCompletionStatus !== isComplete) {
          await updateDoc(userRef, {
            isProfileComplete: isComplete,
          });
        }
      } catch (error) {
        console.error("Error updating profile completion status:", error);
      }
    };

    // Only run this effect if we have valid completion status data
    if (Object.keys(completionStatus).length > 0) {
      updateProfileCompletionStatus();
    }
  }, [completionStatus, currentUser?.uid]);

  useEffect(() => {
    const checkCompletionStatus = async () => {
      if (!currentUser?.uid) return;

      try {
        // Check if profile image is custom (not the default image)
        const hasCustomProfileImage =
          userData.profileImage &&
          !userData.profileImage.includes("defaultImage.jpg") &&
          !userData.profileImage.includes("default.jpg");

        setCompletionStatus({
          profilePicture: hasCustomProfileImage,
          about: !!userData.intro,
          skills: Array.isArray(userData.skills) && userData.skills.length > 0,
          interests:
            Array.isArray(userData.interests) && userData.interests.length > 0,
          experience:
            Array.isArray(userData.experiences) &&
            userData.experiences.length > 0,
          education:
            Array.isArray(userData.education) && userData.education.length > 0,
        });
      } catch (error) {
        console.error("Error checking completion status:", error);
      }
    };

    checkCompletionStatus();
  }, [currentUser?.uid, userData]);

  const checklistItems = [
    // Quick wins (< 1 minute)
    { id: "profilePicture", text: "Add a profile picture" },
    { id: "about", text: "Write an about" },

    // Medium effort (2-3 minutes)
    { id: "skills", text: "Share your skills" },
    { id: "interests", text: "Express your interests" },

    // More involved (5+ minutes)
    { id: "experience", text: "Add your experience" },
    { id: "education", text: "Add your education" },

    // Social (depends on community)
  ];

  const CompletedIcon = () => (
    <svg
      className={classes.checkIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="orangered"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
        clipRule="evenodd"
      />
    </svg>
  );

  const IncompleteIcon = () => (
    <svg
      className={classes.checkIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="orangered"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );

  const isProfileComplete = useMemo(() => {
    return Object.values(completionStatus).every((status) => status === true);
  }, [completionStatus]);

  const openEditSingleExperienceModal = (experience) => {
    setSelectedExperience(experience);
    setShowEditSingleExperienceModal(true);
    setShowEditExperienceModal(false);
  };

  const openConnectionsModal = () => {
    setConnectionsModalScrollPosition(window.scrollY);
    setShowConnectionsModal(true);
    setIsConnectionsModalOpen(true);
  };

  const handleModalClose = () => {
    setShowConnectionsModal(false);
    setIsConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, connectionsModalScrollPosition);
    }, 0);
  };

  // Update the renderComments function to use the new data structure
  const renderComments = () => {
    if (loadingComments) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {commentedPosts.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>
                You haven’t commented yet
              </p>{" "}
              <p className={classes.defaultText}>
                Posts you comment on will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}> commented on this </span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <img
                    src={greyPostImage}
                    alt="Default startup"
                    className={classes.noPostImage}
                  />
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 boosts</div>
                <span className={classes.dot}>•</span>
                <div>50 comments</div>
              </div>
            </div>
          ) : (
            <>
              {commentedPosts
                .slice(0, displayedComments)
                .map((commentedPost, index) => (
                  <React.Fragment key={commentedPost.id}>
                    {index !== 0 && <div className={classes.postBorder}></div>}
                    <div>
                      <div className={classes.postHeader}>
                        <span className={classes.postAuthor}>You</span>
                        <span className={classes.postMessage}>
                          {" "}
                          commented on this{" "}
                        </span>
                        <span className={classes.dot}>•</span>
                        <span>
                          {formatTimestamp(commentedPost.lastCommentedAt)}
                        </span>
                      </div>
                      <div
                        className={`${classes.post} ${
                          !commentedPost.originalPost.postPreview.mediaType &&
                          commentedPost.originalPost.postPreview.text
                            ? classes.textOnlyPost
                            : ""
                        }`}
                        onClick={() =>
                          handlePostClick(commentedPost.originalPost.id, true)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className={classes.postContent}>
                          {commentedPost.originalPost?.postPreview?.mediaType &&
                            renderFilePreview(
                              commentedPost.originalPost.postPreview
                            )}
                          {commentedPost.originalPost?.postPreview?.text && (
                            <p className={classes.textPreview}>
                              {truncateText(
                                stripHtmlTags(
                                  commentedPost.originalPost.postPreview.text
                                ),
                                3
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {(commentedPost.originalPost?.reactions &&
                      Object.keys(commentedPost.originalPost.reactions).length >
                        0) ||
                    (commentedPost.originalPost?.reposts &&
                      Object.keys(commentedPost.originalPost.reposts).length >
                        0) ||
                    (commentedPost.originalPost?.commentCount &&
                      commentedPost.originalPost.commentCount > 0) ? (
                      <div className={classes.postInteractions}>
                        {commentedPost.originalPost?.reactions &&
                          Object.keys(commentedPost.originalPost.reactions)
                            .length > 0 && (
                            <ReactionsSummary
                              reactions={commentedPost.originalPost.reactions}
                              post={commentedPost.originalPost}
                              onClick={() =>
                                handleReactionsClick(
                                  commentedPost.originalPost.id,
                                  commentedPost.originalPost.reactions
                                )
                              }
                            />
                          )}

                        <PostInteractionCount
                          postId={commentedPost.originalPost?.id}
                          reposts={commentedPost.originalPost?.reposts}
                          commentCount={
                            commentedPost.originalPost?.commentCount
                          }
                          onClick={handleRepostsClick}
                          onCommentClick={() =>
                            handlePostClick(commentedPost.originalPost?.id)
                          }
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
            </>
          )}
          {commentedPosts.length > 3 &&
            displayedComments < commentedPosts.length && (
              <div
                className={classes.showAllPosts}
                onClick={handleShowAllComments}
              >
                Show all comments
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 12H5m14 0-4 4m4-4-4-4"
                  />
                </svg>
              </div>
            )}
        </div>
      </>
    );
  };

  const handleReactionClick = (reactionId, reactionEmoji) => {
    navigate(`/reaction/${reactionId}`, {
      state: { fromUserProfile: false, reactionEmoji: reactionEmoji },
    });
  };

  const renderReactions = () => {
    if (loadingBoosts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reactions.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>You haven’t boosted yet</p>{" "}
              <p className={classes.defaultText}>
                Posts you boost will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}> boosted this</span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <img
                    src={greyPostImage}
                    alt="Default startup"
                    className={classes.noPostImage}
                  />
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 boosts</div>
                <span className={classes.dot}>•</span>
                <div>50 comments</div>
              </div>
            </div>
          ) : (
            <>
              {reactions.slice(0, displayedReactions).map((reaction, index) => (
                <React.Fragment key={reaction.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>You</span>
                      <span className={classes.postMessage}> boosted this</span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(reaction.reactedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !reaction.originalPost.postPreview.mediaType &&
                        reaction.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() =>
                        handleReactionClick(reaction.id, reaction.emoji)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {reaction.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(reaction.originalPost.postPreview)}
                        {reaction.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              stripHtmlTags(
                                reaction.originalPost.postPreview.text
                              ),
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {(reaction.originalPost?.reactions &&
                    Object.keys(reaction.originalPost.reactions).length > 0) ||
                  (reaction.originalPost?.reposts &&
                    Object.keys(reaction.originalPost.reposts).length > 0) ||
                  (reaction.originalPost?.commentCount &&
                    reaction.originalPost.commentCount > 0) ? (
                    <div className={classes.postInteractions}>
                      {reaction.originalPost?.reactions &&
                        Object.keys(reaction.originalPost.reactions).length >
                          0 && (
                          <ReactionsSummary
                            reactions={reaction.originalPost.reactions}
                            post={reaction.originalPost}
                            onClick={() =>
                              handleReactionsClick(
                                reaction.originalPost.id,
                                reaction.originalPost.reactions
                              )
                            }
                          />
                        )}

                      <PostInteractionCount
                        postId={reaction.originalPost?.id}
                        reposts={reaction.originalPost?.reposts}
                        commentCount={reaction.originalPost?.commentCount}
                        onClick={handleRepostsClick}
                        onCommentClick={() =>
                          handlePostClick(reaction.originalPost?.id)
                        }
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
            </>
          )}
          {reactions.length > 3 && displayedReactions < reactions.length && (
            <div
              className={classes.showAllPosts}
              onClick={() => navigate("/all-reactions/me")}
            >
              Show all boosts
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderReposts = () => {
    if (loadingReposts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reposts.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>
                You haven’t reposted yet
              </p>{" "}
              <p className={classes.defaultText}>
                Posts you repost will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}> reposted this </span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <img
                    src={greyPostImage}
                    alt="Default startup"
                    className={classes.noPostImage}
                  />
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 boosts</div>
                <span className={classes.dot}>•</span>
                <div>50 comments</div>
              </div>
            </div>
          ) : (
            <>
              {reposts.slice(0, displayedReposts).map((repost, index) => (
                <React.Fragment key={repost.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>You</span>
                      <span className={classes.postMessage}>
                        {" "}
                        reposted this{" "}
                      </span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(repost.repostedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !repost.originalPost.postPreview.mediaType &&
                        repost.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handleRepostClick(repost.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {repost.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(repost.originalPost.postPreview)}
                        {repost.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              repost.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {(repost.originalPost?.reactions &&
                    Object.keys(repost.originalPost.reactions).length > 0) ||
                  (repost.originalPost?.reposts &&
                    Object.keys(repost.originalPost.reposts).length > 0) ||
                  (repost.originalPost?.commentCount &&
                    repost.originalPost.commentCount > 0) ? (
                    <div className={classes.postInteractions}>
                      {repost.originalPost?.reactions &&
                        Object.keys(repost.originalPost.reactions).length >
                          0 && (
                          <ReactionsSummary
                            reactions={repost.originalPost.reactions}
                            post={repost.originalPost}
                            onClick={() =>
                              handleReactionsClick(
                                repost.originalPost.id,
                                repost.originalPost.reactions
                              )
                            }
                          />
                        )}

                      <PostInteractionCount
                        postId={repost.originalPost?.id}
                        reposts={repost.originalPost?.reposts}
                        commentCount={repost.originalPost?.commentCount}
                        onClick={handleRepostsClick}
                        onCommentClick={() =>
                          handlePostClick(repost.originalPost?.id)
                        }
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {reposts.length > 3 && displayedReposts < reposts.length && (
          <div
            className={classes.showAllPosts}
            onClick={() => navigate("/all-reposts/me")}
          >
            Show all reposts
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const handleCloseTouch = (modalToggleFunction) => (event) => {
    event.preventDefault();
    modalToggleFunction();
  };

  useEffect(() => {
    if (currentUser) {
      if (activityType === "Posts") {
        fetchPosts();
      } else if (activityType === "Reposts") {
        fetchReposts();
      } else if (activityType === "Reactions") {
        fetchReactions();
      } else if (activityType === "Comments") {
        fetchComments();
      }
    }
  }, [
    currentUser,
    activityType,
    fetchPosts,
    fetchReposts,
    fetchReactions,
    fetchComments,
  ]);

  const fetchActivityCounts = useCallback(async () => {
    if (!currentUser) return;

    try {
      const userId = currentUser.uid;

      // Fetch posts count
      const postsRef = collection(firestore, `users/${userId}/posts`);
      const postsSnapshot = await getDocs(query(postsRef, limit(1)));
      const postsCount = postsSnapshot.size;

      // Fetch reactions count
      const reactionsRef = collection(
        firestore,
        `users/${userId}/reactedPosts`
      );
      const reactionsSnapshot = await getDocs(query(reactionsRef, limit(1)));
      const reactionsCount = reactionsSnapshot.size;

      // Fetch reposts count
      const repostsRef = collection(firestore, `users/${userId}/reposts`);
      const repostsSnapshot = await getDocs(query(repostsRef, limit(1)));
      const repostsCount = repostsSnapshot.size;

      // Fetch comments count
      const commentsRef = collection(
        firestore,
        `users/${userId}/commentedPosts`
      );
      const commentsSnapshot = await getDocs(query(commentsRef, limit(1)));
      const commentsCount = commentsSnapshot.size;

      setActivityCounts({
        posts: postsCount,
        reactions: reactionsCount,
        reposts: repostsCount,
        comments: commentsCount,
      });
    } catch (error) {}
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchActivityCounts();
    }
  }, [currentUser, fetchActivityCounts]);

  const isActivityEmpty = () => {
    return (
      activityCounts.posts === 0 &&
      activityCounts.reactions === 0 &&
      activityCounts.comments === 0 &&
      activityCounts.reposts === 0
    );
  };

  const renderTruncatedText = useCallback(
    (text, id, htmlContent = null) => {
      if (!text) return null;

      const isExpanded = expandedTexts[id];

      if (text.length <= MAX_LENGTH) {
        return htmlContent ? (
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            onClick={handleLinkClick}
          />
        ) : (
          <p className={classes.description}>{text}</p>
        );
      }

      if (isExpanded) {
        return htmlContent ? (
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            onClick={handleLinkClick}
          />
        ) : (
          <p className={classes.description}>{text}</p>
        );
      }

      // Remove any trailing ellipsis before truncating
      const cleanText = text.replace(/\.{3,}$/, "").trim();
      const truncatedText = cleanText.slice(0, MAX_LENGTH).trim();

      return (
        <p className={classes.description}>
          {htmlContent ? (
            <span
              dangerouslySetInnerHTML={{
                __html: truncatedText,
              }}
              onClick={handleLinkClick}
            />
          ) : (
            truncatedText
          )}
          <span
            className={classes.moreButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleExpandText(id);
            }}
          >
            ...more
          </span>
        </p>
      );
    },
    [expandedTexts, toggleExpandText, handleLinkClick]
  );

  const fetchFollowingCount = useCallback(async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setFollowingCount(userData.followingCount || 0);
      }
    } catch (error) {}
  }, []);

  const fetchStartupData = async (userId) => {
    try {
      const batch = [];

      // Fetch owned startups
      const startupsSnapshot = await getDocs(
        collection(firestore, `users/${userId}/startups`)
      );
      const startupsData = startupsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        membershipType: "owner",
      }));
      batch.push(...startupsData);

      // Fetch TeamMember startups
      const allUsersSnapshot = await getDocs(collection(firestore, "users"));
      const teamMemberPromises = allUsersSnapshot.docs.map(async (userDoc) => {
        const startupRef = collection(
          firestore,
          `users/${userDoc.id}/startups`
        );
        const startupDocs = await getDocs(startupRef);

        const startupTeamPromises = startupDocs.docs.map(async (startupDoc) => {
          const teamRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupDoc.id}/team/${userId}`
          );
          const teamDoc = await getDoc(teamRef);

          if (teamDoc.exists() && teamDoc.data().status === "active") {
            return {
              id: startupDoc.id,
              ownerId: userDoc.id,
              ...startupDoc.data(),
              membershipType: "member",
              role: teamDoc.data().role,
              acceptedAt: teamDoc.data().acceptedAt, // Changed from joinedAt to acceptedAt
            };
          }
          return null;
        });

        const resolvedStartups = await Promise.all(startupTeamPromises);
        return resolvedStartups.filter((startup) => startup !== null);
      });

      // Fetch donated/supported startups
      const donationsRef = collection(firestore, `users/${userId}/donations`);
      const donationsSnapshot = await getDocs(donationsRef);
      const supportedPromises = donationsSnapshot.docs.map(
        async (donationDoc) => {
          const donationData = donationDoc.data();
          if (donationData.status !== "completed") return null;

          const startupRef = doc(
            firestore,
            `users/${donationData.startupOwnerId}/startups/${donationData.startupId}`
          );
          const startupDoc = await getDoc(startupRef);

          if (startupDoc.exists()) {
            return {
              id: startupDoc.id,
              ...startupDoc.data(),
              supported: true,
              supportedAt: donationData.createdAt,
              ownerId: donationData.startupOwnerId,
              donationAmount: donationData.amount,
              donationCurrency: donationData.currency,
            };
          }
          return null;
        }
      );

      // Fetch followed startups
      const followedStartupsRef = collection(
        firestore,
        `users/${userId}/startupsFollowing`
      );
      const followedSnapshot = await getDocs(followedStartupsRef);

      const followedPromises = followedSnapshot.docs.map(async (followDoc) => {
        const startupRef = doc(
          firestore,
          `users/${followDoc.data().ownerId}/startups/${followDoc.id}`
        );
        const startupDoc = await getDoc(startupRef);

        if (startupDoc.exists()) {
          return {
            id: startupDoc.id,
            ...startupDoc.data(),
            following: true,
            followedAt: followDoc.data().followedAt,
            ownerId: followDoc.data().ownerId,
          };
        }
        return null;
      });

      const teamMemberStartups = (await Promise.all(teamMemberPromises)).flat();
      const supportedStartups = (await Promise.all(supportedPromises)).filter(
        Boolean
      );
      const followedStartups = (await Promise.all(followedPromises)).filter(
        Boolean
      );

      batch.push(
        ...teamMemberStartups,
        ...supportedStartups,
        ...followedStartups
      );

      const counts = {
        Owner: batch.filter((s) => s.membershipType === "owner").length,
        TeamMember: batch.filter((s) => s.membershipType === "member").length,
        Supported: batch.filter((s) => s.supported).length,
        Following: batch.filter((s) => s.following).length,
      };
      setStartupCounts(counts);

      setStartups(batch);
    } catch (error) {}
  };

  // Complete fetchAllData function
  const fetchAllData = useCallback(
    async (userId) => {
      try {
        setIsLoading(true);
        setIsLoadingProfile(true);
        setIsLoadingActivity(true);
        setIsLoadingStartups(true);
        setIsLoadingConnections(true);
        setIsLoadingCommunities(true);
        setIsLoadingEvents(true);

        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setFollowingCount(data.followingCount || 0);

          const [experiencesSnapshot, educationSnapshot] = await Promise.all([
            getDocs(collection(firestore, "users", userId, "experiences")),
            getDocs(collection(firestore, "users", userId, "education")),
          ]);

          const experiences = experiencesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            dateAdded: doc.data().dateAdded,
          }));

          const education = educationSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setUserData((prevData) => ({
            ...prevData,
            ...data,
            experiences: experiences || [],
            education: education || [],
          }));
          setIsLoadingProfile(false);

          try {
            await fetchStartupData(userId);
          } finally {
            setIsLoadingStartups(false);
          }

          try {
            await Promise.all([
              fetchPosts(),
              fetchComments(),
              fetchReactions(),
              fetchReposts(),
              fetchActivityCounts(),
            ]);
          } finally {
            setIsLoadingActivity(false);
          }

          try {
            await Promise.all([
              fetchConnectionsCount(userId),
              fetchFollowingCount(userId),
            ]);
          } finally {
            setIsLoadingConnections(false);
          }

          try {
            const userCommunitiesRef = collection(
              firestore,
              `users/${userId}/communities`
            );
            const memberOfRef = collection(
              firestore,
              `users/${userId}/communitiesMemberOf`
            );
            const moderatingRef = collection(
              firestore,
              `users/${userId}/communitiesModerating`
            );

            const [
              userCommunitiesSnapshot,
              memberOfSnapshot,
              moderatingSnapshot,
            ] = await Promise.all([
              getDocs(userCommunitiesRef),
              getDocs(memberOfRef),
              getDocs(moderatingRef),
            ]);

            const ownedCommunitiesData = userCommunitiesSnapshot.docs.map(
              (doc) => ({
                id: doc.id,
                ...doc.data(),
                membershipType: "owner",
                createdAt: doc.data().createdAt,
              })
            );

            // Fetch member communities
            const memberCommunitiesPromises = memberOfSnapshot.docs.map(
              async (memberDoc) => {
                const memberData = memberDoc.data();
                const usersRef = collection(firestore, "users");
                const usersSnapshot = await getDocs(usersRef);

                for (const userDoc of usersSnapshot.docs) {
                  const communityRef = doc(
                    firestore,
                    `users/${userDoc.id}/communities/${memberDoc.id}`
                  );
                  const communityDoc = await getDoc(communityRef);

                  if (communityDoc.exists()) {
                    return {
                      id: memberDoc.id,
                      ownerId: userDoc.id,
                      ...communityDoc.data(),
                      membershipType: "member",
                      joinedAt: memberData.joinedAt || memberData.createdAt,
                    };
                  }
                }
                return null;
              }
            );

            // Fetch moderator communities
            const moderatorCommunitiesPromises = moderatingSnapshot.docs.map(
              async (moderatorDoc) => {
                const moderatorData = moderatorDoc.data();
                const communityRef = doc(
                  firestore,
                  `users/${moderatorData.communityOwnerId}/communities/${moderatorDoc.id}`
                );
                const communityDoc = await getDoc(communityRef);

                if (communityDoc.exists()) {
                  return {
                    id: moderatorDoc.id,
                    ownerId: moderatorData.communityOwnerId,
                    ...communityDoc.data(),
                    membershipType: "moderator",
                    acceptedAt: moderatorData.acceptedAt,
                  };
                }
                return null;
              }
            );

            const [memberCommunitiesData, moderatorCommunitiesData] =
              await Promise.all([
                Promise.all(memberCommunitiesPromises),
                Promise.all(moderatorCommunitiesPromises),
              ]);

            const allCommunitiesData = [
              ...ownedCommunitiesData,
              ...memberCommunitiesData.filter(Boolean),
              ...moderatorCommunitiesData.filter(Boolean),
            ];

            setCommunities(allCommunitiesData);

            const communityCounts = {
              Owner: allCommunitiesData.filter(
                (c) => c.membershipType === "owner"
              ).length,
              Moderator: allCommunitiesData.filter(
                (c) => c.membershipType === "moderator"
              ).length,
              Member: allCommunitiesData.filter(
                (c) => c.membershipType === "member"
              ).length,
            };
            setCommunityCounts(communityCounts);
          } finally {
            setIsLoadingCommunities(false);
          }

          try {
            const ownedEventsRef = collection(
              firestore,
              `users/${userId}/events`
            );
            const eventsOrganisingRef = collection(
              firestore,
              `users/${userId}/eventsOrganising`
            );
            const attendingEventsRef = collection(
              firestore,
              `users/${userId}/eventsAttending`
            );

            const [
              ownedEventsSnapshot,
              eventsOrganisingSnapshot,
              attendingEventsSnapshot,
            ] = await Promise.all([
              getDocs(ownedEventsRef),
              getDocs(eventsOrganisingRef),
              getDocs(attendingEventsRef),
            ]);

            const ownedEvents = ownedEventsSnapshot.docs.map((docSnapshot) => ({
              id: docSnapshot.id,
              ...docSnapshot.data(),
              membershipType: "owner",
            }));

            const organiserEventsPromises = eventsOrganisingSnapshot.docs.map(
              async (docSnapshot) => {
                const eventData = docSnapshot.data();
                if (!eventData.eventOwnerId) return null;

                const eventRef = doc(
                  firestore,
                  `users/${eventData.eventOwnerId}/events/${docSnapshot.id}`
                );
                const eventSnapshot = await getDoc(eventRef);

                if (eventSnapshot.exists()) {
                  const fullEventData = eventSnapshot.data();
                  // Create the event object BEFORE spreading the full event data
                  const organiserEvent = {
                    id: docSnapshot.id,
                    ...fullEventData,
                    membershipType: "Organising", // Make sure this exactly matches what we check for
                    eventOwnerId: eventData.eventOwnerId,
                    acceptedAt: eventData.acceptedAt,
                    // Force these fields again to ensure they're correct
                    membershipType: "Organising", // Set it again after spread to be sure
                    eventName: fullEventData.eventName,
                    eventImage: fullEventData.eventImage,
                    eventUrlSlug: fullEventData.eventUrlSlug,
                    tagline: fullEventData.tagline,
                    link: fullEventData.link,
                    linkText: fullEventData.linkText,
                    isOnline: fullEventData.isOnline,
                    city: fullEventData.city,
                    country: fullEventData.country,
                  };
                  return organiserEvent;
                }
                return null;
              }
            );

            const organiserEvents = (
              await Promise.all(organiserEventsPromises)
            ).filter(Boolean);

            const attendingEventsPromises = attendingEventsSnapshot.docs.map(
              async (docSnapshot) => {
                const eventData = docSnapshot.data();
                const eventRef = doc(
                  firestore,
                  `users/${eventData.eventOwnerId}/events/${docSnapshot.id}`
                );
                const eventSnapshot = await getDoc(eventRef);

                if (eventSnapshot.exists()) {
                  return {
                    id: docSnapshot.id,
                    ...eventSnapshot.data(),
                    membershipType: "attending",
                    endDate: eventData.endDate,
                    endTime: eventData.endTime,
                    timezone: eventData.timezone,
                  };
                }
                return null;
              }
            );

            const attendingEvents = (
              await Promise.all(attendingEventsPromises)
            ).filter(Boolean);
            const allEvents = [
              ...ownedEvents,
              ...organiserEvents,
              ...attendingEvents,
            ];

            const counts = {
              Owner: ownedEvents.length,
              Organising: organiserEvents.length, // Make sure this is being set correctly
              Attending: attendingEvents.filter(
                (event) =>
                  !isEventClosed({
                    endDate: event.endDate,
                    endTime: event.endTime,
                    timezone: event.timezone,
                  })
              ).length,
              Attended: attendingEvents.filter((event) =>
                isEventClosed({
                  endDate: event.endDate,
                  endTime: event.endTime,
                  timezone: event.timezone,
                })
              ).length,
            };

            setEvents(allEvents);
            setEventCounts(counts);
          } finally {
            setIsLoadingEvents(false);
          }
        }

        try {
          await fetchConnections();
        } finally {
          setIsLoadingConnections(false);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [
      fetchPosts,
      fetchComments,
      fetchReactions,
      fetchReposts,
      fetchConnectionsCount,
      fetchActivityCounts,
      fetchFollowingCount,
      fetchConnections,
    ]
  );

  // Create a combined loading state
  const isPageLoading = useMemo(() => {
    return (
      isLoading ||
      isLoadingProfile ||
      isLoadingActivity ||
      isLoadingStartups ||
      isLoadingConnections
    );
  }, [
    isLoading,
    isLoadingProfile,
    isLoadingActivity,
    isLoadingStartups,
    isLoadingConnections,
  ]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchAllData(user.uid);
      } else {
        setCurrentUser(null);
        setUserData({});
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [fetchAllData]);

  const isSearching = eventSearchTerm.trim().length > 0;

  const BadgesCard = ({
    isProfileComplete = false,
    postsCount = 0,
    boostsCount = 0, // Was missing this!
    commentsCount = 0, // Was missing this!
    connectionsCount = 0,
    hasStartup = false,
    startupTeamCount = 0,
    startupFollowingCount = 0,
    startupSupportedCount = 0,
    communityLeadCount = 0,
    communitiesModeratingCount = 0,
    communitiesMemberCount = 0,
    eventsCreatedCount = 0,
    eventsHostedCount = 0,
    eventsAttendedCount = 0,
    skillsCount = 0,
    interestsCount = 0,
    experiences = [],
  }) => {
    const [visibleBadges, setVisibleBadges] = useState(9);

    const allBadges = [
      {
        id: "complete-curator",
        name: "Complete Curator",
        earned: isProfileComplete,
      },
      {
        id: "top-contributor",
        name: "Top Contributor",
        earned: postsCount >= 250,
      },
      {
        id: "growth-hacker",
        name: "Growth Hacker",
        earned: boostsCount >= 500, // Was missing this!
      },
      {
        id: "conversation-starter",
        name: "Conversation Starter",
        earned: commentsCount >= 500, // Was missing this!
      },
      {
        id: "social-butterfly",
        name: "Social Butterfly",
        earned: connectionsCount >= 500,
      },
      {
        id: "first-mover",
        name: "First Mover",
        earned: hasStartup,
      },
      {
        id: "team-player",
        name: "Team Player",
        earned: startupTeamCount >= 5,
      },
      {
        id: "dream-backer",
        name: "Dream Backer",
        earned: startupSupportedCount >= 25,
      },
      {
        id: "venture-pilgrim",
        name: "Venture Pilgrim",
        earned: startupFollowingCount >= 25,
      },
      {
        id: "voice-champion",
        name: "Voice Champion",
        earned: communityLeadCount >= 5,
      },
      {
        id: "peace-keeper",
        name: "Peace Keeper",
        earned: communitiesModeratingCount >= 5,
      },
      {
        id: "wisdom-cultivator",
        name: "Wisdom Cultivator",
        earned: communitiesMemberCount >= 10,
      },
      {
        id: "scene-maker",
        name: "Scene Maker",
        earned: eventsCreatedCount >= 10,
      },
      {
        id: "moment-orchestrator",
        name: "Moment Orchestrator",
        earned: eventsHostedCount >= 10,
      },
      {
        id: "dedicated-explorer",
        name: "Dedicated Explorer",
        earned: eventsAttendedCount >= 25,
      },
      {
        id: "grand-master",
        name: "Grand Master",
        earned: skillsCount >= 50,
      },
      {
        id: "deep-seeker",
        name: "Deep Seeker",
        earned: interestsCount >= 25,
      },
      {
        id: "journey-sage",
        name: "Journey Sage",
        earned: experiences.length >= 15,
      },
    ];

    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>
          Badges
          <a
            href="/badges"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.userLink}
          >
            <svg
              style={{ cursor: "pointer" }}
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </a>
        </div>

        <div className={classes.badges}>
          <div className={classes.defaultText} style={{ margin: "none" }}>
            Complete challenges and contribute to the community to earn badges.
            Each badge tells a story of your journey.
          </div>

          <div className={classes.defaultContainer}>
            {allBadges.slice(0, visibleBadges).map((badge) => (
              <div
                key={badge.id}
                className={
                  badge.earned
                    ? classes.skillContainer
                    : classes.emptySkillContainer
                }
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                  <path
                    fill-rule="evenodd"
                    d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                    clip-rule="evenodd"
                  />
                  <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                </svg>

                <span
                  className={classes.skillText}
                  style={{
                    color: badge.earned ? "orangered" : "gray",
                    fontWeight: badge.earned ? "900" : "900",
                  }}
                >
                  {badge.name}
                </span>
              </div>
            ))}
          </div>
        </div>

        {allBadges.length > 9 && (
          <div
            className={classes.showAllExperiences}
            onClick={() => {
              setVisibleBadges(
                visibleBadges >= allBadges.length
                  ? 9
                  : Math.min(
                      visibleBadges + visibleBadges * 2,
                      allBadges.length
                    )
              );
            }}
          >
            {visibleBadges >= allBadges.length ? "Show less" : "Show more"}
            {visibleBadges >= allBadges.length ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m5 15 7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 9-7 7-7-7"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    );
  };

  if (isPageLoading) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar userImage={userImage} />
        ) : (
          <MainNavbar userImage={userImage} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          </div>
          <div className={classes.rightCards}>
            <Ad />

            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  const formatJoinDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${month} ${year}`;
  };

  const toggleEditConnectionsModal = () => {
    if (!isEditConnectionsModalOpen) {
      setEditConnectionsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editConnectionsModalScrollPosition);
      }, 0);
    }
    setIsEditConnectionsModalOpen(!isEditConnectionsModalOpen);
  };

  return (
    <div
      className={`${classes.page} ${
        isInterestsModalOpen ||
        isSkillsModalOpen ||
        isConnectionsModalOpen ||
        isAddEventModalOpen ||
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isProfileModalOpen ||
        isIntroModalOpen ||
        isAddExperienceModalOpen ||
        isAddEducationModalOpen ||
        isEditExperienceModalOpen ||
        isEditEducationModalOpen ||
        isEditStartupsModalOpen ||
        isAddStartupModalOpen ||
        isFollowModalOpen ||
        isEditCommunityModalOpen ||
        isEditEventsModalOpen ||
        showAddCommunityModal // Add this line
          ? classes.noScroll
          : ""
      }`}
      style={
        isInterestsModalOpen
          ? { top: `-${modalScrollPosition}px` }
          : isSkillsModalOpen
          ? { top: `-${skillsModalScrollPosition}px` }
          : isConnectionsModalOpen
          ? { top: `-${connectionsModalScrollPosition}px` }
          : isAddEventModalOpen
          ? { top: `-${addEventModalScrollPosition}px` }
          : isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isProfileModalOpen
          ? { top: `-${profileModalScrollPosition}px` }
          : isIntroModalOpen
          ? { top: `-${introModalScrollPosition}px` }
          : isAddExperienceModalOpen
          ? { top: `-${addExperienceModalScrollPosition}px` }
          : isAddEducationModalOpen
          ? { top: `-${addEducationModalScrollPosition}px` }
          : isEditExperienceModalOpen
          ? { top: `-${editExperienceModalScrollPosition}px` }
          : isEditEducationModalOpen
          ? { top: `-${editEducationModalScrollPosition}px` }
          : isEditStartupsModalOpen
          ? { top: `-${editStartupsModalScrollPosition}px` }
          : isAddStartupModalOpen
          ? { top: `-${addStartupModalScrollPosition}px` }
          : isFollowModalOpen
          ? { top: `-${followModalScrollPosition}px` }
          : isEditCommunityModalOpen
          ? { top: `-${editCommunityModalScrollPosition}px` }
          : isEditEventsModalOpen
          ? { top: `-${editEventsModalScrollPosition}px` }
          : showAddCommunityModal // Added here
          ? { top: `-${addCommunityModalScrollPosition}px` }
          : {}
      }
    >
      {isMobileView ? (
        <MobileNavbar userImage={userImage} />
      ) : (
        <MainNavbar userImage={userImage} />
      )}
      <div className={classes.content}>
        {showProfileModal && (
          <ProfileModal
            onClose={toggleProfileModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleProfileModal(true);
            }}
          />
        )}
        {showEditCommunityModal && (
          <EditCommunityModal
            onClose={toggleEditCommunityModal}
            currentUser={currentUser}
            onCommunityUpdated={handleCommunityUpdated}
          />
        )}
        {showFollowModal && (
          <FollowModal
            onClose={() => {
              toggleFollowModal();
            }}
            currentUserId={currentUser.uid}
            onUnfollowed={() => {
              fetchFollowingCount(currentUser.uid);
              toggleFollowModal();
            }}
          />
        )}
        {showAddEventModal && (
          <AddEventModal
            onClose={toggleAddEventModal}
            currentUser={currentUser}
            onEventAdded={handleEventAdded}
          />
        )}
        {showEditEventsModal && (
          <EditEventModal
            onClose={toggleEditEventsModal}
            currentUser={currentUser}
            onEventUpdated={handleEventUpdated}
          />
        )}
        {showIntroModal && (
          <IntroModal
            onClose={toggleIntroModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleIntroModal(true);
            }}
          />
        )}
        {showEditStartupsModal && (
          <EditStartupsModal
            onClose={toggleEditStartupsModal}
            currentUser={currentUser}
            onStartupUpdated={handleStartupUpdated}
            onEditSingle={openEditSingleStartupModal}
          />
        )}
        {showSkillsModal && (
          <SkillsModal
            onClose={toggleSkillsModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleSkillsModal(true);
            }}
          />
        )}
        {showInterestsModal && (
          <InterestsModal
            onClose={toggleInterestsModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleInterestsModal(true);
            }}
          />
        )}
        {showAddExperienceModal && (
          <AddExperienceModal
            onClose={toggleAddExperienceModal}
            currentUser={currentUser}
            onExperienceAdded={(newExperience) => {
              handleExperienceAdded(newExperience);
              toggleAddExperienceModal(true);
            }}
          />
        )}
        {showEditExperienceModal && (
          <EditExperienceModal
            onClose={() => {
              toggleEditExperienceModal();
            }}
            currentUser={currentUser}
            onExperienceUpdated={handleProfileSave}
            onEditSingle={openEditSingleExperienceModal}
          />
        )}
        {showEditSingleExperienceModal && selectedExperience && (
          <EditSingleExperienceModal
            experience={selectedExperience}
            onClose={() => {
              setShowEditSingleExperienceModal(false);
              setShowEditExperienceModal(true);
            }}
            onUpdate={(updatedExperience) => {
              handleProfileSave({
                experiences: userData.experiences.map((exp) =>
                  exp.id === updatedExperience.id ? updatedExperience : exp
                ),
              });
              setShowEditSingleExperienceModal(false);
              setShowEditExperienceModal(true);
            }}
            onDelete={(deletedExperienceId) => {
              handleProfileSave({
                experiences: userData.experiences.filter(
                  (exp) => exp.id !== deletedExperienceId
                ),
              });
              setShowEditSingleExperienceModal(false);
              setShowEditExperienceModal(true);
            }}
            currentUser={currentUser}
          />
        )}
        {showAddCommunityModal && (
          <AddCommunityModal
            onClose={toggleAddCommunityModal}
            currentUser={currentUser}
            onCommunityAdded={handleCommunityAdded}
          />
        )}
        {showAddStartupModal && (
          <AddStartupModal
            onClose={(success) => {
              toggleAddStartupModal(); // This will handle scroll position restoration
            }}
            currentUser={currentUser}
            onStartupAdded={handleStartupAdded}
          />
        )}
        {showAddEducationModal && (
          <AddEducationModal
            onClose={toggleAddEducationModal}
            currentUser={currentUser}
            onEducationAdded={(newEducation) => {
              handleEducationAdded(newEducation);
              toggleAddEducationModal(true);
            }}
          />
        )}
        {showEditEducationModal && (
          <EditEducationModal
            onClose={() => {
              toggleEditEducationModal();
            }}
            currentUser={currentUser}
            onEducationUpdated={handleProfileSave}
            onEditSingle={openEditSingleEducationModal}
          />
        )}
        {showEditSingleEducationModal && selectedEducation && (
          <EditSingleEducationModal
            education={selectedEducation}
            onClose={() => {
              setShowEditSingleEducationModal(false);
              setShowEditEducationModal(true);
            }}
            onUpdate={(updatedEducation) => {
              handleProfileSave({
                education: userData.education.map((exp) =>
                  exp.id === updatedEducation.id ? updatedEducation : exp
                ),
              });
              setShowEditSingleEducationModal(false);
              setShowEditEducationModal(true);
            }}
            onDelete={(deletedEducationId) => {
              handleProfileSave({
                education: userData.education.filter(
                  (exp) => exp.id !== deletedEducationId
                ),
              });
              setShowEditSingleEducationModal(false);
              setShowEditEducationModal(true);
            }}
            currentUser={currentUser}
          />
        )}
        {showConnectionsModal && (
          <ConnectionsModal
            onClose={toggleConnectionsModal}
            currentUserId={currentUser?.uid}
            loggedInUserId={currentUser?.uid}
            isOwnProfile={true}
            showConnectionDate={true}
            onConnectionRemoved={handleConnectionRemoved}
          />
        )}
        {showReactionsModal && activePostReactions && (
          <ReactionsModal
            onClose={() => {
              setShowReactionsModal(false);
              setIsReactionsModalOpen(false);
              setTimeout(() => {
                window.scrollTo(0, reactionsModalScrollPosition);
              }, 0);
            }}
            postId={activePostReactions.postId}
            reactions={activePostReactions.reactions}
            currentUserId={currentUser.uid}
          />
        )}
        {showRepostsModal && activePostReposts && (
          <RepostsModal
            onClose={() => {
              setShowRepostsModal(false);
              setIsRepostsModalOpen(false);
              setTimeout(() => {
                window.scrollTo(0, repostsModalScrollPosition);
              }, 0);
            }}
            postId={activePostReposts.postId}
            reposts={activePostReposts.reposts}
            currentUserId={currentUser.uid}
          />
        )}

        <div className={classes.centreCards}>
          {/* PROFILE CARD */}
          <div className={classes.profileCard}>
            {/* <div className={classes.section}>
              <div className={classes.sectionText}></div>
              <div className={classes.check}>
                <span>Incomplete</span>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
            </div> */}
            <div className={classes.profileHeader}>
              <div
                className={classes.profileImage}
                onClick={handleCloseTouch(toggleProfileModal)}
              >
                <img src={userImage || defaultImage} alt="Profile" />
              </div>
              <div onClick={handleCloseTouch(toggleProfileModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.profileTitle}>
              {userData.firstName} {userData.lastName}{" "}
              <span className={classes.connectionType}>• You</span>
            </div>

            {(userData.bio || userData.link) && (
              <div className={classes.userDetails}>
                {userData.bio && (
                  <div className={classes.bio}>{userData.bio}</div>
                )}
                {userData.link && (
                  <div className={classes.link}>
                    <a
                      href={userData.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {userData.linkText || userData.link}
                    </a>
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="orangeRed"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </div>
                )}
              </div>
            )}

            <div className={classes.buttons}>
              {!userData.bio && (
                <div
                  className={classes.headlineButton}
                  onClick={handleCloseTouch(toggleProfileModal)}
                >
                  <svg
                    className=""
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                  Add a headline
                </div>
              )}
              {!userData.link && (
                <div
                  className={classes.button}
                  onClick={handleCloseTouch(toggleProfileModal)}
                >
                  <svg
                    className=""
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                  Add a link
                </div>
              )}
              <div
                className={`${
                  shareButtonText === "Copied"
                    ? classes.following
                    : classes.button
                }`}
                onClick={handleShare}
              >
                {shareButtonText === "Copied" ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                  </svg>
                )}
                {shareButtonText}
              </div>
            </div>
          </div>
          {!isProfileComplete && (
            <div className={classes.card}>
              <div
                className={`${classes.checklist} ${
                  isChecklistOpen ? classes.open : ""
                }`}
                onClick={() => setIsChecklistOpen(!isChecklistOpen)}
                style={{ cursor: "pointer" }}
              >
                Checklist
                <div>
                  {isChecklistOpen ? (
                    <svg
                      className={classes.closeIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m16 14-4-4-4 4"
                      />
                    </svg>
                  ) : (
                    <svg
                      className={classes.closeIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m8 10 4 4 4-4"
                      />
                    </svg>
                  )}
                </div>
              </div>

              <div
                className={`${classes.checklistContent} ${
                  isChecklistOpen ? classes.open : ""
                }`}
              >
                <div
                  style={{
                    color: "gray",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  Get started by taking these essential steps:
                </div>

                <div
                  style={{
                    backgroundColor: "rgba(255, 69, 0, 0.1)",
                    padding: "1rem",
                    borderRadius: "0.5rem",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  {/* Your existing checklist content */}
                  <div style={{ textAlign: "left" }}>
                    {checklistItems.map((item, index) => (
                      <div
                        key={item.id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom:
                            index !== checklistItems.length - 1 ? "0.5rem" : 0,
                        }}
                      >
                        {completionStatus[item.id] ? (
                          <CompletedIcon />
                        ) : (
                          <IncompleteIcon />
                        )}
                        <span
                          style={{
                            color: completionStatus[item.id]
                              ? "orangered"
                              : "orangered",
                            fontWeight: "900",
                          }}
                        >
                          {item.text}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={classes.card}>
            <div className={classes.cardTitle}>
              About
              <div onClick={handleCloseTouch(toggleIntroModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>
            <div className={classes.introText}>
              <pre>
                {userData.introHtml ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: renderIntroText().text }}
                  />
                ) : (
                  <span className={classes.emptyIntroText}>
                    Tell us your story. What drives you? What are you working on
                    or interested in? Give others a quick snapshot of who you
                    are.
                  </span>
                )}
                {renderIntroText().showMore && (
                  <span
                    className={classes.moreButton}
                    onClick={handleMoreClick}
                  >
                    ...more
                  </span>
                )}
              </pre>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.about}>
              {/* <div className={classes.skillContainer}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                  <path
                    fill-rule="evenodd"
                    d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                    clip-rule="evenodd"
                  />
                  <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                </svg>

                <span className={classes.skillText}>Foundry 500 | #01</span>
              </div> */}
              {userData.city && userData.country && (
                <div className={classes.skillContainer}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span
                    className={classes.skillText}
                  >{`${userData.city}, ${userData.country}`}</span>
                </div>
              )}
              {userData.createdAt && (
                <div className={classes.skillContainer}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span className={classes.skillText}>
                    Joined {formatJoinDate(userData.createdAt)}
                  </span>
                </div>
              )}
              {userData.urlSlug && (
                <div className={classes.skillContainer}>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                    />
                  </svg>
                  <span className={classes.skillText}>
                    {`foundry.bz/${userData.urlSlug}`}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className={classes.activityCard}>
            <div
              className={`${classes.activityCardTitle} ${
                isActivityEmpty() ? classes.emptyCardTitle : ""
              }`}
            >
              Activity
            </div>

            <ActivityButtons
              selectedActivity={activityType}
              onSelectionChange={handleActivityChange}
              activityCounts={activityCounts}
              showAllButtons={true}
            />
            {activityType === "Posts" && (
              <>
                {loadingPosts ? (
                  <div className={classes.loadingIndicator}>
                    <span
                      className={`${classes.loadingIcon} material-symbols-outlined`}
                    >
                      progress_activity
                    </span>
                  </div>
                ) : (
                  <>
                    <div className={classes.posts}>
                      {posts.length === 0 ? (
                        <div>
                          <p className={classes.defaultTextBold}>
                            You haven’t posted yet
                          </p>{" "}
                          <p className={classes.defaultText}>
                            Posts you create will be displayed here.
                          </p>
                          <div className={classes.noPostBorder}></div>
                          <div className={classes.postHeader}>
                            <span className={classes.postAuthor}>You</span>
                            <span className={classes.postMessage}>
                              {" "}
                              posted this{" "}
                            </span>
                            <span className={classes.dot}>•</span>
                            <span>now</span>
                          </div>
                          <div className={classes.noPost}>
                            <div className={classes.postContent}>
                              <img
                                src={greyPostImage}
                                alt="Default startup"
                                className={classes.noPostImage}
                              />
                              <p className={classes.noTextPreview}>Post</p>
                            </div>
                          </div>
                          <div className={classes.noPostInteractions}>
                            <div>100 boosts</div>
                            <span className={classes.dot}>•</span>
                            <div>50 comments</div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {posts.slice(0, displayedPosts).map((post, index) => (
                            <React.Fragment key={post.id}>
                              {index !== 0 && (
                                <div className={classes.postBorder}></div>
                              )}
                              <div>
                                <div className={classes.postHeader}>
                                  <span className={classes.postAuthor}>
                                    You
                                  </span>
                                  <span className={classes.postMessage}>
                                    {" "}
                                    posted this{" "}
                                  </span>
                                  <span className={classes.dot}>•</span>
                                  <span>{formatTimestamp(post.createdAt)}</span>
                                </div>
                                <div
                                  className={`${classes.post} ${
                                    !post.postPreview.mediaType &&
                                    post.postPreview.text
                                      ? classes.textOnlyPost
                                      : ""
                                  }`}
                                  onClick={() => handlePostClick(post.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className={classes.postContent}>
                                    {post.postPreview &&
                                      post.postPreview.mediaType &&
                                      renderFilePreview(post.postPreview)}
                                    {post.postPreview &&
                                      post.postPreview.text && (
                                        <p className={classes.textPreview}>
                                          {truncateText(
                                            stripHtmlTags(
                                              post.postPreview.text
                                            ),
                                            3
                                          )}
                                        </p>
                                      )}
                                  </div>
                                </div>
                              </div>

                              {(post.reactions &&
                                Object.keys(post.reactions).length > 0) ||
                              (post.reposts &&
                                Object.keys(post.reposts).length > 0) ||
                              (post.commentCount && post.commentCount > 0) ? (
                                <div className={classes.postInteractions}>
                                  {post.reactions &&
                                    Object.keys(post.reactions).length > 0 && (
                                      <ReactionsSummary
                                        reactions={post.reactions}
                                        post={post}
                                        onClick={() =>
                                          handleReactionsClick(
                                            post.id,
                                            post.reactions
                                          )
                                        }
                                      />
                                    )}

                                  <PostInteractionCount
                                    postId={post.id}
                                    reposts={post.reposts}
                                    commentCount={post.commentCount}
                                    onClick={handleRepostsClick}
                                    onCommentClick={() =>
                                      handlePostClick(post.id)
                                    }
                                  />
                                </div>
                              ) : null}
                            </React.Fragment>
                          ))}
                        </>
                      )}
                      {posts.length > 3 && displayedPosts < posts.length && (
                        <div
                          className={classes.showAllPosts}
                          onClick={() => navigate("/all-posts/me")}
                        >
                          Show all posts
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 12H5m14 0-4 4m4-4-4-4"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {activityType === "Boosts" && renderReactions()}
            {activityType === "Comments" && renderComments()}
            {activityType === "Reposts" && renderReposts()}
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                connections.length === 0 ? classes.emptyCardTitle : ""
              }`}
            >
              Connections
              {connections.length > 0 && (
                <div onClick={toggleConnectionsModal}>
                  <svg
                    className={classes.experienceIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                    />
                  </svg>
                </div>
              )}
            </div>

            {isLoadingConnections ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            ) : (
              <>
                {connections.length === 0 ? (
                  <>
                    <div>
                      <p className={classes.defaultText}>
                        Connect with like-minded people in the startup
                        community. Build meaningful relationships and make ideas
                        happen.
                      </p>
                    </div>
                    <div className={classes.people}>
                      <div
                        className={classes.button}
                        style={{ width: "fit-content" }}
                        onClick={() => navigate("/people")}
                      >
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        People
                      </div>
                    </div>
                    <div className={classes.noPostBorder}></div>
                    <div className={classes.userContainer}>
                      <img
                        src={greyProfileImage}
                        alt={greyProfileImage}
                        className={classes.noUserImage}
                      />
                      <div className={classes.startupAbout}>
                        <div className={classes.userInfo}>
                          <div className={classes.userNameContainer}>
                            <div className={classes.noStartupRole}>
                              Username
                            </div>
                            <span className={classes.userConnectionType}>
                              • Direct
                            </span>
                          </div>
                          <div className={classes.noStartupName}>Headline</div>
                          <div className={classes.noUserLink}>
                            Link
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {connections
                      .slice(0, displayedConnections)
                      .map((connection) => (
                        <div
                          key={connection.id}
                          className={classes.userContainer}
                        >
                          <img
                            src={
                              connection.userData?.profileImage || defaultImage
                            }
                            alt={`${connection.userData?.firstName} ${connection.userData?.lastName}`}
                            className={classes.userImage}
                            onClick={() =>
                              navigate(`/${connection.userData?.urlSlug}`)
                            }
                            style={{ cursor: "pointer" }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = defaultImage;
                            }}
                          />
                          <div className={classes.userInfo}>
                            <div className={classes.userNameContainer}>
                              <div
                                className={classes.userName}
                                onClick={() =>
                                  navigate(`/${connection.userData?.urlSlug}`)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {connection.userData?.firstName}{" "}
                                {connection.userData?.lastName}
                              </div>
                              <span className={classes.userConnectionType}>
                                • {connection.connectionType}
                              </span>
                            </div>
                            {connection.userData?.bio && (
                              <div className={classes.userBio}>
                                {connection.userData.bio}
                              </div>
                            )}
                            {connection.userData?.link && (
                              <a
                                href={connection.userData.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.userLink}
                              >
                                <span>
                                  {connection.userData.linkText ||
                                    connection.userData.link}
                                </span>
                                <svg
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                  />
                                </svg>
                              </a>
                            )}
                          </div>
                        </div>
                      ))}

                    {connections.length > 3 && (
                      <div
                        className={classes.showAllExperiences}
                        onClick={() => {
                          if (displayedConnections >= connections.length) {
                            setDisplayedConnections(3);
                          } else {
                            const additionalItems = displayedConnections * 2;
                            setDisplayedConnections(
                              Math.min(
                                displayedConnections + additionalItems,
                                connections.length
                              )
                            );
                          }
                        }}
                      >
                        {displayedConnections >= connections.length
                          ? "Show less"
                          : "Show more"}
                        {displayedConnections >= connections.length ? (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m5 15 7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 9-7 7-7-7"
                            />
                          </svg>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                startups.length === 0 ? classes.emptyCardTitle : ""
              }`}
            >
              Startups
              <div>
                <div onClick={toggleAddStartupModal}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
                {startupCounts.Owner > 0 && (
                  <div
                    className={classes.pencilIcon}
                    onClick={toggleEditStartupsModal}
                  >
                    <svg
                      className={classes.experienceIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>

            <StartupButtons
              selectedStartupType={startupType}
              onSelectionChange={handleStartupTypeChange}
              startupCounts={startupCounts}
              showAllButtons={true}
            />

            {loadingStartupType ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            ) : (
              <>
                {startupCounts[startupType.replace(" ", "")] === 0 ? (
                  <div>
                    <p className={classes.defaultTextBold}>
                      {startupType === "Owner" &&
                        "You haven't created a startup yet"}
                      {startupType === "TeamMember" &&
                        "You haven't joined a startup yet"}
                      {startupType === "Supported" &&
                        "You haven't supported a startup yet"}
                      {startupType === "Following" &&
                        "You aren't following any startups yet"}
                    </p>
                    <p className={classes.defaultText}>
                      {startupType === "Owner" &&
                        "Startups you create will be displayed here."}
                      {startupType === "TeamMember" &&
                        "Startups you join will be displayed here."}
                      {startupType === "Supported" &&
                        "Startups you support will be displayed here."}
                      {startupType === "Following" &&
                        "Startups you follow will be displayed here."}
                    </p>
                    <div className={classes.noPostBorder}></div>
                    <div className={classes.userContainer}>
                      <img
                        src={greyStartupImage}
                        alt="Default startup"
                        className={classes.noStartupImage}
                      />
                      <div className={classes.userInfo}>
                        <div className={classes.userNameContainer}>
                          <div className={classes.noStartupRole}>
                            Name
                            <span className={classes.userConnectionType}>
                              • Startup
                            </span>
                          </div>
                        </div>
                        <div className={classes.noStartupName}>Headline</div>
                        <div className={classes.noLink}>
                          Link
                          <svg
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {filteredStartups
                      .slice(0, displayedStartups)
                      .map((startup) => (
                        <StartupSection key={startup.id} startup={startup} />
                      ))}
                    {filteredStartups.length > 3 && (
                      <div
                        className={classes.showAllExperiences}
                        onClick={() => {
                          if (displayedStartups >= filteredStartups.length) {
                            setDisplayedStartups(3);
                          } else {
                            const additionalItems = displayedStartups * 2;
                            setDisplayedStartups(
                              Math.min(
                                displayedStartups + additionalItems,
                                filteredStartups.length
                              )
                            );
                          }
                        }}
                      >
                        {displayedStartups >= filteredStartups.length
                          ? "Show less"
                          : "Show more"}
                        {displayedStartups >= filteredStartups.length ? (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m5 15 7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 9-7 7-7-7"
                            />
                          </svg>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                communities.length === 0 ? classes.emptyCardTitle : ""
              }`}
            >
              Communities
              <div>
                <div onClick={toggleAddCommunityModal}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
                {communityCounts.Owner > 0 && (
                  <div
                    className={classes.pencilIcon}
                    onClick={toggleEditCommunityModal}
                  >
                    <svg
                      className={classes.experienceIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>

            <CommunityButtons
              selectedCommunityType={communityType}
              onSelectionChange={handleCommunityTypeChange}
              communityCounts={communityCounts}
              showAllButtons={true}
            />

            {loadingCommunityType ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            ) : (
              <>
                {communityCounts[communityType] === 0 ? (
                  <div>
                    <p className={classes.defaultTextBold}>
                      {communityType === "Owner" &&
                        "You haven't created a community yet"}
                      {communityType === "Moderator" &&
                        "You haven't moderated a community yet"}
                      {communityType === "Member" &&
                        "You haven't joined a community yet"}
                    </p>
                    <p className={classes.defaultText}>
                      {communityType === "Owner" &&
                        "Communities you create will be displayed here."}
                      {communityType === "Moderator" &&
                        "Communities you moderate will be displayed here."}
                      {communityType === "Member" &&
                        "Communities you join will be displayed here."}
                    </p>
                    <div className={classes.noPostBorder}></div>
                    <div className={classes.userContainer}>
                      <img
                        src={greyCommunityImage}
                        alt="Default community"
                        className={classes.noCommunityImage}
                      />
                      <div className={classes.userInfo}>
                        <div className={classes.userNameContainer}>
                          <div className={classes.noStartupRole}>
                            Name
                            <span className={classes.userConnectionType}>
                              • Community
                            </span>
                          </div>
                        </div>
                        <div className={classes.noStartupName}>Headline</div>
                        <div className={classes.noLink}>
                          Link
                          <svg
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {filteredCommunities
                      .slice(0, displayedCommunities)
                      .map((community) => (
                        <CommunitySection
                          key={community.id}
                          community={community}
                        />
                      ))}
                    {filteredCommunities.length > 3 && (
                      <div
                        className={classes.showAllExperiences}
                        onClick={() => {
                          if (
                            displayedCommunities >= filteredCommunities.length
                          ) {
                            setDisplayedCommunities(3);
                          } else {
                            const additionalItems = displayedCommunities * 2;
                            setDisplayedCommunities(
                              Math.min(
                                displayedCommunities + additionalItems,
                                filteredCommunities.length
                              )
                            );
                          }
                        }}
                      >
                        {displayedCommunities >= filteredCommunities.length
                          ? "Show less"
                          : "Show more"}
                        {displayedCommunities >= filteredCommunities.length ? (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m5 15 7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 9-7 7-7-7"
                            />
                          </svg>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                events.length === 0 ? classes.emptyCardTitle : ""
              }`}
            >
              Events
              <div>
                <div onClick={toggleAddEventModal}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
                {eventCounts.Owner > 0 && (
                  <div
                    className={classes.pencilIcon}
                    onClick={toggleEditEventsModal}
                  >
                    <svg
                      className={classes.experienceIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>

            <EventButtons
              selectedEventType={eventType}
              onSelectionChange={handleEventTypeChange}
              eventCounts={eventCounts}
              showAllButtons={true}
            />

            {isLoadingEvents ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            ) : (
              <>
                {loadingEventType ? (
                  <div className={classes.loadingIndicator}>
                    <span
                      className={`${classes.loadingIcon} material-symbols-outlined`}
                    >
                      progress_activity
                    </span>
                  </div>
                ) : (
                  <>
                    {(eventType === "Organiser" &&
                      eventCounts["Organising"] === 0) ||
                    (eventType !== "Organiser" &&
                      eventCounts[eventType] === 0) ? (
                      <div>
                        <p className={classes.defaultTextBold}>
                          {eventType === "Owner" &&
                            "You haven't created an event yet"}
                          {eventType === "Organiser" &&
                            "You haven't hosted any events yet"}
                          {eventType === "Attending" &&
                            "You aren't attending any events yet"}
                          {eventType === "Attended" &&
                            "You haven't attended any events yet"}
                        </p>
                        <p className={classes.defaultText}>
                          {eventType === "Owner" &&
                            "Events you create will be displayed here."}
                          {eventType === "Organiser" &&
                            "Events you host will be displayed here."}
                          {eventType === "Attending" &&
                            "Events you're attending will be displayed here."}
                          {eventType === "Attended" &&
                            "Events you've attended will be displayed here."}
                        </p>
                        <div className={classes.noPostBorder}></div>
                        <div className={classes.userContainer}>
                          <img
                            src={greyEventImage}
                            alt="Default event"
                            className={classes.noEventImage}
                          />
                          <div className={classes.startupAbout}>
                            <div className={classes.userInfo}>
                              <div className={classes.userNameContainer}>
                                <div className={classes.noStartupRole}>
                                  Name
                                </div>
                                <span className={classes.userConnectionType}>
                                  • Event
                                </span>
                              </div>
                              <div className={classes.noStartupName}>
                                Headline
                              </div>
                              <div className={classes.noLink}>
                                Link
                                <svg
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {filteredEvents
                          .slice(0, displayedEventCount)
                          .map((event) => (
                            <div
                              key={event.id}
                              className={classes.userContainer}
                            >
                              <img
                                src={event.eventImage || eventImage}
                                alt={event.eventName}
                                className={classes.eventImage}
                                onClick={() =>
                                  navigate(`/event/${event.eventUrlSlug}`)
                                }
                                style={{ cursor: "pointer" }}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = greyEventImage;
                                }}
                              />
                              <div className={classes.userInfo}>
                                <div className={classes.userNameContainer}>
                                  <div
                                    className={classes.userName}
                                    onClick={() =>
                                      navigate(`/event/${event.eventUrlSlug}`)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {event.eventName}
                                  </div>
                                  <span className={classes.userConnectionType}>
                                    • Event
                                  </span>
                                </div>
                                {event.tagline && (
                                  <div className={classes.userBio}>
                                    {event.tagline}
                                  </div>
                                )}
                                {event.link && (
                                  <a
                                    href={event.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.userLink}
                                  >
                                    <span>{event.linkText || event.link}</span>
                                    <svg
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                      />
                                    </svg>
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                        {filteredEvents.length > 3 && (
                          <div
                            className={classes.showAllExperiences}
                            onClick={() => {
                              if (
                                displayedEventCount >= filteredEvents.length
                              ) {
                                setDisplayedEventCount(3);
                              } else {
                                const additionalItems = displayedEventCount * 2;
                                setDisplayedEventCount(
                                  Math.min(
                                    displayedEventCount + additionalItems,
                                    filteredEvents.length
                                  )
                                );
                              }
                            }}
                          >
                            {displayedEventCount >= filteredEvents.length
                              ? "Show less"
                              : "Show more"}
                            {displayedEventCount >= filteredEvents.length ? (
                              <svg
                                className="w-6 h-6"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m5 15 7-7 7 7"
                                />
                              </svg>
                            ) : (
                              <svg
                                className="w-6 h-6"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m19 9-7 7-7-7"
                                />
                              </svg>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <BadgesCard
            isProfileComplete={isProfileComplete}
            postsCount={activityCounts.Posts || 0}
            connectionsCount={connectionsCount}
            hasStartup={startupCounts.Owner > 0}
            startupTeamCount={startupCounts.TeamMember}
            startupFollowingCount={startupCounts.Following}
            startupSupportedCount={startupCounts.Supported}
            communityLeadCount={communityCounts.Owner}
            communitiesModeratingCount={communityCounts.Moderator}
            communitiesMemberCount={communityCounts.Member}
            eventsCreatedCount={eventCounts.Owner}
            eventsHostedCount={eventCounts.Organiser}
            eventsAttendedCount={eventCounts.Attended}
            skillsCount={userData.skills?.length || 0}
            interestsCount={userData.interests?.length || 0}
            experiences={userData.experiences || []} // Changed to pass the raw array
          />
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                userData.skills && userData.skills.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Skills
              <div onClick={handleCloseTouch(toggleSkillsModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.about}>
              {userData.skills && userData.skills.length > 0 ? (
                userData.skills.slice(0, visibleSkills).map((skill, index) => (
                  <div key={index} className={classes.skillContainer}>
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="orangered"
                      viewBox="0 0 24 24"
                    >
                      <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                    </svg>
                    <span className={classes.skillText}>{skill}</span>
                  </div>
                ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    List your superpowers! What are you great at? Your skills
                    could be the missing piece someone's looking for.
                  </div>

                  <div className={classes.defaultContainer}>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                      <span className={classes.skillText}>Soft skill</span>
                    </div>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                      <span className={classes.skillText}>Technical skill</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {userData.skills && userData.skills.length > 9 && (
              <div
                className={classes.showAllExperiences}
                onClick={() => {
                  if (visibleSkills >= userData.skills.length) {
                    setVisibleSkills(9);
                  } else {
                    const additionalItems = visibleSkills * 2;
                    setVisibleSkills(
                      Math.min(
                        visibleSkills + additionalItems,
                        userData.skills.length
                      )
                    );
                  }
                }}
              >
                {visibleSkills >= userData.skills.length
                  ? "Show less"
                  : "Show more"}
                {visibleSkills >= userData.skills.length ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m5 15 7-7 7 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                userData.interests && userData.interests.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Interests
              <div onClick={handleCloseTouch(toggleInterestsModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>
            <div className={classes.about}>
              {userData.interests && userData.interests.length > 0 ? (
                userData.interests
                  .slice(0, visibleInterests)
                  .map((interest, index) => (
                    <div key={index} className={classes.skillContainer}>
                      <svg
                        className="w-6 h-6 text-gray"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="orangeRed"
                        viewBox="0 0 24 24"
                      >
                        <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                      </svg>
                      <span className={classes.skillText}>{interest}</span>
                    </div>
                  ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    What gets you excited? Share your passions and interests.
                    You never know who might share them!
                  </div>
                  <div className={classes.defaultContainer}>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                      </svg>
                      <span className={classes.skillText}>Soft interest</span>
                    </div>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                      </svg>
                      <span className={classes.skillText}>
                        Technical interest
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {userData.interests && userData.interests.length > 9 && (
              <div
                className={classes.showAllExperiences}
                onClick={() => {
                  if (visibleInterests >= userData.interests.length) {
                    setVisibleInterests(9);
                  } else {
                    const additionalItems = visibleInterests * 2;
                    setVisibleInterests(
                      Math.min(
                        visibleInterests + additionalItems,
                        userData.interests.length
                      )
                    );
                  }
                }}
              >
                {visibleInterests >= userData.interests.length
                  ? "Show less"
                  : "Show more"}
                {visibleInterests >= userData.interests.length ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m5 15 7-7 7 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>
          <div className={classes.experiencesCard}>
            <div
              className={`${classes.experiencesCardTitle} ${
                !userData.experiences || userData.experiences.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Experience
              <div>
                <div onClick={handleCloseTouch(toggleAddExperienceModal)}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>

                <div
                  className={`${classes.pencilIcon} ${
                    !userData.experiences || userData.experiences.length === 0
                      ? classes.disabledPencilIcon
                      : ""
                  }`}
                  onClick={
                    userData.experiences && userData.experiences.length > 0
                      ? handleCloseTouch(toggleEditExperienceModal)
                      : undefined
                  }
                >
                  {userData.experiences && userData.experiences.length > 0 && (
                    <div onClick={toggleEditExperienceModal}>
                      <svg
                        className={classes.experienceIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.experiencesCardAbout}>
              {sortedExperiences.length > 0 ? (
                sortedExperiences
                  .slice(0, visibleExperiences)
                  .map((experience) => (
                    <div key={experience.id} className={classes.cardAbout}>
                      <p className={classes.skillContainer}>
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 4a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2v14a1 1 0 1 1 0 2H5a1 1 0 1 1 0-2V5a1 1 0 0 1-1-1Zm5 2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-1Zm-5 4a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm-3 4a2 2 0 0 0-2 2v3h2v-3h2v3h2v-3a2 2 0 0 0-2-2h-2Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span className={classes.skillText}>
                          {experience.organisation}
                        </span>
                      </p>
                      <p className={classes.skillContainer}>
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 2a3 3 0 0 0-3 3v1H5a3 3 0 0 0-3 3v2.382l1.447.723.005.003.027.013.12.056c.108.05.272.123.486.212.429.177 1.056.416 1.834.655C7.481 13.524 9.63 14 12 14c2.372 0 4.52-.475 6.08-.956.78-.24 1.406-.478 1.835-.655a14.028 14.028 0 0 0 .606-.268l.027-.013.005-.002L22 11.381V9a3 3 0 0 0-3-3h-2V5a3 3 0 0 0-3-3h-4Zm5 4V5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1h6Zm6.447 7.894.553-.276V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-5.382l.553.276.002.002.004.002.013.006.041.02.151.07c.13.06.318.144.557.242.478.198 1.163.46 2.01.72C7.019 15.476 9.37 16 12 16c2.628 0 4.98-.525 6.67-1.044a22.95 22.95 0 0 0 2.01-.72 15.994 15.994 0 0 0 .707-.312l.041-.02.013-.006.004-.002.001-.001-.431-.866.432.865ZM12 10a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span className={classes.skillText}>
                          {experience.role}
                        </span>
                      </p>

                      <p className={classes.skillContainer}>
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span className={classes.skillText}>
                          {experience.startMonth} {experience.startYear} -{" "}
                          {experience.isCurrentlyWorking
                            ? "Current"
                            : `${experience.endMonth} ${experience.endYear}`}
                        </span>
                      </p>

                      {renderTruncatedText(
                        experience.description,
                        `exp-${experience.id}`,
                        experience.descriptionHtml // Pass the HTML content as third parameter
                      )}
                    </div>
                  ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    Your journey matters. Jot down your key experiences - every
                    bit counts.
                  </div>
                  <div className={classes.noCardAbout}>
                    <p className={classes.emptySkillContainer}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 4a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2v14a1 1 0 1 1 0 2H5a1 1 0 1 1 0-2V5a1 1 0 0 1-1-1Zm5 2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-1Zm-5 4a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm-3 4a2 2 0 0 0-2 2v3h2v-3h2v3h2v-3a2 2 0 0 0-2-2h-2Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Organisation
                    </p>
                    <p className={classes.emptySkillContainer}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 2a3 3 0 0 0-3 3v1H5a3 3 0 0 0-3 3v2.382l1.447.723.005.003.027.013.12.056c.108.05.272.123.486.212.429.177 1.056.416 1.834.655C7.481 13.524 9.63 14 12 14c2.372 0 4.52-.475 6.08-.956.78-.24 1.406-.478 1.835-.655a14.028 14.028 0 0 0 .606-.268l.027-.013.005-.002L22 11.381V9a3 3 0 0 0-3-3h-2V5a3 3 0 0 0-3-3h-4Zm5 4V5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1h6Zm6.447 7.894.553-.276V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-5.382l.553.276.002.002.004.002.013.006.041.02.151.07c.13.06.318.144.557.242.478.198 1.163.46 2.01.72C7.019 15.476 9.37 16 12 16c2.628 0 4.98-.525 6.67-1.044a22.95 22.95 0 0 0 2.01-.72 15.994 15.994 0 0 0 .707-.312l.041-.02.013-.006.004-.002.001-.001-.431-.866.432.865ZM12 10a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Role
                    </p>
                    <p className={classes.emptySkillContainer}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Timeline
                    </p>
                    {/* <p className={classes.noDescription}>Description</p> */}
                  </div>
                </div>
              )}
            </div>
            {sortedExperiences.length > 3 && (
              <div
                className={classes.showAllExperiences}
                onClick={() => {
                  if (visibleExperiences >= sortedExperiences.length) {
                    setVisibleExperiences(3);
                  } else {
                    const additionalItems = visibleExperiences * 2;
                    setVisibleExperiences(
                      Math.min(
                        visibleExperiences + additionalItems,
                        sortedExperiences.length
                      )
                    );
                  }
                }}
              >
                {visibleExperiences >= sortedExperiences.length
                  ? "Show less"
                  : "Show more"}
                {visibleExperiences >= sortedExperiences.length ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m5 15 7-7 7 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.experiencesCardTitle} ${
                !userData.education || userData.education.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Education
              <div>
                <div onClick={toggleAddEducationModal}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
                <div
                  className={`${classes.pencilIcon} ${
                    !userData.education || userData.education.length === 0
                      ? classes.disabledPencilIcon
                      : ""
                  }`}
                  onClick={
                    userData.education && userData.education.length > 0
                      ? handleCloseTouch(toggleEditEducationModal)
                      : undefined
                  }
                >
                  {!userData.education || userData.education.length === 0 || (
                    <svg
                      className={classes.experienceIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.experiencesCardAbout}>
              {userData.education && userData.education.length > 0 ? (
                userData.education
                  .sort((a, b) => {
                    const now = new Date();
                    const currentYear = now.getFullYear();
                    const currentMonth = monthsList.indexOf(
                      now.toLocaleString("default", { month: "long" })
                    );

                    const getEndDate = (edu) => {
                      if (edu.isCurrentlyStudying) {
                        return new Date(currentYear, currentMonth);
                      }
                      return new Date(`${edu.endMonth} 1, ${edu.endYear}`);
                    };

                    const aEnd = getEndDate(a);
                    const bEnd = getEndDate(b);

                    if (aEnd > bEnd) return -1;
                    if (aEnd < bEnd) return 1;

                    const aStart = new Date(
                      `${a.startMonth} 1, ${a.startYear}`
                    );
                    const bStart = new Date(
                      `${b.startMonth} 1, ${b.startYear}`
                    );

                    if (aStart > bStart) return -1;
                    if (aStart < bStart) return 1;

                    return new Date(b.dateAdded) - new Date(a.dateAdded);
                  })
                  .slice(0, visibleEducation)
                  .map((education) => (
                    <div key={education.id} className={classes.cardAbout}>
                      <p className={classes.skillContainer}>
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="m6 10.5237-2.27075.6386C3.29797 11.2836 3 11.677 3 12.125V20c0 .5523.44772 1 1 1h2V10.5237Zm12 0 2.2707.6386c.4313.1213.7293.5147.7293.9627V20c0 .5523-.4477 1-1 1h-2V10.5237Z" />
                          <path
                            fill-rule="evenodd"
                            d="M12.5547 3.16795c-.3359-.22393-.7735-.22393-1.1094 0l-6.00002 4c-.45952.30635-.5837.92722-.27735 1.38675.30636.45953.92723.5837 1.38675.27735L8 7.86853V21h8V7.86853l1.4453.96352c.0143.00957.0289.01873.0435.02746.1597.09514.3364.14076.5112.1406.3228-.0003.6395-.15664.832-.44541.3064-.45953.1822-1.0804-.2773-1.38675l-6-4ZM10 12c0-.5523.4477-1 1-1h2c.5523 0 1 .4477 1 1s-.4477 1-1 1h-2c-.5523 0-1-.4477-1-1Zm1-4c-.5523 0-1 .44772-1 1s.4477 1 1 1h2c.5523 0 1-.44772 1-1s-.4477-1-1-1h-2Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span className={classes.skillText}>
                          {education.school}
                        </span>
                      </p>
                      <p className={classes.skillContainer}>
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12.4472 4.10557c-.2815-.14076-.6129-.14076-.8944 0L2.76981 8.49706l9.21949 4.39024L21 8.38195l-8.5528-4.27638Z" />
                          <path d="M5 17.2222v-5.448l6.5701 3.1286c.278.1325.6016.1293.8771-.0084L19 11.618v5.6042c0 .2857-.1229.5583-.3364.7481l-.0025.0022-.0041.0036-.0103.009-.0119.0101-.0181.0152c-.024.02-.0562.0462-.0965.0776-.0807.0627-.1942.1465-.3405.2441-.2926.195-.7171.4455-1.2736.6928C15.7905 19.5208 14.1527 20 12 20c-2.15265 0-3.79045-.4792-4.90614-.9751-.5565-.2473-.98098-.4978-1.27356-.6928-.14631-.0976-.2598-.1814-.34049-.2441-.04036-.0314-.07254-.0576-.09656-.0776-.01201-.01-.02198-.0185-.02991-.0253l-.01038-.009-.00404-.0036-.00174-.0015-.0008-.0007s-.00004 0 .00978-.0112l-.00009-.0012-.01043.0117C5.12215 17.7799 5 17.5079 5 17.2222Zm-3-6.8765 2 .9523V17c0 .5523-.44772 1-1 1s-1-.4477-1-1v-6.6543Z" />
                        </svg>
                        <span className={classes.skillText}>
                          {education.degree}
                        </span>
                      </p>
                      <p className={classes.skillContainer}>
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6 2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 1 0 0-2h-2v-2h2a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2h-8v16h5v2H7a1 1 0 1 1 0-2h1V2H6Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span className={classes.skillText}>
                          {education.subject}
                        </span>
                      </p>
                      {education.grade && (
                        <p className={classes.skillContainer}>
                          <svg
                            class="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12.4472 2.10557c-.2815-.14076-.6129-.14076-.8944 0L5.90482 4.92956l.37762.11119c.01131.00333.02257.00687.03376.0106L12 6.94594l5.6808-1.89361.3927-.13363-5.6263-2.81313ZM5 10V6.74803l.70053.20628L7 7.38747V10c0 .5523-.44772 1-1 1s-1-.4477-1-1Zm3-1c0-.42413.06601-.83285.18832-1.21643l3.49538 1.16514c.2053.06842.4272.06842.6325 0l3.4955-1.16514C15.934 8.16715 16 8.57587 16 9c0 2.2091-1.7909 4-4 4-2.20914 0-4-1.7909-4-4Z" />
                            <path d="M14.2996 13.2767c.2332-.2289.5636-.3294.8847-.2692C17.379 13.4191 19 15.4884 19 17.6488v2.1525c0 1.2289-1.0315 2.1428-2.2 2.1428H7.2c-1.16849 0-2.2-.9139-2.2-2.1428v-2.1525c0-2.1409 1.59079-4.1893 3.75163-4.6288.32214-.0655.65589.0315.89274.2595l2.34883 2.2606 2.3064-2.2634Z" />
                          </svg>
                          <span className={classes.skillText}>
                            {education.grade}
                          </span>
                        </p>
                      )}

                      <p className={classes.skillContainer}>
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span className={classes.skillText}>
                          {education.startMonth} {education.startYear} -{" "}
                          {education.isCurrentlyStudying
                            ? "Present"
                            : `${education.endMonth} ${education.endYear}`}
                        </span>
                      </p>
                      {renderTruncatedText(
                        education.description,
                        `edu-${education.id}`,
                        education.descriptionHtml
                      )}
                    </div>
                  ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    School of life or traditional education? Share your learning
                    path. It might inspire others.
                  </div>
                  <div className={classes.noCardAbout}>
                    <p className={classes.emptySkillContainer}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="m6 10.5237-2.27075.6386C3.29797 11.2836 3 11.677 3 12.125V20c0 .5523.44772 1 1 1h2V10.5237Zm12 0 2.2707.6386c.4313.1213.7293.5147.7293.9627V20c0 .5523-.4477 1-1 1h-2V10.5237Z" />
                        <path
                          fill-rule="evenodd"
                          d="M12.5547 3.16795c-.3359-.22393-.7735-.22393-1.1094 0l-6.00002 4c-.45952.30635-.5837.92722-.27735 1.38675.30636.45953.92723.5837 1.38675.27735L8 7.86853V21h8V7.86853l1.4453.96352c.0143.00957.0289.01873.0435.02746.1597.09514.3364.14076.5112.1406.3228-.0003.6395-.15664.832-.44541.3064-.45953.1822-1.0804-.2773-1.38675l-6-4ZM10 12c0-.5523.4477-1 1-1h2c.5523 0 1 .4477 1 1s-.4477 1-1 1h-2c-.5523 0-1-.4477-1-1Zm1-4c-.5523 0-1 .44772-1 1s.4477 1 1 1h2c.5523 0 1-.44772 1-1s-.4477-1-1-1h-2Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      School
                    </p>
                    <p className={classes.emptySkillContainer}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12.4472 4.10557c-.2815-.14076-.6129-.14076-.8944 0L2.76981 8.49706l9.21949 4.39024L21 8.38195l-8.5528-4.27638Z" />
                        <path d="M5 17.2222v-5.448l6.5701 3.1286c.278.1325.6016.1293.8771-.0084L19 11.618v5.6042c0 .2857-.1229.5583-.3364.7481l-.0025.0022-.0041.0036-.0103.009-.0119.0101-.0181.0152c-.024.02-.0562.0462-.0965.0776-.0807.0627-.1942.1465-.3405.2441-.2926.195-.7171.4455-1.2736.6928C15.7905 19.5208 14.1527 20 12 20c-2.15265 0-3.79045-.4792-4.90614-.9751-.5565-.2473-.98098-.4978-1.27356-.6928-.14631-.0976-.2598-.1814-.34049-.2441-.04036-.0314-.07254-.0576-.09656-.0776-.01201-.01-.02198-.0185-.02991-.0253l-.01038-.009-.00404-.0036-.00174-.0015-.0008-.0007s-.00004 0 .00978-.0112l-.00009-.0012-.01043.0117C5.12215 17.7799 5 17.5079 5 17.2222Zm-3-6.8765 2 .9523V17c0 .5523-.44772 1-1 1s-1-.4477-1-1v-6.6543Z" />
                      </svg>
                      Degree
                    </p>
                    <p className={classes.emptySkillContainer}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 1 0 0-2h-2v-2h2a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2h-8v16h5v2H7a1 1 0 1 1 0-2h1V2H6Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Subject
                    </p>
                    <p className={classes.emptySkillContainer}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12.4472 2.10557c-.2815-.14076-.6129-.14076-.8944 0L5.90482 4.92956l.37762.11119c.01131.00333.02257.00687.03376.0106L12 6.94594l5.6808-1.89361.3927-.13363-5.6263-2.81313ZM5 10V6.74803l.70053.20628L7 7.38747V10c0 .5523-.44772 1-1 1s-1-.4477-1-1Zm3-1c0-.42413.06601-.83285.18832-1.21643l3.49538 1.16514c.2053.06842.4272.06842.6325 0l3.4955-1.16514C15.934 8.16715 16 8.57587 16 9c0 2.2091-1.7909 4-4 4-2.20914 0-4-1.7909-4-4Z" />
                        <path d="M14.2996 13.2767c.2332-.2289.5636-.3294.8847-.2692C17.379 13.4191 19 15.4884 19 17.6488v2.1525c0 1.2289-1.0315 2.1428-2.2 2.1428H7.2c-1.16849 0-2.2-.9139-2.2-2.1428v-2.1525c0-2.1409 1.59079-4.1893 3.75163-4.6288.32214-.0655.65589.0315.89274.2595l2.34883 2.2606 2.3064-2.2634Z" />
                      </svg>
                      Grade
                    </p>
                    <p className={classes.emptySkillContainer}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Timeline
                    </p>
                    {/* <p className={classes.noDescription}>Description</p> */}
                  </div>
                </div>
              )}
            </div>
            {userData.education && userData.education.length > 3 && (
              <div
                className={classes.showAllExperiences}
                onClick={() => {
                  if (visibleEducation >= userData.education.length) {
                    setVisibleEducation(3);
                  } else {
                    const additionalItems = visibleEducation * 2;
                    setVisibleEducation(
                      Math.min(
                        visibleEducation + additionalItems,
                        userData.education.length
                      )
                    );
                  }
                }}
              >
                {visibleEducation >= userData.education.length
                  ? "Show less"
                  : "Show more"}
                {visibleEducation >= userData.education.length ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m5 15 7-7 7 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={classes.rightCards}>
          <Ad />

          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Profile;
