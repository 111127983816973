import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import MentionDropdown from "../../components/Dropdowns/MentionDropdown";
import { useMentions } from "../../hooks/useMentions";
import Ad from "../../components/Cards/Ad";
import defaultStartupImage from "../../assets/images/startupImage.jpg";
import StartupInteractionModal from "../../components/Modals/StartupInteractionModal";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { firestore, auth } from "../../firebase";
import RepostsModal from "../../components/Modals/RepostsModal";
import PostActionPopup from "./PostActionPopup";
import StartupInteractionToggle from "../../components/Dropdowns/StartupInteractionToggle";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import {
  collection,
  addDoc,
  increment,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  writeBatch,
  getDoc,
  updateDoc,
  deleteField,
  deleteDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import MainNavbar from "../../components/Navbars/MainNavbar";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./AllPosts.module.css";
import profileImage from "../../assets/icons/profileImage.jpg";
import { convertUrlsToLinks } from "../../utils/textUtils";
import EmojiPicker from "../../components/Dropdowns/EmojiPicker";
import ReactionsModal from "../../components/Modals/ReactionsModal";
import PostModal from "../../components/Modals/PostModal";
import {
  getStorage,
  ref,
  deleteObject,
  listAll,
  getDownloadURL,
} from "firebase/storage";

function AllPosts() {
  const [deletingCommentMessage, setDeletingCommentMessage] = useState(null);
  const [deletingPostMessage, setDeletingPostMessage] = useState(null);
  const [reportMessage, setReportMessage] = useState(null);

  const [startupModalScrollPosition, setStartupModalScrollPosition] =
    useState(0);
  const [isStartupModalOpen, setIsStartupModalOpen] = useState(false);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const location = useLocation(); // Add this line
  const [isEditingComplete, setIsEditingComplete] = useState(false);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [shareStates, setShareStates] = useState({});
  const [userStartups, setUserStartups] = useState([]);
  const [showStartupModal, setShowStartupModal] = useState(false);
  const [activeStartupToggle, setActiveStartupToggle] = useState(null);
  const [isIOS, setIsIOS] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const { urlSlug, type, id } = useParams();
  const [posts, setPosts] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const {
    showMentions,
    mentionSearch,
    handleInput,
    handleMentionSelect,
    setShowMentions,
    convertMentionsToSpans,
  } = useMentions();
  const [replyMentionStates, setReplyMentionStates] = useState({});
  const [isPlaying, setIsPlaying] = useState({});
  const [timeLeft, setTimeLeft] = useState({});

  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState({});
  const [showReactionsModal, setShowReactionsModal] = useState({});
  const [videoVolume, setVideoVolume] = useState({});
  const videoRefs = useRef({});
  const [expandedPosts, setExpandedPosts] = useState({});
  const [showDropdown, setShowDropdown] = useState({});
  const navigate = useNavigate();
  const storage = getStorage();
  const [showRepostsModal, setShowRepostsModal] = useState({});
  const [repostMessage, setRepostMessage] = useState(null);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const editTextareaRef = useRef(null);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [isStartupView, setIsStartupView] = useState(false);
  const [startupData, setStartupData] = useState(null);

  const [isSafari, setIsSafari] = useState(false);

  // New state variables for comments
  const [errorPopup, setErrorPopup] = useState(null);

  const [comments, setComments] = useState({});
  const [newComment, setNewComment] = useState({});
  const [replyComments, setReplyComments] = useState({});
  const [activeCommentId, setActiveCommentId] = useState({});
  const [visibleComments, setVisibleComments] = useState(3);

  const [visibleReplies, setVisibleReplies] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [showCommentEmojiPicker, setShowCommentEmojiPicker] = useState({});
  const [activeCommentForReaction, setActiveCommentForReaction] =
    useState(null);
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);
  const [showComments, setShowComments] = useState({});

  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);

  const handleModalOpen = useCallback((toggleId) => {
    setStartupModalScrollPosition(window.scrollY);
    setShowStartupModal(true);
    setActiveStartupToggle(toggleId);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowStartupModal(false);
    setTimeout(() => {
      window.scrollTo(0, startupModalScrollPosition);
    }, 0);
  }, [startupModalScrollPosition]);

  const commentInputRef = useRef({});
  const replyInputRef = useRef({});

  const hasInteractions = (post) => {
    return (
      (post.reactions && Object.keys(post.reactions).length > 0) ||
      (post.reposts && Object.keys(post.reposts).length > 0)
    );
  };

  const handleEditPost = (post) => {
    setPostModalScrollPosition(window.scrollY);
    setEditingPost(post);
    setShowEditModal(true);
    setIsPostModalOpen(true);
  };

  useEffect(() => {
    const fetchUserStartups = async () => {
      if (!currentUser?.uid) return;
      try {
        const startupsRef = collection(
          firestore,
          `users/${currentUser.uid}/startups`
        );
        const startupsSnapshot = await getDocs(startupsRef);
        const startupsData = startupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserStartups(startupsData);
      } catch (error) {}
    };

    fetchUserStartups();
  }, [currentUser?.uid]);

  const fetchUserPosts = async () => {
    setLoading(true);
    setHasAttemptedLoad(false);
    try {
      let userId;
      let targetStartupId;

      if (location.state?.startupView) {
        setIsStartupView(true);
        const { startupId, ownerId } = location.state;
        userId = ownerId;
        targetStartupId = startupId;

        const startupDoc = await getDoc(
          doc(firestore, `users/${ownerId}/startups/${startupId}`)
        );
        if (startupDoc.exists()) {
          setStartupData(startupDoc.data());
        }

        const postsRef = collection(
          firestore,
          `users/${ownerId}/startups/${startupId}/posts`
        );
        const q = query(postsRef, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);

        const postsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const postData = doc.data();
            const mediaUrl = await getMediaUrl(postData.content);

            return {
              id: doc.id,
              ...postData,
              user: {
                id: `startup_${startupId}`,
                firstName: startupData.startupName,
                lastName: "",
                profileImage: startupData.startupImage || profileImage,
                bio: startupData.bio,
                link: startupData.link,
                linkText: startupData.linkText,
                isStartup: true,
                startupId: startupId,
                startupOwnerId: ownerId,
                startupName: startupData.startupName,
                startupUrlSlug: startupData.startupUrlSlug, // Add this line
              },
              content: {
                ...postData.content,
                url: mediaUrl || postData.content?.url,
              },
              reactions: postData.reactions || {},
              reposts: postData.reposts || {},
              commentCount: postData.commentCount || 0,
              connectionType: "Startup",
            };
          })
        );

        setPosts(postsData);
      } else {
        if (urlSlug === "me") {
          if (!auth.currentUser) {
            setLoading(false);
            return;
          }
          userId = auth.currentUser.uid;
        } else {
          const usersRef = collection(firestore, "users");
          const q = query(usersRef, where("urlSlug", "==", urlSlug));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            userId = querySnapshot.docs[0].id;
          } else {
            setLoading(false);
            return;
          }
        }

        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (!userDoc.exists()) {
          setLoading(false);
          return;
        }

        const postsRef = collection(firestore, `users/${userId}/posts`);
        const q = query(postsRef, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);

        const fetchedPosts = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const postData = doc.data();
            let mediaUrl = null;

            if (
              postData.content &&
              postData.content.type &&
              postData.content.url
            ) {
              if (Array.isArray(postData.content.url)) {
                mediaUrl = await Promise.all(
                  postData.content.url.map(async (url) => {
                    try {
                      const storageRef = ref(storage, url);
                      return await getDownloadURL(storageRef);
                    } catch (error) {
                      return null;
                    }
                  })
                );
              } else {
                try {
                  const storageRef = ref(storage, postData.content.url);
                  mediaUrl = await getDownloadURL(storageRef);
                } catch (error) {}
              }
            }

            let connectionType = "Extended";
            if (auth.currentUser) {
              connectionType = await checkConnectionType(
                auth.currentUser.uid,
                userId
              );
            }

            await fetchComments(doc.id, userId);

            return {
              id: doc.id,
              ...postData,
              user: {
                id: userId,
                firstName: userDoc.data().firstName,
                lastName: userDoc.data().lastName,
                profileImage: userDoc.data().profileImage || profileImage,
                bio: userDoc.data().bio,
                link: userDoc.data().link,
                linkText: userDoc.data().linkText,
              },
              content: {
                ...postData.content,
                url: mediaUrl || postData.content?.url,
              },
              reactions: postData.reactions || {},
              reposts: postData.reposts || {},
              commentCount: postData.commentCount || 0,
              connectionType: connectionType,
            };
          })
        );

        setPosts(fetchedPosts);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      setHasAttemptedLoad(true);
    }
  };

  const fetchRegularPosts = async (userId, userData) => {
    const postsRef = collection(firestore, `users/${userId}/posts`);
    const q = query(postsRef, orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);

    return Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const postData = doc.data();
        const mediaUrl = await getMediaUrl(postData.content);
        const connectionType = await checkConnectionType(
          auth.currentUser.uid,
          userId
        );

        return {
          id: doc.id,
          ...postData,
          user: {
            id: userId,
            firstName: userData.firstName,
            lastName: userData.lastName,
            profileImage: userData.profileImage || profileImage,
            bio: userData.bio,
            link: userData.link,
            linkText: userData.linkText,
            isStartup: false,
          },
          content: {
            ...postData.content,
            url: mediaUrl || postData.content?.url,
          },
          reactions: postData.reactions || {},
          reposts: postData.reposts || {},
          commentCount: postData.commentCount || 0,
          connectionType,
        };
      })
    );
  };

  const fetchStartupPosts = async (userId) => {
    const startupsRef = collection(firestore, `users/${userId}/startups`);
    const startupsSnapshot = await getDocs(startupsRef);
    const allStartupPosts = [];

    for (const startupDoc of startupsSnapshot.docs) {
      const startupData = startupDoc.data();
      const startupPostsRef = collection(
        firestore,
        `users/${userId}/startups/${startupDoc.id}/posts`
      );
      const postsSnapshot = await getDocs(
        query(startupPostsRef, orderBy("createdAt", "desc"))
      );

      const startupPosts = await Promise.all(
        postsSnapshot.docs.map(async (postDoc) => {
          const postData = postDoc.data();
          const mediaUrl = await getMediaUrl(postData.content);

          return {
            id: postDoc.id,
            ...postData,
            user: {
              id: `startup_${startupDoc.id}`,
              firstName: startupData.startupName,
              lastName: "",
              profileImage: startupData.startupImage || profileImage,
              bio: startupData.bio || "",
              link: startupData.link || null,
              linkText: startupData.linkText || null,
              isStartup: true,
              startupId: startupDoc.id,
              startupOwnerId: userId,
            },
            content: {
              ...postData.content,
              url: mediaUrl || postData.content?.url,
            },
            reactions: postData.reactions || {},
            reposts: postData.reposts || {},
            commentCount: postData.commentCount || 0,
            connectionType: "Startup",
          };
        })
      );

      allStartupPosts.push(...startupPosts);
    }

    return allStartupPosts;
  };

  const getMediaUrl = async (content) => {
    if (!content || !content.type || !content.url) return null;

    try {
      if (content.type === "image" && Array.isArray(content.url)) {
        return Promise.all(
          content.url.map(async (url) => {
            const storageRef = ref(storage, url);
            return await getDownloadURL(storageRef);
          })
        );
      } else if (["image", "video", "pdf"].includes(content.type)) {
        const storageRef = ref(storage, content.url);
        return await getDownloadURL(storageRef);
      }
    } catch (error) {
      return null;
    }
  };

  // Update the getPostRef function to handle startup posts

  // Update the getCommenterInfo function to include startup info
  const getCommenterInfo = (currentUser, selectedStartup) => {
    if (selectedStartup) {
      return {
        id: `startup_${selectedStartup.id}`,
        name: selectedStartup.startupName,
        image: selectedStartup.startupImage,
        bio: selectedStartup.bio || "",
        link: selectedStartup.link || null,
        linkText: selectedStartup.linkText || null,
        connectionType: "Startup",
        isStartup: true,
        startupId: selectedStartup.id,
        ownerId: currentUser.uid,
      };
    }

    return {
      id: currentUser.uid,
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      image: currentUser.profileImage || profileImage,
      bio: currentUser.bio || "",
      link: currentUser.link || null,
      linkText: currentUser.linkText || null,
      connectionType: "You",
      isStartup: false,
      urlSlug: currentUser.urlSlug,
    };
  };

  // Update the getCommentsPath function to handle startup posts
  const getCommentsPath = (post) => {
    if (post.user.isStartup) {
      return `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}/comments`;
    }
    return `users/${post.user.id}/posts/${post.id}/comments`;
  };

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );

      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0rem" : "0rem"
      );
    };

    checkSafari();
  }, []);

  const fetchRepostData = useCallback(async (reposts) => {
    const repostData = {};
    for (const [userId, timestamp] of Object.entries(reposts)) {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        repostData[userId] = {
          id: userId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage || profileImage,
          timestamp: timestamp,
        };
      }
    }
    return repostData;
  }, []);

  const RepostPopup = ({ message, onClose, postId, isRepost, isLoading }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          {isLoading ? (
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
          )}
          <p>{message}</p>
          {isRepost && (
            <Link
              to={`/repost/${postId}`}
              state={{ fromRepostPopup: true }}
              className={classes.viewRepostLink}
            >
              View repost
            </Link>
          )}
        </div>
        <div onClick={onClose} className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const renderComments = (postId) => {
    if (!postId || !comments[postId]) return null;

    const postComments = comments[postId];
    const rootComments = postComments.filter(
      (comment) => comment.isRootComment
    );
    const visibleRootComments = rootComments.slice(
      0,
      visibleComments[postId] || 3 // Default to 3 if not set
    );

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = postComments.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment.reactions || {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || profileImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() =>
                      handleNavigate(
                        comment.isStartup
                          ? `startup/${comment.startupUrlSlug}`
                          : comment.urlSlug || comment.userId
                      )
                    }
                    style={{
                      borderRadius: comment.isStartup ? 0 : "50%",
                      cursor: "pointer",
                    }}
                  />

                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() =>
                          handleNavigate(
                            comment.isStartup
                              ? `startup/${comment.startupUrlSlug}`
                              : comment.urlSlug || comment.userId
                          )
                        }
                      >
                        {comment.userName &&
                        !comment.userName.includes("undefined")
                          ? comment.userName
                          : ""}
                      </span>
                      <span className={classes.commentConnectionType}>
                        • {comment.connectionType}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === `${postId}_${comment.id}`
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(postId, comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === `${postId}_${comment.id}` &&
                    renderCommentOptions(postId, comment)}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(postId, comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() =>
                      handleCommentReactionClick(postId, comment.id)
                    }
                  >
                    Boost {totalReactions > 0 && `(${totalReactions})`}
                  </div>

                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(postId, comment.id)}
                  >
                    <span className={classes.replyText}>
                      Reply {replyCount > 0 && `(${replyCount})`}
                    </span>
                  </button>
                </div>
              )}
              {activeEmojiPicker === `${postId}_${comment.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, comment.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {renderReplies(postId, comment.id)}
              {activeCommentId[postId] === comment.id && (
                <div className={classes.replyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={
                          selectedStartup
                            ? selectedStartup.startupImage
                            : currentUserProfileImage || profileImage
                        }
                        alt={
                          selectedStartup
                            ? selectedStartup.startupName
                            : "Your profile"
                        }
                        className={classes.replyUserImage}
                        onClick={() =>
                          handleNavigate(
                            selectedStartup
                              ? `startup/${selectedStartup.startupUrlSlug}`
                              : currentUser.urlSlug
                          )
                        }
                        style={{
                          borderRadius: selectedStartup ? 0 : "50%",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div className={classes.textareaWrapper}>
                      <textarea
                        ref={(el) => {
                          if (!replyInputRef.current[postId]) {
                            replyInputRef.current[postId] = {};
                          }
                          replyInputRef.current[postId][comment.id] = el;
                        }}
                        value={replyComments[postId]?.[comment.id] || ""}
                        onChange={(e) => {
                          handleReplyInputChange(e, postId, comment.id);
                          const textarea = e.target;
                          const cursorPosition = textarea.selectionStart;
                          const textBeforeCursor = textarea.value.slice(
                            0,
                            cursorPosition
                          );
                          const match = /@(\w*)$/.exec(textBeforeCursor);

                          setReplyMentionStates((prev) => ({
                            ...prev,
                            [`${postId}_${comment.id}`]: {
                              ...prev[`${postId}_${comment.id}`],
                              showMentions: !!match,
                              mentionSearch: match ? match[1] : "",
                            },
                          }));
                        }}
                        onTouchStart={handleTouchStart}
                        placeholder="Add a reply..."
                        className={classes.replyInput}
                      />
                      <div
                        className={classes.textOverlay}
                        dangerouslySetInnerHTML={{
                          __html: (() => {
                            const text =
                              replyComments[postId]?.[comment.id] || "";
                            let styledText = text;
                            const mentionedUsers =
                              replyMentionStates[`${postId}_${comment.id}`]
                                ?.mentionedUsers || {};

                            Object.entries(mentionedUsers).forEach(
                              ([name, user]) => {
                                const escapedName = name.replace(
                                  /[.*+?^${}()|[\]\\]/g,
                                  "\\$&"
                                );
                                const regex = new RegExp(escapedName, "g");
                                styledText = styledText.replace(
                                  regex,
                                  `<span class="${classes.mention}">${name}</span>`
                                );
                              }
                            );
                            return styledText;
                          })(),
                        }}
                      />
                      {replyMentionStates[`${postId}_${comment.id}`]
                        ?.showMentions && (
                        <MentionDropdown
                          searchText={
                            replyMentionStates[`${postId}_${comment.id}`]
                              ?.mentionSearch || ""
                          }
                          inputElement={
                            replyInputRef.current[postId]?.[comment.id]
                          }
                          onSelectUser={(user) => {
                            const textarea =
                              replyInputRef.current[postId]?.[comment.id];
                            if (textarea) {
                              const cursorPosition = textarea.selectionStart;
                              const textBeforeCursor = textarea.value.slice(
                                0,
                                cursorPosition
                              );
                              const textAfterCursor =
                                textarea.value.slice(cursorPosition);
                              const lastAtSymbol =
                                textBeforeCursor.lastIndexOf("@");
                              if (lastAtSymbol !== -1) {
                                const newValue =
                                  textBeforeCursor.slice(0, lastAtSymbol) +
                                  `${user.firstName} ${user.lastName}` + // Remove @ symbol
                                  textAfterCursor;

                                // Store mention data for this reply
                                setReplyMentionStates((prev) => ({
                                  ...prev,
                                  [`${postId}_${comment.id}`]: {
                                    ...prev[`${postId}_${comment.id}`],
                                    showMentions: false,
                                    mentionedUsers: {
                                      ...(prev[`${postId}_${comment.id}`]
                                        ?.mentionedUsers || {}),
                                      [`${user.firstName} ${user.lastName}`]:
                                        user,
                                    },
                                  },
                                }));

                                textarea.value = newValue;
                                textarea.style.height = "auto";
                                textarea.style.height = `${textarea.scrollHeight}px`;

                                const syntheticEvent = { target: textarea };
                                handleReplyInputChange(
                                  syntheticEvent,
                                  postId,
                                  comment.id
                                );
                              }
                            }
                          }}
                          onClose={() => {
                            setReplyMentionStates((prev) => ({
                              ...prev,
                              [`${postId}_${comment.id}`]: {
                                ...prev[`${postId}_${comment.id}`],
                                showMentions: false,
                              },
                            }));
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      const text = replyComments[postId]?.[comment.id];
                      if (text?.trim()) {
                        let processedText = text;
                        const mentionedUsers =
                          replyMentionStates[`${postId}_${comment.id}`]
                            ?.mentionedUsers || {};

                        Object.entries(mentionedUsers).forEach(
                          ([name, user]) => {
                            const escapedName = name.replace(
                              /[.*+?^${}()|[\]\\]/g,
                              "\\$&"
                            );
                            const regex = new RegExp(escapedName, "g");
                            processedText = processedText.replace(
                              regex,
                              `<a href="/${user.urlSlug}" class="${classes.mentionText}">${name}</a>`
                            );
                          }
                        );

                        handleAddComment(
                          postId,
                          comment.id,
                          processedText,
                          mentionedUsers
                        );
                      }
                    }}
                    className={`${classes.addReplyButton} ${
                      !replyComments[postId]?.[comment.id]?.trim()
                        ? classes.disabled
                        : ""
                    }`}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {rootComments.length > (visibleComments[postId] || 3) && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={() => handleLoadMoreComments(postId)}
          >
            Show more comments (
            {rootComments.length - (visibleComments[postId] || 3)})
          </div>
        )}
      </>
    );
  };

  const renderCommentOptions = (postId, comment) => {
    const post = posts.find((p) => p.id === postId);
    if (!post) return null;

    const isOwnComment = comment.isStartup
      ? comment.startupOwnerId === currentUser.uid
      : comment.userId === currentUser.uid;

    const isPostOwner = post.user.isStartup
      ? post.user.startupOwnerId === currentUser.uid
      : post.user.id === currentUser.uid;

    return (
      <div className={classes.commentOptionsDropdown}>
        {isOwnComment && (
          <button
            className={classes.editCommentButton}
            onClick={() =>
              handleEditComment(postId, comment.id, !comment.isRootComment)
            }
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
              />
            </svg>
            Edit
          </button>
        )}

        {(isOwnComment || isPostOwner) && !isOwnComment && (
          <>
            <button
              className={classes.reportCommentButton}
              onClick={() => handleReportComment(postId, comment.id)}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              Report
            </button>
            <button
              className={classes.deleteCommentButton}
              onClick={() => handleDeleteComment(postId, comment.id)}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                />
              </svg>
              Delete
            </button>
          </>
        )}

        {isOwnComment && (
          <button
            className={classes.deleteCommentButton}
            onClick={() => handleDeleteComment(postId, comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
              />
            </svg>
            Delete
          </button>
        )}

        {!isOwnComment && !isPostOwner && (
          <button
            className={classes.singleReportButton}
            onClick={() => handleReportComment(postId, comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Report
          </button>
        )}
      </div>
    );
  };

  const updateCommentVisibility = async (postId, commenterId, commentData) => {
    const postRef = doc(
      firestore,
      `users/${commentData.originalPosterId}/posts/${postId}`
    );
    const postDoc = await getDoc(postRef);

    if (postDoc.exists()) {
      const postData = postDoc.data();
      const currentUserConnectionType = await checkConnectionType(
        currentUser.uid,
        postData.userId
      );
      const commenterConnectionType = await checkConnectionType(
        currentUser.uid,
        commenterId
      );

      if (
        (currentUserConnectionType === "Indirect" ||
          currentUserConnectionType === "Extended") &&
        commenterConnectionType === "Direct"
      ) {
        await updateDoc(postRef, {
          visibleCommentedBy: {
            id: commenterId,
            firstName: commentData.firstName,
            lastName: commentData.lastName,
            commentedAt: commentData.commentedAt,
            commentText: commentData.commentText,
          },
          originalDirectCommentTime:
            postData.originalDirectCommentTime || commentData.commentedAt,
        });
      }
    }
  };

  const renderReplies = (postId, rootCommentId) => {
    if (!postId || !comments[postId]) return null;

    const replies = comments[postId].filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );
    const visibleRepliesCount = visibleReplies[postId]?.[rootCommentId] || 0;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const totalReactions = Object.keys(reply.reactions || {}).length;

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage || profileImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() =>
                      handleNavigate(
                        reply.isStartup
                          ? `startup/${reply.startupUrlSlug}`
                          : reply.urlSlug || reply.userId
                      )
                    }
                    style={{
                      borderRadius: reply.isStartup ? 0 : "50%",
                      cursor: "pointer",
                    }}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() =>
                          handleNavigate(
                            reply.isStartup
                              ? `startup/${reply.startupUrlSlug}`
                              : reply.urlSlug || reply.userId
                          )
                        }
                      >
                        {reply.userName && !reply.userName.includes("undefined")
                          ? reply.userName
                          : ""}
                      </span>
                      <span className={classes.replyConnectionType}>
                        • {reply.connectionType}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === `${postId}_${reply.id}`
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(postId, reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === `${postId}_${reply.id}` &&
                    renderCommentOptions(postId, reply)}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(postId, reply)}
              </div>
              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(postId, reply.id)}
                  >
                    Boost {totalReactions > 0 && `(${totalReactions})`}
                  </div>
                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(postId, reply.id)}
                  >
                    <span className={classes.replyText}>
                      Reply{" "}
                      {comments[postId]?.filter(
                        (c) => c.parentCommentId === reply.id
                      ).length > 0 &&
                        `(${
                          comments[postId].filter(
                            (c) => c.parentCommentId === reply.id
                          ).length
                        })`}
                    </span>
                  </button>
                </div>
              )}
              {activeEmojiPicker === `${postId}_${reply.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, reply.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
            </div>
          );
        })}
        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(postId, rootCommentId)}
          >
            {visibleRepliesCount === 0
              ? `See replies (${replies.length})`
              : `See more replies (${replies.length - visibleRepliesCount})`}
          </div>
        )}
        {activeCommentId &&
          replies.some((reply) => activeCommentId[postId] === reply.id) && (
            <div className={classes.replyToReplyCommentInputSection}>
              <div className={classes.replyInputWrapper}>
                <div>
                  <img
                    src={
                      selectedStartup
                        ? selectedStartup.startupImage
                        : currentUserProfileImage || profileImage
                    }
                    alt={
                      selectedStartup
                        ? selectedStartup.startupName
                        : "Your profile"
                    }
                    className={classes.replyUserImage}
                    onClick={() =>
                      handleNavigate(
                        selectedStartup
                          ? `startup/${selectedStartup.startupUrlSlug}`
                          : currentUser.urlSlug
                      )
                    }
                    style={{
                      borderRadius: selectedStartup ? 0 : "50%",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className={classes.textareaWrapper}>
                  <textarea
                    ref={(el) => {
                      if (!replyInputRef.current[postId]) {
                        replyInputRef.current[postId] = {};
                      }
                      replyInputRef.current[postId][activeCommentId[postId]] =
                        el;
                    }}
                    value={
                      replyComments[postId]?.[activeCommentId[postId]] || ""
                    }
                    onChange={(e) => {
                      handleReplyInputChange(
                        e,
                        postId,
                        activeCommentId[postId]
                      );
                      const textarea = e.target;
                      const cursorPosition = textarea.selectionStart;
                      const textBeforeCursor = textarea.value.slice(
                        0,
                        cursorPosition
                      );
                      const match = /@(\w*)$/.exec(textBeforeCursor);

                      setReplyMentionStates((prev) => ({
                        ...prev,
                        [`${postId}_${activeCommentId[postId]}`]: {
                          ...prev[`${postId}_${activeCommentId[postId]}`],
                          showMentions: !!match,
                          mentionSearch: match ? match[1] : "",
                        },
                      }));
                    }}
                    onTouchStart={handleTouchStart}
                    placeholder="Add a reply..."
                    className={classes.replyInput}
                  />
                  <div
                    className={classes.textOverlay}
                    dangerouslySetInnerHTML={{
                      __html: (() => {
                        const text =
                          replyComments[postId]?.[activeCommentId[postId]] ||
                          "";
                        let styledText = text;
                        const mentionedUsers =
                          replyMentionStates[
                            `${postId}_${activeCommentId[postId]}`
                          ]?.mentionedUsers || {};

                        Object.entries(mentionedUsers).forEach(
                          ([name, user]) => {
                            const escapedName = name.replace(
                              /[.*+?^${}()|[\]\\]/g,
                              "\\$&"
                            );
                            const regex = new RegExp(escapedName, "g");
                            styledText = styledText.replace(
                              regex,
                              `<span class="${classes.mention}">${name}</span>`
                            );
                          }
                        );
                        return styledText;
                      })(),
                    }}
                  />
                  {replyMentionStates[`${postId}_${activeCommentId[postId]}`]
                    ?.showMentions && (
                    <MentionDropdown
                      searchText={
                        replyMentionStates[
                          `${postId}_${activeCommentId[postId]}`
                        ]?.mentionSearch || ""
                      }
                      inputElement={
                        replyInputRef.current[postId]?.[activeCommentId[postId]]
                      }
                      onSelectUser={(user) => {
                        const textarea =
                          replyInputRef.current[postId]?.[
                            activeCommentId[postId]
                          ];
                        if (textarea) {
                          const cursorPosition = textarea.selectionStart;
                          const textBeforeCursor = textarea.value.slice(
                            0,
                            cursorPosition
                          );
                          const textAfterCursor =
                            textarea.value.slice(cursorPosition);
                          const lastAtSymbol =
                            textBeforeCursor.lastIndexOf("@");
                          if (lastAtSymbol !== -1) {
                            const newValue =
                              textBeforeCursor.slice(0, lastAtSymbol) +
                              `${user.firstName} ${user.lastName}` +
                              textAfterCursor;

                            setReplyMentionStates((prev) => ({
                              ...prev,
                              [`${postId}_${activeCommentId[postId]}`]: {
                                ...prev[`${postId}_${activeCommentId[postId]}`],
                                showMentions: false,
                                mentionedUsers: {
                                  ...(prev[
                                    `${postId}_${activeCommentId[postId]}`
                                  ]?.mentionedUsers || {}),
                                  [`${user.firstName} ${user.lastName}`]: user,
                                },
                              },
                            }));

                            textarea.value = newValue;
                            textarea.style.height = "auto";
                            textarea.style.height = `${textarea.scrollHeight}px`;

                            const syntheticEvent = { target: textarea };
                            handleReplyInputChange(
                              syntheticEvent,
                              postId,
                              activeCommentId[postId]
                            );
                          }
                        }
                      }}
                      onClose={() => {
                        setReplyMentionStates((prev) => ({
                          ...prev,
                          [`${postId}_${activeCommentId[postId]}`]: {
                            ...prev[`${postId}_${activeCommentId[postId]}`],
                            showMentions: false,
                          },
                        }));
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                onClick={() => {
                  const text = replyComments[postId]?.[activeCommentId[postId]];
                  if (text?.trim()) {
                    let processedText = text;
                    const mentionedUsers =
                      replyMentionStates[`${postId}_${activeCommentId[postId]}`]
                        ?.mentionedUsers || {};

                    Object.entries(mentionedUsers).forEach(([name, user]) => {
                      const escapedName = name.replace(
                        /[.*+?^${}()|[\]\\]/g,
                        "\\$&"
                      );
                      const regex = new RegExp(escapedName, "g");
                      processedText = processedText.replace(
                        regex,
                        `<a href="/${user.urlSlug}" class="${classes.mentionText}">${name}</a>`
                      );
                    });
                    handleAddComment(
                      postId,
                      activeCommentId[postId],
                      processedText,
                      mentionedUsers
                    );
                  }
                }}
                className={`${classes.addReplyButton} ${
                  !replyComments[postId]?.[activeCommentId[postId]]?.trim()
                    ? classes.disabled
                    : ""
                }`}
              >
                Reply
              </div>
            </div>
          )}
      </div>
    );
  };

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Try to force the keyboard to open
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      // For iOS devices
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const PostedByMessage = ({ post, currentUser }) => {
    if (post.user.isStartup) {
      return (
        <div className={classes.reactionHeader}>
          <img
            src={post.user?.profileImage || profileImage}
            alt={`${post.user?.firstName || ""} ${post.user?.lastName || ""}`}
            className={classes.reactionImage}
            onClick={() =>
              handleNavigate(
                post.user.isStartup
                  ? `startup/${post.user.startupUrlSlug}`
                  : post.user.id
              )
            }
            style={{
              borderRadius: post.user.isStartup ? 0 : "50%",
              cursor: "pointer",
            }}
          />
          <span className={classes.reactionText}>
            <span
              className={classes.reactionUsername}
              onClick={() =>
                handleNavigate(
                  post.user.isStartup
                    ? `startup/${post.user.startupUrlSlug}`
                    : post.user.id
                )
              }
              style={{ cursor: "pointer" }}
            >
              {post.user?.id === currentUser?.uid
                ? "You"
                : `${post.user?.firstName || ""} ${post.user?.lastName || ""}`}
            </span>{" "}
            posted this
          </span>
        </div>
      );
    }

    // Original user post logic...
    if (post.user?.id === currentUser?.uid) {
      return (
        <div className={classes.reactionHeader}>
          <img
            src={post.user?.profileImage || profileImage}
            alt={`${post.user?.firstName || ""} ${post.user?.lastName || ""}`}
            className={classes.reactionImage}
            onClick={() =>
              handleNavigate(
                post.user.isStartup
                  ? `startup/${post.user.startupUrlSlug}`
                  : post.user.id
              )
            }
            style={{
              borderRadius: post.user.isStartup ? 0 : "50%",
              cursor: "pointer",
            }}
          />

          <span className={classes.reactionText}>
            <span
              className={classes.reactionUsername}
              onClick={() =>
                handleNavigate(
                  post.user.isStartup
                    ? `startup/${post.user.startupUrlSlug}`
                    : post.user.id
                )
              }
              style={{ cursor: "pointer" }}
            >
              {post.user?.id === currentUser?.uid
                ? "You"
                : `${post.user?.firstName || ""} ${post.user?.lastName || ""}`}
            </span>{" "}
            posted this
          </span>
        </div>
      );
    }

    return (
      <div className={classes.reactionHeader}>
        <img
          src={post.user?.profileImage || profileImage}
          alt={`${post.user?.firstName || ""} ${post.user?.lastName || ""}`}
          className={classes.reactionImage}
          onClick={() =>
            handleNavigate(
              post.user.isStartup
                ? `startup/${post.user.startupUrlSlug}`
                : post.user.id
            )
          }
          style={{
            borderRadius: post.user.isStartup ? 0 : "50%",
            cursor: "pointer",
          }}
        />
        <span className={classes.reactionText}>
          <span
            className={classes.reactionUsername}
            onClick={() =>
              handleNavigate(
                post.user.isStartup
                  ? `startup/${post.user.startupUrlSlug}`
                  : post.user.id
              )
            }
            style={{ cursor: "pointer" }}
          >
            {post.user?.id === currentUser?.uid
              ? "You"
              : `${post.user?.firstName || ""} ${post.user?.lastName || ""}`}
          </span>{" "}
          posted this
        </span>
      </div>
    );
  };

  const handleRepost = async (postId) => {
    if (!currentUser) return;

    const post = posts.find((p) => p.id === postId);
    if (!post) return;

    const reposterInfo = getCommenterInfo(currentUser, selectedStartup);

    try {
      const postRef = getPostRef(post);
      const repostsPath = reposterInfo.isStartup
        ? `users/${currentUser.uid}/startups/${reposterInfo.startupId}/reposts`
        : `users/${currentUser.uid}/reposts`;
      const repostedPostRef = doc(firestore, `${repostsPath}/${postId}`);

      let updatedReposts = { ...(post.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[reposterInfo.id]) {
        // Remove repost
        await updateDoc(postRef, {
          [`reposts.${reposterInfo.id}`]: deleteField(),
        });
        delete updatedReposts[reposterInfo.id];
        await deleteDoc(repostedPostRef);

        const notificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `repost_${postId}_${reposterInfo.id}`
        );
        await deleteDoc(notificationRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        // Add repost
        await updateDoc(postRef, {
          [`reposts.${reposterInfo.id}`]: now,
        });
        updatedReposts[reposterInfo.id] = now;

        await setDoc(repostedPostRef, {
          originalPosterId: post.user.startupOwnerId || post.user.id,
          postId: postId,
          repostedAt: now,
          originalDirectRepostTime: post.originalDirectRepostTime || now,
        });

        // Don't notify if it's effectively the same person
        const shouldNotify = post.user.isStartup
          ? post.user.startupOwnerId !== currentUser.uid ||
            (selectedStartup && post.user.startupId !== selectedStartup.id)
          : post.user.id !== currentUser.uid;

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${post.user.startupOwnerId || post.user.id}/notifications`,
            `repost_${postId}_${reposterInfo.id}`
          );

          const notificationData = {
            type: "repost",
            postId: postId,
            reposterId: reposterInfo.id,
            reposterName: reposterInfo.name,
            reposterImage: reposterInfo.image,
            createdAt: now,
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(reposterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
              startupName: selectedStartup.startupName,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      // Update local state
      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId ? { ...p, reposts: updatedReposts } : p
        )
      );
    } catch (error) {
      setRepostMessage({
        text: `Error: ${error.message}`,
        postId,
        isRepost: false,
      });
    }
  };

  const handleRepostsClick = useCallback(
    async (postId, reposts) => {
      setRepostsModalScrollPosition(window.scrollY);
      const detailedReposts = await fetchRepostData(reposts);
      setActivePostReposts({ postId, reposts: detailedReposts });
      setShowRepostsModal((prev) => ({ ...prev, [postId]: true }));
      setIsRepostsModalOpen(true);
    },
    [fetchRepostData]
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            ...user,
            firstName: userData.firstName,
            lastName: userData.lastName,
            profileImage: userData.profileImage || profileImage,
            urlSlug: userData.urlSlug, // Add this line
          });
          setCurrentUserProfileImage(userData.profileImage || profileImage);
        } else {
          setCurrentUser(user);
          setCurrentUserProfileImage(profileImage);
        }
      } else {
        setCurrentUser(null);
        setCurrentUserProfileImage(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkIfUsersBlocked = async (currentUserId, otherUserId) => {
    // Check if current user has blocked the other user
    const currentUserBlockedRef = collection(
      firestore,
      `users/${currentUserId}/blockedUsers`
    );
    const currentUserBlockedSnapshot = await getDocs(currentUserBlockedRef);

    for (const doc of currentUserBlockedSnapshot.docs) {
      if (doc.data().userId === otherUserId) {
        return true;
      }
    }

    // Check if other user has blocked current user
    const otherUserBlockedRef = collection(
      firestore,
      `users/${otherUserId}/blockedUsers`
    );
    const otherUserBlockedSnapshot = await getDocs(otherUserBlockedRef);

    for (const doc of otherUserBlockedSnapshot.docs) {
      if (doc.data().userId === currentUserId) {
        return true;
      }
    }

    return false;
  };

  const getUserStartups = async (userId) => {
    try {
      const startupsRef = collection(firestore, `users/${userId}/startups`);
      const startupsSnapshot = await getDocs(startupsRef);
      return startupsSnapshot.docs.map((doc) => doc.id);
    } catch (error) {
      return [];
    }
  };

  const isUserOrStartupsBlocked = async (currentUserId, otherUserId) => {
    try {
      // Get all startups for both users
      const [currentUserStartups, otherUserStartups] = await Promise.all([
        getUserStartups(currentUserId),
        getUserStartups(otherUserId),
      ]);

      // Check current user's blocked users
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedSnapshot = await getDocs(currentUserBlockedRef);
      const currentUserBlockedIds = new Set(
        currentUserBlockedSnapshot.docs.map((doc) => doc.data().userId)
      );

      // Check if other user or their startups are blocked by current user
      if (currentUserBlockedIds.has(otherUserId)) return true;
      for (const startupId of otherUserStartups) {
        if (currentUserBlockedIds.has(`startup_${startupId}`)) return true;
      }

      // Check other user's blocked users
      const otherUserBlockedRef = collection(
        firestore,
        `users/${otherUserId}/blockedUsers`
      );
      const otherUserBlockedSnapshot = await getDocs(otherUserBlockedRef);
      const otherUserBlockedIds = new Set(
        otherUserBlockedSnapshot.docs.map((doc) => doc.data().userId)
      );

      // Check if current user or their startups are blocked by other user
      if (otherUserBlockedIds.has(currentUserId)) return true;
      for (const startupId of currentUserStartups) {
        if (otherUserBlockedIds.has(`startup_${startupId}`)) return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const fetchUserData = async () => {
    if (!currentUser) {
      return;
    }
    setLoading(true);
    setHasAttemptedLoad(false);
    try {
      if (type === "startup" && id) {
        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);
        let startupFound = false;
        let startupData = null;
        let ownerId = null;

        for (const userDoc of usersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${id}`
          );
          const startupSnap = await getDoc(startupRef);

          if (startupSnap.exists()) {
            const isBlocked = await isUserOrStartupsBlocked(
              currentUser.uid,
              userDoc.id
            );
            if (isBlocked) {
              setPosts([]);
              setLoading(false);
              setHasAttemptedLoad(true);
              return;
            }

            startupFound = true;
            startupData = startupSnap.data();
            ownerId = userDoc.id;
            break;
          }
        }

        if (!startupFound) {
          setLoading(false);
          return;
        }

        const postsRef = collection(
          firestore,
          `users/${ownerId}/startups/${id}/posts`
        );
        const q = query(postsRef, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);

        const fetchedPosts = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const postData = doc.data();
            const mediaUrl = await getMediaUrl(postData.content);

            return {
              id: doc.id,
              ...postData,
              user: {
                id: `startup_${id}`,
                firstName: startupData.startupName,
                lastName: "",
                profileImage: startupData.startupImage || profileImage,
                bio: startupData.bio,
                link: startupData.link,
                linkText: startupData.linkText,
                isStartup: true,
                startupId: id,
                startupOwnerId: ownerId,
                startupName: startupData.startupName,
                startupUrlSlug: startupData.startupUrlSlug,
              },
              content: {
                ...postData.content,
                url: mediaUrl || postData.content?.url,
              },
              reactions: postData.reactions || {},
              reposts: postData.reposts || {},
              commentCount: postData.commentCount || 0,
              connectionType: "Startup",
            };
          })
        );

        setPosts(fetchedPosts);
        setUserData({
          id: `startup_${id}`,
          ...startupData,
          ownerId,
        });
      } else {
        let userId;
        if (urlSlug === "me") {
          if (!auth.currentUser) {
            setLoading(false);
            return;
          }
          userId = auth.currentUser.uid;
        } else {
          const usersRef = collection(firestore, "users");
          const q = query(usersRef, where("urlSlug", "==", urlSlug));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            userId = querySnapshot.docs[0].id;

            const isBlocked = await isUserOrStartupsBlocked(
              currentUser.uid,
              userId
            );
            if (isBlocked) {
              setPosts([]);
              setLoading(false);
              setHasAttemptedLoad(true);
              return;
            }
          } else {
            setLoading(false);
            return;
          }
        }

        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          setUserData({ id: userId, ...userDoc.data() });
        } else {
          setLoading(false);
          return;
        }

        const postsRef = collection(firestore, `users/${userId}/posts`);
        const q = query(postsRef, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);

        const fetchedPosts = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const postData = doc.data();
            let mediaUrl = null;

            if (
              postData.content &&
              postData.content.type &&
              postData.content.url
            ) {
              if (Array.isArray(postData.content.url)) {
                mediaUrl = await Promise.all(
                  postData.content.url.map(async (url) => {
                    try {
                      const storageRef = ref(storage, url);
                      return await getDownloadURL(storageRef);
                    } catch (error) {
                      return null;
                    }
                  })
                );
              } else {
                try {
                  const storageRef = ref(storage, postData.content.url);
                  mediaUrl = await getDownloadURL(storageRef);
                } catch (error) {}
              }
            }

            let connectionType = "Extended";
            if (auth.currentUser) {
              connectionType = await checkConnectionType(
                auth.currentUser.uid,
                userId
              );
            }

            await fetchComments(doc.id, userId);

            return {
              id: doc.id,
              ...postData,
              user: {
                id: userId,
                firstName: userDoc.data().firstName,
                lastName: userDoc.data().lastName,
                profileImage: userDoc.data().profileImage || profileImage,
                bio: userDoc.data().bio,
                link: userDoc.data().link,
                linkText: userDoc.data().linkText,
              },
              content: {
                ...postData.content,
                url: mediaUrl || postData.content?.url,
              },
              reactions: postData.reactions || {},
              reposts: postData.reposts || {},
              commentCount: postData.commentCount || 0,
              connectionType: connectionType,
            };
          })
        );

        setPosts(fetchedPosts);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      setHasAttemptedLoad(true);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchUserData();
    }
  }, [urlSlug, currentUser]); // Add currentUser to dependencies

  const handleRefresh = () => {
    setHasAttemptedLoad(false);
    setLoading(true);
    fetchUserData();
  };

  const checkConnectionType = async (currentUserId, targetUserId) => {
    if (currentUserId === targetUserId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${currentUserId}/connections/${targetUserId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    const userConnectionsRef = collection(
      firestore,
      `users/${currentUserId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${targetUserId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const fetchComments = async (postId, postUserId) => {
    const post = posts.find((p) => p.id === postId);

    if (!postId || !post || !currentUser) {
      return;
    }

    try {
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${postId}/comments`
      );
      const q = query(commentsRef, orderBy("createdAt", "asc"));
      const snapshot = await getDocs(q);

      const fetchedComments = await Promise.all(
        snapshot.docs.map(async (docSnapshot) => {
          const commentData = docSnapshot.data();

          if (commentData.isStartup) {
            const isBlocked = await isUserOrStartupsBlocked(
              currentUser.uid,
              commentData.startupOwnerId
            );
            if (isBlocked) return null;

            const startupRef = doc(
              firestore,
              `users/${commentData.startupOwnerId}/startups/${commentData.startupId}`
            );
            const startupSnapshot = await getDoc(startupRef);
            const startupData = startupSnapshot.exists()
              ? startupSnapshot.data()
              : null;

            if (!startupData) {
              return null;
            }

            return {
              id: docSnapshot.id,
              ...commentData,
              userName: startupData.startupName,
              userImage: startupData.startupImage || profileImage,
              connectionType: "Startup",
              bio: startupData.bio || "",
              link: startupData.link || null,
              linkText: startupData.linkText || null,
              postId,
              postUserId: post.user.id,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
              startupUrlSlug: startupData.startupUrlSlug,
              isStartup: true,
            };
          } else {
            const isBlocked = await isUserOrStartupsBlocked(
              currentUser.uid,
              commentData.userId
            );
            if (isBlocked) return null;

            const userDoc = await getDoc(
              doc(firestore, "users", commentData.userId)
            );
            if (!userDoc.exists()) {
              return null;
            }

            const userData = userDoc.data();
            let connectionType = "Unknown";
            try {
              connectionType = await checkConnectionType(
                currentUser.uid,
                commentData.userId
              );
            } catch (error) {}

            return {
              id: docSnapshot.id,
              ...commentData,
              userName: `${userData.firstName} ${userData.lastName}`,
              userImage: userData.profileImage || profileImage,
              connectionType,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              postId,
              postUserId: post.user.id,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
              urlSlug: userData.urlSlug,
              isStartup: false,
            };
          }
        })
      );

      const validComments = fetchedComments.filter(
        (comment) => comment !== null
      );

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: validComments,
      }));

      setVisibleComments((prev) => ({
        ...prev,
        [postId]: 3,
      }));

      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId ? { ...p, commentCount: validComments.length } : p
        )
      );
    } catch (error) {
      setErrorPopup({
        message: "Error loading comments.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (posts.length > 0 && currentUser) {
      posts.forEach((post) => {
        if (!comments[post.id]) {
          fetchComments(post.id, post.user.id);
        }
      });
    }
  }, [posts, currentUser, fetchComments, checkConnectionType]);

  // Function to handle adding a comment

  // Update this in AllPosts.js:
  const handleAddComment = async (
    postId,
    parentCommentId = null,
    commentText,
    mentionedUsers = {}
  ) => {
    if (!currentUser) return;

    console.log("Starting handleAddComment with:", {
      postId,
      parentCommentId,
      commentText,
      mentionedUsers,
    });

    if (!commentText || !commentText.trim()) {
      return;
    }

    const targetPost = posts.find((p) => p.id === postId);
    if (!targetPost) return;

    try {
      const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
      if (!userDoc.exists()) {
        throw new Error("User data not found");
      }
      const userData = userDoc.data();

      const commenterInfo = getCommenterInfo(
        { ...currentUser, ...userData },
        selectedStartup
      );
      console.log("Commenter info:", commenterInfo);

      const processedCommentText = convertUrlsToLinks(commentText.trim());

      const commentsPath = targetPost.user.isStartup
        ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/posts/${targetPost.id}/comments`
        : `users/${targetPost.user.id}/posts/${targetPost.id}/comments`;
      const commentsRef = collection(firestore, commentsPath);

      const postRef = getPostRef(targetPost);
      const postSnap = await getDoc(postRef);

      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();
      const rootCommentId = parentCommentId
        ? await getRootCommentId(targetPost, targetPost.id, parentCommentId)
        : null;

      const now = new Date();
      const commentData = {
        text: processedCommentText,
        userId: commenterInfo.id,
        userName: commenterInfo.name,
        userImage: commenterInfo.image,
        createdAt: now,
        parentCommentId: parentCommentId,
        rootCommentId: rootCommentId,
        isRootComment: !parentCommentId,
        bio: commenterInfo.bio,
        connectionType: commenterInfo.connectionType,
        link: commenterInfo.link,
        linkText: commenterInfo.linkText,
        postUserId: targetPost.user.id,
        isStartup: commenterInfo.isStartup,
        urlSlug: userData.urlSlug,
        ...(commenterInfo.isStartup && {
          startupId: commenterInfo.startupId,
          startupOwnerId: commenterInfo.ownerId,
          startupUrlSlug: selectedStartup.startupUrlSlug,
        }),
      };

      const newCommentRef = await addDoc(commentsRef, {
        ...commentData,
        createdAt: serverTimestamp(),
      });

      await updateDoc(postRef, {
        commentCount: increment(1),
        [`comments.${commenterInfo.id}`]: serverTimestamp(),
        originalDirectCommentTime: serverTimestamp(),
      });

      const commenterPath = commenterInfo.isStartup
        ? `users/${currentUser.uid}/startups/${commenterInfo.startupId}`
        : `users/${currentUser.uid}`;

      const commentedPostRef = doc(
        firestore,
        `${commenterPath}/commentedPosts/${targetPost.id}`
      );
      const commentedPostDoc = await getDoc(commentedPostRef);

      const newCommentData = {
        commentId: newCommentRef.id,
        commentedAt: now,
        commentText: processedCommentText,
        originalPosterId: targetPost.user.startupOwnerId || targetPost.user.id,
        isStartup: commenterInfo.isStartup,
      };

      if (commentedPostDoc.exists()) {
        await updateDoc(commentedPostRef, {
          comments: {
            ...(commentedPostDoc.data().comments || {}),
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      } else {
        await setDoc(commentedPostRef, {
          originalPosterId:
            targetPost.user.startupOwnerId || targetPost.user.id,
          postId: targetPost.id,
          comments: {
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      }

      const batch = writeBatch(firestore);
      const shouldNotifyPostOwner =
        !parentCommentId &&
        ((targetPost.user.isStartup &&
          currentUser.uid !== targetPost.user.startupOwnerId &&
          (!commenterInfo.isStartup ||
            commenterInfo.ownerId !== targetPost.user.startupOwnerId)) ||
          (!targetPost.user.isStartup &&
            currentUser.uid !== targetPost.user.id &&
            (!commenterInfo.isStartup ||
              commenterInfo.ownerId !== targetPost.user.id)));

      if (shouldNotifyPostOwner) {
        const postOwnerNotificationRef = doc(
          firestore,
          `users/${
            targetPost.user.startupOwnerId || targetPost.user.id
          }/notifications`,
          `comment_${newCommentRef.id}`
        );

        const notificationData = {
          type: "comment",
          postId: targetPost.id,
          commentId: newCommentRef.id,
          commenterId: commenterInfo.id,
          commenterName: commenterInfo.name,
          commenterImage: commenterInfo.image,
          commentText: commentText,
          createdAt: serverTimestamp(),
          isNew: true,
          postPreview: {
            text: postData.text || "",
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            fileName: postData.content?.fileName || null,
          },
          ...(commenterInfo.isStartup && {
            isStartup: true,
            startupId: selectedStartup.id,
            startupOwnerId: currentUser.uid,
            startupImage: selectedStartup.startupImage,
            startupName: selectedStartup.startupName,
          }),
        };

        batch.set(postOwnerNotificationRef, notificationData);
      }

      // Handle notifications for mentions and replies
      if (parentCommentId) {
        console.log("Processing reply with parentCommentId:", parentCommentId);
        const parentComment = comments[postId].find(
          (c) => c.id === parentCommentId
        );
        console.log("Found parent comment:", parentComment);
        console.log("Mentioned Users in reply:", mentionedUsers);

        // Handle mentions in replies
        for (const [_, userData] of Object.entries(mentionedUsers)) {
          console.log("Processing mention for user:", userData);

          // Add checks for self-mentions and owned content
          const isSelfMention = userData.id === currentUser.uid;
          const isOwnedStartup =
            userData.isStartup && userData.startupOwnerId === currentUser.uid;
          const isOwnedCommunity =
            userData.isCommunity &&
            userData.communityOwnerId === currentUser.uid;
          const isOwnedEvent =
            userData.isEvent && userData.eventOwnerId === currentUser.uid;

          // Skip if it's a self-mention or owned content
          if (
            isSelfMention ||
            isOwnedStartup ||
            isOwnedCommunity ||
            isOwnedEvent
          )
            continue;

          const recipientId = userData.isCommunity
            ? userData.communityOwnerId
            : userData.isStartup
            ? userData.startupOwnerId
            : userData.isEvent
            ? userData.eventOwnerId
            : userData.id;

          const mentionNotificationRef = doc(
            collection(firestore, `users/${recipientId}/notifications`)
          );
          console.log(
            "Creating mention notification for:",
            userData.isCommunity
              ? "community owner"
              : userData.isStartup
              ? "startup owner"
              : userData.isEvent
              ? "event owner"
              : "user",
            recipientId
          );

          batch.set(mentionNotificationRef, {
            type: "replyMention",
            from: commenterInfo.id,
            fromUserName: commenterInfo.name,
            fromUserImage: commenterInfo.image,
            postId: targetPost.id,
            commentId: newCommentRef.id,
            parentCommentId: parentCommentId,
            replyText: commentText,
            createdAt: serverTimestamp(),
            isNew: true,
            parentCommentPreview: {
              text: parentComment.text || "",
            },
            postPreview: {
              text: postData.text || "",
              mediaType: postData.content?.type || null,
              mediaUrl: postData.content?.url || null,
              fileName: postData.content?.fileName || null,
            },
            ...(commenterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
              startupName: selectedStartup.startupName,
            }),
          });
        }

        // Handle notification for parent comment author
        const parentCommentRef = doc(commentsRef, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);
        const parentCommentData = parentCommentSnap.exists()
          ? parentCommentSnap.data()
          : null;

        const shouldNotifyParentCommenter =
          parentCommentData &&
          ((parentCommentData.isStartup &&
            currentUser.uid !== parentCommentData.startupOwnerId &&
            (!commenterInfo.isStartup ||
              commenterInfo.ownerId !== parentCommentData.startupOwnerId)) ||
            (!parentCommentData.isStartup &&
              currentUser.uid !== parentCommentData.userId &&
              (!commenterInfo.isStartup ||
                commenterInfo.ownerId !== parentCommentData.userId)));

        if (shouldNotifyParentCommenter) {
          const replyNotificationRef = doc(
            firestore,
            `users/${
              parentCommentData.isStartup
                ? parentCommentData.startupOwnerId
                : parentCommentData.userId
            }/notifications`,
            `reply_${newCommentRef.id}`
          );

          const replyNotificationData = {
            type: "reply",
            postId: targetPost.id,
            commentId: newCommentRef.id,
            parentCommentId: parentCommentId,
            replierId: commenterInfo.id,
            replierName: commenterInfo.name,
            replierImage: commenterInfo.image,
            replyText: commentText,
            createdAt: serverTimestamp(),
            isNew: true,
            parentCommentPreview: {
              text: parentCommentData.text || "",
            },
            postPreview: {
              text: postData.text || "",
              mediaType: postData.content?.type || null,
              mediaUrl: postData.content?.url || null,
              fileName: postData.content?.fileName || null,
            },
            ...(commenterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          batch.set(replyNotificationRef, replyNotificationData);
        }
      } else {
        // Handle mentions in regular comments
        console.log("Processing mentions in regular comment:", mentionedUsers);
        // Handle notifications for mentions in regular comments
        for (const [_, userData] of Object.entries(mentionedUsers)) {
          // Add checks for self-mentions and owned content
          const isSelfMention = userData.id === currentUser.uid;
          const isOwnedStartup =
            userData.isStartup && userData.startupOwnerId === currentUser.uid;
          const isOwnedCommunity =
            userData.isCommunity &&
            userData.communityOwnerId === currentUser.uid;
          const isOwnedEvent =
            userData.isEvent && userData.eventOwnerId === currentUser.uid;

          // Skip if it's a self-mention or owned content
          if (
            isSelfMention ||
            isOwnedStartup ||
            isOwnedCommunity ||
            isOwnedEvent
          )
            continue;

          const mentionNotificationRef = doc(
            collection(
              firestore,
              `users/${
                userData.isCommunity
                  ? userData.communityOwnerId
                  : userData.isStartup
                  ? userData.startupOwnerId
                  : userData.isEvent
                  ? userData.eventOwnerId
                  : userData.id
              }/notifications`
            )
          );

          batch.set(mentionNotificationRef, {
            type: "commentMention",
            from: currentUser.uid,
            fromUserName: commenterInfo.name,
            fromUserImage: commenterInfo.image,
            postId: targetPost.id,
            commentId: newCommentRef.id,
            commentText: commentText,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: postData.text || "",
              mediaType: postData.content?.type || null,
              mediaUrl: postData.content?.url || null,
              fileName: postData.content?.fileName || null,
            },
            ...(commenterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
              startupName: selectedStartup.startupName,
            }),
            ...(userData.isStartup && {
              mentionedStartupId: userData.startupId,
              mentionedStartupOwnerId: userData.startupOwnerId,
            }),
            ...(userData.isCommunity && {
              mentionedCommunityId: userData.communityId,
              mentionedCommunityOwnerId: userData.communityOwnerId,
            }),
            ...(userData.isEvent && {
              mentionedEventId: userData.eventId,
              mentionedEventOwnerId: userData.eventOwnerId,
            }),
          });
        }
      }

      await batch.commit();

      const newCommentObject = {
        id: newCommentRef.id,
        ...commentData,
        postId: targetPost.id,
        postUserId: targetPost.user.id,
        bio: commenterInfo.isStartup
          ? selectedStartup.bio || ""
          : userData.bio || "",
        link: commenterInfo.isStartup
          ? selectedStartup.link || null
          : userData.link || null,
        linkText: commenterInfo.isStartup
          ? selectedStartup.linkText || null
          : userData.linkText || null,
        userName: commenterInfo.isStartup
          ? selectedStartup.startupName
          : `${userData.firstName} ${userData.lastName}`,
        userImage: commenterInfo.isStartup
          ? selectedStartup.startupImage
          : userData.profileImage || profileImage,
        urlSlug: commenterInfo.isStartup
          ? selectedStartup.startupUrlSlug
          : userData.urlSlug,
        isStartup: commenterInfo.isStartup,
        ...(commenterInfo.isStartup && {
          startupId: selectedStartup.id,
          startupOwnerId: currentUser.uid,
        }),
      };

      setComments((prevComments) => ({
        ...prevComments,
        [targetPost.id]: [
          ...(prevComments[targetPost.id] || []),
          newCommentObject,
        ],
      }));

      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === targetPost.id
            ? {
                ...p,
                commentCount: (p.commentCount || 0) + 1,
                comments: {
                  ...(p.comments || {}),
                  [commenterInfo.id]: now,
                },
                originalDirectCommentTime: now,
              }
            : p
        )
      );

      if (parentCommentId) {
        setReplyComments((prev) => ({
          ...prev,
          [targetPost.id]: {
            ...(prev[targetPost.id] || {}),
            [parentCommentId]: "",
          },
        }));
      } else {
        setNewComment((prev) => ({ ...prev, [targetPost.id]: "" }));
        if (commentInputRef.current[targetPost.id]) {
          commentInputRef.current[targetPost.id].style.height = "auto";
        }
      }
      setActiveCommentId((prev) => ({ ...prev, [targetPost.id]: null }));
    } catch (error) {
      console.error("Error in handleAddComment:", error);
      setErrorPopup({
        message:
          error.message === "Post not found"
            ? "This post has been deleted."
            : "An error occurred while adding the comment.",
        isError: true,
      });
    }
  };

  // Function to get the root comment ID
  const getRootCommentId = async (post, postId, commentId) => {
    try {
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }
      const commentData = commentSnap.data();

      if (commentData.isRootComment || !commentData.parentCommentId) {
        return commentId;
      }

      return getRootCommentId(post, postId, commentData.parentCommentId);
    } catch (error) {
      throw error;
    }
  };

  // Function to handle comment input change
  const handleCommentInputChange = (e, postId) => {
    const textarea = e.target;
    setNewComment((prev) => ({ ...prev, [postId]: textarea.value }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  // Function to handle reply input change
  const handleReplyInputChange = (e, postId, replyId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [replyId]: textarea.value,
      },
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  // Add the isReplyButtonDisabled function here
  const isReplyButtonDisabled = (postId, replyId) => {
    const replyText = replyComments[postId]?.[replyId];
    return !replyText || !replyText.trim();
  };

  // Function to handle reply button click
  const handleReplyButton = useCallback(
    (postId, commentId) => {
      setActiveCommentId((prev) => ({
        ...prev,
        [postId]: prev[postId] === commentId ? null : commentId,
      }));

      // Reset any existing reply input
      setReplyComments((prev) => ({
        ...prev,
        [postId]: {
          ...(prev[postId] || {}),
          [commentId]: "",
        },
      }));

      setTimeout(() => {
        if (replyInputRef.current[postId]?.[commentId]) {
          const inputElement = replyInputRef.current[postId][commentId];
          inputElement.focus();
          inputElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });

          if (isIOS) {
            forceKeyboardOpen(inputElement);
          }
        }
      }, 100);
    },
    [isIOS]
  );

  // Function to force open the keyboard on iOS
  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  // Function to handle loading more comments
  const handleLoadMoreComments = (postId) => {
    setVisibleComments((prev) => {
      const currentVisible = prev[postId] || 3;
      const increment = currentVisible === 3 ? 6 : (currentVisible - 3) * 2;
      return {
        ...prev,
        [postId]: currentVisible + increment,
      };
    });
  };

  // Add this useEffect
  useEffect(() => {
    // Detect if the device is running iOS
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  // Function to handle viewing more replies
  const handleViewMoreReplies = (postId, rootCommentId) => {
    setVisibleReplies((prev) => {
      const currentVisible = prev[postId]?.[rootCommentId] || 0;
      const increment = currentVisible === 0 ? 3 : currentVisible * 2;
      return {
        ...prev,
        [postId]: {
          ...(prev[postId] || {}),
          [rootCommentId]: currentVisible + increment,
        },
      };
    });
  };

  // Function to toggle comments visibility
  const toggleComments = (postId) => {
    setShowComments((prev) => {
      const newState = {
        ...prev,
        [postId]: !prev[postId],
      };

      return newState;
    });

    // Fetch comments if they haven't been loaded yet
    if (!comments[postId]) {
      fetchComments(postId);
    }
  };

  // Function to toggle comment expansion
  const toggleCommentExpansion = (postId, commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: !prev[postId]?.[commentId],
      },
    }));
  };

  // Function to toggle reply expansion
  const toggleReplyExpansion = (postId, replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [replyId]: !prev[postId]?.[replyId],
      },
    }));
  };

  // Function to handle comment reaction click
  const handleCommentReactionClick = (postId, commentId) => {
    // Directly handle the thumbs up emoji instead of opening the picker
    handleCommentEmojiSelect(postId, commentId, "👍");
  };

  // Function to handle comment emoji selection
  const handleCommentEmojiSelect = async (postId, commentId, emoji) => {
    if (!currentUser || !commentId) return;

    try {
      const targetPost = posts.find((p) => p.id === postId);
      if (!targetPost) {
        throw new Error("Post not found");
      }

      const basePath = targetPost.user.isStartup
        ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}`
        : `users/${targetPost.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      let updatedReactions = { ...(commentData.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];

        const notificationRef = doc(
          firestore,
          `users/${
            commentData.isStartup
              ? commentData.startupOwnerId
              : commentData.userId
          }/notifications`,
          `comment_reaction_${commentId}_${reactorId}`
        );
        await deleteDoc(notificationRef);
      } else {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        const shouldNotify =
          (selectedStartup &&
            !(
              commentData.userId === currentUser.uid ||
              (commentData.isStartup &&
                commentData.startupOwnerId === currentUser.uid)
            )) ||
          (!selectedStartup &&
            (commentData.isStartup
              ? commentData.startupOwnerId !== currentUser.uid
              : commentData.userId !== currentUser.uid));

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );

          const notificationData = {
            type: "comment_reaction",
            postId: postId,
            commentId: commentId,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            commentText: commentData.text,
            isReply: !commentData.isRootComment,
            postPreview: {
              text: targetPost.text || "",
              mediaType: targetPost.content?.type || null,
              mediaUrl: targetPost.content?.url || null,
              fileName: targetPost.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
              startupName: selectedStartup.startupName,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, reactions: updatedReactions };
          }
          if (
            !comment.isRootComment &&
            comment.rootCommentId === commentData.rootCommentId
          ) {
            if (comment.id === commentId) {
              return { ...comment, reactions: updatedReactions };
            }
          }
          return comment;
        }),
      }));

      // Remove the following lines that were managing scroll position
      // setActiveEmojiPicker(null);
      // setTimeout(() => {
      //   window.scrollTo(0, commentEmojiPickerScrollPosition);
      // }, 0);
    } catch (error) {
      if (error.message === "Post not found") {
        setErrorPopup({
          message: "The post containing this comment has been deleted.",
          isError: true,
        });
      } else if (error.message === "Comment not found") {
        setErrorPopup({
          message: "This comment has been deleted.",
          isError: true,
        });
      } else {
        setErrorPopup({
          message: "An error occurred while updating the reaction.",
          isError: true,
        });
      }
    }
  };

  // Function to toggle comment dropdown
  const toggleCommentDropdown = (postId, commentId) => {
    setOpenCommentDropdown((prev) =>
      prev === `${postId}_${commentId}` ? null : `${postId}_${commentId}`
    );
  };

  // Function to handle comment deletion
  const handleDeleteComment = async (postId, commentId) => {
    if (!currentUser) {
      return;
    }

    setDeletingCommentMessage({
      text: "Deleting comment...",
      isLoading: true,
    });

    try {
      const targetPost = posts.find((p) => p.id === postId);
      if (!targetPost) {
        setErrorPopup({
          message: "The post containing this comment has been deleted.",
          isError: true,
        });
        return;
      }

      // Get the correct base path based on whether it's a startup post
      const basePath = targetPost.user.isStartup
        ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}`
        : `users/${targetPost.user.id}`;

      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${postId}/comments`
      );
      const commentToDeleteRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentToDeleteRef);

      if (!commentSnap.exists()) {
        setErrorPopup({
          message: "This comment has already been deleted.",
          isError: true,
        });
        return;
      }

      const commentData = commentSnap.data();
      let canDelete = false;

      if (commentData.userId.startsWith("startup_")) {
        const startupId = commentData.userId.replace("startup_", "");
        canDelete =
          commentData.startupOwnerId === currentUser.uid ||
          (targetPost.user.isStartup
            ? currentUser.uid === targetPost.user.startupOwnerId
            : currentUser.uid === targetPost.user.id);
      } else {
        canDelete =
          currentUser.uid === commentData.userId ||
          (targetPost.user.isStartup
            ? currentUser.uid === targetPost.user.startupOwnerId
            : currentUser.uid === targetPost.user.id);
      }

      if (!canDelete) {
        setErrorPopup({
          message: "You don't have permission to delete this comment.",
          isError: true,
        });
        return;
      }

      let deletedCount = 0;
      const deletedCommentIds = new Set();
      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      const deleteStartupNotifications = async (commentData, commentRef) => {
        try {
          if (commentData.userId.startsWith("startup_")) {
            const startupId = commentData.userId.replace("startup_", "");

            const replyNotificationsQuery = query(
              collection(
                firestore,
                `users/${commentData.startupOwnerId}/notifications`
              ),
              where("parentCommentId", "==", commentRef.id)
            );

            const replyNotificationsSnapshot = await getDocs(
              replyNotificationsQuery
            );
            const deletePromises = replyNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            );
            await Promise.all(deletePromises);

            const commentNotificationRef = doc(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`,
              `comment_${commentRef.id}`
            );
            await deleteDoc(commentNotificationRef);
          }

          if (commentData.parentCommentId) {
            const parentCommentRef = doc(
              commentsRef,
              commentData.parentCommentId
            );
            const parentCommentSnap = await getDoc(parentCommentRef);

            if (parentCommentSnap.exists()) {
              const parentCommentData = parentCommentSnap.data();
              if (parentCommentData.userId.startsWith("startup_")) {
                const replyNotificationRef = doc(
                  firestore,
                  `users/${parentCommentData.startupOwnerId}/notifications`,
                  `reply_${commentRef.id}`
                );
                await deleteDoc(replyNotificationRef);
              }
            }
          }
        } catch (error) {}
      };

      const deleteCommentsAndReplies = async (commentRef) => {
        const commentSnapshot = await getDoc(commentRef);
        if (!commentSnapshot.exists()) return;

        const commentData = commentSnapshot.data();
        deletedCount++;
        deletedCommentIds.add(commentRef.id);

        if (commentData.userId.startsWith("startup_")) {
          const startupId = commentData.userId.replace("startup_", "");
          affectedStartupIds.add(startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        await deleteStartupNotifications(commentData, commentRef);

        // Delete comment notifications for post owner
        if (commentData.userId !== targetPost.user.id) {
          await deleteDoc(
            doc(
              firestore,
              `users/${
                targetPost.user.startupOwnerId || targetPost.user.id
              }/notifications/comment_${commentRef.id}`
            )
          );
        }

        // Delete reply notifications
        if (commentData.parentCommentId) {
          const parentCommentRef = doc(
            commentsRef,
            commentData.parentCommentId
          );
          const parentCommentSnap = await getDoc(parentCommentRef);
          if (parentCommentSnap.exists()) {
            const parentCommentData = parentCommentSnap.data();
            if (
              parentCommentData.userId !== commentData.userId &&
              !parentCommentData.userId.startsWith("startup_")
            ) {
              await deleteDoc(
                doc(
                  firestore,
                  `users/${parentCommentData.userId}/notifications/reply_${commentRef.id}`
                )
              );
            }
          }
        }

        // Delete reaction notifications
        const reactionPromises = [];
        if (commentData.isStartup) {
          const startupOwnerReactionsQuery = query(
            collection(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`
            ),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const startupOwnerReactionsSnapshot = await getDocs(
            startupOwnerReactionsQuery
          );
          reactionPromises.push(
            ...startupOwnerReactionsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            )
          );
        }

        // Delete all reaction notifications from users and startups
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          // Handle user reactions
          const userReactionNotificationsQuery = query(
            collection(firestore, `users/${userDoc.id}/notifications`),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const userReactionNotificationsSnapshot = await getDocs(
            userReactionNotificationsQuery
          );
          reactionPromises.push(
            ...userReactionNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            )
          );

          // Handle startup reactions
          const userStartupsSnapshot = await getDocs(
            collection(firestore, `users/${userDoc.id}/startups`)
          );
          for (const startupDoc of userStartupsSnapshot.docs) {
            const startupId = startupDoc.id;
            const startupReactionNotificationsQuery = query(
              collection(firestore, `users/${userDoc.id}/notifications`),
              where("type", "==", "comment_reaction"),
              where("commentId", "==", commentRef.id),
              where("reactorId", "==", `startup_${startupId}`)
            );
            const startupReactionNotificationsSnapshot = await getDocs(
              startupReactionNotificationsQuery
            );
            reactionPromises.push(
              ...startupReactionNotificationsSnapshot.docs.map((doc) =>
                deleteDoc(doc.ref)
              )
            );
          }
        }
        await Promise.all(reactionPromises);

        // Delete replies
        const repliesQuery = query(
          commentsRef,
          where("parentCommentId", "==", commentRef.id)
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        await Promise.all(
          repliesSnapshot.docs.map((doc) => deleteCommentsAndReplies(doc.ref))
        );

        await deleteDoc(commentRef);
      };

      await deleteCommentsAndReplies(commentToDeleteRef);

      const batch = writeBatch(firestore);

      // Update post data
      const postRef = getPostRef(targetPost);
      batch.update(postRef, {
        commentCount: increment(-deletedCount),
      });

      for (const userId of affectedUserIds) {
        batch.update(postRef, {
          [`comments.${userId}`]: deleteField(),
        });
      }
      for (const startupId of affectedStartupIds) {
        batch.update(postRef, {
          [`comments.startup_${startupId}`]: deleteField(),
        });
      }

      // Update commentedPosts for users
      for (const userId of affectedUserIds) {
        const commentedPostRef = doc(
          firestore,
          `users/${userId}/commentedPosts/${postId}`
        );
        const commentedPostSnap = await getDoc(commentedPostRef);

        if (commentedPostSnap.exists()) {
          const commentedPostData = commentedPostSnap.data();
          const updatedComments = { ...commentedPostData.comments };

          deletedCommentIds.forEach((commentId) => {
            delete updatedComments[commentId];
          });

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(commentedPostRef);
          } else {
            batch.update(commentedPostRef, { comments: updatedComments });
          }
        }
      }

      // Update commentedPosts for startups
      for (const startupId of affectedStartupIds) {
        const usersSnapshot = await getDocs(collection(firestore, "users"));

        for (const userDoc of usersSnapshot.docs) {
          try {
            const startupCommentedPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/commentedPosts/${postId}`
            );
            const startupCommentedPostSnap = await getDoc(
              startupCommentedPostRef
            );

            if (startupCommentedPostSnap.exists()) {
              const startupCommentedPostData = startupCommentedPostSnap.data();
              const updatedComments = { ...startupCommentedPostData.comments };

              deletedCommentIds.forEach((commentId) => {
                delete updatedComments[commentId];
              });

              if (Object.keys(updatedComments).length === 0) {
                batch.delete(startupCommentedPostRef);
              } else {
                batch.update(startupCommentedPostRef, {
                  comments: updatedComments,
                });
              }
            }
          } catch (error) {}
        }
      }

      await batch.commit();

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].filter(
          (c) =>
            !deletedCommentIds.has(c.id) &&
            !deletedCommentIds.has(c.rootCommentId)
        ),
      }));

      setPosts((prevPosts) =>
        prevPosts.map((p) => {
          if (p.id === postId) {
            const updatedPost = {
              ...p,
              commentCount: Math.max((p.commentCount || 0) - deletedCount, 0),
            };
            const updatedComments = { ...updatedPost.comments };
            affectedUserIds.forEach((userId) => {
              delete updatedComments[userId];
            });
            affectedStartupIds.forEach((startupId) => {
              delete updatedComments[`startup_${startupId}`];
            });
            updatedPost.comments = updatedComments;
            return updatedPost;
          }
          return p;
        })
      );

      setDeletingCommentMessage({
        text: "Comment deleted",
        isLoading: false,
      });

      setOpenCommentDropdown(null);
    } catch (error) {
      setErrorPopup({
        message: "An error occurred while deleting the comment.",
        isError: true,
      });
    } finally {
      // If there was an error, clear the "Deleting..." message
      if (deletingCommentMessage?.isLoading) {
        setDeletingCommentMessage(null);
      }
    }
  };

  // Function to render comment text
  const renderCommentText = (postId, comment) => {
    if (!comment || typeof comment.text !== "string") return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <div className={classes.textareaWrapper}>
            <textarea
              ref={editTextareaRef}
              value={editCommentText}
              onChange={(e) => {
                handleEditTextareaChange(e);
                const textarea = e.target;
                const cursorPosition = textarea.selectionStart;
                const textBeforeCursor = textarea.value.slice(
                  0,
                  cursorPosition
                );
                const match = /@(\w*)$/.exec(textBeforeCursor);

                setReplyMentionStates((prev) => ({
                  ...prev,
                  [`edit_${comment.id}`]: {
                    ...prev[`edit_${comment.id}`],
                    showMentions: !!match,
                    mentionSearch: match ? match[1] : "",
                  },
                }));
              }}
              onFocus={handleEditTextareaFocus}
              className={classes.replyInput}
            />
            <div
              className={classes.textOverlay}
              dangerouslySetInnerHTML={{
                __html: (() => {
                  const text = editCommentText;
                  let styledText = text;
                  const mentionedUsers =
                    replyMentionStates[`edit_${comment.id}`]?.mentionedUsers ||
                    {};

                  Object.entries(mentionedUsers).forEach(([name, user]) => {
                    const escapedName = name.replace(
                      /[.*+?^${}()|[\]\\]/g,
                      "\\$&"
                    );
                    const regex = new RegExp(escapedName, "g");
                    styledText = styledText.replace(
                      regex,
                      `<span class="${classes.mention}">${name}</span>`
                    );
                  });
                  return styledText;
                })(),
              }}
            />
            {replyMentionStates[`edit_${comment.id}`]?.showMentions && (
              <MentionDropdown
                searchText={
                  replyMentionStates[`edit_${comment.id}`]?.mentionSearch || ""
                }
                inputElement={editTextareaRef.current}
                onSelectUser={(user) => {
                  const textarea = editTextareaRef.current;
                  if (textarea) {
                    const cursorPosition = textarea.selectionStart;
                    const textBeforeCursor = textarea.value.slice(
                      0,
                      cursorPosition
                    );
                    const textAfterCursor =
                      textarea.value.slice(cursorPosition);
                    const lastAtSymbol = textBeforeCursor.lastIndexOf("@");
                    if (lastAtSymbol !== -1) {
                      const newValue =
                        textBeforeCursor.slice(0, lastAtSymbol) +
                        `${user.firstName} ${user.lastName}` +
                        textAfterCursor;

                      setReplyMentionStates((prev) => ({
                        ...prev,
                        [`edit_${comment.id}`]: {
                          ...prev[`edit_${comment.id}`],
                          showMentions: false,
                          mentionedUsers: {
                            ...(prev[`edit_${comment.id}`]?.mentionedUsers ||
                              {}),
                            [`${user.firstName} ${user.lastName}`]: user,
                          },
                        },
                      }));

                      textarea.value = newValue;
                      textarea.style.height = "auto";
                      textarea.style.height = `${textarea.scrollHeight}px`;

                      setEditCommentText(newValue);
                    }
                  }
                }}
                onClose={() => {
                  setReplyMentionStates((prev) => ({
                    ...prev,
                    [`edit_${comment.id}`]: {
                      ...prev[`edit_${comment.id}`],
                      showMentions: false,
                    },
                  }));
                }}
              />
            )}
          </div>
          {renderEditButtons(postId, comment.id, false)}
        </div>
      );
    }

    const lines = comment.text.split("\n");
    const isLongComment = lines.length > 3 || comment.text.length > 125;

    const displayText =
      isLongComment && !expandedComments[postId]?.[comment.id]
        ? comment.text.slice(0, 125)
        : comment.text;

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongComment && !expandedComments[postId]?.[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleCommentExpansion(postId, comment.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  // Function to render reply text
  const renderReplyText = (postId, reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <div className={classes.editCommentSection}>
          <div className={classes.textareaWrapper}>
            <textarea
              ref={editTextareaRef}
              value={editCommentText}
              onChange={(e) => {
                handleEditTextareaChange(e);
                const textarea = e.target;
                const cursorPosition = textarea.selectionStart;
                const textBeforeCursor = textarea.value.slice(
                  0,
                  cursorPosition
                );
                const match = /@(\w*)$/.exec(textBeforeCursor);

                setReplyMentionStates((prev) => ({
                  ...prev,
                  [`edit_${reply.id}`]: {
                    ...prev[`edit_${reply.id}`],
                    showMentions: !!match,
                    mentionSearch: match ? match[1] : "",
                  },
                }));
              }}
              onFocus={handleEditTextareaFocus}
              className={classes.editReplyInput}
            />
            <div
              className={classes.textOverlay}
              dangerouslySetInnerHTML={{
                __html: (() => {
                  const text = editCommentText;
                  let styledText = text;
                  const mentionedUsers =
                    replyMentionStates[`edit_${reply.id}`]?.mentionedUsers ||
                    {};

                  Object.entries(mentionedUsers).forEach(([name, user]) => {
                    const escapedName = name.replace(
                      /[.*+?^${}()|[\]\\]/g,
                      "\\$&"
                    );
                    const regex = new RegExp(escapedName, "g");
                    styledText = styledText.replace(
                      regex,
                      `<span class="${classes.mention}">${name}</span>`
                    );
                  });
                  return styledText;
                })(),
              }}
            />
            {replyMentionStates[`edit_${reply.id}`]?.showMentions && (
              <MentionDropdown
                searchText={
                  replyMentionStates[`edit_${reply.id}`]?.mentionSearch || ""
                }
                inputElement={editTextareaRef.current}
                onSelectUser={(user) => {
                  const textarea = editTextareaRef.current;
                  if (textarea) {
                    const cursorPosition = textarea.selectionStart;
                    const textBeforeCursor = textarea.value.slice(
                      0,
                      cursorPosition
                    );
                    const textAfterCursor =
                      textarea.value.slice(cursorPosition);
                    const lastAtSymbol = textBeforeCursor.lastIndexOf("@");
                    if (lastAtSymbol !== -1) {
                      const newValue =
                        textBeforeCursor.slice(0, lastAtSymbol) +
                        `${user.firstName} ${user.lastName}` +
                        textAfterCursor;

                      setReplyMentionStates((prev) => ({
                        ...prev,
                        [`edit_${reply.id}`]: {
                          ...prev[`edit_${reply.id}`],
                          showMentions: false,
                          mentionedUsers: {
                            ...(prev[`edit_${reply.id}`]?.mentionedUsers || {}),
                            [`${user.firstName} ${user.lastName}`]: user,
                          },
                        },
                      }));

                      textarea.value = newValue;
                      textarea.style.height = "auto";
                      textarea.style.height = `${textarea.scrollHeight}px`;

                      setEditCommentText(newValue);
                    }
                  }
                }}
                onClose={() => {
                  setReplyMentionStates((prev) => ({
                    ...prev,
                    [`edit_${reply.id}`]: {
                      ...prev[`edit_${reply.id}`],
                      showMentions: false,
                    },
                  }));
                }}
              />
            )}
          </div>
          {renderEditButtons(postId, reply.id, true)}
        </div>
      );
    }

    const isLongReply = reply.text.length > 125;
    const displayText =
      isLongReply && !expandedReplies[postId]?.[reply.id]
        ? `${reply.text.slice(0, 125)}`
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[postId]?.[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(postId, reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  // Function to get top three emojis
  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  const fetchCurrentUserProfileImage = async (uid) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", uid));
      if (userDoc.exists()) {
        setCurrentUserProfileImage(userDoc.data().profileImage || profileImage);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 648);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observers = {};

    posts.forEach((post) => {
      if (post.content?.type === "video") {
        const video = videoRefs.current[post.id];
        if (!video) return;

        const handleTimeUpdate = () => {
          if (video && video.duration) {
            setTimeLeft((prev) => ({
              ...prev,
              [post.id]: video.duration - video.currentTime,
            }));
          }
        };

        const handleIntersection = (entries) => {
          entries.forEach((entry) => {
            if (video) {
              if (entry.isIntersecting) {
                video
                  .play()
                  .then(() =>
                    setIsPlaying((prev) => ({ ...prev, [post.id]: true }))
                  )
                  .catch((error) => {
                    console.log("Autoplay was prevented");
                    setIsPlaying((prev) => ({ ...prev, [post.id]: false }));
                  });
              } else {
                video.pause();
                setIsPlaying((prev) => ({ ...prev, [post.id]: false }));
              }
            }
          });
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        observers[post.id] = observer;

        video.addEventListener("timeupdate", handleTimeUpdate);
        observer.observe(video);

        if (video.readyState >= 2) {
          video
            .play()
            .then(() => setIsPlaying((prev) => ({ ...prev, [post.id]: true })))
            .catch((error) => console.log("Autoplay was prevented"));
        }
      }
    });

    return () => {
      // Cleanup
      posts.forEach((post) => {
        if (post.content?.type === "video") {
          const video = videoRefs.current[post.id];
          if (video) {
            video.removeEventListener("timeupdate", video.handleTimeUpdate);
            if (observers[post.id]) {
              observers[post.id].disconnect();
            }
          }
        }
      });
    };
  }, [posts]);

  const handlePlayPause = (e, postId) => {
    e.preventDefault();
    e.stopPropagation();

    const video = videoRefs.current[postId];
    if (video) {
      if (video.paused) {
        video
          .play()
          .then(() => setIsPlaying((prev) => ({ ...prev, [postId]: true })))
          .catch((error) => {
            console.log("Play was prevented:", error);
            setIsPlaying((prev) => ({ ...prev, [postId]: false }));
          });
      } else {
        video.pause();
        setIsPlaying((prev) => ({ ...prev, [postId]: false }));
      }
    }
  };

  const handleVolumeToggle = (e, postId) => {
    e.preventDefault();
    e.stopPropagation();

    if (videoRefs.current[postId]) {
      setVideoVolume((prev) => {
        const newState = { ...prev, [postId]: !prev[postId] };
        videoRefs.current[postId].muted = !newState[postId];
        return newState;
      });
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return "now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)}w`;
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}mo`;
    return `${Math.floor(diffInSeconds / 31536000)}y`;
  };

  const handleNavigate = async (target) => {
    if (!target) return;

    // Handle startup URLs directly
    if (target.startsWith("startup/")) {
      navigate(`/${target}`);
      return;
    }

    // If it looks like a URL slug (doesn't include any firebase ID characteristics), navigate directly
    if (!target.includes("/") && target.length < 30) {
      navigate(`/${target}`);
      return;
    }

    // Otherwise treat it as a user ID and look up their slug
    try {
      const userDoc = await getDoc(doc(firestore, "users", target));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        navigate(`/${userData.urlSlug}`);
      } else {
        setErrorPopup({
          message: "User not found.",
          isError: true,
        });
      }
    } catch (error) {
      setErrorPopup({
        message: "Error loading user profile.",
        isError: true,
      });
    }
  };

  const renderPostText = (post) => {
    if (!post || !post.text) return null;
    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasNoInteractionsAndNoMedia =
      !post.content &&
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    const hasPdfAttachment = post.content && post.content.type === "pdf";

    if (!isLongPost) {
      return (
        <div className={classes.postTextContainer}>
          <div
            className={`${classes.postText} ${classes.preserveFormatting}`}
            dangerouslySetInnerHTML={{
              __html: convertUrlsToLinks(post.text),
            }}
            onClick={(e) => handleLinkClick(e, post.id)}
          />
        </div>
      );
    }

    const displayText = expandedPosts[post.id]
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasNoInteractionsAndNoMedia || hasPdfAttachment
            ? classes.postTextContainerNoInteractions
            : ""
        }`}
      >
        <span
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{
            __html:
              convertUrlsToLinks(displayText) +
              (expandedPosts[post.id] ? "" : ""),
          }}
        />
        {!expandedPosts[post.id] && (
          <button
            className={classes.showMoreButton}
            onClick={() =>
              setExpandedPosts((prev) => ({ ...prev, [post.id]: true }))
            }
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const [currentImageIndex, setCurrentImageIndex] = useState({});

  const preloadImages = (urls) => {
    urls.forEach((url) => {
      if (url) {
        const img = new Image();
        img.src = url;
      }
    });
  };

  const handlePrevImage = (postId, urlLength, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) - 1 + urlLength) % urlLength,
    }));
  };

  const handleNextImage = (postId, totalImages, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) + 1) % totalImages,
    }));
  };

  const PDFPreview = React.memo(
    ({ content, hasInteractions, classes }) => {
      const getFileName = React.useCallback(() => {
        if (!content?.fileName) return "Unnamed PDF";
        const rawFileName = Array.isArray(content.fileName)
          ? content.fileName[0] || "Unnamed PDF"
          : content.fileName;
        return rawFileName.replace(/\.pdf$/i, "");
      }, [content?.fileName]);

      if (!content || !content.url) return null;

      return (
        <div className={classes.postContentWrapper}>
          <a
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              !hasInteractions ? classes.pdfPreviewNoInteractions : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <div className={classes.fileContainer}>
                <div className={classes.fileAbout}>
                  <div className={classes.fileName}>{getFileName()}</div>
                </div>
                <div className={classes.open}>
                  Open PDF
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.content?.url === nextProps.content?.url &&
        prevProps.content?.fileName === nextProps.content?.fileName &&
        prevProps.hasInteractions === nextProps.hasInteractions
      );
    }
  );

  const renderPostContent = (post) => {
    if (!post || !post.content) return null;

    switch (post.content.type) {
      case "image":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.imageCarousel}>
              {Array.isArray(post.content.url) ? (
                <div style={{ position: "relative" }}>
                  {post.content.url.length > 1 && (
                    <div className={classes.imageCounter}>
                      <div className={classes.imageCounterInner}>
                        {`${(currentImageIndex[post.id] || 0) + 1}/${
                          post.content.url.length
                        }`}
                      </div>
                    </div>
                  )}
                  <img
                    src={post.content.url[currentImageIndex[post.id] || 0]}
                    alt=""
                    className={classes.postContentImage}
                    loading="eager"
                    decoding="sync"
                  />
                  {post.content.url.length > 1 && (
                    <div className={classes.navigationButtons}>
                      <button
                        className={classes.navButton}
                        onClick={(e) =>
                          handlePrevImage(post.id, post.content.url.length, e)
                        }
                        aria-label="Previous image"
                      >
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m14 8-4 4 4 4"
                          />
                        </svg>
                      </button>
                      <button
                        className={classes.navButton}
                        onClick={(e) =>
                          handleNextImage(post.id, post.content.url.length, e)
                        }
                        aria-label="Next image"
                      >
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m10 16 4-4-4-4"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <img
                  src={post.content.url}
                  alt=""
                  className={classes.postContentImage}
                />
              )}
            </div>
          </div>
        );
      case "video":
        return (
          <div className={classes.videoContainer}>
            <div className={classes.videoTimeCounter}>
              <div className={classes.videoTimeCounterInner}>
                {timeLeft[post.id] > 0 ? formatTime(timeLeft[post.id]) : "0:00"}
              </div>
            </div>
            <video
              ref={(el) => (videoRefs.current[post.id] = el)}
              className={classes.postContentVideo}
              playsInline
              muted={!videoVolume[post.id]}
              loop
              controls={false}
              preload="auto"
              poster={post.content.thumbnail || ""}
              onPlay={() =>
                setIsPlaying((prev) => ({ ...prev, [post.id]: true }))
              }
              onPause={() =>
                setIsPlaying((prev) => ({ ...prev, [post.id]: false }))
              }
              onLoadedData={(e) =>
                setTimeLeft((prev) => ({
                  ...prev,
                  [post.id]: e.target.duration,
                }))
              }
              onTimeUpdate={(e) =>
                setTimeLeft((prev) => ({
                  ...prev,
                  [post.id]: e.target.duration - e.target.currentTime,
                }))
              }
            >
              <source src={post.content.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className={classes.videoControlsLeft}>
              <button
                type="button"
                className={classes.volumeControl}
                onClick={(e) => handlePlayPause(e, post.id)}
              >
                {isPlaying[post.id] ? (
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="white"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H8Zm7 0a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="white"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className={classes.videoControlsRight}>
              <button
                type="button"
                className={classes.volumeControl}
                onClick={(e) => handleVolumeToggle(e, post.id)}
              >
                {videoVolume[post.id] ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        );
      case "pdf":
        return post.content ? (
          <PDFPreview
            content={post.content}
            classes={classes}
            hasInteractions={true}
          />
        ) : null;
      default:
        return null;
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.play().catch((error) => console.log("Autoplay was prevented"));
        } else {
          entry.target.pause();
        }
      });
    }, options);

    Object.values(videoRefs.current).forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    return () => {
      Object.values(videoRefs.current).forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };
  }, [posts]);

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const CommentInputSection = ({
    currentUser,
    selectedStartup,
    newComment,
    onCommentChange,
    onSubmit,
    commentInputRef,
  }) => {
    const activeProfile = selectedStartup || currentUser;
    const profileImage = selectedStartup
      ? selectedStartup.startupImage
      : currentUserProfileImage;
    const navigateToProfile = () =>
      handleNavigate(
        selectedStartup
          ? `startup/${selectedStartup.startupUrlSlug}`
          : currentUser.uid
      );

    return (
      <div className={classes.commentInputSection}>
        <div className={classes.commentInputWrapper}>
          <div>
            <img
              src={profileImage}
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.commentUserImage}
              onClick={navigateToProfile}
            />
          </div>
          <textarea
            ref={commentInputRef}
            value={newComment}
            onChange={onCommentChange}
            placeholder={`Add a comment...`}
            className={classes.commentInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addCommentButton} ${
            !newComment?.trim() ? classes.disabled : ""
          }`}
        >
          Comment
        </div>
      </div>
    );
  };

  const CommentInput = React.memo(
    ({
      postId,
      onSubmit,
      currentUser,
      selectedStartup,
      currentUserProfileImage,
    }) => {
      const [inputValue, setInputValue] = useState("");
      const textareaRef = useRef(null);
      const {
        showMentions,
        mentionSearch,
        handleInput,
        handleSelectUser,
        setShowMentions,
        mentionedUsers,
      } = useMentions(textareaRef);

      const handleImageClick = () => {
        if (selectedStartup) {
          navigate(`/startup/${selectedStartup.startupUrlSlug}`);
        } else {
          navigate(`/${currentUser.urlSlug}`);
        }
      };

      const handleChange = (e) => {
        const textarea = e.target;
        setInputValue(textarea.value);
        handleInput(e);
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      const handleUserSelect = (user) => {
        console.log("Reply - User selected:", user);
        console.log("Current mentionedUsers state:", mentionedUsers);
        // No need to track mentionedUsers here anymore - the hook handles it
        handleSelectUser(user, setInputValue);

        if (textareaRef.current) {
          setTimeout(() => {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
          }, 0);
        }
      };

      const handleSubmit = () => {
        if (inputValue.trim()) {
          let processedText = inputValue;

          console.log("Reply submission - Mentioned Users:", mentionedUsers);
          console.log("processedText:", processedText);

          console.log("Before processing mentions:", mentionedUsers);

          // Use mentionedUsers from the hook
          Object.entries(mentionedUsers).forEach(([name, user]) => {
            console.log("Processing mention:", name, user);
            const escapedName = name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
            const regex = new RegExp(escapedName, "g");
            processedText = processedText.replace(
              regex,
              `<a href="/${user.urlSlug}" class="${classes.mentionText}">${name}</a>`
            );
          });

          console.log("Final processed text:", processedText);
          console.log("Passing mentioned users:", mentionedUsers);

          onSubmit(postId, null, processedText, mentionedUsers); // Make sure mentionedUsers is being passed
          setInputValue("");
          if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
          }
        }
      };

      // Create styled version of input text for overlay
      const getStyledContent = () => {
        let styledContent = inputValue;

        // Replace mentioned user names with styled spans
        Object.keys(mentionedUsers).forEach((name) => {
          const escapedName = name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          const regex = new RegExp(escapedName, "g");
          styledContent = styledContent.replace(
            regex,
            `<span class="${classes.mention}">${name}</span>`
          );
        });

        return styledContent;
      };

      return (
        <div className={classes.commentInputSection}>
          <div className={classes.commentInputWrapper}>
            <div>
              <img
                src={
                  selectedStartup
                    ? selectedStartup.startupImage || defaultStartupImage
                    : currentUserProfileImage || profileImage
                }
                alt={
                  selectedStartup ? selectedStartup.startupName : "Your profile"
                }
                className={classes.commentUserImage}
                onClick={handleImageClick}
                style={{
                  borderRadius: selectedStartup ? 0 : "50%",
                  cursor: "pointer",
                }}
              />
            </div>
            <div className={classes.textareaWrapper}>
              <textarea
                ref={textareaRef}
                value={inputValue}
                onChange={handleChange}
                placeholder="Add a comment..."
                className={classes.commentInput}
              />
              <div
                className={classes.textOverlay}
                dangerouslySetInnerHTML={{ __html: getStyledContent() }}
              />
            </div>
            {showMentions && (
              <MentionDropdown
                searchText={mentionSearch}
                inputElement={textareaRef.current}
                onSelectUser={handleUserSelect}
                onClose={() => setShowMentions(false)}
              />
            )}
          </div>
          <div
            onClick={handleSubmit}
            className={`${classes.addCommentButton} ${
              !inputValue.trim() ? classes.disabled : ""
            }`}
          >
            Comment
          </div>
        </div>
      );
    }
  );

  const handleInteraction = (e, type, post) => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target;
    const isStartupToggle = target.closest(".interactionToggle");

    if (!isStartupToggle) {
      switch (type) {
        case "boost":
          handleEmojiSelect("👍", post.id);
          break;
        case "comment":
          toggleComments(post.id); // Make sure this is being called
          break;
        case "share":
          handleShare(post.id);
          break;
      }
    }
  };

  const handleShare = useCallback((postId) => {
    const postUrl = `${window.location.origin}/post/${postId}`;
    navigator.clipboard
      .writeText(postUrl)
      .then(() => {
        setShareStates((prev) => ({ ...prev, [postId]: "Copied" }));
        setTimeout(() => {
          setShareStates((prev) => ({ ...prev, [postId]: null }));
        }, 5000);
      })
      .catch((err) => {});
  }, []);

  const handleEmojiSelect = async (emoji, postId) => {
    if (!currentUser) return;

    try {
      const post = posts.find((p) => p.id === postId);
      if (!post) return;

      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      // Get the correct path and reference for the post
      const postRef = getPostRef(post);
      const reactorPath = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}`
        : `users/${currentUser.uid}`;
      const reactedPostRef = doc(
        firestore,
        `${reactorPath}/reactedPosts/${postId}`
      );

      let updatedReactions = { ...(post.reactions || {}) };

      // If the same emoji exists, remove the reaction
      if (updatedReactions[reactorId] === emoji) {
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];
        await deleteDoc(reactedPostRef);

        const notificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `reaction_${postId}_${reactorId}`
        );
        await deleteDoc(notificationRef);
      } else {
        // Add or update reaction
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        await setDoc(reactedPostRef, {
          originalPosterId: post.user.startupOwnerId || post.user.id,
          postId: postId,
          reactedAt: serverTimestamp(),
          emoji: emoji,
        });

        // Don't notify if it's effectively the same person
        const shouldNotify = post.user.isStartup
          ? post.user.startupOwnerId !== currentUser.uid ||
            (selectedStartup && post.user.startupId !== selectedStartup.id)
          : post.user.id !== currentUser.uid;

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${post.user.startupOwnerId || post.user.id}/notifications`,
            `reaction_${postId}_${reactorId}`
          );

          const notificationData = {
            type: "reaction",
            postId: postId,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
              startupName: selectedStartup.startupName,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      // Update local state
      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId ? { ...p, reactions: updatedReactions } : p
        )
      );

      setActiveEmojiPicker(null);
    } catch (error) {
      setErrorPopup({
        message: "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal((prev) => ({ ...prev, [postId]: true }));
    setIsReactionsModalOpen(true);
  };

  const handleDeletePost = async (postId) => {
    const affectedUserIds = new Set();
    const affectedStartupIds = new Set();

    if (!currentUser) {
      return;
    }

    setDeletingPostMessage({
      text: "Deleting post...",
      isLoading: true,
    });

    try {
      const targetPost = posts.find((p) => p.id === postId);
      if (!targetPost) {
        return;
      }

      const postRef = targetPost.user.isStartup
        ? doc(
            firestore,
            `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/posts/${postId}`
          )
        : doc(firestore, `users/${targetPost.user.id}/posts/${postId}`);

      const postSnap = await getDoc(postRef);

      if (!postSnap.exists()) {
        return;
      }

      const postData = postSnap.data();

      let batch = writeBatch(firestore);
      let operationCount = 0;

      const commitBatchIfNeeded = async () => {
        if (operationCount >= 450) {
          await batch.commit();
          batch = writeBatch(firestore);
          operationCount = 0;
        }
      };

      const commentsRef = collection(
        firestore,
        `users/${targetPost.user.id}/posts/${postId}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      commentsSnapshot.docs.forEach((doc) => {
        const commentData = doc.data();
        if (commentData.isStartup) {
          affectedStartupIds.add(commentData.startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }
      });

      if (postData.reactions) {
        for (const userId of Object.keys(postData.reactions)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupReactedPostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/reactedPosts/${postId}`
              );
              batch.delete(startupReactedPostRef);
              operationCount++;

              const reactionNotificationRef = doc(
                firestore,
                `users/${targetPost.user.id}/notifications/reaction_${postId}_${userId}`
              );
              batch.delete(reactionNotificationRef);
              operationCount++;

              await commitBatchIfNeeded();
            }
          } else {
            const userReactedPostRef = doc(
              firestore,
              `users/${userId}/reactedPosts/${postId}`
            );
            batch.delete(userReactedPostRef);
            operationCount++;

            const reactionNotificationRef = doc(
              firestore,
              `users/${targetPost.user.id}/notifications/reaction_${postId}_${userId}`
            );
            batch.delete(reactionNotificationRef);
            operationCount++;

            await commitBatchIfNeeded();
          }
        }
      }

      if (postData.reposts) {
        for (const userId of Object.keys(postData.reposts)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupRepostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/reposts/${postId}`
              );
              batch.delete(startupRepostRef);
              operationCount++;

              const repostNotificationRef = doc(
                firestore,
                `users/${targetPost.user.id}/notifications/repost_${postId}_${userId}`
              );
              batch.delete(repostNotificationRef);
              operationCount++;

              await commitBatchIfNeeded();
            }
          } else {
            const userRepostRef = doc(
              firestore,
              `users/${userId}/reposts/${postId}`
            );
            batch.delete(userRepostRef);
            operationCount++;

            const repostNotificationRef = doc(
              firestore,
              `users/${targetPost.user.id}/notifications/repost_${postId}_${userId}`
            );
            batch.delete(repostNotificationRef);
            operationCount++;

            await commitBatchIfNeeded();
          }
        }
      }

      if (postData.comments) {
        for (const userId of Object.keys(postData.comments)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupCommentedPostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/commentedPosts/${postId}`
              );
              batch.delete(startupCommentedPostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } else {
            const commentedPostRef = doc(
              firestore,
              `users/${userId}/commentedPosts/${postId}`
            );
            batch.delete(commentedPostRef);
            operationCount++;
            await commitBatchIfNeeded();
          }
        }
      }

      const usersSnapshot = await getDocs(collection(firestore, "users"));
      for (const userDoc of usersSnapshot.docs) {
        const notificationsQuery = query(
          collection(firestore, `users/${userDoc.id}/notifications`),
          where("postId", "==", postId)
        );
        const notificationsSnapshot = await getDocs(notificationsQuery);

        const notificationDeletePromises = notificationsSnapshot.docs.map(
          (doc) => {
            batch.delete(doc.ref);
            operationCount++;
            return commitBatchIfNeeded();
          }
        );

        await Promise.all(notificationDeletePromises);
      }

      const deletePromises = commentsSnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);

      if (postData.content && postData.content.type) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `postContent/${targetPost.user.id}/${postId}`
        );

        try {
          if (
            postData.content.type === "image" &&
            Array.isArray(postData.content.url)
          ) {
            for (const imageUrl of postData.content.url) {
              const imageRef = ref(storage, imageUrl);
              await deleteObject(imageRef);
            }
          } else if (
            ["image", "video", "pdf"].includes(postData.content.type)
          ) {
            const fileRef = ref(storage, postData.content.url);
            await deleteObject(fileRef);

            // Add this block to delete video thumbnails
            if (
              postData.content.type === "video" &&
              postData.content.thumbnail
            ) {
              const thumbnailRef = ref(storage, postData.content.thumbnail);
              await deleteObject(thumbnailRef);
            }
          }

          const folderContents = await listAll(storageRef);
          await Promise.all(
            folderContents.items.map((item) => deleteObject(item))
          );
        } catch (error) {}
      }

      batch.delete(postRef);
      operationCount++;

      await batch.commit();

      setComments((prevComments) => {
        const newComments = { ...prevComments };
        delete newComments[postId];
        return newComments;
      });

      setShowDropdown((prev) => {
        const newState = { ...prev };
        delete newState[postId];
        return newState;
      });

      setPosts((prevPosts) => {
        const updatedPosts = prevPosts.filter((p) => p.id !== postId);

        // If no posts left and we're in startup view, navigate to startup profile
        if (updatedPosts.length === 0) {
          if (type === "startup" && id) {
            // Check all users for the startup with this ID
            const usersRef = collection(firestore, "users");
            getDocs(usersRef).then((usersSnapshot) => {
              usersSnapshot.docs.forEach(async (userDoc) => {
                const startupRef = doc(
                  firestore,
                  `users/${userDoc.id}/startups/${id}`
                );
                const startupSnap = await getDoc(startupRef);
                if (startupSnap.exists()) {
                  const startupData = startupSnap.data();
                  if (startupData.startupUrlSlug) {
                    navigate(`/startup/${startupData.startupUrlSlug}`);
                  }
                }
              });
            });
          } else {
            // Handle regular user navigation
            const userDoc = getDoc(doc(firestore, "users", currentUser.uid));
            userDoc.then((doc) => {
              if (doc.exists()) {
                const userData = doc.data();
                navigate(`/${userData.urlSlug}`);
              }
            });
          }
        }

        return updatedPosts;
      });

      setDeletingPostMessage({
        text: "Post deleted",
        isLoading: false,
      });
    } catch (error) {
      setErrorPopup({
        message: "Failed to delete post.",
        isError: true,
      });
    } finally {
      // If there was an error, clear the "Deleting..." message
      if (deletingPostMessage?.isLoading) {
        setDeletingPostMessage(null);
      }
    }
  };

  const deleteAssociatedNotifications = async (postId) => {
    const batch = writeBatch(firestore);
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);

    for (const userDoc of usersSnapshot.docs) {
      const notificationsRef = collection(userDoc.ref, "notifications");
      const q = query(notificationsRef, where("postId", "==", postId));
      const notificationsSnapshot = await getDocs(q);

      notificationsSnapshot.forEach((notificationDoc) => {
        batch.delete(notificationDoc.ref);
      });
    }

    await batch.commit();
  };

  const handleReportPost = () => {
    setShowDropdown(false);
    setReportMessage({
      text: "Report submitted.",
    });
  };

  // Update handleReportComment:
  const handleReportComment = (commentId) => {
    setOpenCommentDropdown(null);
    setReportMessage({
      text: "Report submitted.",
    });
  };

  const RepostCount = ({ reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div className={classes.repostCount} onClick={onClick}>
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const stripHtmlTags = (html) => {
    if (!html) return "";
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return (tmp.textContent || tmp.innerText || "").trim();
  };

  const handleEditComment = (postId, commentId, isReply = false) => {
    if (!postId || !comments[postId]) {
      return;
    }

    const postComments = comments[postId];
    const commentToEdit = postComments.find((c) => c.id === commentId);

    if (commentToEdit) {
      setEditingCommentId(commentId);
      const strippedText = stripHtmlTags(commentToEdit.text);
      setEditCommentText(strippedText);

      // Parse existing mentions from the comment text
      const mentionRegex =
        /<a href="\/([^"]+)" class="[^"]*mentionText[^"]*">([^<]+)<\/a>/g;
      const existingMentions = {};
      let match;

      while ((match = mentionRegex.exec(commentToEdit.text)) !== null) {
        const [_, urlSlug, fullName] = match;
        existingMentions[fullName] = {
          urlSlug,
          firstName: fullName.split(" ")[0],
          lastName: fullName.split(" ").slice(1).join(" "),
        };
      }

      // Initialize mention state for this edit
      setReplyMentionStates((prev) => ({
        ...prev,
        [`edit_${commentId}`]: {
          ...prev[`edit_${commentId}`],
          showMentions: false,
          mentionedUsers: existingMentions,
        },
      }));

      setOpenCommentDropdown(null);

      setTimeout(() => {
        if (editTextareaRef.current) {
          editTextareaRef.current.focus();
          adjustTextareaHeight(editTextareaRef.current);
          const length = editTextareaRef.current.value.length;
          editTextareaRef.current.setSelectionRange(length, length);
        }
      }, 0);
    }
  };

  const handleSaveCommentChanges = async (
    postId,
    commentId,
    isReply = false
  ) => {
    if (!currentUser) return;

    const trimmedText = editCommentText.trim();
    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      const targetPost = posts.find((p) => p.id === postId);
      if (!targetPost) return;

      let processedText = trimmedText;
      const mentionedUsers =
        replyMentionStates[`edit_${commentId}`]?.mentionedUsers || {};

      // Special handling for single startup mention with no other text
      if (
        Object.keys(mentionedUsers).length === 1 &&
        trimmedText.trim() === Object.keys(mentionedUsers)[0].trim()
      ) {
        const [name, user] = Object.entries(mentionedUsers)[0];
        if (user.isStartup) {
          processedText = `<a href="/startup/${user.urlSlug.replace(
            "/startup/",
            ""
          )}" class="${classes.mentionText}">${name}</a>`;
        }
      } else {
        // Regular processing for mentions with other text
        Object.entries(mentionedUsers).forEach(([name, user]) => {
          const escapedName = name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          const regex = new RegExp(escapedName, "g");
          const href = user.isStartup
            ? `/startup/${user.urlSlug.replace("/startup/", "")}`
            : `/${user.urlSlug}`;
          processedText = processedText.replace(
            regex,
            `<a href="${href}" class="${classes.mentionText}">${name}</a>`
          );
        });
      }

      const basePath = targetPost.user.isStartup
        ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}`
        : `users/${targetPost.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      let canEdit = false;
      if (commentData.isStartup) {
        canEdit = commentData.startupOwnerId === currentUser.uid;
      } else {
        canEdit = commentData.userId === currentUser.uid;
      }

      if (!canEdit) {
        throw new Error("Not authorized to edit this comment");
      }

      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
      });

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        ),
      }));

      setEditingCommentId(null);
      setEditCommentText("");

      const commenterPath = commentData.isStartup
        ? `users/${commentData.startupOwnerId}/startups/${commentData.startupId}`
        : `users/${commentData.userId}`;

      const commentedPostRef = doc(
        firestore,
        `${commenterPath}/commentedPosts/${postId}`
      );
      const commentedPostSnap = await getDoc(commentedPostRef);

      if (commentedPostSnap.exists()) {
        const commentedPostData = commentedPostSnap.data();
        if (commentedPostData.comments?.[commentId]) {
          await updateDoc(commentedPostRef, {
            [`comments.${commentId}.commentText`]: processedText,
          });
        }
      }
    } catch (error) {
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : error.message === "Not authorized to edit this comment"
            ? "You don't have permission to edit this comment."
            : "An error occurred while updating the comment.",
        isError: true,
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  const renderPostDropdown = (post) => {
    // Check if current user owns the post (either directly or via startup ownership)
    const isOwnPost = post.user.isStartup
      ? post.user.startupOwnerId === currentUser.uid // User owns the startup
      : post.user.id === currentUser.uid; // User's own post

    return (
      <div className={classes.optionsDropdown}>
        {isOwnPost ? (
          <>
            <button
              onClick={() => handleEditPost(post)}
              className={classes.editPost}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                />
              </svg>
              Edit
            </button>
            <button
              onClick={() => handleDeletePost(post.id)}
              className={classes.deletePost}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                />
              </svg>
              Delete
            </button>
          </>
        ) : (
          <button
            onClick={() => handleReportPost(post.id)}
            className={classes.reportPost}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Report
          </button>
        )}
      </div>
    );
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  // In AllPosts.js, update the renderEditButtons function:

  const renderEditButtons = (postId, commentId, isReply = false) => {
    // Find the original comment text from the comments state using postId
    const originalComment = comments[postId]?.find((c) => c.id === commentId);

    // Strip HTML and whitespace from both original and current text
    const strippedOriginalText = stripHtmlTags(
      originalComment?.text || ""
    ).trim();
    const currentEditText = editCommentText.trim();

    // Button should be disabled if:
    // 1. Current text is empty OR
    // 2. Current text is exactly the same as original text
    const isDisabled =
      !currentEditText || currentEditText === strippedOriginalText;

    return (
      <div className={classes.editCommentButtons}>
        <div
          className={`${classes.saveChangesButton} ${
            isDisabled ? classes.disabledButton : ""
          }`}
          onClick={() => {
            if (!isDisabled) {
              handleSaveCommentChanges(postId, commentId, isReply);
            }
          }}
        >
          Save changes
        </div>
        <div className={classes.dot}> • </div>
        <div className={classes.cancelEditButton} onClick={handleCancelEdit}>
          Cancel
        </div>
      </div>
    );
  };

  // In AllPosts.js, update the handleEditComplete function:

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      // Get the correct path using getPostRef helper
      const postRef = getPostRef(editingPost);

      const updateData = {
        text: updatedText,
        isEdited: isEdited,
      };

      await updateDoc(postRef, updateData);

      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === editingPost.id
            ? { ...post, text: updatedText, isEdited: isEdited }
            : post
        )
      );

      setIsEditingComplete(true);
      setShowEditModal(false);
      setEditingPost(null);
      setIsPostModalOpen(false);
    } catch (error) {
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  // Make sure we have the correct getPostRef helper:
  const getPostRef = (post) => {
    if (post.user.isStartup) {
      return doc(
        firestore,
        `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}`
      );
    }
    return doc(firestore, `users/${post.user.id}/posts/${post.id}`);
  };

  useEffect(() => {
    if (isEditingComplete) {
      window.scrollTo(0, postModalScrollPosition);
      setIsEditingComplete(false);
    }
  }, [isEditingComplete, postModalScrollPosition]);

  const getMediaClass = (post) => {
    if (!post || !post.content) return "";
    if (post.content.type === "video") return classes.hasVideo;
    if (post.content.type === "image") {
      return Array.isArray(post.content.url)
        ? classes.hasMultipleImages
        : classes.hasImage;
    }
    return "";
  };

  return (
    <div
      className={`${classes.page} ${
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isPostModalOpen ||
        showStartupModal ||
        activeEmojiPicker !== null
          ? classes.noScroll
          : ""
      }`}
      style={
        isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : showStartupModal
          ? { top: `-${startupModalScrollPosition}px` }
          : activeEmojiPicker
          ? {
              top: `-${
                activeEmojiPicker.includes("_")
                  ? commentEmojiPickerScrollPosition
                  : emojiPickerScrollPosition
              }px`,
            }
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={currentUser?.profileImage || profileImage} />
      ) : (
        <MainNavbar userImage={currentUser?.profileImage || profileImage} />
      )}
      {showStartupModal && (
        <StartupInteractionModal
          onClose={() => {
            setShowStartupModal(false);
            handleModalClose();
            setActiveStartupToggle(null);
          }}
          currentUser={currentUser}
          onStartupSelect={(startup) => {
            setSelectedStartup(startup);
            setShowStartupModal(false);
            handleModalClose();
          }}
          selectedStartup={selectedStartup}
          currentUserProfileImage={currentUserProfileImage}
        />
      )}
      {deletingCommentMessage && (
        <RepostPopup
          message={deletingCommentMessage.text}
          onClose={() => setDeletingCommentMessage(null)}
          isLoading={deletingCommentMessage.isLoading}
        />
      )}

      {deletingPostMessage && (
        <RepostPopup
          message={deletingPostMessage.text}
          onClose={() => setDeletingPostMessage(null)}
          isLoading={deletingPostMessage.isLoading}
        />
      )}

      {reportMessage && (
        <RepostPopup
          message={reportMessage.text}
          onClose={() => setReportMessage(null)}
        />
      )}
      {showEditModal && editingPost && (
        <PostModal
          onClose={() => {
            setShowEditModal(false);
            setEditingPost(null);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialPost={editingPost}
          onEditComplete={handleEditComplete}
        />
      )}

      {repostMessage && (
        <RepostPopup
          message={repostMessage.text}
          postId={repostMessage.postId}
          isRepost={repostMessage.isRepost}
          onClose={() => setRepostMessage(null)}
        />
      )}
      {deleteMessage && (
        <PostActionPopup
          message={deleteMessage.text}
          postId={deleteMessage.postId}
          isDelete={true}
          isSuccess={deleteMessage.isSuccess}
          onClose={() => setDeleteMessage(null)}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : hasAttemptedLoad && posts.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No posts found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : (
            <div className={classes.postsContainer}>
              {posts.map((post) => (
                <div
                  key={post.id}
                  className={`${classes.postCard} ${getMediaClass(post)}`}
                >
                  <div className={classes.reactionHeader}>
                    <img
                      src={post.user?.profileImage || profileImage}
                      alt={`${post.user?.firstName || ""} ${
                        post.user?.lastName || ""
                      }`}
                      className={classes.reactionImage}
                      onClick={() =>
                        handleNavigate(
                          post.user.isStartup
                            ? `startup/${post.user.startupUrlSlug}`
                            : post.user.id
                        )
                      }
                      style={{
                        borderRadius: post.user.isStartup ? 0 : "50%",
                        cursor: "pointer",
                      }}
                    />

                    <div className={classes.reactionTextWrapper}>
                      <span className={classes.reactionText}>
                        <span
                          className={classes.reactionUsername}
                          onClick={() =>
                            handleNavigate(
                              post.user.isStartup
                                ? `startup/${post.user.startupUrlSlug}`
                                : post.user.id
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {post.user?.id === currentUser?.uid
                            ? "You"
                            : `${post.user?.firstName || ""} ${
                                post.user?.lastName || ""
                              }`}
                        </span>{" "}
                        posted this
                      </span>
                    </div>
                  </div>
                  <div className={classes.postHeader}>
                    <div className={classes.userInfo}>
                      <img
                        src={post.user?.profileImage || profileImage}
                        alt={
                          post.user.isStartup
                            ? post.user.firstName
                            : `${post.user.firstName} ${post.user.lastName}`
                        }
                        className={classes.postImage}
                        onClick={() =>
                          handleNavigate(
                            post.user.isStartup
                              ? `startup/${post.user.startupUrlSlug}`
                              : post.user.id
                          )
                        }
                        style={{
                          borderRadius: post.user.isStartup ? 0 : "50%",
                          cursor: "pointer",
                        }}
                      />
                      <div className={classes.userDetails}>
                        <div className={classes.userNameContainer}>
                          <p
                            className={classes.userName}
                            onClick={() =>
                              handleNavigate(
                                post.user.isStartup
                                  ? `startup/${post.user.startupUrlSlug}`
                                  : post.user.id
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <span className={classes.nameText}>
                              {`${post.user?.firstName || ""} ${
                                post.user?.lastName || ""
                              }`}
                            </span>
                          </p>
                          {post.connectionType && (
                            <span className={classes.connectionType}>
                              • {post.connectionType}
                            </span>
                          )}
                        </div>

                        {post.user?.bio && (
                          <p className={classes.bio}>{post.user.bio}</p>
                        )}
                        {post.user?.link && (
                          <div className={classes.userLinkContainer}>
                            <a
                              href={post.user.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                              className={classes.userLink}
                            >
                              <span className={classes.userLinkText}>
                                {post.user.linkText || post.user.link}
                              </span>
                              <svg
                                className={classes.userLinkIcon}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="orangered"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                        <div className={classes.timeAndEditDetails}>
                          {post.createdAt && (
                            <p className={classes.time}>
                              {formatTimestamp(post.createdAt)}
                            </p>
                          )}
                          {post.isEdited && (
                            <div className={classes.editDot}>
                              •
                              <span className={classes.editedPostText}>
                                Edited
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.postOptions}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          showDropdown[post.id] ? classes.optionsIconActive : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDropdown((prev) => ({
                            ...prev,
                            [post.id]: !prev[post.id],
                          }));
                        }}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {showDropdown[post.id] && renderPostDropdown(post)}
                    </div>
                  </div>
                  {post.text && renderPostText(post)}
                  {renderPostContent(post)}

                  {post.reactions && Object.keys(post.reactions).length > 0 && (
                    <div
                      className={`${classes.postInteractionsInfo} ${
                        post.content?.type === "video" ||
                        post.content?.type === "image" ||
                        (post.content?.type === "image" &&
                          Array.isArray(post.content.url))
                          ? classes.hasMediaContent
                          : ""
                      }`}
                      onClick={() => {
                        if (post && post.reactions) {
                          setReactionsModalScrollPosition(window.scrollY);
                          setActivePostReactions({
                            postId: post.id,
                            reactions: post.reactions,
                          });
                          setShowReactionsModal(true);
                          setIsReactionsModalOpen(true);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      See who boosted{" "}
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M8 20V7m0 13-4-4m4 4 4-4m4-12v13m0-13 4 4m-4-4-4 4"
                        />
                      </svg>
                    </div>
                  )}
                  <div className={classes.postInteractions}>
                    <StartupInteractionToggle
                      currentUser={currentUser}
                      onStartupSelect={setSelectedStartup}
                      selectedStartup={selectedStartup}
                      currentUserProfileImage={currentUserProfileImage}
                      onModalOpen={() => handleModalOpen("top")}
                      onModalClose={handleModalClose}
                      className={classes.interactionToggle}
                      userStartups={userStartups}
                      toggleId="top"
                    />
                    <button
                      type="button"
                      className={`${classes.interactionButton} ${
                        (selectedStartup &&
                          post.reactions?.[`startup_${selectedStartup.id}`]) ||
                        (!selectedStartup && post.reactions?.[currentUser?.uid])
                          ? classes.activeInteraction
                          : ""
                      }`}
                      onClick={(e) => handleInteraction(e, "boost", post)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Boost{" "}
                      {post.reactions &&
                        Object.keys(post.reactions).length > 0 &&
                        `(${Object.keys(post.reactions).length})`}
                    </button>

                    <button
                      className={`${classes.interactionButton} ${
                        (selectedStartup &&
                          post.comments?.[`startup_${selectedStartup.id}`]) ||
                        (!selectedStartup && post.comments?.[currentUser?.uid])
                          ? classes.activeInteraction
                          : ""
                      }`}
                      onClick={(e) => handleInteraction(e, "comment", post)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Comment{" "}
                      {post.commentCount > 0 && `(${post.commentCount})`}
                    </button>

                    <button
                      className={classes.interactionButton}
                      onClick={(e) => handleInteraction(e, "share", post)}
                    >
                      {shareStates[post.id] === "Copied" ? (
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                            clipRule="evenodd"
                          />
                          <path
                            fillRule="evenodd"
                            d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                        </svg>
                      )}
                      {shareStates[post.id] || "Share"}
                    </button>
                  </div>
                  {activeEmojiPicker === post.id && (
                    <EmojiPicker
                      onEmojiClick={(emoji) =>
                        handleEmojiSelect(emoji, post.id)
                      }
                      onClose={() => {
                        setActiveEmojiPicker(null);
                        setTimeout(() => {
                          window.scrollTo(0, emojiPickerScrollPosition);
                        }, 0);
                      }}
                    />
                  )}
                  {/* After your post content */}
                  {showComments[post.id] && (
                    <div className={classes.commentsSection}>
                      <CommentInput
                        postId={post.id}
                        onSubmit={handleAddComment}
                        currentUser={currentUser}
                        selectedStartup={selectedStartup}
                        currentUserProfileImage={currentUserProfileImage}
                      />
                      <div className={classes.commentsList}>
                        {renderComments(post.id)}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {isReactionsModalOpen && activePostReactions && (
            <ReactionsModal
              onClose={() => {
                setShowReactionsModal((prev) => ({
                  ...prev,
                  [activePostReactions.postId]: false,
                }));
                setIsReactionsModalOpen(false);
                setTimeout(() => {
                  window.scrollTo(0, reactionsModalScrollPosition);
                }, 0);
              }}
              postId={activePostReactions.postId}
              reactions={activePostReactions.reactions || {}}
              currentUserId={currentUser?.uid}
            />
          )}

          {isRepostsModalOpen && activePostReposts && (
            <RepostsModal
              onClose={() => {
                setShowRepostsModal((prev) => ({
                  ...prev,
                  [activePostReposts.postId]: false,
                }));
                setIsRepostsModalOpen(false);
                setTimeout(() => {
                  window.scrollTo(0, repostsModalScrollPosition);
                }, 0);
              }}
              postId={activePostReposts.postId}
              reposts={activePostReposts.reposts || {}}
              currentUserId={currentUser?.uid}
            />
          )}
        </div>
        <div className={classes.rightCards}>
          <Ad />
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default AllPosts;
