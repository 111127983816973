import React, { useState } from "react";
import Nav from "../../components/Navbars/Nav";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./Affiliates.module.css";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import userImage from "../../assets/icons/profileImage.jpg";

function Affiliates() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);

  return (
    <div className={classes.page}>
      <Nav />
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div className={classes.cardTitle}>Why am I seeing this ad?</div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                We receive referral fees when you click or sign up through ads
                due to our partnerships with the featured organizations.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardAbout}>
              <div className={classes.email}>
                Want to be a partner? Reach out to{" "}
                <a href="mailto:hello@foundry.bz" className={classes.link}>
                  hello@foundry.bz
                </a>
              </div>
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardTitle}>Partnerships</div>
            <div className={classes.cardContent}>
              <a
                href="https://get.brevo.com/qq7m3lhqb6bl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.icon}
                  src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/brevo.jpg?alt=media&token=a15a0cc4-f4d8-45a2-bf31-5cb7e35c4e9f"
                  alt=""
                ></img>
              </a>

              <div className={classes.text}>
                <a
                  href="https://get.brevo.com/qq7m3lhqb6bl"
                  className={classes.link1}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Brevo
                </a>
                <div className={classes.link2}>Sendinblue SAS</div>
              </div>
            </div>

            <div className={classes.noPostBorder}></div>
            <div className={classes.cardContent}>
              <a
                href="https://try.web.clickup.com/5rbffbnys91v"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.icon}
                  src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/clickup.jpg?alt=media&token=efdad85a-9558-403b-b37a-1acaa58f1ac6"
                  alt=""
                ></img>
              </a>

              <div className={classes.text}>
                <a
                  href="https://try.web.clickup.com/5rbffbnys91v"
                  className={classes.link1}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ClickUp
                </a>
                <div className={classes.link2}>Mango Technologies, Inc.</div>
              </div>
            </div>
            <div className={classes.noPostBorder}></div>

            <div className={classes.cardContent}>
              <a
                href="https://affiliates.meliopayments.com/bxd42sj1tmul"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.icon}
                  src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/melio.jpeg?alt=media&token=1f77a088-f644-4a75-8cc2-aa92f22f0f56"
                  alt=""
                ></img>
              </a>

              <div className={classes.text}>
                <a
                  href="https://affiliates.meliopayments.com/bxd42sj1tmul"
                  className={classes.link1}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Melio
                </a>
                <div className={classes.link2}>Melio Payments Inc.</div>
              </div>
            </div>
            <div className={classes.noPostBorder}></div>

            <div className={classes.cardContent}>
              <a
                href="https://affiliate.notion.so/mvw80dbxrh8s"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.icon}
                  src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/notion-logo.png?alt=media&token=002694cc-c186-42fb-9aee-80a157624121"
                  alt=""
                ></img>
              </a>

              <div className={classes.text}>
                <a
                  href="https://affiliate.notion.so/mvw80dbxrh8s"
                  className={classes.link1}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notion
                </a>
                <div className={classes.link2}>Notion Labs, Inc.</div>
              </div>
            </div>
            <div className={classes.noPostBorder}></div>

            <div className={classes.cardContent}>
              <a
                href="https://try.printify.com/mh1ivpf8saoo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.icon}
                  src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/printify.jpg?alt=media&token=371afe5c-6367-4c7e-b335-a15a7e02f22b"
                  alt=""
                ></img>
              </a>

              <div className={classes.text}>
                <a
                  href="https://try.printify.com/mh1ivpf8saoo"
                  className={classes.link1}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Printify
                </a>
                <div className={classes.link2}>Printify, Inc.</div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Affiliates;
