import { firestore } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";

export async function generateUrlSlug(firstName, lastName) {
  if (!firstName || !lastName) {
    throw new Error("First name and last name are required");
  }

  const characterMap = {
    å: "a",
    Å: "A",
    ä: "a",
    Ä: "A",
    ö: "o",
    Ö: "O",
    ø: "o",
    Ø: "O",
    æ: "ae",
    Æ: "AE",
    ü: "u",
    Ü: "U",
    ß: "ss",
    é: "e",
    É: "E",
    è: "e",
    È: "E",
    ê: "e",
    Ê: "E",
    ë: "e",
    Ë: "E",
    à: "a",
    À: "A",
    â: "a",
    Â: "A",
    ç: "c",
    Ç: "C",
    î: "i",
    Î: "I",
    ï: "i",
    Ï: "I",
    ì: "i",
    Ì: "I",
    í: "i",
    Í: "I",
    ô: "o",
    Ô: "O",
    û: "u",
    Û: "U",
    ù: "u",
    Ù: "U",
    ÿ: "y",
    Ÿ: "Y",
    ñ: "n",
    Ñ: "N",
    á: "a",
    Á: "A",
    ã: "a",
    Ã: "A",
    õ: "o",
    Õ: "O",
    ą: "a",
    Ą: "A",
    ć: "c",
    Ć: "C",
    ę: "e",
    Ę: "E",
    ł: "l",
    Ł: "L",
    ń: "n",
    Ń: "N",
    ś: "s",
    Ś: "S",
    ź: "z",
    Ź: "Z",
    ż: "z",
    Ż: "Z",
    č: "c",
    Č: "C",
    ď: "d",
    Ď: "D",
    ě: "e",
    Ě: "E",
    ň: "n",
    Ň: "N",
    ř: "r",
    Ř: "R",
    š: "s",
    Š: "S",
    ť: "t",
    Ť: "T",
    ů: "u",
    Ů: "U",
    ý: "y",
    Ý: "Y",
    ž: "z",
    Ž: "Z",
    ă: "a",
    Ă: "A",
    ș: "s",
    Ș: "S",
    ț: "t",
    Ț: "T",
    ğ: "g",
    Ğ: "G",
    ı: "i",
    İ: "I",
    ş: "s",
    Ş: "S",
    ā: "a",
    Ā: "A",
    ē: "e",
    Ē: "E",
    ģ: "g",
    Ģ: "G",
    ī: "i",
    Ī: "I",
    ķ: "k",
    Ķ: "K",
    ļ: "l",
    Ļ: "L",
    ņ: "n",
    Ņ: "N",
    ū: "u",
    Ū: "U",
    ė: "e",
    Ė: "E",
    į: "i",
    Į: "I",
    ų: "u",
    Ų: "U",

    '"': "",
    '"': "",
    "-": "",
    ".": "",
    ",": "",
    "'": "",
    " ": "",
    _: "",
    "+": "",
    "&": "",
    "@": "",
    "#": "",
    $: "",
    "%": "",
    "*": "",
    "!": "",
    "?": "",
    "=": "",
    "|": "",
    "/": "",
    "\\": "",
    "(": "",
    ")": "",
    "[": "",
    "]": "",
    "{": "",
    "}": "",
    "<": "",
    ">": "",
    "~": "",
    "`": "",
    "^": "",
    ";": "",
    ":": "",
  };

  const transliterate = (text) => {
    return text
      .toLowerCase()
      .split("")
      .map((char) => characterMap[char] || char)
      .join("");
  };

  const sanitizedFirst = transliterate(firstName).replace(/[^a-z0-9]/g, "");
  const sanitizedLast = transliterate(lastName).replace(/[^a-z0-9]/g, "");

  const baseSlug = `${sanitizedFirst}${sanitizedLast}`;
  let slug = baseSlug;
  let counter = 1;
  let maxAttempts = 10;

  try {
    while (maxAttempts > 0) {
      const q = query(
        collection(firestore, "users"),
        where("urlSlug", "==", slug)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return slug;
      }

      counter++;
      slug = `${baseSlug}${counter}`;
      maxAttempts--;
    }

    return `${baseSlug}${Date.now()}`;
  } catch (error) {
    throw new Error("Failed to generate URL slug");
  }
}

export async function updateUserUrlSlug(userId, firstName, lastName) {
  if (!userId) {
    throw new Error("User ID is required");
  }

  try {
    const userRef = doc(firestore, "users", userId);
    const userDoc = await getDoc(userRef);
    const newSlug = await generateUrlSlug(firstName, lastName);

    const updates = {
      firstName,
      lastName,
      urlSlug: newSlug,
      updatedAt: new Date().toISOString(),
    };

    if (!userDoc.exists()) {
      await setDoc(userRef, {
        ...updates,
        createdAt: new Date().toISOString(),
      });
    } else {
      await updateDoc(userRef, updates);
    }

    return newSlug;
  } catch (error) {
    throw error;
  }
}
