import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Events.module.css";
import Ad from "../../../components/Cards/Ad";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import { firestore, auth } from "../../../firebase";
import {
  doc,
  getDoc,
  getDocs,
  limit,
  startAfter,
  orderBy,
  collection,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import eventImage from "../../../assets/icons/eventImage.jpg";
import InterestsDropdown from "../../../components/Dropdowns/InterestsDropdown";
import TagsDropdown from "../../../components/Dropdowns/TagsDropdown";
import CustomDropdown from "../../../components/Dropdowns/CustomDropdown";
import EventTypeDropdown from "../../../components/Dropdowns/EventTypeDropdown";

import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  "X6JFTXGTHU",
  "334296000267001b1430be651a4794d9"
);
const index = searchClient.initIndex("events_index");

function Events() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });
  // const [eventType, setEventType] = useState("All"); // Default to "All" instead of "Extended"
  const [blockedUsers, setBlockedUsers] = useState(new Set());
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [nameInput, setNameInput] = useState("");
  const [isInterestsMenuOpen, setIsInterestsMenuOpen] = useState(false);
  const [interests, setInterests] = useState([]);
  const [isTagsMenuOpen, setIsTagsMenuOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [isLocationMenuOpen, setIsLocationMenuOpen] = useState(false);
  const [country, setCountry] = useState({ object: null, inputValue: "" });
  const [city, setCity] = useState({ object: null, inputValue: "" });
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [isTypeMenuOpen, setIsTypeMenuOpen] = useState(false);

  const [hasSearched, setHasSearched] = useState(false);
  const [initialResults, setInitialResults] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const config = {
    cUrl: "https://api.countrystatecity.in/v1",
    ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
  };

  const cityCache = useMemo(() => ({}), []);

  const loadCountries = async () => {
    try {
      const response = await fetch(`${config.cUrl}/countries`, {
        headers: { "X-CSCAPI-KEY": config.ckey },
      });
      const data = await response.json();
      setCountries(Array.isArray(data) ? data : []);
    } catch (error) {
      setCountries([]);
    }
  };

  const loadCities = useCallback(async () => {
    if (!selectedCountry) return;
    try {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        const response = await fetch(
          `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
          {
            headers: { "X-CSCAPI-KEY": config.ckey },
          }
        );
        const data = await response.json();
        cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
        setCities(cityCache[selectedCountry.iso2]);
      }
    } catch (error) {
      setCities([]);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    loadCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      loadCities();
    }
  }, [selectedCountry, loadCities]);

  const isUserBlocked = async (currentUserId, targetUserId) => {
    try {
      // Check if current user has blocked target user
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedSnapshot = await getDocs(currentUserBlockedRef);
      const isBlockedByCurrentUser = currentUserBlockedSnapshot.docs.some(
        (doc) => doc.data().userId === targetUserId
      );

      // Check if target user has blocked current user
      const targetUserBlockedRef = collection(
        firestore,
        `users/${targetUserId}/blockedUsers`
      );
      const targetUserBlockedSnapshot = await getDocs(targetUserBlockedRef);
      const isBlockedByTargetUser = targetUserBlockedSnapshot.docs.some(
        (doc) => doc.data().userId === currentUserId
      );

      return isBlockedByCurrentUser || isBlockedByTargetUser;
    } catch (error) {
      return false;
    }
  };

  // Add this useEffect to fetch blocked users on component mount
  useEffect(() => {
    const fetchBlockedUsers = async () => {
      if (!currentUser?.uid) return;

      try {
        const blockedUsersRef = collection(
          firestore,
          `users/${currentUser.uid}/blockedUsers`
        );
        const blockedUsersSnapshot = await getDocs(blockedUsersRef);
        const blockedUserIds = new Set(
          blockedUsersSnapshot.docs.map((doc) => doc.data().userId)
        );
        setBlockedUsers(blockedUserIds);
      } catch (error) {
        setBlockedUsers(new Set());
      }
    };

    fetchBlockedUsers();
  }, [currentUser?.uid]);

  const toggleLocationMenu = () => {
    setIsLocationMenuOpen(!isLocationMenuOpen);
  };

  // const handleTypeChange = (newType) => {
  //   setEventType(newType);
  //   setIsChanged(true);
  // };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCityInputValue("");
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
    setIsChanged(checkIfAnyInputHasValue());
  };

  const checkIfAnyInputHasValue = useCallback(() => {
    return (
      nameInput.trim() !== "" ||
      tags.length > 0 ||
      selectedCountry !== null ||
      selectedCity !== null
    );
  }, [nameInput, tags, selectedCountry, selectedCity]);

  const toggleFilters = () => {
    setShowFilters((prevState) => !prevState);
  };

  const handleInterestsChange = (newInterests) => {
    setInterests(newInterests);
    setIsChanged(true);
  };

  const handleTagsChange = (newTags) => {
    setTags(newTags);
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleNameInputChange = (e) => {
    const value = e.target.value;
    setNameInput(value);
    setIsChanged(value.trim() !== "");
  };

  const handleEventClick = (event) => {
    navigate(`/event/${event.eventUrlSlug}`);
  };

  const handleInputChange = (inputValue, type) => {
    if (type === "tags") {
      if (inputValue.trim() === "") {
        setTags([]);
      } else {
        setTags([{ name: inputValue, id: inputValue }]);
      }
    }
    // We need to wait for the next tick to ensure tags state is updated
    setTimeout(() => {
      setIsChanged(checkIfAnyInputHasValue());
    }, 0);
  };

  const fetchInitialResults = useCallback(async () => {
    if (!currentUser) return;
    setIsInitialLoading(true);

    try {
      const eventsToExclude = new Set();
      const seenEventIds = new Set();
      let events = [];

      const [ownedEvents, attendingEvents, organizingEvents] =
        await Promise.all([
          getDocs(collection(firestore, `users/${currentUser.uid}/events`)),
          getDocs(
            collection(firestore, `users/${currentUser.uid}/eventsAttending`)
          ),
          getDocs(
            collection(firestore, `users/${currentUser.uid}/eventsOrganising`)
          ),
        ]);

      ownedEvents.docs.forEach((doc) => eventsToExclude.add(doc.id));
      attendingEvents.docs.forEach((doc) => eventsToExclude.add(doc.id));
      organizingEvents.docs.forEach((doc) => eventsToExclude.add(doc.id));

      const connectionsSnapshot = await getDocs(
        query(
          collection(firestore, `users/${currentUser.uid}/connections`),
          where("status", "==", "Connected")
        )
      );

      // Process connection events
      await Promise.all(
        connectionsSnapshot.docs.map(async (connection) => {
          const [connOwnedEvents, connAttendingEvents] = await Promise.all([
            getDocs(collection(firestore, `users/${connection.id}/events`)),
            getDocs(
              collection(firestore, `users/${connection.id}/eventsAttending`)
            ),
          ]);

          // First check if this connection is blocked
          const isBlocked = await isUserBlocked(currentUser.uid, connection.id);
          if (!isBlocked) {
            connOwnedEvents.docs.forEach((doc) => {
              if (!seenEventIds.has(doc.id) && !eventsToExclude.has(doc.id)) {
                seenEventIds.add(doc.id);
                events.push({
                  id: doc.id,
                  ...doc.data(),
                  ownerId: connection.id,
                });
              }
            });

            connAttendingEvents.docs.forEach((doc) => {
              if (
                !seenEventIds.has(doc.id) &&
                !eventsToExclude.has(doc.id) &&
                doc.data().eventName?.trim()
              ) {
                seenEventIds.add(doc.id);
                events.push({
                  id: doc.id,
                  ...doc.data(),
                  ownerId: connection.id,
                });
              }
            });
          }
        })
      );

      // If we need more events, get from random users
      if (events.length < 36) {
        let lastUserDoc = null;
        let attempts = 0;
        const MAX_ATTEMPTS = 10;

        while (events.length < 36 && attempts < MAX_ATTEMPTS) {
          const usersQuery = lastUserDoc
            ? query(
                collection(firestore, "users"),
                startAfter(lastUserDoc),
                limit(50)
              )
            : query(collection(firestore, "users"), limit(50));

          const usersSnap = await getDocs(usersQuery);
          if (usersSnap.empty) break;

          lastUserDoc = usersSnap.docs[usersSnap.docs.length - 1];

          await Promise.all(
            usersSnap.docs
              .filter((doc) => doc.id !== currentUser.uid)
              .map(async (userDoc) => {
                const isBlocked = await isUserBlocked(
                  currentUser.uid,
                  userDoc.id
                );
                if (!isBlocked) {
                  const [userEvents, userAttending, userOrganizing] =
                    await Promise.all([
                      getDocs(
                        collection(firestore, `users/${userDoc.id}/events`)
                      ),
                      getDocs(
                        collection(
                          firestore,
                          `users/${userDoc.id}/eventsAttending`
                        )
                      ),
                      getDocs(
                        collection(
                          firestore,
                          `users/${userDoc.id}/eventsOrganising`
                        )
                      ),
                    ]);

                  userEvents.docs.forEach((doc) => {
                    if (
                      !seenEventIds.has(doc.id) &&
                      !eventsToExclude.has(doc.id)
                    ) {
                      seenEventIds.add(doc.id);
                      events.push({
                        id: doc.id,
                        ...doc.data(),
                        ownerId: userDoc.id,
                      });
                    }
                  });

                  [...userAttending.docs, ...userOrganizing.docs].forEach(
                    (doc) => {
                      if (
                        !seenEventIds.has(doc.id) &&
                        !eventsToExclude.has(doc.id)
                      ) {
                        seenEventIds.add(doc.id);
                        events.push({
                          id: doc.id,
                          ...doc.data(),
                          ownerId: userDoc.id,
                        });
                      }
                    }
                  );
                }
              })
          );

          attempts++;
        }
      }

      const currentDate = new Date();
      const validEvents = events
        .filter((event) => {
          if (!event.endDate || !event.endTime) return false;
          const [day, month, year] = event.endDate.split("/");
          const [hours, minutes] = event.endTime.split(":");
          const period = event.endTime.includes("PM") ? 12 : 0;
          const endDate = new Date(
            year,
            month - 1,
            day,
            parseInt(hours) + period,
            parseInt(minutes)
          );
          return endDate > currentDate;
        })
        .sort((a, b) => (b.attendeeCount || 0) - (a.attendeeCount || 0))
        .slice(0, 36);

      setInitialResults(validEvents);
    } catch (error) {
      setInitialResults([]);
    } finally {
      setIsInitialLoading(false);
    }
  }, [currentUser]);

  const scoreMatch = (event, searchTags, searchCountry, searchCity) => {
    let matches = 0;
    const nameParts = nameInput.toLowerCase().trim().split(" ");

    // Name matching
    if (nameInput) {
      if (event.eventName.toLowerCase().includes(nameInput.toLowerCase())) {
        matches++;
      }
    }

    // Tags matching
    const eventTags = (event.tags || []).map((t) => t.toLowerCase().trim());
    searchTags.forEach((tag) => {
      if (eventTags.some((et) => et.includes(tag.name.toLowerCase().trim()))) {
        matches++;
      }
    });

    // Location matching (only for in-person events)
    if (!event.isOnline) {
      if (
        searchCountry &&
        event.country.toLowerCase() === searchCountry.name.toLowerCase()
      ) {
        matches++;
      }
      if (
        searchCity &&
        event.city.toLowerCase() === searchCity.name.toLowerCase()
      ) {
        matches++;
      }
    }

    // Event type matching
    // if (eventType !== "All") {
    //   if (
    //     (eventType === "Online" && event.isOnline) ||
    //     (eventType === "In Person" && !event.isOnline)
    //   ) {
    //     matches++;
    //   }
    // }

    return matches;
  };

  const handleSearch = useCallback(async () => {
    setIsSearching(true);
    setHasSearched(true);

    setLastSearchCriteria({
      nameInput,
      tags,
      selectedCountry,
      selectedCity,
    });

    try {
      const searchParams = {
        hitsPerPage: 1000,
      };

      if (nameInput) {
        searchParams.query = nameInput;
      } else {
        const eventsToExclude = new Set();

        const [ownedEvents, attendingEvents, organizingEvents] =
          await Promise.all([
            getDocs(collection(firestore, `users/${currentUser.uid}/events`)),
            getDocs(
              collection(firestore, `users/${currentUser.uid}/eventsAttending`)
            ),
            getDocs(
              collection(firestore, `users/${currentUser.uid}/eventsOrganising`)
            ),
          ]);

        ownedEvents.docs.forEach((doc) => eventsToExclude.add(doc.id));
        attendingEvents.docs.forEach((doc) => eventsToExclude.add(doc.id));
        organizingEvents.docs.forEach((doc) => eventsToExclude.add(doc.id));
      }

      let facetFilters = [];

      if (selectedCountry) {
        facetFilters.push(`country:${selectedCountry.name}`);
      }

      if (selectedCity) {
        facetFilters.push(`city:${selectedCity.name}`);
      }

      if (tags.length > 0) {
        tags.forEach((tag) => {
          facetFilters.push(`_tags:${tag.name}`);
        });
      }

      if (facetFilters.length > 0) {
        searchParams.facetFilters = [facetFilters];
      }

      const { hits } = await index.search(
        searchParams.query || "",
        searchParams
      );

      const validHits = await Promise.all(
        hits.map(async (event) => {
          if (!event.ownerId) return null;
          if (!event.eventName?.trim()) return null; // Filter out events without names
          const isBlocked = await isUserBlocked(currentUser.uid, event.ownerId);
          if (isBlocked) return null;
          return event;
        })
      );

      const filteredHits = validHits.filter((hit) => hit !== null);

      let processedHits = filteredHits;

      if (!nameInput) {
        const currentDate = new Date();
        processedHits = filteredHits.filter((event) => {
          if (!event.endDate || !event.endTime || !event.eventName?.trim())
            return false;
          const [day, month, year] = event.endDate.split("/");
          const [hours, minutes] = event.endTime.split(":");
          const period = event.endTime.includes("PM") ? 12 : 0;
          const endDate = new Date(
            year,
            month - 1,
            day,
            parseInt(hours) + period,
            parseInt(minutes)
          );
          return endDate > currentDate;
        });
      }

      const scoredResults = processedHits.map((event) => ({
        ...event,
        matches: scoreMatch(event, tags, selectedCountry, selectedCity),
      }));

      const sortedResults = scoredResults.sort((a, b) => {
        if (b.matches !== a.matches) {
          return b.matches - a.matches;
        }
        return new Date(b.startDate) - new Date(a.startDate);
      });

      setSearchResults(sortedResults);
    } catch (error) {
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  }, [
    nameInput,
    tags,
    selectedCountry,
    selectedCity,
    currentUser?.uid,
    isUserBlocked,
    scoreMatch,
  ]);

  const triggerSearch = () => {
    if (isChanged) {
      setIsSearching(true);
    }
  };

  useEffect(() => {
    if (isSearching) {
      handleSearch();
    }
  }, [isSearching, handleSearch]);

  useEffect(() => {
    if (currentUser?.uid) {
      fetchInitialResults();
    }
  }, [currentUser?.uid]); // <-- Only depend on currentUser.uid

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser({ uid: user.uid });
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } else {
        setCurrentUser(null);
        setUserData({
          firstName: "",
          lastName: "",
          city: "",
          country: "",
          bio: "",
          profileImage: "",
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const [lastSearchCriteria, setLastSearchCriteria] = useState({
    nameInput: "",
    tags: [],
    selectedCountry: null,
    selectedCity: null,
  });

  // Update the shouldShowMatchTally function
  const shouldShowMatchTally = () => {
    const hasSearchCriteria =
      lastSearchCriteria.tags.length > 0 ||
      lastSearchCriteria.selectedCountry ||
      lastSearchCriteria.selectedCity;

    return hasSearched && !isSearching && hasSearchCriteria;
  };

  const handleRefresh = () => {
    setNameInput("");
    setTags([]);
    setSelectedCountry(null);
    setSelectedCity(null);
    setCountryInputValue("");
    setCityInputValue("");
    setIsChanged(false);
    setHasSearched(false);
    setSearchResults([]);
    setLastSearchCriteria({
      nameInput: "",
      tags: [],
      selectedCountry: null,
      selectedCity: null,
    });
    setShowFilters(false);
    fetchInitialResults();
  };

  return (
    <div className={classes.page}>
      {isMobile ? (
        <MobileNavbar userImage={userData.profileImage} />
      ) : (
        <MainNavbar userImage={userData.profileImage} />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              Events
              <svg
                className={`${classes.closeIcon} ${
                  showFilters ? classes.closeIconActive : ""
                }`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
                onClick={toggleFilters}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4"
                />
              </svg>
            </div>
            <div className={classes.cardAbout}>
              <label htmlFor="name" className={classes.label}>
                Name
              </label>
              <div className={classes.inputWrapper}>
                <input
                  type="text"
                  placeholder="Ex: YC Startup School"
                  value={nameInput}
                  onChange={handleNameInputChange}
                  className={classes.userInput}
                />
                <svg
                  className={classes.searchIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              {showFilters && (
                <>
                  <div className={classes.filters}>
                    {/* <div className={classes.section}>
                      <div className={classes.filterLabel}>Type</div>
                      <div>
                        <EventTypeDropdown
                          selectedType={eventType}
                          onTypeChange={handleTypeChange}
                        />
                      </div>
                    </div> */}
                    {/* {eventType !== "Online" && ( */}
                    <div className={classes.bottom}>
                      <div htmlFor="country" className={classes.filterLabel}>
                        Country
                      </div>
                      <CustomDropdown
                        options={countries}
                        selectedValue={selectedCountry}
                        onChange={handleCountryChange}
                        inputValue={countryInputValue}
                        setInputValue={setCountryInputValue}
                        disabled={loading}
                        placeholder="Ex: United States"
                      />
                      <div
                        htmlFor="city"
                        className={`${classes.filterLabel} ${
                          !selectedCountry || loading ? classes.disabled : ""
                        }`}
                      >
                        City
                      </div>
                      <CustomDropdown
                        options={cities}
                        selectedValue={selectedCity}
                        onChange={handleCityChange}
                        inputValue={cityInputValue}
                        setInputValue={setCityInputValue}
                        disabled={!selectedCountry || loading}
                        placeholder="Ex: San Francisco"
                      />
                    </div>
                    {/* )} */}
                    <div className={classes.section}>
                      <div className={classes.filterLabel}>Tags</div>
                      <TagsDropdown
                        selectedTags={tags}
                        onTagsChange={handleTagsChange}
                        onInputChange={(value) =>
                          handleInputChange(value, "tags")
                        }
                        disabled={loading}
                        placeholder="Ex: Accelerator, Hackathon, Demo Day"
                        customClassName={classes.skillsInput}
                      />
                      <div className={classes.smallerPrint}>
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <p>Use commas to seperate tags</p>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className={classes.buttons}>
                <button
                  type="button"
                  className={`${classes.button} ${
                    isSearching ? classes.loading : ""
                  }`}
                  disabled={
                    isSearching ||
                    (!nameInput &&
                      !tags.length &&
                      !selectedCountry &&
                      !selectedCity)
                  }
                  onClick={() => {
                    handleSearch();
                  }}
                >
                  {isSearching ? (
                    <>
                      <span
                        className={`material-symbols-outlined ${classes.loadingIcon}`}
                      >
                        progress_activity
                      </span>
                      <span className={classes.searching}>Searching...</span>
                    </>
                  ) : (
                    <>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeWidth="2"
                          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      Search
                    </>
                  )}
                </button>
                <button
                  className={classes.refreshButton}
                  onClick={handleRefresh}
                >
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 9H8a5 5 0 0 0 0 10h9m4-10-4-4m4 4-4 4"
                    />
                  </svg>
                  Reset
                </button>
              </div>
            </div>
          </div>

          {isInitialLoading ? (
            <div className={classes.noResults}>
              <span
                className={`material-symbols-outlined ${classes.pageLoader}`}
              >
                progress_activity
              </span>
            </div>
          ) : hasSearched ? (
            isSearching ? (
              <div className={classes.noResults}>
                {/* Empty div to maintain layout during search */}
              </div>
            ) : searchResults.length > 0 ? (
              searchResults.map((event) => (
                <div
                  key={event.objectID || event.id}
                  className={classes.profileCard}
                  onClick={() => handleEventClick(event)}
                >
                  <div className={classes.profileHeader}>
                    <div className={classes.profileImage}>
                      <img src={event.eventImage || eventImage} alt="" />
                    </div>
                    {shouldShowMatchTally() && hasSearched && !isSearching && (
                      <div className={classes.matchTally}>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className={classes.matchCount}>
                          {event.matches}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={classes.profileTitle}>
                    {event.eventName}
                    <span className={classes.connectionType}>• Event</span>
                  </div>

                  {(event.tagline || event.link) && (
                    <div className={classes.userDetails}>
                      {event.tagline && (
                        <div className={classes.bio}>{event.tagline}</div>
                      )}
                      {event.link && (
                        <div className={classes.link}>
                          <a
                            href={event.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {event.linkText || event.link}
                          </a>
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangeRed"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.75"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  )}

                  <button
                    className={classes.viewProfileButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEventClick(event);
                    }}
                  >
                    See event
                    <svg
                      class="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 12H5m14 0-4 4m4-4-4-4"
                      />
                    </svg>
                  </button>
                </div>
              ))
            ) : (
              <div className={classes.noNotifications}>
                <div className={classes.bold}>No results found</div>
                <div className={classes.text}>
                  Try searching for something else.
                </div>
                <div className={classes.homeButton} onClick={handleRefresh}>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                    />
                  </svg>
                  Refresh
                </div>
              </div>
            )
          ) : initialResults.length > 0 ? (
            initialResults.map((event) => (
              <div
                key={event.id}
                className={classes.profileCard}
                onClick={() => handleEventClick(event)}
              >
                <div className={classes.reactionHeader}>Suggested</div>
                <div className={classes.profileHeader}>
                  <div className={classes.profileImage}>
                    <img src={event.eventImage || eventImage} alt="" />
                  </div>
                </div>
                <div className={classes.profileTitle}>
                  {event.eventName}
                  <span className={classes.connectionType}>• Event</span>
                </div>
                {(event.tagline || event.link) && (
                  <div className={classes.userDetails}>
                    {event.tagline && (
                      <div className={classes.bio}>{event.tagline}</div>
                    )}
                    {event.link && (
                      <div className={classes.link}>
                        <a
                          href={event.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {event.linkText || event.link}
                        </a>
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="orangeRed"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.75"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                )}

                <button
                  className={classes.viewProfileButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEventClick(event);
                  }}
                >
                  See event
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"
                    />
                  </svg>
                </button>
              </div>
            ))
          ) : (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No suggestions found</div>
              <div className={classes.text}>Try checking Home for updates.</div>
              <Link to="/home" className={classes.homeButton}>
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
                    clipRule="evenodd"
                  />
                </svg>
                Home
              </Link>
            </div>
          )}
        </div>

        <div className={classes.rightCards}>
          <Ad />
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Events;
