import React, { useState, useEffect } from "react";
import { firestore } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import _ from "lodash";
import styles from "./UserStats.module.css";

function UserStats() {
  const [stats, setStats] = useState({
    totalUsers: null,
    countries: [],
    cities: [],
    dailySignups: [],
    growthStats: {
      last24h: 0,
      last7d: 0,
      last30d: 0,
    },
    loading: true,
    error: null,
  });

  useEffect(() => {
    async function fetchStats() {
      try {
        const usersRef = collection(firestore, "users");
        const snapshot = await getDocs(usersRef);

        const users = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            createdAt: data.createdAt ? new Date(data.createdAt) : null,
          };
        });

        const totalUsers = users.length;

        // Calculate time-based metrics
        const now = new Date();
        const last24h = users.filter(
          (user) =>
            user.createdAt && now - user.createdAt <= 24 * 60 * 60 * 1000
        ).length;

        const last7d = users.filter(
          (user) =>
            user.createdAt && now - user.createdAt <= 7 * 24 * 60 * 60 * 1000
        ).length;

        const last30d = users.filter(
          (user) =>
            user.createdAt && now - user.createdAt <= 30 * 24 * 60 * 60 * 1000
        ).length;

        // Location stats
        const locations = users.map((user) => ({
          country: user.country || "Unknown",
          city: user.city || "Unknown",
        }));

        const countryTallies = _.countBy(locations, "country");
        const cityTallies = _.countBy(locations, "city");

        const countries = Object.entries(countryTallies)
          .map(([name, count]) => ({
            name,
            count,
            percentage: ((count / totalUsers) * 100).toFixed(1),
          }))
          .sort((a, b) => b.count - a.count);

        const cities = Object.entries(cityTallies)
          .map(([name, count]) => ({
            name,
            count,
            percentage: ((count / totalUsers) * 100).toFixed(1),
          }))
          .sort((a, b) => b.count - a.count);

        // Daily signups with growth rate
        const signupsByDate = _.groupBy(users, (user) => {
          if (!user.createdAt) return "Unknown Date";
          return user.createdAt.toISOString().split("T")[0];
        });

        const dailySignups = Object.entries(signupsByDate)
          .map(([date, usersOnDate], index, array) => {
            const prevDay = array[index + 1];
            const growthRate = prevDay
              ? (
                  ((usersOnDate.length - prevDay[1].length) /
                    prevDay[1].length) *
                  100
                ).toFixed(1)
              : null;

            return {
              date,
              count: usersOnDate.length,
              growthRate,
            };
          })
          .sort((a, b) => b.date.localeCompare(a.date));

        setStats({
          totalUsers,
          countries,
          cities,
          dailySignups,
          growthStats: {
            last24h,
            last7d,
            last30d,
          },
          loading: false,
          error: null,
        });
      } catch (error) {
        console.error("Error fetching stats:", error);
        setStats((prev) => ({
          ...prev,
          loading: false,
          error: "Failed to fetch user statistics",
        }));
      }
    }

    fetchStats();
  }, []);

  const formatDate = (dateStr) => {
    if (dateStr === "Unknown Date") return dateStr;
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  return (
    <div className={styles.container}>
      <div className={styles.totalUsers}>
        <h2>Total Users</h2>
        <div className={styles.count}>{stats.totalUsers}</div>
        <div className={styles.growthMetrics}>
          <div className={styles.growthMetric}>
            <span>Last 24h</span>
            <span>+{stats.growthStats.last24h}</span>
          </div>
          <div className={styles.growthMetric}>
            <span>Last 7d</span>
            <span>+{stats.growthStats.last7d}</span>
          </div>
          <div className={styles.growthMetric}>
            <span>Last 30d</span>
            <span>+{stats.growthStats.last30d}</span>
          </div>
        </div>
      </div>

      <div className={styles.statsGrid}>
        <div className={styles.statsCard}>
          <h3>Users by Country</h3>
          <div className={styles.statsList}>
            {stats.countries.map(({ name, count, percentage }) => (
              <div key={name} className={styles.statsRow}>
                <span className={styles.locationName}>{name}</span>
                <div className={styles.statsInfo}>
                  <span className={styles.badge}>{count}</span>
                  <span className={styles.percentage}>({percentage}%)</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.statsCard}>
          <h3>Users by City</h3>
          <div className={styles.statsList}>
            {stats.cities.map(({ name, count, percentage }) => (
              <div key={name} className={styles.statsRow}>
                <span className={styles.locationName}>{name}</span>
                <div className={styles.statsInfo}>
                  <span className={styles.badge}>{count}</span>
                  <span className={styles.percentage}>({percentage}%)</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.statsCard}>
          <h3>Daily Signups</h3>
          <div className={styles.statsList}>
            {stats.dailySignups.map(({ date, count, growthRate }) => (
              <div key={date} className={styles.statsRow}>
                <span className={styles.locationName}>{formatDate(date)}</span>
                <div className={styles.statsInfo}>
                  <span className={styles.badge}>{count}</span>
                  {growthRate && (
                    <span
                      className={
                        growthRate > 0
                          ? styles.positiveGrowth
                          : styles.negativeGrowth
                      }
                    >
                      ({growthRate > 0 ? "+" : ""}
                      {growthRate}%)
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserStats;
