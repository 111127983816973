import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  memo,
} from "react";
import Ad from "../../../components/Cards/Ad";
import { filterBlockedContent } from "../../../utils/block-filter";
import defaultStartupImage from "../../../assets/images/startupImage.jpg";
import StartupInteractionModal from "../../../components/Modals/StartupInteractionModal";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import StartupInteractionToggle from "../../../components/Dropdowns/StartupInteractionToggle";
import profileImage from "../../../assets/icons/profileImage.jpg";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import PostModal from "../../../components/Modals/PostModal";
import { useUpload } from "../../../contexts/UploadContext";
import { Link, useNavigate } from "react-router-dom";
import EmojiPicker from "../../../components/Dropdowns/EmojiPicker";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import RepostsModal from "../../../components/Modals/RepostsModal";
import classes from "./Feed.module.css";
import { convertUrlsToLinks } from "../../../utils/textUtils";
import { firestore, auth } from "../../../firebase";
import {
  doc,
  increment,
  addDoc,
  getDoc,
  collection,
  query,
  orderBy,
  writeBatch,
  where,
  limit,
  setDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  deleteField,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";

const MemoizedToggle = React.memo(
  ({ currentUser, selectedStartup, onModalOpen, onModalClose, ...props }) => (
    <StartupInteractionToggle
      currentUser={currentUser}
      selectedStartup={selectedStartup}
      onModalOpen={onModalOpen}
      onModalClose={onModalClose}
      imgProps={{ "data-is-startup": !!selectedStartup }}
      {...props}
    />
  )
);

function Feed() {
  const [deletingCommentMessage, setDeletingCommentMessage] = useState(null);
  const [activeStartupToggle, setActiveStartupToggle] = useState(null); // Add this with your other state variables
  const [reportMessage, setReportMessage] = useState(null);
  const [userStartups, setUserStartups] = useState([]);
  const [newComment, setNewComment] = useState({});
  const [replyComments, setReplyComments] = useState({});
  const [activeCommentId, setActiveCommentId] = useState({});
  const [visibleComments, setVisibleComments] = useState({});
  const [visibleReplies, setVisibleReplies] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});

  const [showCommentEmojiPicker, setShowCommentEmojiPicker] = useState({});
  const [activeCommentForReaction, setActiveCommentForReaction] =
    useState(null);
  const [deletingPostMessage, setDeletingPostMessage] = useState(null);
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);
  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const commentInputRef = useRef({});
  const replyInputRef = useRef({});
  const editTextareaRef = useRef(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    profileImage: "",
  });
  const [hasCompletedInitialFetch, setHasCompletedInitialFetch] =
    useState(false);

  const [loadingImages, setLoadingImages] = useState({});
  const showMoreRef = useRef(null);
  const [commentData, setCommentData] = useState({});
  const [loadingComments, setLoadingComments] = useState({});
  const [showComments, setShowComments] = useState({});
  const [videoVolume, setVideoVolume] = useState({});

  const [showStartupModal, setShowStartupModal] = useState(false);
  const [startupModalScrollPosition, setStartupModalScrollPosition] =
    useState(0);

  const [loadedPostIds, setLoadedPostIds] = useState(new Set());
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [authChecked, setAuthChecked] = useState(false);
  const [comments, setComments] = useState({});
  const [cancelMessage, setCancelMessage] = useState(null);
  const [posts, setPosts] = useState([]);
  const [showPostModal, setShowPostModal] = useState(false);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [showDropdown, setShowDropdown] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [hasTriedInitialFetch, setHasTriedInitialFetch] = useState(false);
  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const connectionTypeCache = new Map();
  const [allSeenPostIds, setAllSeenPostIds] = useState(new Set());
  const [editingPost, setEditingPost] = useState(null);
  const [shareStates, setShareStates] = useState({});
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [errorPopup, setErrorPopup] = useState(null);
  const [repostMessage, setRepostMessage] = useState(null);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [successPostMessage, setSuccessPostMessage] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const { state, updateUploadProgress, removeUploadingPost, cancelUpload } =
    useUpload();
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const navigate = useNavigate();
  const videoRefs = useRef({});
  const [videoVolumes, setVideoVolumes] = useState({});
  const [uniquePostsMap, setUniquePostsMap] = useState(new Map());

  // Effect for handling window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 648);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleUserStartupsChange = useCallback((startups) => {
    setUserStartups(startups);
  }, []);

  const PDFPreview = React.memo(
    ({ content, hasInteractions, classes }) => {
      const getFileName = React.useCallback(() => {
        if (!content?.fileName) return "Unnamed PDF";
        const rawFileName = Array.isArray(content.fileName)
          ? content.fileName[0] || "Unnamed PDF"
          : content.fileName;
        return rawFileName.replace(/\.pdf$/i, "");
      }, [content?.fileName]);

      if (!content || !content.url) return null;

      return (
        <div className={classes.postContentWrapper}>
          <a
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              !hasInteractions ? classes.pdfPreviewNoInteractions : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <div className={classes.fileContainer}>
                <div className={classes.fileAbout}>
                  <div className={classes.fileName}>{getFileName()}</div>
                </div>
                <div className={classes.open}>
                  Open PDF
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.content?.url === nextProps.content?.url &&
        prevProps.content?.fileName === nextProps.content?.fileName &&
        prevProps.hasInteractions === nextProps.hasInteractions
      );
    }
  );

  useEffect(() => {
    const fetchUserStartups = async () => {
      if (!currentUser?.uid) return;
      try {
        const startupsRef = collection(
          firestore,
          `users/${currentUser.uid}/startups`
        );
        const startupsSnapshot = await getDocs(startupsRef);
        const startupsData = startupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserStartups(startupsData);
      } catch (error) {}
    };

    fetchUserStartups();
  }, [currentUser?.uid]);

  const getRootCommentId = async (post, postId, commentId) => {
    try {
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }
      const commentData = commentSnap.data();

      if (commentData.isRootComment || !commentData.parentCommentId) {
        return commentId;
      }

      return getRootCommentId(post, postId, commentData.parentCommentId);
    } catch (error) {
      throw error;
    }
  };

  const checkConnectionType = async (userId, targetId, isStartup = false) => {
    // Cache key creation
    const cacheKey = `${userId}_${targetId}_${isStartup}`;

    // Check cache first
    if (connectionTypeCache.has(cacheKey)) {
      return connectionTypeCache.get(cacheKey);
    }

    // If it's a startup post, always return "Startup"
    if (isStartup) {
      connectionTypeCache.set(cacheKey, "Startup");
      return "Startup";
    }

    if (userId === targetId) {
      connectionTypeCache.set(cacheKey, "You");
      return "You";
    }

    try {
      // Check for direct connection
      const userConnectionRef = doc(
        firestore,
        `users/${userId}/connections/${targetId}`
      );
      const userConnectionDoc = await getDoc(userConnectionRef);

      if (
        userConnectionDoc.exists() &&
        userConnectionDoc.data().status === "Connected"
      ) {
        connectionTypeCache.set(cacheKey, "Direct");
        return "Direct";
      }

      // Check for indirect connections
      const userConnectionsRef = collection(
        firestore,
        `users/${userId}/connections`
      );
      const userConnectionsQuery = query(
        userConnectionsRef,
        where("status", "==", "Connected")
      );
      const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

      // Create an array of promises for parallel execution
      const connectionChecks = userConnectionsSnapshot.docs.map(
        async (conn) => {
          const indirectConnectionRef = doc(
            firestore,
            `users/${conn.id}/connections/${targetId}`
          );
          const indirectConnectionDoc = await getDoc(indirectConnectionRef);
          return (
            indirectConnectionDoc.exists() &&
            indirectConnectionDoc.data().status === "Connected"
          );
        }
      );

      // Execute all checks in parallel
      const results = await Promise.all(connectionChecks);

      // If any check returns true, it's an indirect connection
      if (results.some((result) => result)) {
        connectionTypeCache.set(cacheKey, "Indirect");
        return "Indirect";
      }

      connectionTypeCache.set(cacheKey, "Extended");
      return "Extended";
    } catch (error) {
      connectionTypeCache.set(cacheKey, "Extended");
      return "Extended"; // Default fallback
    }
  };

  const findPostById = useCallback(
    (postId) => {
      const post = posts.find((p) => p?.id === postId);

      if (post && post.user.isStartup) {
        return {
          ...post,
          user: {
            ...post.user,
            startupOwnerId: post.user.startupOwnerId || currentUser.uid,
          },
        };
      }
      return post;
    },
    [posts, currentUser]
  );

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const renderEditButtons = (postId, commentId, isReply = false) => (
    <div className={classes.editCommentButtons}>
      <div
        className={`${classes.saveChangesButton} ${
          editCommentText.trim() === "" ? classes.disabledButton : ""
        }`}
        onClick={() =>
          editCommentText.trim() !== "" &&
          handleSaveCommentChanges(postId, commentId, isReply)
        }
      >
        Save
      </div>
      <div className={classes.dot}> • </div>
      <div className={classes.cancelEditButton} onClick={handleCancelEdit}>
        Cancel
      </div>
    </div>
  );

  const handleSaveCommentChanges = useCallback(
    async (postId, commentId, updatedText, isReply = false) => {
      if (!currentUser) return;

      const targetPost = posts.find((p) => p.id === postId);
      if (!targetPost) return;

      const trimmedText = updatedText.trim();
      if (trimmedText === "") {
        setErrorPopup({
          message: "Comment cannot be empty.",
          isError: true,
        });
        return;
      }

      try {
        const basePath = targetPost.user.isStartup
          ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}`
          : `users/${targetPost.user.id}`;

        const commentRef = doc(
          firestore,
          `${basePath}/posts/${postId}/comments/${commentId}`
        );
        const commentSnap = await getDoc(commentRef);

        if (!commentSnap.exists()) {
          throw new Error("Comment not found");
        }

        const commentData = commentSnap.data();
        let canEdit = false;
        if (commentData.isStartup) {
          canEdit = commentData.startupOwnerId === currentUser.uid;
        } else {
          canEdit = commentData.userId === currentUser.uid;
        }

        if (!canEdit) {
          throw new Error("Not authorized to edit this comment");
        }

        const processedText = convertUrlsToLinks(trimmedText);
        await updateDoc(commentRef, {
          text: processedText,
          edited: true,
        });

        setComments((prevComments) => ({
          ...prevComments,
          [postId]: prevComments[postId].map((comment) =>
            comment.id === commentId
              ? { ...comment, text: processedText, edited: true }
              : comment
          ),
        }));

        setEditingCommentId(null);

        const commenterPath = commentData.isStartup
          ? `users/${commentData.startupOwnerId}/startups/${commentData.startupId}`
          : `users/${commentData.userId}`;

        const commentedPostRef = doc(
          firestore,
          `${commenterPath}/commentedPosts/${postId}`
        );
        const commentedPostSnap = await getDoc(commentedPostRef);

        if (commentedPostSnap.exists()) {
          const commentedPostData = commentedPostSnap.data();
          if (commentedPostData.comments?.[commentId]) {
            await updateDoc(commentedPostRef, {
              [`comments.${commentId}.commentText`]: processedText,
            });
          }
        }
      } catch (error) {
        setErrorPopup({
          message:
            error.message === "Comment not found"
              ? "This comment has been deleted."
              : error.message === "Not authorized to edit this comment"
              ? "You don't have permission to edit this comment."
              : "An error occurred while updating the comment.",
          isError: true,
        });
      }
    },
    [currentUser, posts]
  );

  const handleCancelEdit = () => {
    // Immediately update UI states
    setEditingCommentId(null);
    setEditCommentText("");

    // Force re-render of comments section
    setComments((prev) => ({ ...prev }));
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const [isIOS, setIsIOS] = useState(false);

  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Try to force the keyboard to open
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      // For iOS devices
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const renderCommentOptions = (postId, comment) => {
    // First check if this is a startup comment and if the current user is properly authorized
    const isOwnComment = comment.isStartup
      ? comment.startupOwnerId === currentUser.uid // User owns the startup that made the comment
      : comment.userId === currentUser.uid; // User's own comment

    const isPostOwner =
      posts.find((p) => p.id === postId)?.user?.id === currentUser?.uid;

    return (
      <div className={classes.commentOptionsDropdown}>
        {isOwnComment && (
          <button
            className={classes.editCommentButton}
            onClick={() =>
              handleEditComment(postId, comment.id, !comment.isRootComment)
            }
          >
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
              />
            </svg>
            Edit
          </button>
        )}

        {(isOwnComment || isPostOwner) && (
          <button
            className={classes.deleteCommentButton}
            onClick={() => handleDeleteComment(postId, comment.id)}
          >
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
              />
            </svg>
            Delete
          </button>
        )}

        {!isOwnComment && !isPostOwner && (
          <button
            className={classes.reportCommentButton}
            onClick={() => handleReportComment(comment.id)}
          >
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Report
          </button>
        )}
      </div>
    );
  };

  const maintainScrollPosition = useCallback((action) => {
    const scrollPos = window.scrollY;
    action();
    requestAnimationFrame(() => {
      window.scrollTo(0, scrollPos);
    });
  }, []);

  const handleShare = useCallback((postId) => {
    // Set the share state
    setShareStates((prev) => ({ ...prev, [postId]: "Copied" }));

    // Force an immediate update of posts to trigger re-render
    setPosts((prevPosts) => [...prevPosts]);

    const postUrl = `${window.location.origin}/post/${postId}`;
    navigator.clipboard.writeText(postUrl).then(() => {
      setTimeout(() => {
        setShareStates((prev) => ({ ...prev, [postId]: null }));
        // Force another update when removing the "Copied" state
        setPosts((prevPosts) => [...prevPosts]);
      }, 5000);
    });
  }, []);

  const handleEditComment = (postId, commentId, isReply = false) => {
    if (!postId || !comments[postId]) {
      return;
    }

    // Immediately update UI states
    maintainScrollPosition(() => {
      setEditingCommentId(commentId);
      setOpenCommentDropdown(null);

      // Force immediate re-render of comments
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) =>
          comment.id === commentId ? { ...comment, isEditing: true } : comment
        ),
      }));

      // Find and set comment text immediately
      const commentToEdit = comments[postId].find((c) => c.id === commentId);
      if (commentToEdit) {
        const strippedText = stripHtmlTags(commentToEdit.text);
        setEditCommentText(strippedText);

        // Focus and adjust textarea in next frame
        requestAnimationFrame(() => {
          if (editTextareaRef.current) {
            editTextareaRef.current.focus();
            adjustTextareaHeight(editTextareaRef.current);
            const length = editTextareaRef.current.value.length;
            editTextareaRef.current.setSelectionRange(length, length);
          }
        });
      }
    });
  };

  const handleDeleteComment = async (postId, commentId) => {
    if (!currentUser) {
      return;
    }

    // Show "Deleting..." message immediately
    setDeletingCommentMessage({
      text: "Deleting comment...",
      isLoading: true,
    });

    try {
      const targetPost = posts.find((p) => p.id === postId);
      if (!targetPost) {
        setErrorPopup({
          message: "The post containing this comment has been deleted.",
          isError: true,
        });
        return;
      }

      // Get the correct base path based on whether it's a startup post
      const basePath = targetPost.user.isStartup
        ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}`
        : `users/${targetPost.user.id}`;

      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${postId}/comments`
      );
      const commentToDeleteRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentToDeleteRef);

      if (!commentSnap.exists()) {
        setErrorPopup({
          message: "This comment has already been deleted.",
          isError: true,
        });
        return;
      }

      const commentData = commentSnap.data();
      let canDelete = false;

      if (commentData.userId.startsWith("startup_")) {
        const startupId = commentData.userId.replace("startup_", "");
        canDelete =
          commentData.startupOwnerId === currentUser.uid ||
          (targetPost.user.isStartup
            ? currentUser.uid === targetPost.user.startupOwnerId
            : currentUser.uid === targetPost.user.id);
      } else {
        canDelete =
          currentUser.uid === commentData.userId ||
          (targetPost.user.isStartup
            ? currentUser.uid === targetPost.user.startupOwnerId
            : currentUser.uid === targetPost.user.id);
      }

      if (!canDelete) {
        setErrorPopup({
          message: "You don't have permission to delete this comment.",
          isError: true,
        });
        return;
      }

      let deletedCount = 0;
      const deletedCommentIds = new Set();
      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      const deleteStartupNotifications = async (commentData, commentRef) => {
        try {
          if (commentData.userId.startsWith("startup_")) {
            const startupId = commentData.userId.replace("startup_", "");

            const replyNotificationsQuery = query(
              collection(
                firestore,
                `users/${commentData.startupOwnerId}/notifications`
              ),
              where("parentCommentId", "==", commentRef.id)
            );

            const replyNotificationsSnapshot = await getDocs(
              replyNotificationsQuery
            );
            const deletePromises = replyNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            );
            await Promise.all(deletePromises);

            const commentNotificationRef = doc(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`,
              `comment_${commentRef.id}`
            );
            await deleteDoc(commentNotificationRef);
          }

          if (commentData.parentCommentId) {
            const parentCommentRef = doc(
              commentsRef,
              commentData.parentCommentId
            );
            const parentCommentSnap = await getDoc(parentCommentRef);

            if (parentCommentSnap.exists()) {
              const parentCommentData = parentCommentSnap.data();
              if (parentCommentData.userId.startsWith("startup_")) {
                const replyNotificationRef = doc(
                  firestore,
                  `users/${parentCommentData.startupOwnerId}/notifications`,
                  `reply_${commentRef.id}`
                );
                await deleteDoc(replyNotificationRef);
              }
            }
          }
        } catch (error) {}
      };

      const deleteCommentsAndReplies = async (commentRef) => {
        const commentSnapshot = await getDoc(commentRef);
        if (!commentSnapshot.exists()) return;

        const commentData = commentSnapshot.data();
        deletedCount++;
        deletedCommentIds.add(commentRef.id);

        if (commentData.userId.startsWith("startup_")) {
          const startupId = commentData.userId.replace("startup_", "");
          affectedStartupIds.add(startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        await deleteStartupNotifications(commentData, commentRef);

        // Delete comment notifications for post owner
        if (commentData.userId !== targetPost.user.id) {
          await deleteDoc(
            doc(
              firestore,
              `users/${
                targetPost.user.startupOwnerId || targetPost.user.id
              }/notifications/comment_${commentRef.id}`
            )
          );
        }

        // Delete reply notifications
        if (commentData.parentCommentId) {
          const parentCommentRef = doc(
            commentsRef,
            commentData.parentCommentId
          );
          const parentCommentSnap = await getDoc(parentCommentRef);
          if (parentCommentSnap.exists()) {
            const parentCommentData = parentCommentSnap.data();
            if (
              parentCommentData.userId !== commentData.userId &&
              !parentCommentData.userId.startsWith("startup_")
            ) {
              await deleteDoc(
                doc(
                  firestore,
                  `users/${parentCommentData.userId}/notifications/reply_${commentRef.id}`
                )
              );
            }
          }
        }

        // Delete reaction notifications
        const reactionPromises = [];
        if (commentData.isStartup) {
          const startupOwnerReactionsQuery = query(
            collection(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`
            ),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const startupOwnerReactionsSnapshot = await getDocs(
            startupOwnerReactionsQuery
          );
          reactionPromises.push(
            ...startupOwnerReactionsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            )
          );
        }

        // Delete all reaction notifications from users and startups
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          // Handle user reactions
          const userReactionNotificationsQuery = query(
            collection(firestore, `users/${userDoc.id}/notifications`),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const userReactionNotificationsSnapshot = await getDocs(
            userReactionNotificationsQuery
          );
          reactionPromises.push(
            ...userReactionNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            )
          );

          // Handle startup reactions
          const userStartupsSnapshot = await getDocs(
            collection(firestore, `users/${userDoc.id}/startups`)
          );
          for (const startupDoc of userStartupsSnapshot.docs) {
            const startupId = startupDoc.id;
            const startupReactionNotificationsQuery = query(
              collection(firestore, `users/${userDoc.id}/notifications`),
              where("type", "==", "comment_reaction"),
              where("commentId", "==", commentRef.id),
              where("reactorId", "==", `startup_${startupId}`)
            );
            const startupReactionNotificationsSnapshot = await getDocs(
              startupReactionNotificationsQuery
            );
            reactionPromises.push(
              ...startupReactionNotificationsSnapshot.docs.map((doc) =>
                deleteDoc(doc.ref)
              )
            );
          }
        }
        await Promise.all(reactionPromises);

        // Delete replies
        const repliesQuery = query(
          commentsRef,
          where("parentCommentId", "==", commentRef.id)
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        await Promise.all(
          repliesSnapshot.docs.map((doc) => deleteCommentsAndReplies(doc.ref))
        );

        await deleteDoc(commentRef);
      };

      await deleteCommentsAndReplies(commentToDeleteRef);

      const batch = writeBatch(firestore);

      // Update post data
      const postRef = getPostRef(targetPost);
      batch.update(postRef, {
        commentCount: increment(-deletedCount),
      });

      for (const userId of affectedUserIds) {
        batch.update(postRef, {
          [`comments.${userId}`]: deleteField(),
        });
      }
      for (const startupId of affectedStartupIds) {
        batch.update(postRef, {
          [`comments.startup_${startupId}`]: deleteField(),
        });
      }

      // Update commentedPosts for users
      for (const userId of affectedUserIds) {
        const commentedPostRef = doc(
          firestore,
          `users/${userId}/commentedPosts/${postId}`
        );
        const commentedPostSnap = await getDoc(commentedPostRef);

        if (commentedPostSnap.exists()) {
          const commentedPostData = commentedPostSnap.data();
          const updatedComments = { ...commentedPostData.comments };

          deletedCommentIds.forEach((commentId) => {
            delete updatedComments[commentId];
          });

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(commentedPostRef);
          } else {
            batch.update(commentedPostRef, { comments: updatedComments });
          }
        }
      }

      // Update commentedPosts for startups
      for (const startupId of affectedStartupIds) {
        const usersSnapshot = await getDocs(collection(firestore, "users"));

        for (const userDoc of usersSnapshot.docs) {
          try {
            const startupCommentedPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/commentedPosts/${postId}`
            );
            const startupCommentedPostSnap = await getDoc(
              startupCommentedPostRef
            );

            if (startupCommentedPostSnap.exists()) {
              const startupCommentedPostData = startupCommentedPostSnap.data();
              const updatedComments = { ...startupCommentedPostData.comments };

              deletedCommentIds.forEach((commentId) => {
                delete updatedComments[commentId];
              });

              if (Object.keys(updatedComments).length === 0) {
                batch.delete(startupCommentedPostRef);
              } else {
                batch.update(startupCommentedPostRef, {
                  comments: updatedComments,
                });
              }
            }
          } catch (error) {}
        }
      }

      await batch.commit();

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].filter(
          (c) =>
            !deletedCommentIds.has(c.id) &&
            !deletedCommentIds.has(c.rootCommentId)
        ),
      }));

      setPosts((prevPosts) =>
        prevPosts.map((p) => {
          if (p.id === postId) {
            const updatedPost = {
              ...p,
              commentCount: Math.max((p.commentCount || 0) - deletedCount, 0),
            };
            const updatedComments = { ...updatedPost.comments };
            affectedUserIds.forEach((userId) => {
              delete updatedComments[userId];
            });
            affectedStartupIds.forEach((startupId) => {
              delete updatedComments[`startup_${startupId}`];
            });
            updatedPost.comments = updatedComments;
            return updatedPost;
          }
          return p;
        })
      );

      setOpenCommentDropdown(null);

      // Show success message
      setDeletingCommentMessage({
        text: "Comment deleted",
        isLoading: false,
      });
    } catch (error) {
      setErrorPopup({
        message: "An error occurred while deleting the comment.",
        isError: true,
      });
    } finally {
      // If there was an error, clear the "Deleting..." message
      if (deletingCommentMessage?.isLoading) {
        setDeletingCommentMessage(null);
      }
    }
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const handleCommentEmojiSelect = async (postId, commentId, emoji) => {
    if (!currentUser || !commentId) return;

    try {
      const targetPost = posts.find((p) => p.id === postId);
      if (!targetPost) {
        throw new Error("Post not found");
      }

      const basePath = targetPost.user.isStartup
        ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}`
        : `users/${targetPost.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;
      let updatedReactions = { ...(commentData.reactions || {}) };

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) => {
          if (comment.id === commentId) {
            const newReactions = { ...comment.reactions } || {};
            if (newReactions[reactorId] === emoji) {
              delete newReactions[reactorId];
            } else {
              newReactions[reactorId] = emoji;
            }
            return { ...comment, reactions: newReactions };
          }
          if (
            !comment.isRootComment &&
            comment.rootCommentId === commentData.rootCommentId
          ) {
            if (comment.id === commentId) {
              const newReactions = { ...comment.reactions } || {};
              if (newReactions[reactorId] === emoji) {
                delete newReactions[reactorId];
              } else {
                newReactions[reactorId] = emoji;
              }
              return { ...comment, reactions: newReactions };
            }
          }
          return comment;
        }),
      }));

      if (updatedReactions[reactorId] === emoji) {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];

        const notificationRef = doc(
          firestore,
          `users/${
            commentData.isStartup
              ? commentData.startupOwnerId
              : commentData.userId
          }/notifications`,
          `comment_reaction_${commentId}_${reactorId}`
        );
        await deleteDoc(notificationRef);
      } else {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        const shouldNotify =
          (selectedStartup &&
            !(
              commentData.userId === currentUser.uid ||
              (commentData.isStartup &&
                commentData.startupOwnerId === currentUser.uid)
            )) ||
          (!selectedStartup &&
            (commentData.isStartup
              ? commentData.startupOwnerId !== currentUser.uid
              : commentData.userId !== currentUser.uid));

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );

          const notificationData = {
            type: "comment_reaction",
            postId: postId,
            commentId: commentId,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage || defaultStartupImage
              : currentUserProfileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            commentText: commentData.text,
            isReply: !commentData.isRootComment,
            postPreview: {
              text: targetPost.text || "",
              mediaType: targetPost.content?.type || null,
              mediaUrl: targetPost.content?.url || null,
              fileName: targetPost.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage || defaultStartupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }
    } catch (error) {
      if (error.message === "Post not found") {
        setErrorPopup({
          message: "The post containing this comment has been deleted.",
          isError: true,
        });
      } else if (error.message === "Comment not found") {
        setErrorPopup({
          message: "This comment has been deleted.",
          isError: true,
        });
      } else {
        setErrorPopup({
          message: "An error occurred while updating the reaction.",
          isError: true,
        });
      }
    }
  };

  const handleAddComment = useCallback(
    async (postId, commentText, parentCommentId = null) => {
      if (!currentUser) return;

      const targetPost = posts.find((p) => p.id === postId);
      if (!targetPost) return;

      if (!commentText.trim()) return;

      try {
        let commenterInfo;
        if (selectedStartup) {
          const startupRef = doc(
            firestore,
            `users/${currentUser.uid}/startups/${selectedStartup.id}`
          );
          const startupDoc = await getDoc(startupRef);
          const startupData = startupDoc.data();

          commenterInfo = {
            id: `startup_${selectedStartup.id}`,
            name: selectedStartup.startupName,
            image: selectedStartup.startupImage || defaultStartupImage,
            bio: startupData.bio || "",
            link: startupData.link || null,
            linkText: startupData.linkText || null,
            connectionType: "Startup",
            isStartup: true,
            startupId: selectedStartup.id,
            ownerId: currentUser.uid,
            startupUrlSlug: startupData.startupUrlSlug,
          };
        } else {
          const userDoc = await getDoc(
            doc(firestore, "users", currentUser.uid)
          );
          const userData = userDoc.data();

          commenterInfo = {
            id: currentUser.uid,
            name: `${userData.firstName} ${userData.lastName}`,
            image: userData.profileImage || profileImage,
            bio: userData.bio || "",
            link: userData.link || null,
            linkText: userData.linkText || null,
            connectionType: "You",
            isStartup: false,
            urlSlug: userData.urlSlug,
          };
        }

        const processedCommentText = convertUrlsToLinks(commentText);

        const basePath = targetPost.user.isStartup
          ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}`
          : `users/${targetPost.user.id}`;

        const commentsRef = collection(
          firestore,
          `${basePath}/posts/${postId}/comments`
        );
        const postRef = getPostRef(targetPost);
        const postSnap = await getDoc(postRef);

        if (!postSnap.exists()) {
          throw new Error("Post not found");
        }

        const postData = postSnap.data();
        const rootCommentId = parentCommentId
          ? await getRootCommentId(targetPost, postId, parentCommentId)
          : null;

        const now = new Date();
        const commentData = {
          text: processedCommentText,
          userId: commenterInfo.id,
          userName: commenterInfo.name,
          userImage: commenterInfo.image,
          createdAt: now,
          parentCommentId: parentCommentId,
          rootCommentId: rootCommentId,
          isRootComment: !parentCommentId,
          bio: commenterInfo.bio,
          connectionType: commenterInfo.connectionType,
          link: commenterInfo.link,
          linkText: commenterInfo.linkText,
          // Here's the critical change - ensure we properly identify startup posts
          postUserId: targetPost.user.isStartup
            ? `startup_${targetPost.user.startupId}`
            : targetPost.user.id,
          isStartup: commenterInfo.isStartup,
          ...(commenterInfo.isStartup
            ? {
                startupId: commenterInfo.startupId,
                startupOwnerId: commenterInfo.ownerId,
                startupUrlSlug: commenterInfo.startupUrlSlug,
              }
            : {
                urlSlug: commenterInfo.urlSlug,
              }),
          // Add startup data for the post being commented on
          originalPost: targetPost.user.isStartup
            ? {
                startupId: targetPost.user.startupId,
                startupOwnerId: targetPost.user.startupOwnerId,
                isStartup: true,
              }
            : null,
        };

        const newCommentRef = await addDoc(commentsRef, {
          ...commentData,
          createdAt: serverTimestamp(),
        });

        await updateDoc(postRef, {
          commentCount: increment(1),
          [`comments.${commenterInfo.id}`]: serverTimestamp(),
          originalDirectCommentTime: serverTimestamp(),
        });

        const commenterPath = commenterInfo.isStartup
          ? `users/${currentUser.uid}/startups/${commenterInfo.startupId}`
          : `users/${currentUser.uid}`;

        const commentedPostRef = doc(
          firestore,
          `${commenterPath}/commentedPosts/${postId}`
        );
        const commentedPostDoc = await getDoc(commentedPostRef);

        const commentedPostData = {
          originalPosterId: targetPost.user.isStartup
            ? `startup_${targetPost.user.startupId}`
            : targetPost.user.id,
          originalStartupOwnerId: targetPost.user.isStartup
            ? targetPost.user.startupOwnerId
            : null,
          postId: postId,
          comments: {
            [newCommentRef.id]: {
              commentId: newCommentRef.id,
              commentedAt: now,
              commentText: processedCommentText,
              originalPosterId: targetPost.user.isStartup
                ? `startup_${targetPost.user.startupId}`
                : targetPost.user.id,
              originalStartupOwnerId: targetPost.user.isStartup
                ? targetPost.user.startupOwnerId
                : null,
              isStartup: commenterInfo.isStartup,
              startupData: targetPost.user.isStartup
                ? {
                    startupId: targetPost.user.startupId,
                    startupOwnerId: targetPost.user.startupOwnerId,
                    isStartup: true,
                  }
                : null,
            },
          },
          lastCommentedAt: now,
          startupData: targetPost.user.isStartup
            ? {
                startupId: targetPost.user.startupId,
                startupOwnerId: targetPost.user.startupOwnerId,
                isStartup: true,
              }
            : null,
        };

        if (commentedPostDoc.exists()) {
          await updateDoc(commentedPostRef, {
            comments: {
              ...(commentedPostDoc.data().comments || {}),
              [newCommentRef.id]: commentedPostData.comments[newCommentRef.id],
            },
            lastCommentedAt: now,
            originalPosterId: commentedPostData.originalPosterId,
            originalStartupOwnerId: commentedPostData.originalStartupOwnerId,
            startupData: commentedPostData.startupData,
          });
        } else {
          await setDoc(commentedPostRef, commentedPostData);
        }

        const batch = writeBatch(firestore);

        const shouldNotifyPostOwner =
          !parentCommentId &&
          ((targetPost.user.isStartup &&
            currentUser.uid !== targetPost.user.startupOwnerId &&
            (!commenterInfo.isStartup ||
              commenterInfo.ownerId !== targetPost.user.startupOwnerId)) ||
            (!targetPost.user.isStartup &&
              currentUser.uid !== targetPost.user.id &&
              (!commenterInfo.isStartup ||
                commenterInfo.ownerId !== targetPost.user.id)));

        if (shouldNotifyPostOwner) {
          const postOwnerNotificationRef = doc(
            firestore,
            `users/${
              targetPost.user.startupOwnerId || targetPost.user.id
            }/notifications`,
            `comment_${newCommentRef.id}`
          );

          const notificationData = {
            type: "comment",
            postId: postId,
            commentId: newCommentRef.id,
            commenterId: commenterInfo.id,
            commenterName: commenterInfo.name,
            commenterImage: commenterInfo.image,
            commentText: commentText,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: postData.text || "",
              mediaType: postData.content?.type || null,
              mediaUrl: postData.content?.url || null,
              fileName: postData.content?.fileName || null,
            },
            ...(commenterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage || defaultStartupImage,
            }),
          };

          batch.set(postOwnerNotificationRef, notificationData);
        }

        if (parentCommentId) {
          const parentCommentRef = doc(commentsRef, parentCommentId);
          const parentCommentSnap = await getDoc(parentCommentRef);
          const parentCommentData = parentCommentSnap.exists()
            ? parentCommentSnap.data()
            : null;

          const shouldNotifyParentCommenter =
            parentCommentData &&
            ((parentCommentData.isStartup &&
              currentUser.uid !== parentCommentData.startupOwnerId &&
              (!commenterInfo.isStartup ||
                commenterInfo.ownerId !== parentCommentData.startupOwnerId)) ||
              (!parentCommentData.isStartup &&
                currentUser.uid !== parentCommentData.userId &&
                (!commenterInfo.isStartup ||
                  commenterInfo.ownerId !== parentCommentData.userId)));

          if (shouldNotifyParentCommenter) {
            const replyNotificationRef = doc(
              firestore,
              `users/${
                parentCommentData.isStartup
                  ? parentCommentData.startupOwnerId
                  : parentCommentData.userId
              }/notifications`,
              `reply_${newCommentRef.id}`
            );

            const replyNotificationData = {
              type: "reply",
              postId: postId,
              commentId: newCommentRef.id,
              parentCommentId: parentCommentId,
              replierId: commenterInfo.id,
              replierName: commenterInfo.name,
              replierImage: commenterInfo.image,
              replyText: commentText,
              createdAt: serverTimestamp(),
              isNew: true,
              parentCommentPreview: {
                text: parentCommentData.text || "",
              },
              postPreview: {
                text: postData.text || "",
                mediaType: postData.content?.type || null,
                mediaUrl: postData.content?.url || null,
                fileName: postData.content?.fileName || null,
              },
              ...(commenterInfo.isStartup && {
                isStartup: true,
                startupId: selectedStartup.id,
                startupOwnerId: currentUser.uid,
                startupImage:
                  selectedStartup.startupImage || defaultStartupImage,
              }),
            };

            batch.set(replyNotificationRef, replyNotificationData);
          }
        }

        await batch.commit();

        const newCommentObject = {
          id: newCommentRef.id,
          ...commentData,
          postId,
          postUserId: targetPost.user.id,
        };

        setComments((prevComments) => ({
          ...prevComments,
          [postId]: [...(prevComments[postId] || []), newCommentObject],
        }));

        setPosts((prevPosts) =>
          prevPosts.map((p) =>
            p.id === postId
              ? {
                  ...p,
                  commentCount: (p.commentCount || 0) + 1,
                  comments: {
                    ...(p.comments || {}),
                    [commenterInfo.id]: now,
                  },
                  originalDirectCommentTime: now,
                }
              : p
          )
        );
      } catch (error) {
        setErrorPopup({
          message:
            error.message === "Post not found"
              ? "This post has been deleted."
              : "An error occurred while adding the comment.",
          isError: true,
        });
      }
    },
    [currentUser, selectedStartup, comments]
  );

  const renderReplyText = (postId, reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <EditInput
          postId={postId}
          commentId={reply.id}
          initialText={stripHtmlTags(reply.text)}
          isReply={true}
          onSave={handleSaveCommentChanges}
          onCancel={handleCancelEdit}
        />
      );
    }

    const lines = reply.text.split("\n");
    const isLongReply = lines.length > 3 || reply.text.length > 125;

    const displayText =
      isLongReply && !expandedReplies[postId]?.[reply.id]
        ? reply.text.slice(0, 125)
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[postId]?.[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(postId, reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const isReplyButtonDisabled = (postId, replyId) => {
    const replyText = replyComments[postId]?.[replyId];
    return !replyText || !replyText.trim();
  };

  const toggleCommentDropdown = useCallback((postId, commentId) => {
    const dropdownId = `${postId}_${commentId}`;

    setOpenCommentDropdown((prev) => (prev === dropdownId ? null : dropdownId));

    // Force a re-render of affected comment
    requestAnimationFrame(() => {
      setComments((prevComments) => {
        if (!prevComments[postId]) return prevComments;
        return {
          ...prevComments,
          [postId]: prevComments[postId].map((comment) =>
            comment.id === commentId ? { ...comment } : comment
          ),
        };
      });
    });
  }, []);

  const handleCommentReactionClick = (postId, commentId) => {
    // Prevent any default scrolling behavior
    handleCommentEmojiSelect(postId, commentId, "👍");
  };

  const toggleCommentExpansion = (postId, commentId) => {
    maintainScrollPosition(() => {
      setExpandedComments((prev) => ({
        ...prev,
        [postId]: {
          ...(prev[postId] || {}),
          [commentId]: !prev[postId]?.[commentId],
        },
      }));

      // Force re-render of comments
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) =>
          comment.id === commentId ? { ...comment } : comment
        ),
      }));
    });
  };

  const toggleReplyExpansion = (postId, replyId) => {
    maintainScrollPosition(() => {
      setExpandedReplies((prev) => ({
        ...prev,
        [postId]: {
          ...(prev[postId] || {}),
          [replyId]: !prev[postId]?.[replyId],
        },
      }));

      // Force re-render of comments
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) => {
          if (comment.id === replyId) {
            return { ...comment };
          }
          return comment;
        }),
      }));
    });
  };

  const EditInput = React.memo(
    ({ postId, commentId, initialText, isReply, onSave, onCancel }) => {
      const [inputValue, setInputValue] = useState(initialText);
      const [originalText] = useState(initialText);
      const textareaRef = useRef(null);

      useEffect(() => {
        if (textareaRef.current) {
          // Focus the textarea
          textareaRef.current.focus();

          // Set cursor to end of text
          const length = textareaRef.current.value.length;
          textareaRef.current.setSelectionRange(length, length);

          // Adjust height
          textareaRef.current.style.height = "auto";
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      }, []);

      const handleChange = (e) => {
        const textarea = e.target;
        setInputValue(textarea.value);
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      const isTextChanged = inputValue.trim() !== originalText.trim();
      const canSave = inputValue.trim() && isTextChanged;

      const handleSave = () => {
        if (canSave) {
          onSave(postId, commentId, inputValue, isReply);
        }
      };

      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={handleChange}
            className={isReply ? classes.editReplyInput : classes.replyInput}
          />
          <div className={classes.editCommentButtons}>
            <div
              className={`${classes.saveChangesButton} ${
                !canSave ? classes.disabledButton : ""
              }`}
              onClick={handleSave}
            >
              Save changes
            </div>
            <div className={classes.dot}> • </div>
            <div className={classes.cancelEditButton} onClick={onCancel}>
              Cancel
            </div>
          </div>
        </div>
      );
    }
  );

  const ReplyInput = React.memo(({ postId, commentId, onSubmit }) => {
    const [inputValue, setInputValue] = useState("");
    const textareaRef = useRef(null);
    const containerRef = useRef(null);

    const handleImageClick = () => {
      if (selectedStartup) {
        // Navigate directly to startup profile using correct format
        navigate(`/startup/${selectedStartup.startupUrlSlug}`);
      } else if (currentUser?.urlSlug) {
        // Navigate directly to user profile using urlSlug
        navigate(`/${currentUser.urlSlug}`);
      }
    };

    useEffect(() => {
      if (textareaRef.current && containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        textareaRef.current.focus();
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

        if (isIOS) {
          forceKeyboardOpen(textareaRef.current);
        } else {
          openMobileKeyboard(textareaRef.current);
        }
      }
    }, []);

    const handleChange = (e) => {
      const textarea = e.target;
      setInputValue(textarea.value);
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleSubmit = () => {
      if (inputValue.trim()) {
        onSubmit(postId, commentId, inputValue);
        setInputValue("");
        if (textareaRef.current) {
          textareaRef.current.style.height = "auto";
        }
      }
    };

    return (
      <div ref={containerRef} className={classes.replyCommentInputSection}>
        <div className={classes.replyInputWrapper}>
          <div>
            <img
              src={
                selectedStartup
                  ? selectedStartup.startupImage || defaultStartupImage
                  : currentUserProfileImage || profileImage
              }
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.replyUserImage}
              onClick={handleImageClick}
              style={{
                borderRadius: selectedStartup ? 0 : "50%",
                cursor: "pointer",
              }}
            />
          </div>
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={handleChange}
            placeholder="Add a reply..."
            className={classes.replyInput}
          />
        </div>
        <div
          onClick={handleSubmit}
          className={`${classes.addReplyButton} ${
            !inputValue.trim() ? classes.disabled : ""
          }`}
        >
          Reply
        </div>
      </div>
    );
  });

  const handleAddReply = useCallback(
    async (postId, commentId, replyText) => {
      if (!currentUser) return;

      try {
        // Pass the reply text directly to handleAddComment
        await handleAddComment(postId, replyText, commentId);

        // Clear the active comment after successful reply
        setActiveCommentId((prev) => ({
          ...prev,
          [postId]: null,
        }));
      } catch (error) {
        setErrorPopup({
          message: "An error occurred while adding the reply.",
          isError: true,
        });
      }
    },
    [currentUser, handleAddComment]
  );

  // Add handleImageClick function
  const CommentInput = React.memo(({ postId, onSubmit }) => {
    const [inputValue, setInputValue] = useState("");
    const textareaRef = useRef(null);

    const handleChange = (e) => {
      const textarea = e.target;
      setInputValue(textarea.value);
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleSubmit = () => {
      if (inputValue.trim()) {
        onSubmit(postId, inputValue);
        setInputValue("");
        if (textareaRef.current) {
          textareaRef.current.style.height = "auto";
        }
      }
    };

    const handleImageClick = () => {
      if (selectedStartup) {
        // Navigate directly to startup profile using correct format
        navigate(`/startup/${selectedStartup.startupUrlSlug}`);
      } else if (currentUser?.urlSlug) {
        // Navigate directly to user profile using urlSlug
        navigate(`/${currentUser.urlSlug}`);
      }
    };

    return (
      <div className={classes.commentInputSection}>
        <div className={classes.commentInputWrapper}>
          <div>
            <img
              src={
                selectedStartup
                  ? selectedStartup.startupImage || defaultStartupImage
                  : currentUserProfileImage || defaultImage
              }
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.commentUserImage}
              onClick={handleImageClick}
              style={{
                borderRadius: selectedStartup ? 0 : "50%",
                cursor: "pointer",
              }}
            />
          </div>
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={handleChange}
            placeholder="Add a comment..."
            className={classes.commentInput}
          />
        </div>
        <div
          onClick={handleSubmit}
          className={`${classes.addCommentButton} ${
            !inputValue.trim() ? classes.disabled : ""
          }`}
        >
          Comment
        </div>
      </div>
    );
  });

  useEffect(() => {}, [comments, showComments]);

  // ReplySection Component
  const ReplySection = ({
    comment,
    currentUser,
    selectedStartup,
    replyComments,
    onReplyChange,
    onSubmit,
    replyInputRef,
  }) => {
    const profileImage = selectedStartup
      ? selectedStartup.startupImage || defaultStartupImage
      : currentUserProfileImage;

    return (
      <div className={classes.replyToReplyCommentInputSection}>
        <div className={classes.replyInputWrapper}>
          <div>
            <img
              src={
                selectedStartup
                  ? selectedStartup.startupImage || defaultStartupImage
                  : currentUserProfileImage || profileImage
              }
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.replyUserImage}
              style={{
                borderRadius: selectedStartup ? 0 : "50%",
              }}
            />
          </div>
          <textarea
            ref={replyInputRef}
            value={replyComments}
            onChange={onReplyChange}
            placeholder={`Add a reply...`}
            className={classes.replyInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addReplyButton} ${
            !replyComments?.trim() ? classes.disabled : ""
          }`}
        >
          Reply
        </div>
      </div>
    );
  };

  // Add these handler functions
  const handleCommentInputChange = useCallback((e, postId) => {
    const value = e.target.value;
    setNewComment((prev) => ({
      ...prev,
      [postId]: value,
    }));
  }, []);

  const handleReplyInputChange = (e, postId, replyId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [replyId]: textarea.value,
      },
    }));
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyButton = useCallback((postId, commentId) => {
    setActiveCommentId((prev) => ({
      ...prev,
      [postId]: prev[postId] === commentId ? null : commentId,
    }));
    // Force re-render of comment section
    setComments((prev) => ({ ...prev }));
  }, []);

  const handleLoadMoreComments = (postId) => {
    setVisibleComments((prev) => {
      const currentVisible = prev[postId] || 3;
      const increment = currentVisible === 3 ? 6 : (currentVisible - 3) * 2;

      // Immediately update UI
      requestAnimationFrame(() => {
        setComments((prevComments) => ({ ...prevComments }));
      });

      return {
        ...prev,
        [postId]: currentVisible + increment,
      };
    });
  };

  const handleViewMoreReplies = (rootCommentId) => {
    setVisibleReplies((prev) => {
      const currentVisible = prev[rootCommentId] || 0;
      const increment = currentVisible === 0 ? 3 : currentVisible * 2;

      // Immediately update UI
      requestAnimationFrame(() => {
        setComments((prevComments) => ({ ...prevComments }));
      });

      return {
        ...prev,
        [rootCommentId]: currentVisible + increment,
      };
    });
  };

  const renderCommentText = (postId, comment) => {
    if (!comment || typeof comment.text !== "string") return null;

    if (editingCommentId === comment.id) {
      return (
        <EditInput
          postId={postId}
          commentId={comment.id}
          initialText={stripHtmlTags(comment.text)}
          isReply={false}
          onSave={handleSaveCommentChanges}
          onCancel={handleCancelEdit}
        />
      );
    }

    const lines = comment.text.split("\n");
    const isLongComment = lines.length > 3 || comment.text.length > 125;

    const displayText =
      isLongComment && !expandedComments[postId]?.[comment.id]
        ? comment.text.slice(0, 125)
        : comment.text;

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongComment && !expandedComments[postId]?.[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleCommentExpansion(postId, comment.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  // In Feed.js
  const renderReplies = (postId, rootCommentId) => {
    if (!comments[postId]) return null;

    const replies = comments[postId].filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );

    const visibleRepliesCount = visibleReplies[rootCommentId] || 0;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const topThreeEmojis = getTopThreeEmojis(reply.reactions || {});
          const totalReactions = Object.keys(reply.reactions || {}).length;

          const replyCount = comments[postId].filter(
            (subReply) => subReply.parentCommentId === reply.id
          ).length;

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage || profileImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() =>
                      handleNavigate(
                        reply.userId,
                        reply.isStartup,
                        reply.startupUrlSlug
                      )
                    }
                    style={{
                      borderRadius: reply.isStartup ? 0 : "50%",
                      cursor: "pointer",
                    }}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() =>
                          handleNavigate(
                            reply.userId,
                            reply.isStartup,
                            reply.startupUrlSlug
                          )
                        }
                      >
                        {reply.userName}
                      </span>
                      <span className={classes.replyConnectionType}>
                        • {reply.connectionType}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTime(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === `${postId}_${reply.id}`
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(postId, reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === `${postId}_${reply.id}` &&
                    renderCommentOptions(postId, reply)}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(postId, reply)}
              </div>
              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(postId, reply.id)}
                  >
                    Boost {totalReactions > 0 && `(${totalReactions})`}
                  </div>

                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(postId, reply.id)}
                  >
                    <span className={classes.replyText}>
                      Reply {replyCount > 0 && `(${replyCount})`}
                    </span>
                  </button>
                </div>
              )}
              {activeEmojiPicker === `${postId}_${reply.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, reply.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
            </div>
          );
        })}
        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(rootCommentId)}
          >
            {visibleRepliesCount === 0 ? "Show replies" : `Show more replies`} (
            {replies.length - visibleRepliesCount})
          </div>
        )}
        {activeCommentId[postId] &&
          replies.some((reply) => reply.id === activeCommentId[postId]) && (
            <ReplyInput
              postId={postId}
              commentId={activeCommentId[postId]}
              onSubmit={handleAddReply}
            />
          )}
      </div>
    );
  };

  const renderComments = (postId) => {
    if (!posts || !comments[postId]) {
      if (loadingComments[postId]) {
        return (
          <div className={classes.loadingIndicator}>
            <span
              className={`${classes.loadingIcon} material-symbols-outlined`}
            >
              progress_activity
            </span>
          </div>
        );
      }
      return null;
    }

    const postComments = comments[postId];
    const rootComments = postComments.filter(
      (comment) => comment.isRootComment
    );
    const visibleCount = visibleComments[postId] || 3;
    const visibleRootComments = rootComments.slice(0, visibleCount);

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = postComments.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment.reactions || {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || profileImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() =>
                      handleNavigate(
                        comment.userId,
                        comment.isStartup,
                        comment.startupUrlSlug
                      )
                    }
                    style={{
                      borderRadius: comment.isStartup ? 0 : "50%",
                      cursor: "pointer",
                    }}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() =>
                          handleNavigate(
                            comment.userId,
                            comment.isStartup,
                            comment.startupUrlSlug
                          )
                        }
                      >
                        {comment.userName}
                      </span>
                      <span className={classes.commentConnectionType}>
                        • {comment.connectionType}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTime(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === `${postId}_${comment.id}`
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(postId, comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === `${postId}_${comment.id}` &&
                    renderCommentOptions(postId, comment)}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(postId, comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() =>
                      handleCommentReactionClick(postId, comment.id)
                    }
                  >
                    Boost {totalReactions > 0 && `(${totalReactions})`}
                  </div>

                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(postId, comment.id)}
                  >
                    <span className={classes.replyText}>
                      Reply {replyCount > 0 && `(${replyCount})`}
                    </span>
                  </button>
                </div>
              )}

              {activeEmojiPicker === `${postId}_${comment.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, comment.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {renderReplies(postId, comment.id)}

              {activeCommentId[postId] === comment.id && (
                <ReplyInput
                  postId={postId}
                  commentId={comment.id}
                  onSubmit={handleAddReply}
                />
              )}
            </div>
          );
        })}
        {rootComments.length > 3 && rootComments.length > visibleCount && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={() => handleLoadMoreComments(postId)}
          >
            Show more comments ({rootComments.length - visibleCount})
          </div>
        )}
      </>
    );
  };

  // ImageCarousel component
  const ImageCarousel = React.memo(
    ({ images, classes }) => {
      const [currentIndex, setCurrentIndex] = useState(0);

      // Add key to memorize carousel state
      const carouselKey = useMemo(() => images?.join(","), [images]);

      if (!Array.isArray(images) || images.length === 0) return null;

      const handleNextImage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentIndex((prev) => (prev + 1) % images.length);
      };

      const handlePrevImage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
      };

      return (
        <div className={classes.imageCarousel} key={carouselKey}>
          <div style={{ position: "relative" }}>
            {images.length > 1 && (
              <div className={classes.imageCounter}>
                <div className={classes.imageCounterInner}>
                  {`${currentIndex + 1}/${images.length}`}
                </div>
              </div>
            )}

            <img
              src={images[currentIndex]}
              alt=""
              className={classes.postContentImage}
              loading="eager"
              decoding="sync"
              key={`${carouselKey}-${currentIndex}`} // Important: Add composite key
            />

            {/* Navigation buttons - only show if there are multiple images */}
            {images.length > 1 && (
              <div className={classes.navigationButtons}>
                <div
                  className={classes.navButton}
                  onClick={handlePrevImage}
                  aria-label="Previous image"
                >
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m14 8-4 4 4 4"
                    />
                  </svg>
                </div>
                <div
                  className={classes.navButton}
                  onClick={handleNextImage}
                  aria-label="Next image"
                >
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m10 16 4-4-4-4"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    },
    (prevProps, nextProps) =>
      prevProps.images?.join(",") === nextProps.images?.join(",")
  );

  const isUserOrStartupsBlocked = async (
    firestore,
    currentUserId,
    otherUserId
  ) => {
    if (!currentUserId || !otherUserId) {
      return false;
    }

    try {
      // Get all startups for both users
      const getUserStartups = async (userId) => {
        const startupsRef = collection(firestore, `users/${userId}/startups`);
        const startupsSnapshot = await getDocs(startupsRef);
        return startupsSnapshot.docs.map((doc) => doc.id);
      };

      const [currentUserStartups, otherUserStartups] = await Promise.all([
        getUserStartups(currentUserId),
        getUserStartups(otherUserId),
      ]);

      // Check current user's blocked users
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedSnapshot = await getDocs(currentUserBlockedRef);
      const currentUserBlockedIds = new Set(
        currentUserBlockedSnapshot.docs.map((doc) => doc.data().userId)
      );

      // Check if other user is blocked
      if (currentUserBlockedIds.has(otherUserId)) {
        return true;
      }

      // Check if any of other user's startups are blocked
      for (const startupId of otherUserStartups) {
        if (currentUserBlockedIds.has(`startup_${startupId}`)) {
          return true;
        }
      }

      // Check other user's blocked users
      const otherUserBlockedRef = collection(
        firestore,
        `users/${otherUserId}/blockedUsers`
      );
      const otherUserBlockedSnapshot = await getDocs(otherUserBlockedRef);
      const otherUserBlockedIds = new Set(
        otherUserBlockedSnapshot.docs.map((doc) => doc.data().userId)
      );

      // Check if current user is blocked
      if (otherUserBlockedIds.has(currentUserId)) {
        return true;
      }

      // Check if any of current user's startups are blocked
      for (const startupId of currentUserStartups) {
        if (otherUserBlockedIds.has(`startup_${startupId}`)) {
          return true;
        }
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  // Add this to your fetchComments function
  const fetchComments = async (postId) => {
    if (!currentUser) {
      return;
    }

    setLoadingComments((prev) => ({ ...prev, [postId]: true }));

    try {
      const targetPost = findPostById(postId);
      if (!targetPost) {
        return;
      }

      // Fix the base path logic
      const basePath = targetPost.user.isStartup
        ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}`
        : `users/${targetPost.user.id || targetPost.userId}`;

      // Get all comments for this post
      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${postId}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      const processedComments = [];
      const blockedComments = [];

      for (const commentDoc of commentsSnapshot.docs) {
        const commentData = commentDoc.data();

        if (!commentData.userId) {
          continue;
        }

        const commenterId = commentData.userId;
        const commenterOwnerId = commentData.isStartup
          ? commentData.startupOwnerId
          : commenterId;

        const isBlocked = await isUserOrStartupsBlocked(
          firestore,
          currentUser.uid,
          commenterOwnerId
        );

        if (isBlocked) {
          blockedComments.push(commentDoc.id);
          continue;
        }

        // Determine commenter path based on if it's a startup or user
        let commenterPath;
        if (commenterId.startsWith("startup_")) {
          const startupId = commenterId.replace("startup_", "");
          if (!commentData.startupOwnerId) {
            continue;
          }
          commenterPath = `users/${commentData.startupOwnerId}/startups/${startupId}`;
        } else {
          commenterPath = `users/${commenterId}`;
        }

        try {
          let commenterData;
          if (commenterId.startsWith("startup_")) {
            const startupDoc = await getDoc(doc(firestore, commenterPath));
            if (!startupDoc.exists()) {
              continue;
            }
            commenterData = startupDoc.data();
          } else {
            const userDoc = await getDoc(
              doc(firestore, `users/${commenterId}`)
            );
            if (!userDoc.exists()) {
              continue;
            }
            commenterData = userDoc.data();
          }

          const processedComment = {
            id: commentDoc.id,
            ...commentData,
            userName: commenterId.startsWith("startup_")
              ? commenterData.startupName
              : `${commenterData.firstName} ${commenterData.lastName}`,
            userImage: commenterId.startsWith("startup_")
              ? commenterData.startupImage || defaultStartupImage
              : commenterData.profileImage || profileImage,
            userId: commenterId,
            bio: commenterData.bio || "",
            link: commenterData.link || null,
            linkText: commenterData.linkText || null,
            reactions: commentData.reactions || {},
            isStartup: commenterId.startsWith("startup_"),
            startupId: commenterId.startsWith("startup_")
              ? commenterId.replace("startup_", "")
              : null,
            startupOwnerId: commentData.startupOwnerId,
            postId,
            postUserId: targetPost.user.id || targetPost.userId,
            isRootComment: !commentData.parentCommentId,
            rootCommentId: commentData.rootCommentId || commentDoc.id,
            parentCommentId: commentData.parentCommentId || null,
            edited: commentData.edited || false,
            connectionType:
              commenterId === currentUser.uid
                ? "You"
                : await checkConnectionType(
                    currentUser.uid,
                    commenterId,
                    commenterId.startsWith("startup_")
                  ),
          };

          processedComments.push(processedComment);
        } catch (error) {
          continue;
        }
      }

      const sortedComments = processedComments.sort((a, b) => {
        const dateA =
          a.createdAt instanceof Date ? a.createdAt : a.createdAt.toDate();
        const dateB =
          b.createdAt instanceof Date ? b.createdAt : b.createdAt.toDate();
        return dateA - dateB;
      });

      // Update local state
      setComments((prev) => ({
        ...prev,
        [postId]: sortedComments,
      }));

      setVisibleComments((prev) => ({
        ...prev,
        [postId]: 3,
      }));

      // Update post comment count
      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId ? { ...p, commentCount: sortedComments.length } : p
        )
      );
    } catch (error) {
      setErrorPopup({
        message: "Error loading comments.",
        isError: true,
      });
    } finally {
      setLoadingComments((prev) => ({ ...prev, [postId]: false }));
    }
  };

  useEffect(() => {
    Object.entries(showComments).forEach(([postId, isVisible]) => {
      if (isVisible && !comments[postId]) {
        const post = findPostById(postId);
        if (post) {
          fetchComments(postId, post);
        }
      }
    });
  }, [showComments, comments, findPostById]);

  const toggleCommentInput = useCallback(
    async (postId) => {
      setShowComments((prev) => {
        const newState = { ...prev, [postId]: !prev[postId] };
        // Only trigger fetchComments if we're opening comments and don't have them
        if (newState[postId] && !comments[postId]) {
          requestAnimationFrame(() => {
            const post = findPostById(postId);
            if (post) {
              fetchComments(postId, post);
            }
          });
        }
        return newState;
      });
    },
    [findPostById, fetchComments, comments]
  );

  const handleReactionsClick = useCallback(async (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  }, []);

  const fetchRepostData = useCallback(async (reposts) => {
    const repostData = {};

    for (const [userId, timestamp] of Object.entries(reposts)) {
      // Handle both user and startup reposts
      if (userId.startsWith("startup_")) {
        const startupId = userId.replace("startup_", "");
        try {
          const usersSnapshot = await getDocs(collection(firestore, "users"));
          for (const userDoc of usersSnapshot.docs) {
            const startupDoc = await getDoc(
              doc(firestore, `users/${userDoc.id}/startups/${startupId}`)
            );
            if (startupDoc.exists()) {
              const startupData = startupDoc.data();
              repostData[userId] = {
                id: userId,
                firstName: startupData.startupName,
                lastName: "",
                profileImage: startupData.startupImage || defaultStartupImage,
                timestamp: timestamp,
                isStartup: true,
                startupId: startupId,
                startupOwnerId: userDoc.id,
              };
              break;
            }
          }
        } catch (error) {}
      } else {
        try {
          const userDoc = await getDoc(doc(firestore, "users", userId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            repostData[userId] = {
              id: userId,
              firstName: userData.firstName,
              lastName: userData.lastName,
              profileImage: userData.profileImage || defaultImage,
              timestamp: timestamp,
              isStartup: false,
            };
          }
        } catch (error) {}
      }
    }

    return repostData;
  }, []);

  const handleRepostsClick = useCallback(
    async (postId, reposts) => {
      setRepostsModalScrollPosition(window.scrollY);
      const detailedReposts = await fetchRepostData(reposts);
      setActivePostReposts({ postId, reposts: detailedReposts });
      setShowRepostsModal(true);
      setIsRepostsModalOpen(true);
    },
    [fetchRepostData]
  );

  const hasInteractions = (post) => {
    return (
      (post.reactions && Object.keys(post.reactions).length > 0) ||
      (post.reposts && Object.keys(post.reposts).length > 0) ||
      (post.commentCount && post.commentCount > 0)
    );
  };

  const toggleComments = useCallback(
    async (postId) => {
      setShowComments((prev) => {
        const newState = { ...prev, [postId]: !prev[postId] };
        if (newState[postId] && !comments[postId]) {
          const post = findPostById(postId);
          if (post) {
            fetchComments(postId);
          }
        }
        return newState;
      });
    },
    [comments, findPostById, fetchComments]
  );

  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  const preloadImages = (urls) => {
    urls.forEach((url) => {
      if (url) {
        const img = new Image();
        img.src = url;
      }
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.play().catch((error) => console.log("Autoplay was prevented"));
        } else {
          entry.target.pause();
        }
      });
    }, options);

    Object.values(videoRefs.current).forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    return () => {
      Object.values(videoRefs.current).forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };
  }, [posts]);

  const handlePrevImage = (postId, totalImages) => {
    const nextIndex =
      ((currentImageIndex[postId] || 0) - 1 + totalImages) % totalImages;
    setLoadingImages((prev) => ({ ...prev, [postId]: true }));
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: nextIndex,
    }));
  };

  const handleNextImage = (postId, totalImages) => {
    const nextIndex = ((currentImageIndex[postId] || 0) + 1) % totalImages;
    setLoadingImages((prev) => ({ ...prev, [postId]: true }));
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: nextIndex,
    }));
  };

  const handleNavigate = useCallback(
    (userId, isStartup = false, startupUrlSlug = null) => {
      if (!userId) return;

      // If it's a startup and we have the urlSlug, navigate directly
      if (isStartup && startupUrlSlug) {
        navigate(`/startup/${startupUrlSlug}`);
        return;
      }

      // If it's a startup ID without urlSlug
      if (userId.startsWith("startup_")) {
        const startupId = userId.replace("startup_", "");
        try {
          // We need to find the startup doc across all users to get the urlSlug
          const findStartupUrlSlug = async () => {
            const usersSnapshot = await getDocs(collection(firestore, "users"));
            for (const userDoc of usersSnapshot.docs) {
              const startupDoc = await getDoc(
                doc(firestore, `users/${userDoc.id}/startups/${startupId}`)
              );
              if (startupDoc.exists()) {
                const startupData = startupDoc.data();
                navigate(`/startup/${startupData.startupUrlSlug}`);
                return;
              }
            }
          };
          findStartupUrlSlug();
        } catch (error) {
          setErrorPopup({
            message: "Error loading startup profile.",
            isError: true,
          });
        }
        return;
      }

      // For regular users
      try {
        const getUserUrlSlug = async () => {
          const userDoc = await getDoc(doc(firestore, "users", userId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            navigate(`/${userData.urlSlug}`);
          } else {
            setErrorPopup({
              message: "User not found.",
              isError: true,
            });
          }
        };
        getUserUrlSlug();
      } catch (error) {
        setErrorPopup({
          message: "Error loading user profile.",
          isError: true,
        });
      }
    },
    [navigate]
  );

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const seenPostIds = new Set();

  const [displayPosts, setDisplayPosts] = useState(posts);

  useEffect(() => {
    const filterPosts = async () => {
      if (!posts || !currentUser) return;
      // First filter blocked content
      const blockedFiltered = await filterBlockedContent(
        firestore,
        currentUser.uid,
        posts
      );

      // Then get only newest post per user by comparing createdAt timestamps
      const userNewestPosts = new Map();
      blockedFiltered.forEach((post) => {
        const userId = post.user.isStartup
          ? `startup_${post.user.startupId}`
          : post.user.id;
        const currentPostDate =
          post.createdAt instanceof Date
            ? post.createdAt
            : post.createdAt?.toDate();

        const existingPost = userNewestPosts.get(userId);
        const existingPostDate =
          existingPost?.createdAt instanceof Date
            ? existingPost?.createdAt
            : existingPost?.createdAt?.toDate();

        // Only update if we don't have a post from this user yet OR if this post is newer
        if (
          !existingPost ||
          (currentPostDate &&
            existingPostDate &&
            currentPostDate > existingPostDate)
        ) {
          userNewestPosts.set(userId, post);
        }
      });

      setDisplayPosts(Array.from(userNewestPosts.values()));
    };
    filterPosts();
  }, [posts, currentUser, firestore]);

  const fetchSuggestedPosts = async (
    userId,
    neededPosts,
    currentlyDisplayedPosts
  ) => {
    try {
      const suggestedPosts = [];

      const [directConnectionsSnap] = await Promise.all([
        getDocs(
          query(
            collection(firestore, `users/${userId}/connections`),
            where("status", "==", "Connected") // Only exclude Connected users
          )
        ),
      ]);

      const connectedUserIds = new Set(
        directConnectionsSnap.docs.map((doc) => doc.id)
      );

      const usersSnapshot = await getDocs(collection(firestore, "users"));

      const potentialUsers = usersSnapshot.docs.filter(
        (doc) => !connectedUserIds.has(doc.id) && doc.id !== userId
      );

      const shuffledUsers = shuffleArray(potentialUsers);

      const selectedUsers = shuffledUsers.slice(
        0,
        Math.min(5, shuffledUsers.length)
      );

      const userPostsPromises = selectedUsers.map(async (userDoc) => {
        const postsSnap = await getDocs(
          query(collection(firestore, `users/${userDoc.id}/posts`))
        );

        return Promise.all(
          postsSnap.docs.map(async (postDoc) => {
            const postData = postDoc.data();
            const userData = userDoc.data();

            return {
              id: postDoc.id,
              ...postData,
              globalPostId: `${userDoc.id}_${postDoc.id}`,
              isSuggested: true,
              suggestedType: "connection",
              user: {
                id: userDoc.id,
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || defaultImage,
                bio: userData.bio || "",
                link: userData.link || null,
                linkText: userData.linkText || null,
                connectionType: "Extended",
              },
            };
          })
        );
      });

      const allStartupsPromises = usersSnapshot.docs.map(async (userDoc) => {
        const startupsSnap = await getDocs(
          collection(firestore, `users/${userDoc.id}/startups`)
        );
        return startupsSnap.docs.map((startupDoc) => ({
          ...startupDoc.data(),
          id: startupDoc.id,
          ownerId: userDoc.id,
        }));
      });

      const allStartups = (await Promise.all(allStartupsPromises)).flat();

      const followedStartupsSnap = await getDocs(
        collection(firestore, `users/${userId}/startupsFollowing`)
      );
      const followedStartupIds = new Set(
        followedStartupsSnap.docs.map((doc) => doc.id)
      );

      const unfollowedStartups = shuffleArray(
        allStartups.filter(
          (startup) =>
            !followedStartupIds.has(startup.id) && startup.ownerId !== userId
        )
      ).slice(0, 5);

      const startupPostsPromises = unfollowedStartups.map(async (startup) => {
        const postsSnap = await getDocs(
          query(
            collection(
              firestore,
              `users/${startup.ownerId}/startups/${startup.id}/posts`
            )
          )
        );

        return Promise.all(
          postsSnap.docs.map(async (postDoc) => {
            const postData = postDoc.data();

            return {
              id: postDoc.id,
              ...postData,
              globalPostId: `${startup.ownerId}_${startup.id}_${postDoc.id}`,
              isSuggested: true,
              suggestedType: "startup",
              user: {
                id: `startup_${startup.id}`,
                firstName: startup.startupName,
                lastName: "",
                profileImage: startup.startupImage || defaultStartupImage,
                bio: startup.bio || "",
                link: startup.link || null,
                linkText: startup.linkText || null,
                connectionType: "Startup",
                isStartup: true,
                startupId: startup.id,
                startupOwnerId: startup.ownerId,
              },
            };
          })
        );
      });

      const [userPosts, startupPosts] = await Promise.all([
        Promise.all(userPostsPromises),
        Promise.all(startupPostsPromises),
      ]);

      const allSuggestedPosts = shuffleArray([
        ...userPosts.flat(),
        ...startupPosts.flat(),
      ]).filter((post) => !currentlyDisplayedPosts.has(post.globalPostId));

      return allSuggestedPosts.slice(0, neededPosts);
    } catch (error) {
      return [];
    }
  };

  const fetchPosts = async (userId) => {
    if (!userId) return;
    setLoading(true);

    try {
      const [
        blockedByUserSnap,
        userBlockedSnap,
        removedPostsSnapshot,
        directConnectionsSnap,
        followedStartupsSnap,
        userStartupsSnap,
        userReactedSnap,
        userCommentedSnap,
        usersSnapshot,
      ] = await Promise.all([
        getDocs(collection(firestore, `users/${userId}/blockedBy`)),
        getDocs(collection(firestore, `users/${userId}/blockedUsers`)),
        getDocs(collection(firestore, `users/${userId}/removedPosts`)),
        getDocs(
          query(
            collection(firestore, `users/${userId}/connections`),
            where("status", "==", "Connected")
          )
        ),
        getDocs(collection(firestore, `users/${userId}/startupsFollowing`)),
        getDocs(collection(firestore, `users/${userId}/startups`)),
        getDocs(collection(firestore, `users/${userId}/reactedPosts`)),
        getDocs(collection(firestore, `users/${userId}/commentedPosts`)),
        getDocs(collection(firestore, "users")),
      ]);

      const blockedIds = new Set([
        ...blockedByUserSnap.docs.map((doc) => doc.id),
        ...userBlockedSnap.docs.map((doc) => doc.id),
      ]);
      const removedPostIds = new Set(
        removedPostsSnapshot.docs.map((doc) => doc.id)
      );
      const directConnectionIds = new Set(
        directConnectionsSnap.docs.map((doc) => doc.id)
      );
      const followedStartupIds = new Set(
        followedStartupsSnap.docs.map((doc) => doc.id)
      );
      const userStartupIds = new Set(
        userStartupsSnap.docs.map((doc) => doc.id)
      );
      const interactedPostIds = new Set([
        ...userReactedSnap.docs.map((doc) => doc.id),
        ...userCommentedSnap.docs.map((doc) => doc.id),
      ]);

      const userDataCache = new Map();
      usersSnapshot.docs.forEach((doc) => {
        if (!blockedIds.has(doc.id)) {
          userDataCache.set(doc.id, doc.data());
        }
      });

      const indirectConnectionPromises = Array.from(directConnectionIds)
        .filter((id) => !blockedIds.has(id))
        .map((connId) =>
          getDocs(
            query(
              collection(firestore, `users/${connId}/connections`),
              where("status", "==", "Connected")
            )
          )
        );

      const indirectConnectionSnapshots = await Promise.all(
        indirectConnectionPromises
      );
      const indirectConnectionIds = new Set(
        indirectConnectionSnapshots
          .flatMap((snapshot) => snapshot.docs.map((doc) => doc.id))
          .filter((id) => !directConnectionIds.has(id) && id !== userId)
      );

      const postInteractions = new Map();

      const processInteractions = async (connId, isStartup = false) => {
        if (blockedIds.has(connId)) return;

        const [reactedSnap, commentedSnap] = await Promise.all([
          getDocs(
            collection(
              firestore,
              isStartup
                ? `users/${connId.split("_")[1]}/startups/${
                    connId.split("_")[0]
                  }/reactedPosts`
                : `users/${connId}/reactedPosts`
            )
          ),
          getDocs(
            collection(
              firestore,
              isStartup
                ? `users/${connId.split("_")[1]}/startups/${
                    connId.split("_")[0]
                  }/commentedPosts`
                : `users/${connId}/commentedPosts`
            )
          ),
        ]);

        const userData =
          userDataCache.get(isStartup ? connId.split("_")[1] : connId) || {};

        reactedSnap.docs.forEach((doc) => {
          interactedPostIds.add(doc.id);
          if (!postInteractions.has(doc.id)) {
            postInteractions.set(doc.id, {});
          }
          if (!postInteractions.get(doc.id).reactor) {
            postInteractions.get(doc.id).reactor = {
              id: connId,
              firstName: isStartup
                ? userData.startupName
                : userData.firstName || "",
              lastName: isStartup ? "" : userData.lastName || "",
              profileImage: isStartup
                ? userData.startupImage || defaultStartupImage
                : userData.profileImage || defaultImage,
              ...(isStartup && {
                isStartup: true,
                startupId: connId.split("_")[0],
                startupOwnerId: connId.split("_")[1],
                startupUrlSlug: userData.startupUrlSlug,
              }),
              emoji: doc.data().emoji,
            };
          }
        });

        commentedSnap.docs.forEach((doc) => {
          interactedPostIds.add(doc.id);
          if (!postInteractions.has(doc.id)) {
            postInteractions.set(doc.id, {});
          }
          if (!postInteractions.get(doc.id).commenter) {
            postInteractions.get(doc.id).commenter = {
              id: connId,
              firstName: isStartup
                ? userData.startupName
                : userData.firstName || "",
              lastName: isStartup ? "" : userData.lastName || "",
              profileImage: isStartup
                ? userData.startupImage || defaultStartupImage
                : userData.profileImage || defaultImage,
              ...(isStartup && {
                isStartup: true,
                startupId: connId.split("_")[0],
                startupOwnerId: connId.split("_")[1],
                startupUrlSlug: userData.startupUrlSlug,
              }),
            };
          }
        });
      };

      const interactionPromises = [
        ...Array.from(directConnectionIds).map((id) => processInteractions(id)),
        ...Array.from(followedStartupIds).map((id) =>
          processInteractions(`startup_${id}`, true)
        ),
      ];

      await Promise.all(interactionPromises);

      const getConnectionType = (postUserId) => {
        if (postUserId === userId) return "You";
        if (directConnectionIds.has(postUserId)) return "Direct";
        if (indirectConnectionIds.has(postUserId)) return "Indirect";
        return "Extended";
      };

      const isSuggestedPost = (post) => {
        if (post.user.id === userId) return false;
        if (post.user.isStartup && post.user.startupOwnerId === userId)
          return false;
        if (directConnectionIds.has(post.user.id)) return false;
        if (post.user.isStartup && followedStartupIds.has(post.user.startupId))
          return false;
        if (interactedPostIds.has(post.id)) return false;
        return true;
      };

      const processUserPosts = async (userDoc) => {
        if (blockedIds.has(userDoc.id)) return [];

        const userPosts = await getDocs(
          collection(firestore, `users/${userDoc.id}/posts`)
        );
        const userData = userDataCache.get(userDoc.id) || {};
        const connectionType = getConnectionType(userDoc.id);

        return userPosts.docs.map((postDoc) => {
          const interactions = postInteractions.get(postDoc.id) || {};
          const postData = {
            ...postDoc.data(),
            id: postDoc.id,
            globalPostId: `${userDoc.id}_${postDoc.id}`,
            user: {
              id: userDoc.id,
              firstName: userData.firstName || "",
              lastName: userData.lastName || "",
              profileImage: userData.profileImage || defaultImage,
              connectionType: connectionType,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
            },
            visibleReactedBy: interactions.reactor,
            visibleCommentedBy: !interactions.reactor
              ? interactions.commenter
              : undefined,
          };
          postData.isSuggested = isSuggestedPost(postData);
          return postData;
        });
      };

      const processStartupPosts = async (userDoc) => {
        if (blockedIds.has(userDoc.id)) return [];

        const startupsSnapshot = await getDocs(
          collection(firestore, `users/${userDoc.id}/startups`)
        );
        const startupPromises = startupsSnapshot.docs.map(
          async (startupDoc) => {
            const startupPosts = await getDocs(
              collection(
                firestore,
                `users/${userDoc.id}/startups/${startupDoc.id}/posts`
              )
            );
            const startupData = startupDoc.data() || {};

            return startupPosts.docs.map((postDoc) => {
              const interactions = postInteractions.get(postDoc.id) || {};
              const postData = {
                ...postDoc.data(),
                id: postDoc.id,
                globalPostId: `${userDoc.id}_${startupDoc.id}_${postDoc.id}`,
                user: {
                  id: `startup_${startupDoc.id}`,
                  firstName: startupData.startupName || "",
                  lastName: "",
                  profileImage: startupData.startupImage || defaultStartupImage,
                  connectionType: "Startup",
                  bio: startupData.bio || "",
                  link: startupData.link || null,
                  linkText: startupData.linkText || null,
                  isStartup: true,
                  startupId: startupDoc.id,
                  startupOwnerId: userDoc.id,
                  startupUrlSlug: startupData.startupUrlSlug,
                },
                visibleReactedBy: interactions.reactor,
                visibleCommentedBy: !interactions.reactor
                  ? interactions.commenter
                  : undefined,
              };
              postData.isSuggested = isSuggestedPost(postData);
              return postData;
            });
          }
        );

        return (await Promise.all(startupPromises)).flat();
      };

      const [userPosts, startupPosts] = await Promise.all([
        Promise.all(usersSnapshot.docs.map(processUserPosts)),
        Promise.all(usersSnapshot.docs.map(processStartupPosts)),
      ]);

      let allPosts = [...userPosts.flat(), ...startupPosts.flat()];

      allPosts = allPosts.filter((post) => {
        if (removedPostIds.has(post.globalPostId)) return false;
        if (!post.user.isStartup && blockedIds.has(post.user.id)) return false;
        if (post.user.isStartup && blockedIds.has(post.user.startupOwnerId))
          return false;
        return true;
      });

      const shuffledPosts = shuffleArray(allPosts);
      setPosts(shuffledPosts);

      return shuffledPosts;
    } catch (error) {
      console.error("Error fetching posts:", error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Post Management Functions
  const handleAddPost = () => {
    setPostModalScrollPosition(window.scrollY);
    setShowPostModal(true);
    setIsPostModalOpen(true);
  };

  const handleClosePostModal = useCallback(
    (success, newPostId, isUploading, progress) => {
      // Ensure modal state variables are reset
      setShowPostModal(false);
      setIsPostModalOpen(false);
      setSelectedFile(null);
      setEditingPost(null); // Reset editing state
      setShowEditModal(false); // Ensure edit modal is also closed

      // Handle scroll position restoration
      requestAnimationFrame(() => {
        window.scrollTo(0, postModalScrollPosition);
      });

      // Handle successful post creation/update
      if (success && newPostId) {
        if (isUploading) {
          updateUploadProgress(newPostId, progress, "uploading");
        } else {
          removeUploadingPost(newPostId);
          if (currentUser) {
            const currentSelectedStartup = selectedStartup;
            fetchNewPost(newPostId)
              .then(() => {
                setSelectedStartup(currentSelectedStartup);
              })
              .catch((error) => {
                console.error("Error fetching new post:", error);
              });
          }
        }
      }
    },
    [
      currentUser,
      updateUploadProgress,
      removeUploadingPost,
      selectedStartup,
      postModalScrollPosition,
    ]
  );

  const fetchNewPost = async (postId) => {
    if (!currentUser) {
      return;
    }

    try {
      let postDoc;
      let startupData;
      let userData;

      if (selectedStartup) {
        // For startup posts
        const startupPath = `users/${currentUser.uid}/startups/${selectedStartup.id}`;
        const [postSnapshot, startupSnapshot] = await Promise.all([
          getDoc(doc(firestore, `${startupPath}/posts/${postId}`)),
          getDoc(doc(firestore, startupPath)),
        ]);
        postDoc = postSnapshot;
        startupData = startupSnapshot.data();
      } else {
        // For user posts, get both post and user data
        const [postSnapshot, userSnapshot] = await Promise.all([
          getDoc(doc(firestore, `users/${currentUser.uid}/posts/${postId}`)),
          getDoc(doc(firestore, `users/${currentUser.uid}`)),
        ]);
        postDoc = postSnapshot;
        userData = userSnapshot.data();
      }

      if (!postDoc.exists()) {
        throw new Error("Post not found");
      }

      const postData = postDoc.data();
      const newPost = {
        ...postData,
        id: postId,
        globalPostId: selectedStartup
          ? `${currentUser.uid}_${selectedStartup.id}_${postId}`
          : `${currentUser.uid}_${postId}`,
        user: selectedStartup
          ? {
              id: `startup_${selectedStartup.id}`,
              firstName: selectedStartup.startupName,
              lastName: "",
              profileImage: selectedStartup.startupImage || defaultStartupImage,
              bio: startupData?.bio || "",
              link: startupData?.link || null,
              linkText: startupData?.linkText || null,
              connectionType: "You",
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
            }
          : {
              id: currentUser.uid,
              firstName: userData.firstName,
              lastName: userData.lastName,
              profileImage: userData.profileImage || defaultImage,
              bio: userData?.bio || "",
              link: userData?.link || null,
              linkText: userData?.linkText || null,
              connectionType: "You",
            },
      };

      setPosts((prevPosts) => {
        const exists = prevPosts.some(
          (p) => p.globalPostId === newPost.globalPostId
        );
        if (exists) return prevPosts;
        return [newPost, ...prevPosts];
      });

      setSuccessPostMessage({
        text: "Post successful.",
        postId: postId,
      });
    } catch (error) {
      setErrorPopup({
        message: "Error loading the new post.",
        isError: true,
      });
    }
  };

  const UploadingPost = React.memo(
    ({ postId, progress, status, onCancelUpload }) => {
      const handleCancelUpload = useCallback(() => {
        onCancelUpload(postId);
      }, [onCancelUpload, postId]);

      const roundedProgress = Math.round(progress);

      if (status === "cancelling") {
        return null; // Don't render anything if cancelling
      }

      return (
        <div className={classes.uploadingPost}>
          <div className={classes.uploadingHeader}>
            <span>Posting...</span>
            <div onClick={handleCancelUpload} className={classes.cancelButton}>
              Cancel
            </div>
          </div>

          <div className={classes.barAndText}>
            <div className={classes.progressBarContainer}>
              <div
                className={classes.progressBarFill}
                style={{ width: `${roundedProgress}%` }}
              ></div>
            </div>
            <div className={classes.progressText}>{roundedProgress}%</div>
          </div>
          <div className={classes.header}>
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="gray"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                clip-rule="evenodd"
              />
            </svg>
            <span className={classes.smallText}>
              Do not leave this page while posting.
            </span>
          </div>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.status === nextProps.status &&
        Math.floor(prevProps.progress) === Math.floor(nextProps.progress) &&
        prevProps.postId === nextProps.postId
      );
    }
  );

  const handleCancelUpload = useCallback(
    async (postId) => {
      cancelUpload(postId);
      updateUploadProgress(postId, 0, "cancelling");

      try {
        // Determine the correct path based on whether it's a startup post
        let postRef;
        if (selectedStartup) {
          postRef = doc(
            firestore,
            `users/${currentUser?.uid}/startups/${selectedStartup.id}/posts/${postId}`
          );
        } else {
          postRef = doc(firestore, `users/${currentUser?.uid}/posts/${postId}`);
        }
        await deleteDoc(postRef);

        // Delete from Storage - use the correct path for storage as well
        const storage = getStorage();
        const storagePath = selectedStartup
          ? `postContent/${currentUser?.uid}/startups/${selectedStartup.id}/${postId}`
          : `postContent/${currentUser?.uid}/${postId}`;
        const storageRef = ref(storage, storagePath);
        const items = await listAll(storageRef);
        await Promise.all(items.items.map((itemRef) => deleteObject(itemRef)));

        // Set the cancel message
        setCancelMessage("Post cancelled.");
      } catch (error) {
      } finally {
        removeUploadingPost(postId);
      }
    },
    [
      currentUser,
      selectedStartup,
      cancelUpload,
      updateUploadProgress,
      removeUploadingPost,
    ]
  );

  const handleRepost = async (postId) => {
    if (!currentUser) return;

    try {
      const post = findPostById(postId);
      if (!post) {
        throw new Error("Post not found");
      }

      const postRef = getPostRef(post);
      const reposterId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;
      const path = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}`
        : `users/${currentUser.uid}`;
      const repostedPostRef = doc(firestore, `${path}/reposts/${postId}`);

      // Get the correct originalPosterId format
      const originalPosterId = post.user.isStartup
        ? `startup_${post.user.startupId}`
        : post.user.id;

      let updatedReposts = { ...(post.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[reposterId]) {
        // Undo repost
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: deleteField(),
        });
        delete updatedReposts[reposterId];
        await deleteDoc(repostedPostRef);

        const notificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `repost_${postId}_${reposterId}`
        );
        await deleteDoc(notificationRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        // Add repost
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: now,
        });
        updatedReposts[reposterId] = now;

        await setDoc(repostedPostRef, {
          originalPosterId: originalPosterId,
          originalStartupOwnerId: post.user.isStartup
            ? post.user.startupOwnerId
            : null,
          postId: postId,
          repostedAt: now,
          originalDirectRepostTime: post.originalDirectRepostTime || now,
        });

        const shouldNotify =
          // Don't notify if the post belongs to current user
          post.user.id !== currentUser.uid &&
          // Also don't notify if it's a startup post and current user owns the startup
          (!post.user.isStartup ||
            post.user.startupOwnerId !== currentUser.uid);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${post.user.startupOwnerId || post.user.id}/notifications`,
            `repost_${postId}_${reposterId}`
          );

          const notificationData = {
            type: "repost",
            postId: postId,
            reposterId: reposterId,
            reposterName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reposterImage: selectedStartup
              ? selectedStartup.startupImage || defaultStartupImage
              : currentUserProfileImage,
            createdAt: now,
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            originalPosterId: originalPosterId,
            originalStartupOwnerId: post.user.isStartup
              ? post.user.startupOwnerId
              : null,
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage || defaultStartupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId
            ? {
                ...p,
                reposts: updatedReposts,
                originalDirectRepostTime: updatedReposts[reposterId]
                  ? now
                  : p.originalDirectRepostTime,
              }
            : p
        )
      );
    } catch (error) {
      setErrorPopup({
        message:
          error.message === "Post not found"
            ? "This post has been deleted or is no longer available."
            : "An error occurred while updating the repost.",
        isError: true,
      });
    }
  };

  const getPostRef = (post) => {
    if (post.user.isStartup) {
      return doc(
        firestore,
        `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}`
      );
    }
    return doc(firestore, `users/${post.user.id}/posts/${post.id}`);
  };

  const handleDeletePost = async (postId) => {
    // Show "Deleting..." message immediately
    setDeletingPostMessage({
      text: "Deleting post...",
      isLoading: true,
    });

    const affectedUserIds = new Set();
    const affectedStartupIds = new Set();

    if (!currentUser) {
      return;
    }

    try {
      const targetPost = findPostById(postId);
      if (!targetPost) {
        setErrorPopup({
          message: "This post has been deleted or is no longer available.",
          isError: true,
        });
        return;
      }

      // Get the correct post reference based on whether it's a startup post
      const postRef = targetPost.user.isStartup
        ? doc(
            firestore,
            `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/posts/${postId}`
          )
        : doc(firestore, `users/${targetPost.user.id}/posts/${postId}`);

      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        setErrorPopup({
          message: "This post has already been deleted.",
          isError: true,
        });
        return;
      }

      const postData = postSnap.data();

      // Verify permission to delete
      const canDelete =
        targetPost.user.id === currentUser.uid ||
        (targetPost.user.isStartup &&
          targetPost.user.startupOwnerId === currentUser.uid);

      if (!canDelete) {
        setErrorPopup({
          message: "You don't have permission to delete this post.",
          isError: true,
        });
        return;
      }

      let batch = writeBatch(firestore);
      let operationCount = 0;

      const commitBatchIfNeeded = async () => {
        if (operationCount >= 450) {
          await batch.commit();
          batch = writeBatch(firestore);
          operationCount = 0;
        }
      };

      // Get the correct comments collection reference
      const commentsRef = collection(
        firestore,
        targetPost.user.isStartup
          ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/posts/${postId}/comments`
          : `users/${targetPost.user.id}/posts/${postId}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      const deletePromises = commentsSnapshot.docs.map(async (commentDoc) => {
        const commentData = commentDoc.data();
        if (commentData.isStartup) {
          affectedStartupIds.add(commentData.startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        await deleteDoc(commentDoc.ref);
        operationCount++;
        await commitBatchIfNeeded();
      });

      await Promise.all(deletePromises);

      if (postData.reactions) {
        for (const userId of Object.keys(postData.reactions)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupReactedPostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/reactedPosts/${postId}`
              );
              batch.delete(startupReactedPostRef);
              operationCount++;

              const reactionNotificationRef = doc(
                firestore,
                `users/${targetPost.user.id}/notifications/reaction_${postId}_${userId}`
              );
              batch.delete(reactionNotificationRef);
              operationCount++;

              await commitBatchIfNeeded();
            }
          } else {
            const userReactedPostRef = doc(
              firestore,
              `users/${userId}/reactedPosts/${postId}`
            );
            batch.delete(userReactedPostRef);
            operationCount++;

            const reactionNotificationRef = doc(
              firestore,
              `users/${targetPost.user.id}/notifications/reaction_${postId}_${userId}`
            );
            batch.delete(reactionNotificationRef);
            operationCount++;

            await commitBatchIfNeeded();
          }
        }
      }

      if (postData.reposts) {
        for (const userId of Object.keys(postData.reposts)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupRepostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/reposts/${postId}`
              );
              batch.delete(startupRepostRef);
              operationCount++;

              const repostNotificationRef = doc(
                firestore,
                `users/${targetPost.user.id}/notifications/repost_${postId}_${userId}`
              );
              batch.delete(repostNotificationRef);
              operationCount++;

              await commitBatchIfNeeded();
            }
          } else {
            const userRepostRef = doc(
              firestore,
              `users/${userId}/reposts/${postId}`
            );
            batch.delete(userRepostRef);
            operationCount++;

            const repostNotificationRef = doc(
              firestore,
              `users/${targetPost.user.id}/notifications/repost_${postId}_${userId}`
            );
            batch.delete(repostNotificationRef);
            operationCount++;

            await commitBatchIfNeeded();
          }
        }
      }

      if (postData.comments) {
        for (const userId of Object.keys(postData.comments)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupCommentedPostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/commentedPosts/${postId}`
              );
              batch.delete(startupCommentedPostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } else {
            const commentedPostRef = doc(
              firestore,
              `users/${userId}/commentedPosts/${postId}`
            );
            batch.delete(commentedPostRef);
            operationCount++;
            await commitBatchIfNeeded();
          }
        }
      }

      const usersSnapshot = await getDocs(collection(firestore, "users"));
      for (const userDoc of usersSnapshot.docs) {
        const notificationsQuery = query(
          collection(firestore, `users/${userDoc.id}/notifications`),
          where("postId", "==", postId)
        );
        const notificationsSnapshot = await getDocs(notificationsQuery);

        const notificationDeletePromises = notificationsSnapshot.docs.map(
          (doc) => {
            batch.delete(doc.ref);
            operationCount++;
            return commitBatchIfNeeded();
          }
        );

        await Promise.all(notificationDeletePromises);
      }

      if (postData.content && postData.content.type) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `postContent/${targetPost.user.id}/${postId}`
        );

        try {
          if (
            postData.content.type === "image" &&
            Array.isArray(postData.content.url)
          ) {
            for (const imageUrl of postData.content.url) {
              const imageRef = ref(storage, imageUrl);
              await deleteObject(imageRef);
            }
          } else if (
            ["image", "video", "pdf"].includes(postData.content.type)
          ) {
            // Delete main file
            const fileRef = ref(storage, postData.content.url);
            await deleteObject(fileRef);

            // If it's a video, also delete the thumbnail
            if (
              postData.content.type === "video" &&
              postData.content.thumbnail
            ) {
              const thumbnailRef = ref(storage, postData.content.thumbnail);
              await deleteObject(thumbnailRef);
            }
          }

          // Delete entire folder contents to ensure nothing is left
          const folderContents = await listAll(storageRef);
          await Promise.all(
            folderContents.items.map((item) => deleteObject(item))
          );
        } catch (error) {}
      }

      batch.delete(postRef);
      operationCount++;

      await batch.commit();

      setPosts((prevPosts) => prevPosts.filter((p) => p.id !== postId));

      setComments((prevComments) => {
        const newComments = { ...prevComments };
        delete newComments[postId];
        return newComments;
      });

      setShowDropdown((prev) => {
        const newState = { ...prev };
        delete newState[postId];
        return newState;
      });

      // Show success message
      setDeletingPostMessage({
        text: "Post deleted",
        isLoading: false,
      });
    } catch (error) {
      setErrorPopup({
        message: "Failed to delete post.",
        isError: true,
      });
    } finally {
      // If there was an error, clear the "Deleting..." message
      if (deletingPostMessage?.isLoading) {
        setDeletingPostMessage(null);
      }
    }
  };

  const handleEditPost = (post) => {
    const isStartupPost = post.user?.isStartup;
    const ownerId = isStartupPost ? post.user.startupOwnerId : post.user.id;
    const startupId = isStartupPost ? post.user.startupId : null;

    const postToEdit = {
      ...post,
      _path: {
        userId: post.user.startupOwnerId,
        startupId: post.user.startupId,
        isStartup: post.user.isStartup,
      },
    };

    setPostModalScrollPosition(window.scrollY);
    setEditingPost(postToEdit);
    setShowEditModal(true);
    setIsPostModalOpen(true);
  };

  // Add this handler for when editing is complete
  const handleEditComplete = async (updatedText, isEdited, post) => {
    try {
      const postRef = getPostRef(post);
      const updateData = {
        text: updatedText,
        isEdited: isEdited,
      };

      await updateDoc(postRef, updateData);

      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === post.id ? { ...p, text: updatedText, isEdited: isEdited } : p
        )
      );

      setShowEditModal(false);
      setIsPostModalOpen(false);

      setTimeout(() => {
        window.scrollTo(0, postModalScrollPosition);
      }, 0);
    } catch (error) {
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  const handleCloseReactionsModal = () => {
    setShowReactionsModal(false);
    setIsReactionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, reactionsModalScrollPosition);
    }, 0);
  };

  const handleCloseRepostsModal = () => {
    setShowRepostsModal(false);
    setIsRepostsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, repostsModalScrollPosition);
    }, 0);
  };

  const SuccessPostPopup = ({ message, onClose, postId }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          <Link to={`/post/${postId}`} className={classes.viewPostLink}>
            View post
          </Link>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const ReportPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const RepostPopup = ({ message, onClose, postId, isRepost, isLoading }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          {isLoading ? (
            // Clock icon for "Deleting..." state
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            // Regular checkmark icon for other states
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
          )}
          <p>{message}</p>
          {isRepost && (
            <Link
              to={`/repost/${postId}`}
              state={{ fromRepostPopup: true }}
              className={classes.viewRepostLink}
            >
              View repost
            </Link>
          )}
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const VideoPlayer = memo(({ content, classes }) => {
    const [isMuted, setIsMuted] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0);
    const videoRef = useRef(null);
    const observerRef = useRef(null);

    // Format time to MM:SS
    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    };

    useEffect(() => {
      const video = videoRef.current;
      if (!video) return;

      const handleTimeUpdate = () => {
        setTimeLeft(video.duration - video.currentTime);
      };

      const handleLoadedMetadata = () => {
        setDuration(video.duration);
        setTimeLeft(video.duration);
      };

      // Create single IntersectionObserver
      const options = {
        root: null,
        rootMargin: "50px",
        threshold: 0.5,
      };

      observerRef.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            video.play().catch(() => {
              // Silent catch for autoplay prevention
              video.addEventListener(
                "click",
                () => {
                  video.play().catch(() => {});
                },
                { once: true }
              );
            });
            setIsPlaying(true);
          } else {
            video.pause();
            setIsPlaying(false);
          }
        });
      }, options);

      // Set up event listeners
      video.addEventListener("timeupdate", handleTimeUpdate);
      video.addEventListener("loadedmetadata", handleLoadedMetadata);
      video.addEventListener("play", () => setIsPlaying(true));
      video.addEventListener("pause", () => setIsPlaying(false));

      // Start observing
      observerRef.current.observe(video);

      // Cleanup
      return () => {
        video.removeEventListener("timeupdate", handleTimeUpdate);
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
        video.removeEventListener("play", () => setIsPlaying(true));
        video.removeEventListener("pause", () => setIsPlaying(false));
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }, []);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.muted = isMuted;
      }
    }, [isMuted]);

    const handleVolumeToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsMuted(!isMuted);
    };

    const handlePlayPause = (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (videoRef.current) {
        if (videoRef.current.paused) {
          videoRef.current.play().catch(() => {});
        } else {
          videoRef.current.pause();
        }
      }
    };

    return (
      <div className={classes.videoContainer}>
        <div className={classes.videoTimeCounter}>
          <div className={classes.videoTimeCounterInner}>
            {formatTime(timeLeft)}
          </div>
        </div>
        <video
          ref={videoRef}
          className={classes.postContentVideo}
          playsInline
          muted={isMuted}
          loop
          controls={false}
          preload="auto"
          poster={content.thumbnail || ""}
        >
          <source src={content.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className={classes.videoControlsLeft}>
          <button
            type="button"
            className={classes.volumeControl}
            onClick={handlePlayPause}
          >
            {isPlaying ? (
              <svg width="24" height="24" fill="white" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M8 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H8Zm7 0a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1Z"
                />
              </svg>
            ) : (
              <svg width="24" height="24" fill="white" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z"
                />
              </svg>
            )}
          </button>
        </div>
        <div className={classes.videoControlsRight}>
          <button
            type="button"
            className={classes.volumeControl}
            onClick={handleVolumeToggle}
          >
            {!isMuted ? (
              <svg viewBox="0 0 24 24" fill="white" width="24" height="24">
                <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
              </svg>
            ) : (
              <svg viewBox="0 0 24 24" fill="white" width="24" height="24">
                <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
              </svg>
            )}
          </button>
        </div>
      </div>
    );
  });

  const PostMedia = React.memo(
    ({ content, classes }) => {
      if (!content) return null;

      switch (content.type) {
        case "image":
          // Handle both single and multiple images
          if (Array.isArray(content.url)) {
            // For multiple images, use ImageCarousel
            return <ImageCarousel images={content.url} classes={classes} />;
          }
          // For single image
          return (
            <img
              src={content.url}
              alt=""
              className={classes.postContentImage}
              loading="eager"
            />
          );
        case "video":
          return <VideoPlayer content={content} classes={classes} />;
        case "pdf":
          return content ? (
            <PDFPreview
              content={content}
              classes={classes}
              hasInteractions={true}
            />
          ) : null;
        default:
          return null;
      }
    },
    (prev, next) => prev.content?.url === next.content?.url
  );

  const renderPostContent = useCallback((post) => {
    if (!post?.content) return null;
    return <PostMedia content={post.content} classes={classes} />;
  }, []);

  const formatTime = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return "now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)}w`;
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}mo`;
    return `${Math.floor(diffInSeconds / 31536000)}y`;
  };

  // Post Component
  const Post = React.memo(
    ({ post, currentUser, selectedStartup, ...props }) => {
      const [showDropdown, setShowDropdown] = useState(false);
      const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
      const [expandedPosts, setExpandedPosts] = useState({});

      const handleInteraction = (e, type) => {
        // Prevent default behavior for all interactions
        e.preventDefault();
        e.stopPropagation();

        const target = e.target;
        const isStartupToggle = target.closest(".interactionToggle");

        if (!isStartupToggle) {
          switch (type) {
            case "boost":
              handleEmojiSelect("👍", post.id);
              break;
            case "comment":
              toggleCommentInput(post.id);
              break;
            case "repost":
              handleRepost(post.id);
              break;
            case "share":
              handleShare(post.id);
              break;
          }
        }
      };

      const handleOpenEmojiPicker = () => {
        const currentEmoji = selectedStartup
          ? post.reactions?.[`startup_${selectedStartup.id}`]
          : post.reactions?.[currentUser?.uid];

        if (currentEmoji) {
          // If there's already an emoji, remove it
          handleEmojiSelect(currentEmoji, post.id);
        } else {
          // Otherwise show emoji picker
          setEmojiPickerScrollPosition(window.scrollY);
          setActiveEmojiPicker(`post_${post.id}`);
        }
      };

      const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
      };

      const formatTime = (timestamp) => {
        if (!timestamp) return "";
        const date = timestamp instanceof Date ? timestamp : timestamp.toDate();
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);

        if (diffInSeconds < 60) return "now";
        if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
        if (diffInSeconds < 86400)
          return `${Math.floor(diffInSeconds / 3600)}h`;
        if (diffInSeconds < 604800)
          return `${Math.floor(diffInSeconds / 86400)}d`;
        if (diffInSeconds < 2592000)
          return `${Math.floor(diffInSeconds / 604800)}w`;
        return `${Math.floor(diffInSeconds / 2592000)}mo`;
      };

      const togglePostExpansion = (postId) => {
        setExpandedPosts((prev) => ({
          ...prev,
          [postId]: !prev[postId],
        }));
      };

      const renderPostText = () => {
        if (!post.text) return null;
        const lines = post.text.split("\n");
        const isLongPost = lines.length > 3 || post.text.length > 149;
        const displayText = expandedPosts[post.id]
          ? post.text
          : lines.slice(0, 3).join("\n").slice(0, 149);

        // Ensure links are converted properly
        const textWithLinks = convertUrlsToLinks(displayText);

        return (
          <div className={classes.postTextContainer}>
            <div
              className={`${classes.postText} ${classes.preserveFormatting}`}
              dangerouslySetInnerHTML={{ __html: textWithLinks }}
              onClick={handleLinkClick}
            />
            {!expandedPosts[post.id] && isLongPost && (
              <button
                className={classes.showMoreButton}
                onClick={() => togglePostExpansion(post.id)}
              >
                ...more
              </button>
            )}
          </div>
        );
      };

      const getMediaClass = (post) => {
        if (!post || !post.content) return "";
        if (post.content.type === "video") return classes.hasVideo;
        if (post.content.type === "image") {
          return Array.isArray(post.content.url)
            ? classes.hasMultipleImages
            : classes.hasImage;
        }
        return "";
      };

      return (
        <div className={`${classes.postCard} ${getMediaClass(post)}`}>
          {post.visibleReactedBy && (
            <div className={classes.reactionHeader}>
              <img
                src={post.visibleReactedBy.profileImage || defaultImage}
                alt={`${post.visibleReactedBy.firstName} ${post.visibleReactedBy.lastName}`}
                className={classes.reactionImage}
                onClick={() =>
                  handleNavigate(
                    post.visibleReactedBy.id,
                    post.visibleReactedBy.isStartup,
                    post.visibleReactedBy.startupUrlSlug
                  )
                }
                style={{
                  borderRadius: post.visibleReactedBy.isStartup ? 0 : "50%",
                  cursor: "pointer",
                }}
              />
              <span className={classes.reactionText}>
                <span
                  className={classes.reactorName}
                  onClick={() =>
                    handleNavigate(
                      post.visibleReactedBy.id,
                      post.visibleReactedBy.isStartup,
                      post.visibleReactedBy.startupUrlSlug
                    )
                  }
                >
                  {post.visibleReactedBy.firstName}{" "}
                  {post.visibleReactedBy.lastName}
                </span>{" "}
                boosted this
              </span>
            </div>
          )}

          {post.visibleCommentedBy &&
            !post.visibleReactedBy &&
            !post.visibleRepostedBy && (
              <div className={classes.reactionHeader}>
                <img
                  src={post.visibleCommentedBy.profileImage || defaultImage}
                  alt={`${post.visibleCommentedBy.firstName} ${post.visibleCommentedBy.lastName}`}
                  className={classes.reactionImage}
                  onClick={() =>
                    handleNavigate(
                      post.visibleCommentedBy.id,
                      post.visibleCommentedBy.isStartup,
                      post.visibleCommentedBy.startupUrlSlug
                    )
                  }
                  style={{
                    borderRadius: post.visibleCommentedBy.isStartup ? 0 : "50%",
                    cursor: "pointer",
                  }}
                />
                <span className={classes.reactionText}>
                  <span
                    className={classes.reactorName}
                    onClick={() =>
                      handleNavigate(
                        post.visibleCommentedBy.id,
                        post.visibleCommentedBy.isStartup,
                        post.visibleCommentedBy.startupUrlSlug
                      )
                    }
                  >
                    {post.visibleCommentedBy.firstName}{" "}
                    {post.visibleCommentedBy.lastName}
                  </span>{" "}
                  commented on this
                </span>
              </div>
            )}
          {post.isSuggested && (
            <div className={classes.reactionHeader}>
              <span className={classes.suggestedText}>Suggested</span>
            </div>
          )}
          <div className={classes.postHeader}>
            <div className={classes.userInfo}>
              <img
                src={post.user.profileImage || defaultImage}
                alt=""
                className={classes.postImage}
                onClick={() =>
                  handleNavigate(
                    post.user.id,
                    post.user.isStartup,
                    post.user.startupUrlSlug
                  )
                }
                style={{
                  borderRadius: post.user.isStartup ? 0 : "50%",
                  cursor: "pointer",
                }}
              />
              <div className={classes.userDetails}>
                <div className={classes.userNameContainer}>
                  <span
                    className={classes.userName}
                    onClick={() =>
                      handleNavigate(
                        post.user.id,
                        post.user.isStartup,
                        post.user.startupUrlSlug
                      )
                    }
                  >
                    {post.user.firstName} {post.user.lastName}
                  </span>
                  <span className={classes.connectionType}>
                    • {post.user.connectionType}
                  </span>
                </div>
                {post.user.bio && (
                  <p className={classes.bio}>{post.user.bio}</p>
                )}
                {post.user.link && (
                  <div className={classes.userLinkContainer}>
                    <a
                      href={post.user.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                      className={classes.userLink}
                    >
                      <span className={classes.userLinkText}>
                        {post.user.linkText || post.user.link}
                      </span>
                      <svg
                        className={classes.userLinkIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="orangered"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                        />
                      </svg>
                    </a>
                  </div>
                )}
                <div className={classes.timeAndEditDetails}>
                  <p className={classes.time}>{formatTime(post.createdAt)}</p>
                  {post.isEdited && (
                    <div className={classes.editDot}>
                      • <span>Edited</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={classes.postOptions}>
              <svg
                className={`${classes.optionsIcon} ${
                  showDropdown[post.id] ? classes.optionsIconActive : ""
                }`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
                onClick={() =>
                  setShowDropdown((prev) => ({
                    ...prev,
                    [post.id]: !prev[post.id],
                  }))
                }
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="3"
                  d="M6 12h.01m6 0h.01m5.99 0h.01"
                />
              </svg>
              {showDropdown[post.id] && renderPostDropdown(post)}
            </div>
          </div>

          {renderPostText()}
          {renderPostContent(post)}
          {post.reactions && Object.keys(post.reactions).length > 0 && (
            <div
              className={`${classes.postInteractionsInfo} ${
                post.content?.type === "video" ||
                post.content?.type === "image" ||
                (post.content?.type === "image" &&
                  Array.isArray(post.content.url))
                  ? classes.hasMediaContent
                  : ""
              }`}
              onClick={() => {
                if (post && post.reactions) {
                  setReactionsModalScrollPosition(window.scrollY);
                  setActivePostReactions({
                    postId: post.id,
                    reactions: post.reactions,
                  });
                  setShowReactionsModal(true);
                  setIsReactionsModalOpen(true);
                }
              }}
              style={{ cursor: "pointer" }}
            >
              See who boosted{" "}
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 20V7m0 13-4-4m4 4 4-4m4-12v13m0-13 4 4m-4-4-4 4"
                />
              </svg>
            </div>
          )}
          <div className={classes.postInteractions}>
            <MemoizedToggle
              currentUser={currentUser}
              selectedStartup={selectedStartup}
              onStartupSelect={handleStartupSelect}
              currentUserProfileImage={currentUserProfileImage}
              onModalOpen={() => handleModalOpen("post")}
              onModalClose={handleModalClose}
              className={classes.interactionToggle}
              userStartups={userStartups}
              toggleId="post"
            />

            <button
              type="button"
              className={`${classes.interactionButton} ${
                (selectedStartup &&
                  post.reactions?.[`startup_${selectedStartup.id}`]) ||
                (!selectedStartup && post.reactions?.[currentUser?.uid])
                  ? classes.activeInteraction
                  : ""
              }`}
              onClick={(e) => handleInteraction(e, "boost")}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z"
                  clipRule="evenodd"
                />
              </svg>
              Boost{" "}
              {post.reactions &&
                Object.keys(post.reactions).length > 0 &&
                `(${Object.keys(post.reactions).length})`}
            </button>

            {/* Comment Button */}
            <button
              className={`${classes.interactionButton} ${
                (selectedStartup &&
                  post.comments?.[`startup_${selectedStartup.id}`]) ||
                (!selectedStartup && post.comments?.[currentUser?.uid])
                  ? classes.activeInteraction
                  : ""
              }`}
              onClick={(e) => handleInteraction(e, "comment")}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
                  clipRule="evenodd"
                />
              </svg>
              Comment {post.commentCount > 0 && `(${post.commentCount})`}
            </button>

            {/* Repost Button */}
            {/* <button
              className={`${classes.interactionButton} ${
                (selectedStartup &&
                  post.reposts?.[`startup_${selectedStartup.id}`]) ||
                (!selectedStartup && post.reposts?.[currentUser?.uid])
                  ? classes.activeInteraction
                  : ""
              }`}
              onClick={(e) => handleInteraction(e, "repost")}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M14.516 6.743c-.41-.368-.443-1-.077-1.41a.99.99 0 0 1 1.405-.078l5.487 4.948.007.006A2.047 2.047 0 0 1 22 11.721a2.06 2.06 0 0 1-.662 1.51l-5.584 5.09a.99.99 0 0 1-1.404-.07 1.003 1.003 0 0 1 .068-1.412l5.578-5.082a.05.05 0 0 0 .015-.036.051.051 0 0 0-.015-.036l-5.48-4.942Zm-6.543 9.199v-.42a4.168 4.168 0 0 0-2.715 2.415c-.154.382-.44.695-.806.88a1.683 1.683 0 0 1-2.167-.571 1.705 1.705 0 0 1-.279-1.092V15.88c0-3.77 2.526-7.039 5.967-7.573V7.57a1.957 1.957 0 0 1 .993-1.838 1.931 1.931 0 0 1 2.153.184l5.08 4.248a.646.646 0 0 1 .012.011l.011.01a2.098 2.098 0 0 1 .703 1.57 2.108 2.108 0 0 1-.726 1.59l-5.08 4.25a1.933 1.933 0 0 1-2.929-.614 1.957 1.957 0 0 1-.217-1.04Z"
                  clipRule="evenodd"
                />
              </svg>
              Repost{" "}
              {post.reposts &&
                Object.keys(post.reposts).length > 0 &&
                `(${Object.keys(post.reposts).length})`}
            </button> */}
            <button
              className={classes.interactionButton}
              onClick={(e) => handleInteraction(e, "share")}
            >
              {shareStates[post.id] === "Copied" ? (
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                </svg>
              )}
              {shareStates[post.id] || "Share"}
            </button>
          </div>
          {showComments[post.id] && (
            <div className={classes.commentsSection}>
              <CommentInput postId={post.id} onSubmit={handleAddComment} />
              <div className={classes.commentsList}>
                {loadingComments[post.id] ? (
                  <div className={classes.commentLoadingIndicator}>
                    <span
                      className={`${classes.loadingIcon} material-symbols-outlined`}
                    >
                      progress_activity
                    </span>
                  </div>
                ) : comments[post.id] && comments[post.id].length > 0 ? (
                  renderComments(post.id)
                ) : (
                  <div className={classes.noComments}></div>
                )}
              </div>
            </div>
          )}

          {activeEmojiPicker === `post_${post.id}` && (
            <EmojiPicker
              onEmojiClick={(emoji) => handleEmojiSelect(emoji, post.id)}
              onClose={() => {
                setActiveEmojiPicker(null);
                setTimeout(() => {
                  window.scrollTo(0, emojiPickerScrollPosition);
                }, 0);
              }}
            />
          )}
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.post.id === nextProps.post.id &&
        prevProps.post.content === nextProps.post.content &&
        prevProps.currentUser?.uid === nextProps.currentUser?.uid &&
        prevProps.selectedStartup?.id === nextProps.selectedStartup?.id
      );
    }
  );

  // Handle Reaction Function
  const handleReaction = useCallback(
    async (postId) => {
      if (!currentUser) return;

      setLoading(true);
      try {
        const post = posts.find((p) => p.id === postId);
        if (!post) {
          throw new Error("Post not found");
        }

        // Check if user has already reacted
        const reactorId = selectedStartup
          ? `startup_${selectedStartup.id}`
          : currentUser.uid;

        if (post.reactions && post.reactions[reactorId]) {
          // User has already reacted, so remove the reaction
          const postRef = doc(
            firestore,
            "users",
            post.user.id,
            "posts",
            postId
          );
          await updateDoc(postRef, {
            [`reactions.${currentUser.uid}`]: deleteField(),
          });

          // Remove from reactedPosts
          const reactedPostRef = doc(
            firestore,
            "users",
            currentUser.uid,
            "reactedPosts",
            postId
          );
          await deleteDoc(reactedPostRef);

          // Remove notification
          const notificationRef = doc(
            firestore,
            "users",
            post.user.id,
            "notifications",
            `reaction_${postId}_${currentUser.uid}`
          );
          await deleteDoc(notificationRef);

          // Update local state
          setPosts((prevPosts) =>
            prevPosts.map((p) => {
              if (p.id === postId) {
                const updatedReactions = { ...p.reactions };
                delete updatedReactions[currentUser.uid];
                return {
                  ...p,
                  reactions: updatedReactions,
                };
              }
              return p;
            })
          );
        } else {
          // Toggle EmojiPicker with proper scroll position handling
          setEmojiPickerScrollPosition(window.scrollY);
          setActiveEmojiPicker((prev) =>
            prev === `post_${postId}` ? null : `post_${postId}`
          );
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [currentUser]
  );

  const handleToggleComments = useCallback(
    async (postId) => {
      setShowComments((prev) => ({
        ...prev,
        [postId]: !prev[postId],
      }));

      // Only fetch if we're showing comments and haven't loaded them yet
      if (!comments[postId] && !showComments[postId]) {
        await fetchComments(postId);
      }
    },
    [comments, showComments, fetchComments]
  );

  const handleEmojiSelect = async (emoji, postId) => {
    if (!currentUser) return;

    try {
      const post = findPostById(postId);
      if (!post) {
        throw new Error("Post not found");
      }

      const postRef = getPostRef(post);
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      let updatedReactions = { ...(post.reactions || {}) };

      // Update local state first to prevent UI jump
      if (updatedReactions[reactorId] === emoji) {
        delete updatedReactions[reactorId];
      } else {
        updatedReactions[reactorId] = emoji;
      }

      // Update local state immediately
      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId ? { ...p, reactions: updatedReactions } : p
        )
      );

      // Then handle the Firebase updates
      const originalPosterId = post.user.isStartup
        ? `startup_${post.user.startupId}`
        : post.user.id;

      const path = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}`
        : `users/${currentUser.uid}`;

      if (updatedReactions[reactorId]) {
        // Add reaction
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: emoji,
        });

        const reactedPostRef = doc(
          firestore,
          `${path}/reactedPosts/${post.id}`
        );
        await setDoc(reactedPostRef, {
          originalPosterId: originalPosterId,
          originalStartupOwnerId: post.user.isStartup
            ? post.user.startupOwnerId
            : null,
          postId: post.id,
          reactedAt: serverTimestamp(),
          emoji: emoji,
        });

        const shouldNotify =
          // Don't notify if the post belongs to current user
          post.user.id !== currentUser.uid &&
          // Also don't notify if it's a startup post and current user owns the startup
          (!post.user.isStartup ||
            post.user.startupOwnerId !== currentUser.uid);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              post.user.startupOwnerId || post.user.id
            }/notifications/reaction_${post.id}_${reactorId}`
          );

          const notificationData = {
            type: "reaction",
            postId: post.id,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage || defaultStartupImage
              : currentUserProfileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage || defaultStartupImage,
            }),
            originalPosterId: originalPosterId,
            originalStartupOwnerId: post.user.isStartup
              ? post.user.startupOwnerId
              : null,
          };

          await setDoc(notificationRef, notificationData);
        }
      } else {
        // Remove reaction
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });

        const reactedPostRef = doc(
          firestore,
          `${path}/reactedPosts/${post.id}`
        );
        await deleteDoc(reactedPostRef);

        const notificationRef = doc(
          firestore,
          `users/${
            post.user.startupOwnerId || post.user.id
          }/notifications/reaction_${post.id}_${reactorId}`
        );
        await deleteDoc(notificationRef);
      }

      // Close emoji picker if it's open
      if (activeEmojiPicker) {
        setActiveEmojiPicker(null);
      }
    } catch (error) {
      if (error.message === "Post not found") {
        setErrorPopup({
          message: "This post has been deleted or is no longer available.",
          isError: true,
        });
      } else {
        setErrorPopup({
          message: "An error occurred while updating the reaction.",
          isError: true,
        });
      }
    }
  };

  const scrollPositionRef = useRef(0);

  const handleScroll = useCallback(() => {
    scrollPositionRef.current = window.scrollY;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const renderPosts = useMemo(() => {
    // Sort posts by newest first
    const sortedPosts = [...displayPosts].sort((a, b) => {
      const dateA =
        a.createdAt instanceof Date ? a.createdAt : a.createdAt?.toDate();
      const dateB =
        b.createdAt instanceof Date ? b.createdAt : b.createdAt?.toDate();
      return dateB - dateA; // Newest first
    });

    return sortedPosts.map((post) => (
      <Post
        key={post.globalPostId || post.id}
        post={post}
        currentUser={currentUser}
        selectedStartup={selectedStartup}
        onReaction={handleReaction}
        onToggleComments={handleToggleComments}
        showComments={showComments[post.id]}
        comments={comments[post.id]}
        loadingComments={loadingComments[post.id]}
        onRepost={handleRepost}
        onShare={handleShare}
        onNavigate={handleNavigate}
        onEdit={handleEditPost}
        onDelete={handleDeletePost}
        classes={classes}
        defaultImage={defaultImage}
      />
    ));
  }, [
    displayPosts,
    currentUser,
    selectedStartup,
    showComments,
    comments,
    loadingComments,
  ]);

  // In Feed.js
  const handleStartupSelect = useCallback((startup) => {
    setSelectedStartup(startup);
  }, []);

  const handleModalOpen = useCallback((toggleId) => {
    setStartupModalScrollPosition(window.scrollY);
    setShowStartupModal(true);
    setActiveStartupToggle(toggleId);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowStartupModal(false);
    setTimeout(() => {
      window.scrollTo(0, startupModalScrollPosition);
    }, 0);
  }, [startupModalScrollPosition]);

  const handleRemovePost = async (post) => {
    try {
      // Create a document in the removedPosts subcollection
      const removedPostRef = doc(
        firestore,
        `users/${currentUser.uid}/removedPosts/${post.globalPostId}`
      );

      await setDoc(removedPostRef, {
        removedAt: serverTimestamp(),
        postId: post.id,
        originalPosterId: post.user.isStartup
          ? `startup_${post.user.startupId}`
          : post.user.id,
        startupOwnerId: post.user.startupOwnerId || null,
      });

      // Remove the post from the local state
      setPosts((prevPosts) =>
        prevPosts.filter((p) => p.globalPostId !== post.globalPostId)
      );
      setShowDropdown((prev) => ({ ...prev, [post.id]: false }));

      // Show success message
      setErrorPopup({
        message: "Post removed.",
        isError: false,
      });
    } catch (error) {
      setErrorPopup({
        message: "Error removing post.",
        isError: true,
      });
    }
  };

  // Add this function inside Feed.js, near other render functions
  const renderPostDropdown = (post) => {
    const isOwnPost = post.user.isStartup
      ? post.user.startupOwnerId === currentUser.uid
      : post.user.id === currentUser.uid;

    return (
      <div className={classes.optionsDropdown}>
        {isOwnPost ? (
          <>
            <button
              onClick={() => handleEditPost(post)}
              className={classes.editPost}
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                />
              </svg>
              Edit
            </button>
            <button
              onClick={() => handleDeletePost(post.id)}
              className={classes.deletePost}
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                />
              </svg>
              Delete
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => handleRemovePost(post)}
              className={classes.hidePost}
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              Hide
            </button>
            <button
              onClick={() => handleReportPost(post.id)}
              className={classes.reportPost}
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              Report
            </button>
          </>
        )}
      </div>
    );
  };

  const handleReportPost = (postId) => {
    setShowDropdown((prev) => ({ ...prev, [postId]: false }));
    setReportMessage({
      text: "Report submitted.",
    });
  };

  const handleReportComment = (commentId) => {
    setOpenCommentDropdown(null);
    setReportMessage({
      text: "Report submitted.",
    });
  };

  useEffect(() => {
    // Initialize video volumes
    setVideoVolume((prev) => {
      const newVolumes = { ...prev };
      posts.forEach((post) => {
        if (
          post.content?.type === "video" &&
          newVolumes[post.id] === undefined
        ) {
          newVolumes[post.id] = false; // Start muted
        }
      });
      return newVolumes;
    });

    const options = {
      root: null,
      rootMargin: "50px",
      threshold: [0.1, 0.5, 0.9], // Multiple thresholds for smoother transitions
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const video = entry.target;
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
          if (video.paused) {
            const playPromise = video.play();
            if (playPromise !== undefined) {
              playPromise.catch((error) => {
                console.log("Autoplay was prevented:", error);
                // Retry play on user interaction
                video.addEventListener(
                  "click",
                  () => {
                    video.play().catch((e) => console.log("Play failed:", e));
                  },
                  { once: true }
                );
              });
            }
          }
        } else {
          if (!video.paused) {
            video.pause();
          }
        }
      });
    }, options);

    // Observe all video elements
    Object.values(videoRefs.current).forEach((video) => {
      if (video) {
        observer.observe(video);
        // Ensure video is loaded
        video.load();
      }
    });

    return () => {
      Object.values(videoRefs.current).forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };
  }, [posts]);

  useEffect(() => {
    const batchedVideoUpdates = requestAnimationFrame(() => {
      const options = {
        root: null,
        rootMargin: "50px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const video = entry.target;
          if (entry.isIntersecting) {
            video.play().catch(() => {
              // Silently handle autoplay prevention
            });
          } else {
            video.pause();
          }
        });
      }, options);

      Object.values(videoRefs.current).forEach((video) => {
        if (video) {
          observer.observe(video);
        }
      });

      return () => {
        Object.values(videoRefs.current).forEach((video) => {
          if (video) {
            observer.unobserve(video);
            video.pause();
          }
        });
      };
    });

    return () => cancelAnimationFrame(batchedVideoUpdates);
  }, [posts]);

  // Add this after the auth useEffect
  // Replace the existing effect
  useEffect(() => {
    if (
      authChecked &&
      currentUser?.uid &&
      !loading &&
      !posts.length &&
      !hasTriedInitialFetch &&
      !Object.keys(state.uploadingPosts).length
    ) {
      setHasTriedInitialFetch(true);
      fetchPosts(currentUser.uid, false, null, true);
    }
  }, [authChecked, currentUser?.uid]); // Simplified dependencies

  useEffect(() => {
    return () => {
      setAllSeenPostIds(new Set());
      setUniquePostsMap(new Map());
    };
  }, []);

  const resetPostTracking = useCallback(() => {
    setAllSeenPostIds(new Set());
    setUniquePostsMap(new Map());
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      resetPostTracking();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [resetPostTracking]);

  // Effect for auth state and user data
  // Update the auth effect in Feed.js
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        resetPostTracking(); // Add this if you have it
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            ...user,
            firstName: userData.firstName,
            lastName: userData.lastName,
            profileImage: userData.profileImage || defaultImage,
            urlSlug: userData.urlSlug, // Add this line
          });
          setUserData(userData);
          setCurrentUserProfileImage(userData.profileImage || defaultImage);
          fetchPosts(user.uid); // Initial fetch
        }
      } else {
        setCurrentUser(null);
        setUserData({});
        setCurrentUserProfileImage(null);
      }
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, [resetPostTracking]);

  // Render function
  return (
    <div
      className={`${classes.page} ${
        showStartupModal || showPostModal || showEditModal
          ? classes.noScroll
          : ""
      }`}
      style={
        showStartupModal
          ? { top: `-${startupModalScrollPosition}px` }
          : showPostModal || showEditModal
          ? { top: `-${postModalScrollPosition}px` }
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={userData.profileImage || defaultImage} />
      ) : (
        <MainNavbar userImage={userData.profileImage || defaultImage} />
      )}
      {/* Add this alongside your other popups */}
      {deletingPostMessage && (
        <RepostPopup
          message={deletingPostMessage.text}
          onClose={() => setDeletingPostMessage(null)}
          isLoading={deletingPostMessage.isLoading}
        />
      )}
      {deletingCommentMessage && (
        <RepostPopup
          message={deletingCommentMessage.text}
          onClose={() => setDeletingCommentMessage(null)}
          isLoading={deletingCommentMessage.isLoading}
        />
      )}
      {showStartupModal &&
        (console.log(
          "Mounting StartupInteractionModal, activeStartupToggle:",
          activeStartupToggle
        ),
        (
          <StartupInteractionModal
            onClose={() => {
              setShowStartupModal(false);
              handleModalClose();
              setActiveStartupToggle(null);
            }}
            currentUser={currentUser}
            onStartupSelect={(startup) => {
              handleStartupSelect(startup);
              setShowStartupModal(false);
              handleModalClose();
            }}
            selectedStartup={selectedStartup}
            currentUserProfileImage={currentUserProfileImage}
          />
        ))}

      {reportMessage && (
        <ReportPopup
          message={reportMessage.text}
          onClose={() => setReportMessage(null)}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          {/* Top Card */}
          {authChecked && currentUser && (
            <div className={classes.topCard}>
              <div className={classes.postContent}>
                <MemoizedToggle
                  currentUser={currentUser}
                  onStartupSelect={handleStartupSelect}
                  selectedStartup={selectedStartup}
                  currentUserProfileImage={currentUserProfileImage}
                  onModalOpen={() => handleModalOpen("top")}
                  onModalClose={handleModalClose}
                  className={classes.interactionToggle}
                  userStartups={userStartups}
                  toggleId="top"
                />
                <div className={classes.postButton} onClick={handleAddPost}>
                  Share something...
                </div>
              </div>
            </div>
          )}
          {/* Show uploading posts */}
          {Object.entries(state.uploadingPosts).map(
            ([postId, postData]) =>
              !state.cancelledPosts[postId] && (
                <UploadingPost
                  key={postId}
                  postId={postId}
                  progress={postData.progress}
                  status={postData.status}
                  onCancelUpload={handleCancelUpload}
                />
              )
          )}
          {/* Show loading indicator for initial load */}
          {loading && !posts.length && (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          )}
          {/* Show no posts message when not loading and no posts found */}
          {!loading &&
            authChecked &&
            !posts.length &&
            !Object.keys(state.uploadingPosts).length &&
            hasCompletedInitialFetch && ( // Add this condition
              <div className={classes.noNotifications}>
                <div className={classes.bold}>No posts found</div>
                <div className={classes.text}>
                  Please check your internet connection.
                </div>
                <div
                  className={classes.homeButton}
                  onClick={() => window.location.reload()}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                    />
                  </svg>
                  Refresh
                </div>
              </div>
            )}
          {/* Show posts */}
          {renderPosts}
          {/* <FilteredFeed
            posts={posts}
            firestore={firestore}
            currentUser={currentUser}
            selectedStartup={selectedStartup}
            onReaction={handleReaction}
            onToggleComments={handleToggleComments}
            showComments={showComments}
            comments={comments}
            loadingComments={loadingComments}
            onRepost={handleRepost}
            onShare={handleShare}
            onNavigate={handleNavigate}
            onEdit={handleEditPost}
            onDelete={handleDeletePost}
            classes={classes}
            defaultImage={defaultImage}
          /> */}
          {/* Show loading indicator for "Show more" */}
          {loading && posts.length > 0 && (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          )}
          {/* Show "Show more" button only if we have posts and more can be loaded */}
          {/* Handle both "Show more" and "No more posts" states */}

          {/* {!loading && posts.length > 0 && (
            <div className={classes.showMoreContainer}>
              {loadingMore ? (
                <div className={classes.loadingIndicator}>
                  <span
                    className={`${classes.loadingIcon} material-symbols-outlined`}
                  >
                    progress_activity
                  </span>
                </div>
              ) : hasMorePosts ? (
                <button
                  className={classes.miscButton}
                  onClick={async () => {
                    const currentScroll = window.scrollY;
                    setLoadingMore(true);
                    try {
                      const morePostsFound = await fetchPosts(
                        currentUser?.uid,
                        true
                      );
                      if (!morePostsFound || morePostsFound.length === 0) {
                        setHasMorePosts(false);
                      }
                      window.scrollTo(0, currentScroll);
                    } finally {
                      setLoadingMore(false);
                    }
                  }}
                >
                  Show more
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                </button>
              ) : (
                <div className={classes.noNotifications}>
                  <div className={classes.bold}>No posts found</div>
                  <div className={classes.text}>
                    Please check your internet connection.
                  </div>
                  <div
                    className={classes.homeButton}
                    onClick={() => window.location.reload()}
                  >
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                      />
                    </svg>
                    Refresh
                  </div>
                </div>
              )}
            </div>
          )} */}
        </div>

        <div className={classes.rightCards}>
          <Ad />
          <TermsAndConditions />
        </div>
      </div>

      {/* Modals */}
      {showPostModal && !showEditModal && (
        <PostModal
          onClose={handleClosePostModal}
          currentUser={currentUser}
          initialFile={selectedFile}
          selectedStartup={selectedStartup}
        />
      )}
      {showEditModal && (
        <PostModal
          onClose={() => {
            setShowEditModal(false);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialPost={editingPost} // Use editingPost instead of post
          onEditComplete={(updatedText, isEdited) =>
            handleEditComplete(updatedText, isEdited, editingPost)
          } // Pass editingPost here
        />
      )}

      {showReactionsModal && activePostReactions && (
        <ReactionsModal
          onClose={handleCloseReactionsModal}
          postId={activePostReactions.postId}
          reactions={activePostReactions.reactions}
          currentUserId={currentUser?.uid}
        />
      )}

      {showRepostsModal && activePostReposts && (
        <RepostsModal
          onClose={handleCloseRepostsModal}
          postId={activePostReposts.postId}
          reposts={activePostReposts.reposts}
          currentUserId={currentUser?.uid}
        />
      )}

      {/* Status Messages */}
      {successPostMessage && (
        <SuccessPostPopup
          message={successPostMessage.text}
          postId={successPostMessage.postId}
          onClose={() => setSuccessPostMessage(null)}
        />
      )}

      {errorPopup && (
        <ErrorPopup
          message={errorPopup.message}
          onClose={() => setErrorPopup(null)}
        />
      )}

      {repostMessage && (
        <RepostPopup
          message={repostMessage.text}
          postId={repostMessage.postId}
          isRepost={repostMessage.isRepost}
          onClose={() => setRepostMessage(null)}
        />
      )}
    </div>
  );
}

export default Feed;
