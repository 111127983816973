import { useState, useCallback, useRef } from "react";

export const useMentions = (inputRef) => {
  const [showMentions, setShowMentions] = useState(false);
  const [mentionSearch, setMentionSearch] = useState("");
  const [mentionedUsers, setMentionedUsers] = useState({});
  const lastAtPositionRef = useRef(null);

  const initializeMentions = useCallback((mentions) => {
    setMentionedUsers(mentions);
  }, []);

  const handleInput = useCallback((event) => {
    const textarea = event.target;
    const text = textarea.value;
    const caretPosition = textarea.selectionStart;

    const textBeforeCaret = text.slice(0, caretPosition);
    const lastAtIndex = textBeforeCaret.lastIndexOf("@");

    if (lastAtIndex !== -1) {
      const textAfterAt = textBeforeCaret.slice(lastAtIndex + 1);
      const hasSpaceAfterAt = /\s/.test(textAfterAt);
      const nextChar = text[caretPosition];
      const isAtEndOfName = nextChar === " " || nextChar === undefined;

      if (
        (!hasSpaceAfterAt ||
          (textAfterAt.trim().length > 0 && isAtEndOfName)) &&
        caretPosition > lastAtIndex
      ) {
        const searchText = textAfterAt.trim();
        setMentionSearch(searchText);
        setShowMentions(true);
        lastAtPositionRef.current = lastAtIndex;
      } else {
        setShowMentions(false);
      }
    } else {
      setShowMentions(false);
    }
  }, []);

  const handleSelectUser = useCallback(
    (selected, setText) => {
      if (lastAtPositionRef.current === null) return;

      setText((currentText) => {
        const before = currentText?.slice(0, lastAtPositionRef.current) || "";
        const after = currentText?.slice(lastAtPositionRef.current) || "";
        const remainingText = after.replace(/^@\S*/, "");

        const isCommunity = selected.isCommunity;
        const isStartup = selected.isStartup;
        const isEvent = selected.isEvent;
        const displayName =
          isStartup || isCommunity || isEvent
            ? selected.firstName
            : `${selected.firstName} ${selected.lastName}`.trim();
        const newText = `${before}${displayName}${remainingText}`;

        setMentionedUsers((prev) => ({
          ...prev,
          [displayName]: {
            ...selected,
            urlSlug: selected.urlSlug,
            isStartup,
            isCommunity,
            isEvent,
            startupOwnerId: selected.startupOwnerId,
            communityOwnerId: selected.communityOwnerId,
            eventOwnerId: selected.eventOwnerId,
            startupId: selected.id,
            communityId: selected.id,
            eventId: selected.id,
          },
        }));

        if (inputRef.current) {
          const newCursorPosition =
            lastAtPositionRef.current + displayName.length;
          setTimeout(() => {
            inputRef.current.selectionStart = newCursorPosition;
            inputRef.current.selectionEnd = newCursorPosition;
            inputRef.current.focus();
          }, 0);
        }

        return newText;
      });

      setShowMentions(false);
      lastAtPositionRef.current = null;
    },
    [inputRef]
  );

  return {
    showMentions,
    mentionSearch,
    handleInput,
    handleSelectUser,
    setShowMentions,
    mentionedUsers,
    initializeMentions,
  };
};
